// modules/Df/Stock/OutsourcingCount/F/Style/Pc/index.styl
.outsourcing-scanning-count
  padding 40px
  .search
    margin-top 20px
  .buttons
    margin-top 20px
    .download_excel
      width 41px
      height 36px
      display flex
      justify-content center
      align-content center
      box-sizing border-box
      border 1px solid #ddd
      border-radius 3px
      cursor pointer
  .outsourcing-scanning-count__table
    margin-top 20px
    width 100%
    .text-align-right
      text-align right
    thead
      tr
        border-bottom 1px solid #000000
    tbody
      tr
        border-bottom 1px solid #F2F2F2
        &.fast-track
          background-color #e8d600
    td
    th
      padding 8px
      &.outsourcing-scanning-count__spread_row
        cursor pointer
        &.active
          img
            transform rotateX(180deg);
        img
          width 24px
          height 24px

    .detail-tr
      display none
      &.active
        display table-row
        &:nth-child(even)
          background-color #F2F2F2
