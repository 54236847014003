html.font_manager
html.iamport_list
  #body
    margin 50px 100px 100px
    padding-top 50px
    .header
      > *
        margin-left 8px
    table
      border-spacing 0
      border-collapse collapse
      width 100%
      tr
        th
        td
          text-align center
          border 1px solid #000
          padding 20px
          &.th
            width 170px
          input
          textarea
            font-size 14px
          &.center
            text-align center
          &.url
            word-break break-all
          button
            width 40px
          label
            display block
            width 60px
            span
              margin-left 8px

