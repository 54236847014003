.don_tab[tab_name="/modules/Df/PrintTemplate/S/Mui/tab.js"]
  .printing_templates
    .header
      margin 3rem
    .body
      margin 3rem
      .controls
        text-align right
        margin-bottom 1rem
        button
          padding 0.5rem 1rem
          border-radius 0.3rem
          border none
          color white
          &[name="add_template"]
            background-color #1C77C3
          &[name="parse_metadata"]
            background-color #39A9DB
          &[name="pdf_thumbnails"]
            background-color #40BCD8

      .tabulator-row
        white-space nowrap
        background-color inherit !important
        border-bottom 1px solid #ddd
      .tabulator-cell
        a:hover
          color #0b5ed7
          text-decoration underline

        &[tabulator-field="base_product_ids"]
          .bp_ids
            display flex
            gap 0.8rem
            user-select none
            overflow-x scroll
            scrollbar-width none
            .bp_id
              display flex
              justify-content center
              gap 0.3rem
              background-color #ddd
              padding 0.2rem 0.8rem
              border-radius 0.3rem
              min-width 2.5rem
              &:hover
                color white
                background-color #d72626
        button
          color white
          width 1.6rem
          border-radius 0.3rem
          border none
          &[name="add"]
            background-color #24b358
            &:hover
              background-color #13813b
          &[name="delete_row"]
            background-color #ce1b44
            &:hover
              background-color #a11333
.swal2-html-container
  #printing_template_uploader
    display flex
    flex-direction column
    align-items flex-start
    gap 1rem
    .row
      display flex
      width 100%
      gap 0.5rem
      label
        flex-shrink 0
        background-color black
        width 6rem
        margin-right 0.8rem
        color white
        padding 0.5rem 1rem
        border-radius 0.3rem
      input
        flex-grow 1
        min-width 10rem
        font-size 1.2rem
        border 1px solid #ddd
        background-color #ddd
        border-radius 0.3rem
        padding 0 0.8rem
        text-align center
        &[type="number"]
          &::-webkit-inner-spin-button, ::-webkit-outer-spin-button
            -webkit-appearance none
        &::placeholder
          color #999
      .file_upload_input
        color dodgerblue
        display flex
        justify-content center
        align-items center
        flex-grow 1
        font-size 1.2rem
        border 1px solid #ddd
        background-color #ddd
        border-radius 0.3rem
        padding 0 0.8rem
        cursor pointer
        span
          user-select none
      .lang_select
        display flex
  #printing_template_connector
    .replace
      margin-bottom 1rem
      label
        color darkorchid
      input[type="checkbox"]
        scale 1.2
    textarea
      font-size 1.2rem
      padding 1rem
      background-color #eee
      border none

  #ai_file_metadata
    display flex
    gap 0.5rem
    flex-direction column
    justify-content flex-start
    font-size 1.2rem
    .row
      display flex
      gap 0.8rem
      label
        font-weight bold
        width 10rem
        background-color #ddd
        padding 0.2rem 0.3rem
        border-radius 0.3rem
      input
        padding 0.2rem 1rem
        font-size 1.1rem
        border none
        background-color #eee
        flex-grow 1
        border-radius 0.3rem

  #pdf_thumbnail_images
    display flex
    flex-direction column
    gap 1rem
    img
      object-fit contain
