pxToRem(value)
  unit(value/14, 'rem')
.companies-release-list.don_frame
  .companies-release-list-page
    .contents
      min-width 1400px
      max-width 1800px
      width 100%
      margin 0 auto
      display flex
      justify-self center
      flex-direction column
      padding 50px
      box-sizing border-box
      &__title
        flex-shrink 0
        align-self flex-start
        font-size 28px
        font-weight bold
        height 34px
        margin-bottom 24px
      .btn
        width 120px
        height 36px
        padding 9px 30px
        border-radius 4px
        background-color #3c89f9
        border none
        font-size 15px
        font-weight bold
        color #fff
        flex-shrink 0
        align-self flex-end

    .search
        display flex
        flex-direction column
        gap 20px
        background-color #f6f6f6
        flex-shrink 0
        padding 20px 40px
        border-radius 4px
        margin-bottom 50px
        hr
          background-color #f6f6f6
          width 100%

        &__company
          display flex
          align-items center
          flex-shrink 0
          &__name
            height 18px
            font-size 15px
            font-weight bold
            flex-shrink 0

          &__outsourcing-company
            width 200px
            height 28px
            padding-left 10px
            border-radius 4px
            border solid 1px #d5dbe0
            background-color #fff
            font-size 15px
            flex-shrink 0
            color #6a6a6a
            margin-left 22px

        &__wrap
          width 100%
          display flex
          justify-content flex-start
          align-items center
          flex-wrap wrap
          gap 10px 100px

          .search-date
            display flex
            flex-direction column
            gap 10px
            flex-shrink 0
            &__info
              display flex
              align-items center
              gap 8px
              flex-shrink 0
              &__name
                flex-shrink 0
                height 18px
                font-size 15px
                font-weight bold

              &__target-wrap
                display flex
                align-items center
                gap 6px
                flex-shrink 0
                &__from
                &__to
                  padding 5px 10px
                  border-radius 4px
                  border solid 1px #d5dbe0
                  background-color #fff
                  font-size 15px
                  flex-shrink 0


        .search-number
          display flex
          flex-direction column
          gap 10px
          flex-shrink 0
          &__info
            display flex
            align-items center
            gap 130px
            flex-shrink 0

            &__shipping-wrap
            &__product-wrap
              display flex
              align-items center
              gap 8px
              &__name
                flex-shrink 0
                height 18px
                font-size 15px
                font-weight bold
              &__input
                width 200px
                height 28px
                border-radius 4px
                border solid 1px #d5dbe0
                background-color #fff
                padding-left 5px
      .results
        & .result
          display flex
          flex-direction column
          gap 24px
          padding 24px
          border-radius 4px
          background-color #f6f6f6
          margin-bottom 50px
          &__info
            display flex
            gap 12px
            flex-wrap wrap
            & > .info-box
              padding 8px 16px
              border-radius 19px
              border solid 1px #3c89f9
              background-color #edf4ff
              font-size 14px
            &__divide
              margin-right auto
            &__delete-release-btn
              background-color black
            &__download-excel
              color #23a566
              width 120px
              height 36px
              padding 9px 10px
              display flex
              font-size 15px
              font-weight bold
              align-items center
              justify-content center
              line-height 1.54
              background-color white
              border-radius 4px
              border solid 1px #dddddd
              &.loading
                background linear-gradient(120deg, #fff 30%, #f0f0f0 38%, #f0f0f0 40%, #fff 48%)
                background-size 200% 100%
                background-position 100% 0
                animation load 1s infinite
                @keyframes load
                  100%
                    background-position -100% 0

          &__barcode-box
            border-radius 4px
            display flex
            justify-content space-between
            textarea
              width 96%
              border-color #ddd
          &__table-section
            margin-bottom pxToRem(10px)
            background #fff
            border 1px solid #ddd
            min-height pxToRem(400px)
            table
              border-collapse collapse
              width 100%
            thead
              th
                position sticky
                background-color #fff
                top 50px
                z-index 100
                font-size pxToRem(14px)
                font-weight 600
                border-bottom 1px solid #000
            tbody
              .important_projection_id
                color red
                font-weight bold
              a
                text-decoration underline !important
              .highlight
                background-color #ffff00
                border-radius 10px
              tr
                font-size pxToRem(14px)
                border-bottom 1px solid #ededed
              tr.no-line
                border-bottom unset
              tr:hover
                background-color #f5f9fe
            tr
              th
                height pxToRem(57px)
                text-align center
              td
                height 44px

            [data-key="주문번호"]
            [data-key="상품번호"]
            [data-key="상품명"]
            [data-key="색상"]
            [data-key="옵션"]
            [data-key="검수일"]
            [data-key="수량"]
              word-break: break-all
              white-space: nowrap
              text-align: center
              padding 0 pxToRem(20px)
              *
                word-break: break-all
                white-space: nowrap
                text-align: center
                padding 0 pxToRem(20px)
