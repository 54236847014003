html.base_product.list
  body.base_product.list
    #body
      margin-top 0 !important
      .mp_tbody
        .bp_td
          height 88px
          .thumb
            position relative
        li:hover
          background-color #eee
      .bp_td
        &[is_check="true"]
          .closed_color_count
          .closed_size_count
            background-color purple !important
      .id
        color #666
//.info
//  input
//    width 50px
body.base_product.editor
  select[name="warning_type"]
    width 100%
  .base_product_editor
    > .don_wrapper > .header
    .don_page[page_name="df.base_product_editor_page"] > .don_wrapper > .header
      display none
  .don_page[page_name="df.base_product_editor_page"]
    padding 50px 50px 50px
    box-sizing border-box
    width 1300px
    margin 0 auto
  .scroll_to
    position fixed
    top 50px
    left 0
    right 0
    text-align center
    background-color #ddd
    z-index 10
    span
      display inline-block
      padding 5px 10px
      margin 0 40px
      cursor pointer
  .header
    display flex
    align-items center
    gap 16px
    button
      padding 0 14px
      background-color #0c81c9
      font-size 24px
      height 38px
      border-radius 5px
      color ghostwhite
      border none
      cursor pointer
  #bp_editor
    .mp_color
      background-color #444
      color #fff
      padding 3px
      border-radius 3px
    .line_color
      background-color #00d664
      color #fff
      padding 3px
      border-radius 3px
    .creator_color
      background-color #1b77ff
      color #fff
      padding 3px
      border-radius 3px
    h2
    h3
      span
        font-size 12px
        font-weight normal
        color #e25453
        margin-left 10px
    .btn_edit_mode
      background-color #666
    .btn_edit_mode.on
      background-color #e25453
    .bp_info
    .seo_metas
    .bp_cate_list_filter
    .bp_additional_info
    .detail
    .bp_faces_editor
    .bp_sizes_editor
    .bp_colors_editor
    #bp_out_of_stock
      margin-top 30px
    .bp_info
    .seo_metas
      position relative
      padding 10px
      .btn_edit_mode
        position absolute
        top 10px
        right 0
      &[edit_mode="false"]
        .btn_add_gmc_url
        .btn_add_gmc_url_en
        .btn_add_gmc_url_jp
        .btn_brand_logo
        .btn_delete_brand_logo
        .options .create
        .options .save
        .options .copy_base_product
        .btn_add_nep_url
        .btn_add_tmpl_file
        .btn_size_info_url
        .btn_add_seo_img
          display none
      &[edit_mode="true"]
        border 1px solid #aa2222
      .brand_logo
        position relative
        .logo_area
          display inline-block
          min-height 170px
          input[type="file"]
            display none
        button
          color #fff
          margin 0 0 10px
          padding 5px 10px
          border-radius 5px
          background-color #333
          border 0 none
        .img
          position absolute
          top 0
          left 350px
          width 160px
          height 160px
          border 1px solid #ccc
          img
            position absolute
            top 0
            left 0
            right 0
            bottom 0
            margin auto
            max-width 150px
            max-height 150px
      .gmc_url
      .gmc_url_en
      .gmc_url_jp
      .seo_img
      .nep_url
      .size_info_url
        input[type="file"]
          display none
        button
          margin-right 10px
        .img
          position relative
          margin-top 10px
          width 160px
          height 160px
          border 1px solid #ccc
          img
            position absolute
            top 0
            left 0
            right 0
            bottom 0
            margin auto
            max-width 150px
            max-height 150px
      .tmpl_file_url
        input[type="file"]
          display none
        button
          margin-right 10px
        .tmpl_file_url_txt
          position relative
          margin-top 10px
          input
            border 1px solid #ccc
            background-color #eee
      .product_info
      .product_info_en
        min-height 300px
      > div:not(.options)
        border-bottom 1px solid #ddd
        padding-bottom 10px
        margin 10px 0 0
        &.name
          padding-top 10px
          border-top 1px solid #ddd
        a
          text-decoration underline
          font-weight 500
      label
        display inline-block
        margin-right 10px
        width 240px
        vertical-align top
        padding 5px 10px
        box-sizing border-box
      .editor_area
        width 800px
        display inline-block
      input[type="text"]
        width 70%
        padding 5px
        box-sizing border-box
      textarea
        width 70%
        height 100px
        padding 5px
        box-sizing border-box
      .options
        margin 20px 0
        button
          border 0 none
          padding 10px 20px
          margin-right 50px
          border-radius 5px
          color #fff
          font-weight 400
          cursor pointer
          &.btn_edit_mode
            margin-right 10px
          &.create
          &.save
            background-color #2f8bc7
          &.remove
            background-color #e25453
      .texture_pattern_url
        input[type="button"]
          margin-right 10px
      .bp_measure_editor
        p.warning
          margin-left 10px
          margin-bottom 10px
          color #777
        input
          outline none
        .volumetric_weight
          input
            background-color #ccc
            cursor default

    #bp_out_of_stock
      table
      tr
      td
        border 1px solid #ddd
        padding 3px 10px
      thead
        background-color #f5f5f5
    .bp_cate_list_filter
      position relative
      padding 20px 0
      border-top 1px solid #999
      border-bottom 1px solid #999
      &[edit_mode="false"]
        > *
          display none
        > h2
        > .options
          display block
      &[edit_mode="true"]
        border 1px solid #e25453
      .options
        button.btn_edit_mode
          position absolute
          top 15px
          right 3px
          border 0 none
          padding 10px 20px
          border-radius 5px
          color #fff
          font-weight 400
          cursor pointer
      .custom_filter_list[is_hide="true"]
        display none !important
      .bp_cate_list
      .bp_cate_item
      .bp_brand
        margin 20px 0
        label
          width 100px
          display inline-block
          vertical-align top
      ul.custom_filter_list
        margin-left 20px
        h3
          margin 20px 0 5px
        li.custom_filter_list
          ul.custom_filter_items
          ul.bp_tag_filters
            margin-left 10px
            li.custom_filter_item
            li.bp_tag_filter
              margin-right 10px
              display inline-block
    .bp_additional_info
      .ness_base_info
        margin-top 20px
        label
          margin-bottom 10px
          display block
          font-size 16px
          font-weight bold
    .detail
      position relative
      margin 100px 0
      padding 30px 0
      border-top 2px solid #333
      border-bottom 2px solid #333
      .detail_thumbnails
      .detail_tips
      .detail_tip_desc
      .bp_size_compare
        position relative
        margin 50px 0
        padding 30px 0
        &[edit_mode="false"]
          .options
          .copy_base_product_detail_tip_description
          .btn_delete
          .base_product_detail_tip_copy_btn
          .btn
            display none !important
        &[edit_mode="true"]
          padding-left 5px
          padding-right 5px
          border 1px solid #e25453
        .options_btn
          button.btn_edit_mode
            position absolute
            top 15px
            right 3px
            border 0 none
            padding 10px 20px
            border-radius 5px
            color #fff
            font-weight 400
            cursor pointer
      h2
        padding-bottom 10px
        border-bottom 1px solid #999
      h3
        margin-bottom 0
        padding-bottom 10px
        border-bottom 1px solid #ddd
      .options
        margin-bottom 10px
        padding 10px
        background-color #eee
        input[type="file"]
          width 0
          height 0
          visibility hidden
        button
          margin-right 10px
      > div
        margin-bottom 50px
      .bpd_thumb_area
        .list
          .item
            display inline-block
            position relative
            margin 0 5px 5px 0
            width 100px
            height 100px
            text-align center
            border 1px solid #eee
            img
              position absolute
              top 0
              left 0
              right 0
              bottom 0
              margin auto
              max-width 80px
              max-height 80px
            span.btn_delete
              display block
              position absolute
              top -4px
              right -4px
              background-color #fff
              width 16px
              height 16px
              cursor pointer
              line-height 18px
              align-items center
              justify-content center
              border-radius 8px
              border 1px solid #eee
      .bpd_tip_description
      .bpd_tip_description_en
        .editor
          height 300px
        .btn
          margin-top 10px
      .bp_size_compare
        width auto
        .list
          margin-top 10px
          .item
            display inline-block
            width 270px
            min-height 350px
            padding-top 20px
            margin-right 20px
            vertical-align top
            .image_area
              position relative
              height 300px
              margin-bottom 10px
              background-color #eee
              label
                position absolute
                top 0
                left 0
                right 0
                bottom 0
                cursor pointer
                z-index 5
                input[type="file"]
                  display none
                span.txt1
                  position absolute
                  top 0
                  left 0
                  right 0
                  padding 5px 10px
                  color #fff
                  font-weight bold
                  background-color rgba(0, 0, 0, 0.5)
              .img
                height 300px
              img
                position absolute
                top 0
                left 0
                right 0
                bottom 0
                margin auto
                max-width 100%
                max-height 100%
            .text1
            .text2
            .btns
              margin-top 10px
            input[type="text"]
              width 100%
              padding 5px 10px
              box-sizing border-box
              border 1px solid #aaa
              background-color #fff
    .bp_model_thumbs_area
      position relative
      &[edit_mode="false"]
        .options
        .btn_delete
        .btn
          display none !important
      &[edit_mode="true"]
        padding-left 5px
        padding-right 5px
        border 1px solid #e25453
      .options_btn
        button.btn_edit_mode
          position absolute
          top 15px
          right 3px
          border 0 none
          padding 10px 20px
          border-radius 5px
          color #fff
          font-weight 400
          cursor pointer
    .bp_model_thumb
      width auto
      .options
        input[type="file"]
          visibility hidden
      .thumb
        img
          max-width 300px
          max-height 300px
    .bp_model_thumb_en
    .bp_model_thumb_jp
      width auto
      .options
        input[type="file"]
          visibility hidden
      .thumb
        img
          max-width 300px
          max-height 300px
    .thumb_swiper_ex
      height 600px
      .swiper-container
        width 100%
        height 300px
        margin 0 auto
      .swiper-slide
        width 90%
        background-repeat no-repeat
        background-size contain
        background-position center
        background-color #eee
      .swiper-pagination
        display none
      .swiper_thumbnails_icon
        margin-top 20px
        height 50px
        text-align center
        .swiper-slide
          width 20%
          height 100%
    .bp_model_thumb
    .bp_model_thumb_en
    .bp_model_thumb_jp
    .infos
      position relative
      > div
        margin-bottom 50px
      h3
        margin-bottom 0
        padding-bottom 10px
        border-bottom 1px solid #ddd
      .options
        margin-bottom 10px
        padding 10px
        background-color #eee
        button
          margin 0 3px

    .bp_faces_editor
      position relative
      padding 15px 0
      &[edit_mode="false"]
        .options
        .save
        .copy_base_product_size
        .bpfe_opener
        .remove_safe_area_url
        .remove_shading_url
          display none
      &[edit_mode="true"]
        padding-left 5px
        padding-right 5px
        border 1px solid #e25453
      .options_btn
        button.btn_edit_mode
          position absolute
          top 15px
          right 3px
          border 0 none
          padding 10px 20px
          border-radius 5px
          color #fff
          font-weight 400
          cursor pointer
      .bp_size_table_editor
      .bp_size_table_editor_en
        position relative
        z-index 1
        .a-table-action-group
          display none
    .bp_face
      margin 0 5px 5px 0
      padding 5px
      display inline-block
      width 180px
      border 1px solid #ddd
      .file_area
        margin-bottom 5px

        &_wrapper
          display flex
          flex-direction column

        label
          display flex
          padding 5px 5px 0 5px
          min-height 50px
          cursor pointer
          background-color #eee
          border 1px solid #ddd
          border-bottom none
          flex-wrap nowrap
          justify-content space-evenly
          align-items center

          & .bp_option_layer_choice-delete
            color red
            padding 10px

          &.flex-row
            flex-direction row


          &.flex-column
            flex-direction column

        & .bp_option_layer_choice-img
          display flex
          flex-direction column
          gap 10px
          overflow-x auto
          overflow-y hidden
          padding 10px 0


        input[type="file"]
          width 1px
          visibility hidden

        .img
          background-color #eee
          text-align center
          min-height 84px


        img
          height 60px

      label
        display inline-block

        span
          display inline-block
          width 70px
          font-size 12px
          letter-spacing -1px
        input
          width 80px
          margin-left 10px
      button
        margin 0 4px
    .bp_sizes_editor
      position relative
      padding 20px 0
      &[edit_mode="false"]
        .options
        .save
        .save
          display none
      &[edit_mode="true"]
        padding-left 5px
        padding-right 5px
        border 1px solid #e25453
      .options_btn
        button.btn_edit_mode
          position absolute
          top 15px
          right 3px
          border 0 none
          padding 10px 20px
          border-radius 5px
          color #fff
          font-weight 400
          cursor pointer
    .bp_size_table_area
      position relative
      &[edit_mode="false"]
        .options
        .save
          display none
      &[edit_mode="true"]
        padding-left 5px
        padding-right 5px
        border 1px solid #e25453
      .options_btn
        button.btn_edit_mode
          position absolute
          top 15px
          right 3px
          border 0 none
          padding 10px 20px
          border-radius 5px
          color #fff
          font-weight 400
          cursor pointer
    .bp_sizes
      .bp_size
        padding 10px
        background-color #eee
        margin-bottom 10px
        > div
          padding 5px 0 0
        .check
          margin-bottom 10px
          label
            margin-right 30px
      label
        display inline-block
        margin-right 60px
        > span
          display inline-block
          width 100px
          margin-right 10px
        input[type="text"]
        input[type="number"]
          width 70px
      span.btns
        display inline-block
        button
          margin-right 5px
          &.save
            width 70px
            background-color greenyellow
    .bp_colors_editor
      position relative
      padding 20px 0
      &[edit_mode="false"]
        .options
        .save
        .btn_bp_color_thumb
        .btn_bp_color_thumb_delete
        .btn_bp_color_seamless_texture_url
        .btn_bp_color_seamless_texture_url_delete
        .remove_mask2_url
        .remove
          display none
      &[edit_mode="true"]
        padding-left 5px
        padding-right 5px
        border 1px solid #e25453
      .options_btn
        button.btn_edit_mode
          position absolute
          top 15px
          right 3px
          border 0 none
          padding 10px 20px
          border-radius 5px
          color #fff
          font-weight 400
          cursor pointer
      .bp_color
        .download_original
          text-decoration underline
          margin-left 5px
        position relative
        .info
          label
            margin-right 20px
      .bp_color_seamless_texture_url
        top 320px
        right 0
      .bp_color_thumb
        top 40px
        right 0
      .bp_color_seamless_texture_url
      .bp_color_thumb
        position absolute
        width 240px
        height 260px
        padding 10px
        box-sizing border-box
        text-align center
        border 1px solid #ccc
        .thumb
        .seamless_texture_url
          padding-top 20px
          img
            max-width 240px
            max-height 160px
        .input
          position relative
          padding-top 5px
          height 30px
          overflow hidden
          input[type="file"]
            position absolute
            top -3000px
            right -3000px
          button
            margin 0 5px
    .bp_colors
      padding 0
      margin 10px 0 50px
      .info
        margin-bottom 10px
        label
          display inline-block
          margin-right 5px
          > *
            margin-left 5px
          input[type="text"]
          input[type="number"]
            width 60px
        span.btns
          display inline-block
          button
            margin-right 5px
      .bp_color_faces
        min-height 280px
        margin-bottom 20px
        padding-bottom 10px
        border-bottom 1px solid #ddd
        .base_product_color_face_price_info
          margin-top 8px
          div
            margin-bottom 8px
          input
            width 60px
    .bp_color_face
      border 1px solid black
      display inline-block
      width 120px
      margin 0 10px 10px 0
      > div
        padding 5px
      h4
        margin 0
        padding 4px
      input[type=file]
        width 1px
        visibility hidden
      .file_area
        label
          display block
          height 130px
          cursor pointer
          position relative
          background-color #eee
          img
            max-width 100px
            max-height 100px
            position absolute
            right 0
            left 0
            bottom 0
            margin 0 auto



  #bpf_editor
    position fixed
    background-color #eee
    border 1px solid #000
    //top 20px
    //left 20px
    //right 20px
    //bottom 20px
    top 50px
    left 200px
    right 50px
    bottom 40px
    >div
      border 1px solid black
    .body
      >div
        border 1px solid blue
      .left
        float left
      .right
        float left
        input[type=number]
          width 70px
        .size_name
          font-weight bold
          font-size 17px
          padding 3px
        .size_name:hover
          background-color lightgrey
        [is_standard]>input
          background-color darkgrey
        ul.sizes
          list-style none
          padding 0px

    .size_editor
      background-color white
    .my_info
      background-color

#base_products_list
  margin 50px
  .df_cate_lists
    display flex
    align-items center
    li
      margin-right 10px
      font-size 16px
      a:hover
        text-decoration underline
        font-weight bold
  span.bp_sort
    margin-left 10px
  #cate_lists_wrap.cate_lists_wrap
    ul.brands
    ul.cate_lists
      margin 10px 0
    [is_public="false"]
      a
        color #888
    li.cate_list
      margin 0 0 30px
      > a
        text-decoration underline
        font-size 16px
    .brand
    .cate_item
      margin 10px 0
      list-style initial
      display inline-block
      vertical-align top
      margin-left 20px
  h3
  h4
    margin 4px 0
  #search
  #is_use_line
  #cate_lists
    margin-bottom 16px
    .cate_items
    .is_use_lines
      .cate_item
      .is_use_line_item
        display inline-block
        vertical-align top
        margin-right 24px
  #filters
    .filter_lists
      .filter_list
        margin 0 8px 8px 0
        display inline-block
        vertical-align top
        .filter_items
          max-width 280px
          max-height 90px
          padding 10px 0 2px
          overflow scroll
          border 1px solid #ccc
          .filter_item
            width 120px
            display inline-block
            vertical-align top
            margin 0 10px
            margin-bottom 8px
  #base_products_wrap
    ul.bp_cate_lists
      margin 10px 0
      padding-bottom 20px
      li.bp_cate_list
        padding 50px 0 10px
        font-size 16px
        font-weight 400
        >span
          font-size 16px
        .bp_cate_item
          margin 30px 0
    .bp_td
      display none
      &.show
        display table-row
      > div
        padding 0
        vertical-align middle
        text-align center
        &.name
          text-align left
          padding-left 16px
        &.product_info
        &.product_info_en
          width 180px
          font-size 11px
      .thumb
        padding 0
        line-height 0
        width 100px
        font-size 0
        img
          font-size 0
          width 100px
      .omp-renewal
        padding 0
        line-height 0
        width 100px
        font-size 0
        background red
        img
          font-size 0
          width 100px


.don_frame[frame_name="df.base_product.sort"]
  .base_products
    .base_product
      display inline-block
      vertical-align top
      width 100px
      height 230px
      margin 10px
      cursor move
      .img
        img
          width 100%
      .info
        font-size 10px

.sku-table-container
  display flex
  flex-direction column
  h1.title
    margin-bottom 0
    background-color black
    padding 12px
    border-radius 99px
    color white

  .controls
    display flex
    align-items center
    justify-content center
    gap 24px
    background-color #ccc
    padding 12px
    margin-bottom 22px
    .filters
      display flex
      gap 16px
      .filter
        display flex
        align-items center
        user-select none
        input[type=radio]
          width 18px
          height 18px
          margin-bottom 3px
          cursor pointer
        label
          cursor pointer
    .buttons
      button
        cursor pointer
        border 0
        border-radius 0.25em
        background initial
        color #fff
        font-size 1em
        padding 5px 10px
        &[name="renew-sku"]
          background-color #1468a4
        &[name="update-sku-supplier"]
          background-color #c93e0c
        &[name="download-excel"]
          background-color #1e8f04
        &[name="upload-excel"]
          background-color #e30fe0

        &[disabled]
          background-color #a4bccb
  .tabulator-cell[tabulator-field="sku"]
    font-weight bolder


.d-none
    display none
