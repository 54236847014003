// modules/Df/OMP/Seo/F/Style/Pc/index.styl
.don_tab[tab_name='/modules/Df/OMP/Seo/S/Mui/tab.js']
  .don_wrapper
    width 50%
    min-width 1400px
    margin auto
    padding-bottom 50px
  .meta_wrapper
    background #dbdbdb
    padding 20px
    margin 30px 0
    border-radius 4px
  .table_wrapper
    table, td
      border 1px solid black
      border-collapse collapse
      text-align center
    table
      width 100%
      td
        input
          width 90%
          height 50px
    tbody
      background white
      > tr
        height 60px
      input
        border none
      img
        height 70px
  .meta[data-type='cate_list']
  .meta[data-type='cate_item']
    > .meta__type_value
      display none
  .meta
    &__description
      width 600px
    &__og_img
      width 200px
  .lang
    display flex
    gap 10px
    font-size large
    &__selected
      color blue
  .cate_list_name
    font-size large
    padding-left 10px

