order-table-header =
  display flex
  font-size 14px
  font-weight bold
  box-sizing border-box
  border-bottom 1px solid #d5dbe0
  >span
    display flex
    align-items center
    justify-content center
    height 48px

html#dream_factory
  .df_order_editor_frame
    position fixed
    > .don_wrapper
      > .header
        border-bottom 1px solid #ccc
        .title
          padding-top 20px
          font-size 18px
          font-weight bold
          text-align center
    .df_order_editor
      position relative
      &[has_task="true"]
        .task_ids
          display block
        .list table
          th.projection_count
          td.projection_count
            display table-cell
      .options
        position relative
        margin 10px
        padding 10px 15px
        text-align left
        background-color #eee
        input[name="name"]
          margin-right 10px
          padding 5px
          width 400px
        button
          position absolute
          top 12px
          right 10px
      .task_ids
        display none
        position relative
        margin 10px
        padding 10px 15px 0
        border 1px solid #ccc
        h3
          position absolute
          top 12px
          left 15px
          margin 0
          padding 0
        ul
          margin 0 0 0 120px
          padding 0
          list-style none
          li
            display inline-block
            margin 0 10px 10px 0
            padding 5px 10px
            font-size 12px
            background-color #eee
            border-radius 4px
      .list
        margin 30px
        table
          width 100%
          text-align center
          border-collapse collapse
          th.projection_count
          td.projection_count
            display none
          tr
            &:first-child
              td
              th
                background-color #bbb !important
            &:hover
              td
              th
                background-color #eee
          th
          td
            padding 5px 10px
            border 1px solid #ccc
          td.bp_name
            text-align left
        input[type="text"]
        input[type="number"]
          text-align center



html.order_list
  .order_list_area
    padding 50px
    h2
      margin 0 0 20px !important
      padding 0
    .options
      position relative
      width 100%
      margin-bottom 20px
      select,
      input
        margin-right 10px
      input
        width 400px
      .btns
        position absolute
        top 0
        right 0
        button
          margin-left 10px
    table
      border-spacing 0
      border-collapse collapse
      width 100%
      text-align center
      thead
        td
        th
          padding 10px
          background-color #bbb
          border 1px solid #000
      tbody
        tr
          &:hover
            td
            th
              background-color #eee
        th
        td
          border 1px solid #000
          padding 10px
        td.bp_name
          text-align left
    .btn_order_detail
      display inline-block
      padding 8px 16px
      background-color #eee
      border-radius 3px
    .don_pagination
      margin-top 30px

html.order_detail
  .df_order_detail_frame
    .df_order_detail
      padding 50px
      h2
        margin 0 0 20px !important
        padding 0
      .name
        padding 10px
        border-top 1px solid #ccc
        font-size 14px
        input[name="name"]
          padding 5px
          width 300px
        button
          margin 0 5px
      .info
        margin-bottom 20px
        padding 10px
        border-top 1px solid #ccc
        border-bottom 1px solid #ccc
        font-size 14px
        span.status
          font-size 16px
          font-weight bold
        span
          display inline-block
          margin-right 30px
      .options
        position relative
        width 100%
        margin-bottom 20px
        .btns
          text-align right
          button
            margin-right 0
            margin-left 10px
          form
            display inline-block
        button
          margin-right 0
          padding 10px 20px
          border-radius 3px
          &.btn_order_delete
            position absolute
            top 0
            left 0
            background-color #e25453
            border 0 none
            color #fff
      .list
        margin 0 0 30px
        table
          width 100%
          text-align center
          border-collapse collapse
          tr
            &:first-child
              td
              th
                padding 10px
                background-color #bbb !important
            &:hover
              td
              th
                background-color #eee
          th
          td
            padding 8px 10px
            border 1px solid #ccc
          td.bp_name
            text-align left
            width 130px
        input[type="text"]
        input[type="number"]
          width 100px
          text-align center
        input[name="add_order_count"]
        input[name="add_arrival_count"]
        input[name="add_cancel_count"]
        input[name="cancel_count"]
          width 40px
        button
          margin 0 5px
          border-radius 3px
          background-color #eee
          border 1px solid #eee
        .btn_order_item_delete
          background-color #e25453
          border 1px solid #e25453
          color #fff

.df_ready_order_list_frame
  position fixed
  > .don_wrapper
    > .header
      border-bottom 1px solid #ccc
      .title
        padding-top 20px
        font-size 18px
        font-weight bold
        text-align center
    .df_ready_order_list
      padding 50px




html.print_receipt
  #header
    display none
  .info
    margin 10px 0 30px
    table
      width 100%
      border-collapse collapse
      th
      td
        padding 5px 2px
        vertical-align middle
        border 1px solid #999
      .seal
        margin-left 10px
        width 40px
        vertical-align middle
      .total
        td
          &.total_price
            font-size 16px
            font-weight bold
            text-align right
  .print_receipt_area
    padding 0 40px
    box-sizing border-box
    width 1100px
    input[type="text"]
      border 0 none
      background transparent
    .btn_print_area
      padding 10px 0
      text-align center
      background-color #eee
      @media print
        display none
    .item_body_wrap1
      display flex
    .up_c_item
      position relative
      padding 24px 0
      border-top none !important
      .thumb
        display inline-block
      .item_body_wrap2
        position relative
        &[is_wrote="true"]
          .product_price
            position absolute
            bottom 0
            right 0
        &[is_review="true"]
          .product_price
            position absolute
            bottom 8px
            right 0
      &:not(:last-child)
        border-bottom solid 1px #d5dbe0
    .up_c_list
      margin-top 16px
      border-top 1px solid #000000
      border-bottom 1px solid #d5dbe0
      .item_body_wrap1
        width 100%
        align-items center
      .table_header
        {order-table-header}
        span:nth-child(1)
          width 480px
          margin-right 32px
        span:nth-child(2)
          width 224px
          margin-right 32px
        span:nth-child(3)
          width 224px
      .up_c_info
        position relative
        display flex
        align-items center
        justify-content space-between
        width 100%
        .faces
          > *
            display inline-block
        .origin
          text-decoration line-through
          font-weight 300
          text-align right
          color color_gray3
        .price
        .product_price
          color color_black
          .discount_price
            margin-right 8px
            color #ea291f
          .origin_price.discounted
            text-decoration line-through
            color color_gray3
      .title_and_faces
        {flex-left-center}
        flex-direction column
        width 400px
        height 100%
        display flex
        .title
          line-height 1.5
          a
            span
              margin-right 6px
            &:hover
              text-decoration underline
              &:after
                background #eee
            &:after
              display inline-block
              position relative
              cursor pointer
              border 1px solid #d5dbe0
              padding 0px 4px
              font-size 12px
              content '재주문'
      .sizes
        display flex
        align-items center
        justify-content flex-start
        flex 1
        gap 6px
        .up_c_s_list
          flex 1
          display flex
          align-items center
          justify-content flex-start
          .up_c_s_item
            .size
            .quantity
              display inline-block
        .quantity
          font-size 14px
          {flex-center-right}
          >span.qty
            display none
          >span.val
            background-color #ffffff
      .product_price.pc_v
        padding-right 20px
        display flex
        flex-direction column
        align-items flex-end
        justify-content center
        gap 6px




