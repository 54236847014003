html[mobile_possible="true"]
  .don_frame[frame_name="df.review.list"]
    padding-top 0 !important

#df_review
  width 992px
  margin 0 auto
  >.header
    .langs
      display none
      &[collabo_type="mp"]
        display block
      ul
        margin 0
        padding 0
      li
        list-style circle
        display inline-block
        margin-right 10px
        a[selected="true"]
          color blue
          text-decoration underline
  .review_title
  .cate_list_selector_wrap
      display none
  .comment_wrap.comment_wrap2
    html.df_review_list:not(.line):not(.creator) &
      display none
  .review_item_header
    display flex
    height 80px
    border 1px solid #000
    border-bottom-width 0
    border-top-width 0
    > .item
      padding 0 16px
  .admin_review
    z-index 1
    display none !important
    &.is_not_public
      flex-direction column
    &:not(.is_not_public)
      h4
        flex-basis 30px
        margin-bottom 0
      .up_info
        display grid
        grid-template-columns repeat(2, 1fr)
        grid-template-rows repeat(2, 1fr)
        gap 8px
        margin 16px 0
        width 300px
        .cell
          display flex
          gap 8px
          .title
            font-weight 700
            flex-basis 90px
            text-align end
          .value
            flex-basis 50px
    html.review.list[review_policy="true"] &
      display flex !important
      @media screen and (max-width 576px)
        display flex !important
        flex-direction column
        .admin_review_item
          border-right-width 1px
          border-bottom-width 0
          flex-basis unset !important
        button.is_public
          margin 0 0 16px 0
      @media screen and (min-width: 577px) and (max-width 1024px)
        display grid !important
        grid-template-columns repeat(2, 1fr)
        .admin_review_item
          border-bottom-width 0
          flex-basis unset !important
          &:nth-child(1)
            order 1
          &:nth-child(2)
            order 3
          &:nth-child(3)
            order 4
            border-right-width 1px
          &:nth-child(4)
            order 2
          &.txt.reply
            > div
              display flex
              flex-direction column
              textarea
                width 100%
                box-sizing border-box
              button.reply
                width 100%
          button.is_public
            margin 0 0 16px 0
    .admin_review_item
      flex-grow 1
      border 1px solid #000
      border-right-width 0
      display flex
      flex-direction column
      justify-items center
      align-items center
      &:last-child
        border-right-width 1px
      &:first-child
        flex-grow 0
        flex-basis 209px
  .review_item
    .user_name
      cursor pointer
      text-decoration underline
    .admin_review > .points
      button
      input
        background 0 0
        padding 0 6px
        height 30px
        vertical-align top
        border 1px solid #000
      input
        width 50px
        padding 0 10px
        height 28px
        text-align right
      .notice
        vertical-align middle
        display inline-block
        padding 0 10px
        border solid 2px red
        color #fc5926
        font-size 17px
        line-height 34px
        height 34px
        text-align center
        margin-right 10px
    .admin_review > .buttons
      >button
        width 38px
        border 0
        background-color transparent
        padding 0
        margin 0
        &.is_public
          margin 16px 0
          height 38px
          background url(//s3.marpple.co/files/u_18050/2018/10/original/f_6295_1540782511635_MD9TwirXVcD0hctSb.png)
          &[is_public="false"]
            background url(//s3.marpple.co/files/u_18050/2018/10/original/f_6296_1540782511635_g6hO0KnDCoZU1LsIV6p.png)
        &.is_best
          margin-left 8px
          height 48px
          background url(//s3.marpple.co/files/u_18050/2018/10/original/f_6297_1540791824919_eVQ1H5FQzmGc8p8wVG3v.png)
          &[is_best="true"]
            background url(//s3.marpple.co/files/u_18050/2018/10/original/f_6298_1540791824919_D8R3ZGLfTB0NdWxf1xN.png)
    .admin_review .txt
    div.comment_en
    div.comment_jp
      textarea
        border 1px solid #000
        padding 2px
        width 80%
        height 41px
        font-size 14px
      button
        vertical-align top
        height 47px
        border 1px solid #000
        background #fff
        padding 2px
        width 15%
  @media screen and (max-width 1024px)
    width auto
    >.header
      margin 0 16px
      .langs
        li
          display block
          margin-bottom 8px
          a.hide
            display none
