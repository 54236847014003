[frame_name="url_mask_url_modal"]
  .don_tab .don_wrapper
    text-align center
    transform scale(0.4)
    transform-origin top center
    .title
      font-size 30px
  canvas
    border 3px solid red
    display inline-block
  .mask_url_canvas
  .shading_url_canvas
    canvas
      background grey
[frame_name="@FRAME/DF/BaseProductColorFaceImage/list"]
  .name
    text-align center
  padding 20px
  max-width 1400px
  margin 0 auto
  .base_product_colors
    display flex
    flex-wrap wrap
    .base_product_color
      margin-right 20px
  .base_product
    margin-bottom 20px
    a
      color blue
      text-decoration underline
  .base_product_color_faces
    display flex
    margin-bottom 10px
    .base_product_color_face
      margin-right 10px
      display flex
      flex-direction column
      align-items center
      .canvas
        width 300px
        height 300px
        position relative
        display none
        canvas
          max-width 100%
          max-height 100%
          &.lower-canvas
          &.upper-canvas
            display none !important
          top 50%
          left 50%
          transform translate(-50%, -50%)
          position absolute
          border 1px solid red
  .image_wrapper
    display none
    img
    canvas
      max-width 300px
      max-height 300px
      width auto
      height auto
      border 1px solid red
    &.mask_url
    &.shading_url
      img
      canvas
        background grey
