html.we-stats
  .don_page
    .month
      font-size 20px
      font-weight bold

  .options
    display flex
    justify-content space-between
    margin-bottom 40px

  .btn.btn-blue
    border-color #3c97fe
    background #3c97fe
    color #fff
    display: inline-block;
    font-weight: 400;
    cursor: pointer;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid;
    padding: .438rem 1.125rem;
    line-height: 1.54;
    border-radius: .15rem;
    transition: all .2s ease-in-out;
    border-color: rgba(24,28,33,0.1);
    margin: 0 3px;

  .empty
    margin-top 200px
    display flex
    justify-content center
    font-size 20px
    color gray

  .we_stats
    margin 50px 30px 0
    padding-bottom 50px

  .chart
    .total
      font-weight bold

    .gray
      color #a1a1a1