.don_frame[frame_name="/modules/Df/Outsourcing/Company/Manager/S/Mui/frame.js"]
  a
    text-decoration underline
  .outsourcing-company-manager__price-set
    margin-bottom 20px
    &__title
      font-size 20px
      padding 8px
    &__body
      padding 8px
      border 1px solid black
      &__prices
        margin 3px 0
    label
      padding-right 10px
    &__base_product_quantity_discounts
      margin-bottom 30px
      margin-left 30px
    span
      margin-left 30px
  .outsourcing-company-manager__base-product-group
    input[name="bp_ids"]
      width 70%
  .outsourcing-company-manager
    padding 16px
    &__section
      border 1px solid black
      padding 8px
      margin-top 30px
      &__title
        border-bottom 1px solid black
        padding 8px
        font-size 20px
      &__body
        border 1px solid black
        padding 8px
        form
          padding 8px
          margin-bottom 8px
          &:last-child
            margin-bottom 0
        .table-bp-name
          width 400px !important
        table
          border: 1px solid #444444;
          border-collapse: collapse;
          td
          th
            border: 1px solid #444444;
            padding: 10px;
            width 130px
            text-align center

