.don_frame[frame_name="/modules/Df/Lgl/ProjectionPriceList/S/Mui/frame.js"]
  height calc(100vh-101px)
  .don_wrapper
    >.header
      width calc(100vw - 128px)
      margin-top 51px
      padding 0 64px
  .don_tab[tab_name="/modules/Df/Lgl/ProjectionPriceList/S/Mui/tab.js"]
    width calc(100vw - 128px)
    height calc(100vh - 101px)
    padding 0 64px
    >.don_wrapper
      padding-top 32px
      .lgl-projection-price-list-container
        padding 2rem
        
        .button-group
          display flex
          gap 1rem
          justify-content center
          align-items center
          
        .action-button
          position relative
          display flex
          align-items center
          padding 1rem 2rem
          border-radius 12px
          cursor pointer
          transition all 0.3s ease
          background linear-gradient(135deg, #ffffff 0%, #f5f5f5 100%)
          border 1px solid rgba(0, 0, 0, 0.1)
          box-shadow 0 2px 8px rgba(0, 0, 0, 0.05)
          
          &:hover
            transform translateY(-2px)
            box-shadow 0 4px 12px rgba(0, 0, 0, 0.1)
            
          &:active
            transform translateY(0)
            box-shadow 0 2px 4px rgba(0, 0, 0, 0.05)
          
          .button-content
            display flex
            align-items center
            gap 0.75rem
            color #333
            font-weight 500
            
          .icon
            width 24px
            height 24px
            
          .button-text
            font-size 1rem
            white-space nowrap
            
          &.download
            background linear-gradient(135deg, #4CAF50 0%, #45a049 100%)
            .button-content
              color white
              
          &.upload
            background linear-gradient(135deg, #2196F3 0%, #1e88e5 100%)
            .button-content
              color white
            
          .file-input
            position absolute
            width 0
            height 0
            opacity 0
            
        .action-button:hover .icon
          animation bounce 0.5s ease infinite
          
      @keyframes bounce
        0%, 100%
          transform translateY(0)
        50%
          transform translateY(-3px)