color_black = #000
color_gray1 = #333
color_gray2 = #666
color_gray3 = #868e96
color_gray4 = #a5aab0
color_active = #0157ff
color_black066 = #000066
color_black0cc = #0000cc

flex-center-between =
  display flex
  align-items center
  justify-content space-between

flex-center-right =
  display flex
  align-items center
  justify-content flex-end

flex-top-left =
  display flex
  align-items flex-start
  justify-content flex-start

flex-center-left =
  display flex
  align-items center
  justify-content flex-start

flex-center-center =
  display flex
  align-items center
  justify-content center

create_color = #2f8bc7

jip-don_page_head =
  >.head
    background #ebebeb
    display grid
    grid-gap 20px
    padding 10px 0
    width 100%
    justify-items center
    > div
      text-align center
      padding 5px 0
      font-size 16px
    >.title
      font-weight 800
    >div:not(.title)
      border-radius 8px
      color #fff
      width 105px
      cursor pointer
    >.delete
    >.delete_task
      background #e25453
    >.cancel
      background #adadad
    >.close
      background #424140
    >.close_all
      background #424140
      visibility hidden
      width 130px !important
    >.close, >.done
      width 70px !important
    >.create, >.done, >.create_task
      background-color #2f8bc7

i_plus = url("//s3.marpple.co/file/guest/2018/2/original/f_1656_1518152961135_TZW9pZVTRLJ0fqb3s8b.svg");
