body.settlement
body.settlement_youtube
  #body
    html.settlement &
    html.settlement_youtube &
      padding 60px
    div.settlement
      .table
        overflow auto
      .collabo-type
        span
          margin 0 10px
          font-size 16px
          color #000
        a
          text-decoration underline
          span
            color #666
      >.header
        text-align center
        h2
          display inline-block
          margin 12px 10px
          line-height 50px
          padding 0
          font-size 34px
          font-weight normal
        .settlement_collabo_selector
          margin 10px 0 20px
          a
            margin 0 10px
        .teams
          margin 24px
          a
            margin 0 5px
            font-size 16px
            &[selected="true"]
            &:hover
              color #fc5926

      >.body
        .detail_header
          text-align center
          padding 30px 0
        .category_navi
          .items
            margin-bottom 16px
            .item
              display inline-block
              vertical-align top
              margin-right 8px
              &[selected="true"]
                color #0e3a95
        .bps-menu
          display flex
          flex-direction row-reverse
          justify-content center
          margin 0rem 0rem 2rem
          .export-all-btn
            padding 8px 12px
            border-radius 4px
            color white
            background-color #222
            border 0
            margin-bottom 1rem
        .bps
          font-size 12px
          .bp
            .bp_name
              font-size 16px
              font-weight 600
              margin-bottom 8px
              .total_count
                margin-left 8px
                color #16a495
                font-weight 400
                font-size 18px
                html[lang="en"] &
                  font-family CircularStd
            .bp_table_container
              width 100%
              overflow-x auto
              position relative
              margin-bottom 3rem
              .copy-table-btn
                padding 4px 8px
                border-radius 4px
                color white
                background-color #222
                border 0
                margin-bottom 8px
            .bp_table
              width 100%
              position relative
              border-collapse collapse
              thead
                color black
                font-weight 500
              th,
              td
                border 1px solid #ddd
                padding 8px
                text-align center
              th
                background-color #f2f2f2

              tbody
                tr:nth-child(even)
                  background-color #f9f9f9
                tr:hover
                  background-color #f5f5f5

            .fixed-column
              position sticky
              left 0
              background-color #f2f2f2
              z-index 1
              border 1px solid #ddd
              padding 8px 24px !important

            .title
              .color
                width 10px
                height 10px
                display inline-block
                margin-right 5px
                border 1px solid #ccc
                vertical-align middle
              .name
                display inline-block
                vertical-align middle

            .count[count="0"] 
              color #999

            .bp_c_s[is_not_stock="true"]
              background-color #ffeeee

            .sizes
              position absolute
              top 0
              left 0
              bottom 0
              width 110px
              z-index 1
              background-color #fff
              .size
                padding 7px 10px
                border 1px solid #000
                margin-bottom -1px
                height 16px
            .bp_cs
              padding-left 110px
              white-space nowrap
              overflow-y hidden
              overflow-x auto
              padding-bottom 20px
              .bp_c
                display inline-block
                text-align center
                vertical-align top
                .bp_c_ss
                  .bp_c_s
                    padding 7px 10px
                    border 1px solid #000
                    margin-left -1px
                    margin-bottom -1px
                    height 16px
                    .count[count="0"]
                      color #888
                    &[is_not_stock="true"]
                      background-color #ffcaca
                    .color
                      width 10px
                      height 10px
                      display inline-block
                      vertical-align middle
                      border 1px solid #ccc
                      margin-right 4px
                    .name
                      display inline-block
                      vertical-align middle
        .date_picker
          form
            .anohter
              margin 16px 0
              display flex
              align-items center
              justify-content center
          input[type="text"]
            width 90px
            font-size 14px
            text-align center
            border-radius 4px
            border 1px solid #d5dbe0
            padding 4px 8px
          button
            padding 4px 8px
            border-radius 4px
            color #fff
            background-color #222
            border 0
            margin-left 4px
          span
            margin 0 5px
        .sales_summary
          display flex
          .category_navi
            border-right 1px solid #ccc
            width 380px
            flex-shrink 0
            .navi-fixed
              position fixed
              width 350px
              overflow auto
              top 0
              bottom 0
              left 0
              background #fff
              padding 110px 30px 40px 60px
            .navi_wrap
              display flex
              flex-direction column
            table
              border none
              &:not(:first-child)
                margin-top 12px
              *
                border none
              .category, .category_detail
                .name
                  text-align left
                  a:hover
                    color #0e3a95
                .quantity
                  text-align right
              .category
                & > *
                  font-size 16px
                .quantity
                  color #0e3a95
              .category_detail
                & > *
                  font-size 14px
                .name
                  padding-left 24px
          .contents
            display flex
            flex-direction column
            width calc(100% - 240px)
            margin-left 30px;

            &[is_sale_count="true"]
              .name
              .quantity
                &[quantity="0"]
                  display none !important
            h2
              text-align center
              margin 60px 0 0
              line-height 50px
              font-size 34px
              font-weight normal
            .category, .category_detail
              margin-bottom 12px
              & > .name
                display inline-block
                margin-bottom 12px
                color #0e3a95

            .category_detail
              padding-top 60px
              .name
                font-size 16px
                font-weight 600
            .products
              width: 100%;
              .name
              .quantity
                font-weight 400
                font-size 14px
                &[quantity="0"]
                  color #888
              .name
                text-align left
              .quantity
                text-align right



        table, td, th, tr, thead, tbody
          border solid 1px #cfcfcf
          font-size 12px
        table
          border-collapse collapse
          border-spacing 0
          thead
            background-color #3ac5bb
            color #ffffff
            th, td
              background #16a495
            [mp_worker_policy="false"]
              background-color #444
              color #fff
          th, td
            padding 7px 10px
            text-align center
            white-space nowrap
          tr.result
            border-top 1px solid #cfcfcf
          .seller
            z-index 1
            .seller_wrap
              cursor zoom-in
              .sellers
                border 1px solid #000
                background-color #fefefe
                position absolute
                display none
                img
                  border 1px solid #000
                  margin 7px
          td.seller:hover
            .seller_wrap
              .sellers
                display block
.productivity-group
  margin 0 auto
  width 95%
  padding 0 20px 50px
  button
    margin-right 2px
    border-radius 2px
    background #f2f2f2
    border 1px solid #ccc
    &.insert-btn
      border 1px solid #0055aa
      background #257acf
      color #fff
    &.delete-btn
      border 1px solid #5e0000
      background #a00
      color #fff
      font-size 12px
  &__list
    display flex
    flex-wrap wrap
    margin-top 20px
    font-size 16px
  &__item
    width calc(20% - 10px)
    margin-right 10px
    margin-bottom 10px
    box-sizing border-box
    padding 10px
    border 1px solid #ddd
    position relative
    &:hover
      .productivity-group-user
        display block
  &__title
    font-size 18px
    font-weight bold
  &__header
    display flex
    align-items center
    justify-content space-between
  &__title
    margin-right 8px
    display flex
    align-items center
    justify-content space-between
    .productivity-group__anchor
      text-decoration underline
      margin 0 4px
      font-size 16px
.productivity-group-user
  font-size 13px
  display none
  &__list
    display flex
    flex-wrap wrap
  &__info
    margin-bottom 4px
    position relative
  &__item
    display flex
    flex-wrap wrap
    align-items center
    justify-content space-between
    margin 8px 8px 0 0
    width calc(50% - 8px)
  &__name
    margin-right 4px
    &--no-worker
      color #a00
.add-productivity-group-user
  position absolute
  top 0
  left 50%
  transform translateX(-50%)
  padding 20px
  width auto
  box-shadow 0 0 20px 000
  background #fff
  z-index 1
  &__selects
    margin-bottom 10px
    select
      display block
      font-size 15px
      margin-right 4px
      margin-bottom 4px

.image-job
  padding 60px
