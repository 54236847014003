// modules/Df/Stock/DeliveryScanning/F/Style/Pc/index.styl
@import "../../../modules/Df/Task/DeliveryScanning/ModePop/F/Style/Pc/index.styl"
.important-df-scanning-pop
  font-size 32px;
.df-scanning__container
  height 100vh
  width 100vw
  box-sizing border-box
  margin-top -50px
  padding 70px 20px 20px
  display flex
  animation focusing-import-export 0.8s alternate infinite
  .cancel-mode
  &.cancel-mode
    animation focusing-cancel 0.8s alternate infinite
  &.focus-out
    color #474847 !important
    animation out-focusing-import-export 3s alternate infinite

    .df-scanning__a-side
    .df-scanning__b-side
    .df-scanning__body
      background-color #808080
      color #474847 !important
      & > section
        .label_counters
          .counter
            color #474847
      .projection-warp
        .wrap
          &.success
          &.error
            color #474847
  div
    box-sizing border-box
  .df-scanning__a-side
  .df-scanning__b-side
    width 10%
    padding 0 10px
    background-color white
    display flex
    justify-content center
    align-items center
    flex-direction column
    .button-type
      width 100%
      display flex
      justify-content center
      align-items center
      flex 1
      font-size 1vw
      cursor pointer
  .btn-3d
    position relative
    font-size 1.8rem
    padding 20px 20px
    color white
    margin 10px 10px 30px
    border-radius 6px
    text-align center
    transition top .01s linear
    text-shadow 0 1px 0 rgba(0,0,0,0.15)
    border none
    &.import
      background-color #4f504f
      box-shadow 0 0 0 1px #474847 inset,
      0 0 0 2px rgba(255,255,255,0.15) inset,
      0 8px 0 0 #383a38,
      0 8px 0 1px rgba(0,0,0,0.4),
      0 8px 8px 1px rgba(0,0,0,0.5)
    &.fullscreen
    &.cancel
    &.complete
      background-color #c5c5c5
      box-shadow 0 0 0 1px #b4b3b3 inset,
      0 0 0 2px rgba(255,255,255,0.15) inset,
      0 8px 0 0 #383a38,
      0 8px 0 1px rgba(0,0,0,0.4),
      0 8px 8px 1px rgba(0,0,0,0.5)
      &:active
      &.active
        animation focusing-cancel 0.8s alternate infinite

    &.active
      top 9px
      background-color #e74c3c
      box-shadow 0 0 0 1px #c63702 inset,
      0 0 0 2px rgba(255,255,255,0.15) inset,
      0 0 0 1px rgba(0,0,0,0.4) inset
  .df-scanning__body
    flex 1
    width 80%
    height 100%
    display flex
    align-items center
    justify-content center
    flex-direction column
    box-sizing border-box
    background-color white
    & > label
      &.title
        font-size 5vw
        font-weight bold
        span:first-child
          display inline-block
        span:last-child
          display none
        &.cancel
          span:first-child
            display none
          span:last-child
            display inline-block
    & > section
      width 100%
      flex 1
      display flex
      justify-content center
      align-items center
      box-sizing border-box
      flex-direction column
      margin-top -10%
      .export_to_delivery
        font-size 20px
        display flex
        align-items center
        input[type="checkbox"]
          width 20px
          height 20px
      .full-page
        margin-top 0
      .label_counters
        user-select none
        z-index 99
        span
          font-size 20vw
          transition font-size 0.5s
          &.fail
            color #fb8500
          &.success
            color #07711f
          &.cancel
            color #b00f0f
    .projection-warp
      display flex
      justify-content space-evenly
      align-items center
      width 100%
      .wrap
        display flex
        flex-direction column
        gap 12px
        align-items center
        box-sizing border-box
        padding-top 16px
        &.success
          color #03790e
        &.error
          color red
        & > label
          font-weight bold
          font-size 50px
          & > .count
            color #111111
        .list
          font-size 60px
          text-align left
          overflow auto
          padding 4px
          .item
            //opacity 0
            &last-child
              outline 2px solid #0e2ee2
            &.add
              transition opacity 400ms
              will-change opacity
              opacity 1

  .bottom-btn-area
    z-index 999
    position absolute
    display flex
    justify-content center
    align-items center
    cursor pointer
    bottom 10px
    &.fullscreen
      left calc(10% + 30px)
    &.cancel
      left calc(50% - 75px)
    &.complete
      right calc(10% + 30px)
  .aztec_code
    z-index 999
    position absolute
    display flex
    justify-content center
    align-items center
    cursor pointer
    width 110px
    &.fullscreen
      bottom 30px
      left calc(10% + 30px)
    &.complete
      bottom 30px
      right calc(10% + 30px)
    &.cancel
      display none
      bottom 0
      left calc(50% + 10px)

  @keyframes focusing-cancel
    0%
      background-color #e74c3c
    100%
      background-color #cc1400
  @keyframes focusing-import-export
    0%
      background-color #ff9500
    100%
      background-color #ffc300
  @keyframes out-focusing-import-export
    0%
      background-color #474847
    100%
      background-color #7a7a7a
