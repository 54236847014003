// modules/Df/Lgl/InspectionDetail/F/Style/Pc/index.styl
$gray-300 = #DDDDDD
.don_frame[frame_name="/modules/Df/Lgl/InspectionDetail/S/Mui/frame.js"]
  > .don_wrapper
      width 500px
      height fit-content
      max-height 500px
      overflow-y auto
      border-radius 12px
      > .body
        border-radius 12px
.don_tab[tab_name="/modules/Df/Lgl/InspectionDetail/S/Mui/tab.js"]
  .don_wrapper
    .header
      border-bottom 1px solid $gray-300
      > h1
        font-size 18px
        font-weight 700
        margin 0
        padding 16px 24px
    .body
      overflow-y auto
      overflow-x hidden
      dl
        display grid
        grid-template-columns 58px 1fr
        row-gap 16px
        padding 0 24px
        dt
          font-weight 600
        dd
          margin-inline-start 22px
          white-space pre-wrap
          word-wrap break-word
          word-break break-word
          img
            width 320px
            border-radius 8px;
