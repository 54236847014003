body.help_groups_buying_shipping .help_list_area > .link a:nth-child(1)
body.help_groups_creating_selling .help_list_area > .link a:nth-child(2)
body.help_groups_order_pay_kr .help_list_area > .link a:nth-child(1)
body.help_groups_make_group_kr .help_list_area > .link a:nth-child(2)
body.help_groups_image_edit_kr .help_list_area > .link a:nth-child(3)
body.help_groups_order_pay_jp .help_list_area > .link a:nth-child(1)
body.help_groups_make_group_jp .help_list_area > .link a:nth-child(2)
body.help_groups_image_edit_jp .help_list_area > .link a:nth-child(3)
body.help_groups_image_copy_jp .help_list_area > .link a:nth-child(4)
body.help_groups_lf_order_pay_kr .help_list_area > .link a:nth-child(1)
body.help_groups_lf_make_group_kr .help_list_area > .link a:nth-child(2)
body.help_groups_lf_image_edit_kr .help_list_area > .link a:nth-child(3)
body.help_groups_lf_order_pay .help_list_area > .link a:nth-child(1)
body.help_groups_lf_make_group .help_list_area > .link a:nth-child(2)
body.help_groups_lf_image_edit .help_list_area > .link a:nth-child(3)
body.help_groups_creator_mall_faq .help_list_area > .link a:nth-child(1)
body.help_groups_creator_mall_customer_faq .help_list_area > .link a:nth-child(2)
body.help_groups_creator_mall_faq_kr .help_list_area > .link a:nth-child(1)
body.help_groups_creator_mall_customer_faq_kr .help_list_area > .link a:nth-child(2)
body.help_groups_creator_mall_faq_jp .help_list_area > .link a:nth-child(1)
body.help_groups_creator_mall_customer_faq_jp .help_list_area > .link a:nth-child(2)
  font-weight bold
  background-color #ddd

.df_help_groups_frame > .don_wrapper > .header
.df_help_groups_frame .don_page > .don_wrapper > .header
  display none



.df_help_groups_frame .help_all
  width: 1150px;
  margin: 50px auto;
  ul
    display: flex;
    gap: 14px;
    flex-wrap: wrap;
  li
    padding: 8px 12px;
    background-color: #eee;


.df_help_groups_frame .help_list_area
  width 1150px
  margin 50px auto 50px
  > h2
    margin 0 0 20px !important
  > .link
    margin-bottom 10px
    a
      display inline-block
      margin-right 5px
      padding 10px 10px 8px
      text-align center
      background-color #eee
  > .controls
    position relative
    padding 10px
    background-color #eee
    > *
      vertical-align middle
    input
      width 30%
      padding 0 10px
      height 30px
      line-height 30px
    button
      margin-left 10px
      height 30px
      border 1px solid #888
    .btn_order_align
      position absolute
      top 10px
      right 10px
      bottom 10px
  .help_pgn
    position relative
    padding 20px auto 0
    overflow hidden
    .don_pagination
      margin-top 0
      position absolute
      top 10px
      right -2px
      button
        margin 0 5px
    .header
      margin-top 60px
      padding 5px 0 6px
      display flex
      align-items center
      justify-content center
      font-weight bold
      border-top 1px solid #aaa
      border-bottom 1px solid #aaa
      .name
        flex 1
        padding-left 20px
        line-height 24px
        text-align center
      .id
      .no
      .created_at
      .btns
        width 100px
        text-align center
    .help_groups
      .help_group_item
        display flex
        position relative
        padding 4px 0 5px
        border-bottom 1px solid #ddd
        align-items center
        justify-content center
        .name
          flex 1
          padding-left 20px
          line-height 24px
          text-align left
        .id
        .no
        .created_at
        .btns
          width 100px
          text-align center
        .btns
          a
          button
            padding 3px 5px
            font-size 11px
            display inline-block
            margin-right 5px
            border 1px solid #ccc
            background-color #fff

.help_group_detail_area
  width 1150px
  margin 0 auto
  > h2
    margin 20px 0 10px !important
  .help_group_editor
    padding 10px
    background-color #ddd
    table
      width 700px
      border-collapse collapse
      th
        width 80px
      th
      td
        padding-bottom 5px
        vertical-align middle
      input
      textarea
        width 100%
        padding 10px
        box-sizing border-box
        border 1px solid #ccc
        background-color #fff
    .btn
      padding-left 20px
  > .controls
    position relative
    margin-top 10px
    padding 10px
    background-color #eee
    > *
      vertical-align middle
    input
      width 30%
      padding 0 10px
      height 30px
      line-height 30px
    button
      margin-left 10px
      height 30px
      border 1px solid #888
    .btn_order_align
      position absolute
      top 10px
      right 10px
      bottom 10px
  .hp_cont
    margin-top 20px
    padding 10px
    background-color #eee
    .title
    .body
      padding 0px
  .hp_list
    margin-top 20px


#body .help_editor_area
  position fixed
  top 0
  left 0
  right 0
  bottom 0
  z-index 100000
  background-color rgba(0, 0, 0, 0.6)
  .editor
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    margin auto
    width 900px
    height 700px
    background-color #fff
    .close
      position absolute
      top -10px
      right -10px
      width 20px
      height 20px
      cursor pointer
      text-align center
      line-height 20px
      border 1px solid #999
      border-radius 10px
      background-color #fff
    h3
      margin 0 0 20px
      padding 10px 10px
      border-bottom 1px solid #999
      background-color #eee
    .title
    .body
      padding 0 20px
    .title
      textarea
        width 100%
        padding 5px 10px
        box-sizing border-box
        border 1px solid #ccc
    .file_upload
      position relative
      margin-top 5px
      padding 0 20px
      overflow hidden
      input[type="file"]
        opacity 0
        position absolute
        top -999999px
        left -999999px
    .body
      margin-top 10px
      line-height 20px
    .btn
      margin 10px 20px 0
      padding-top 10px
      text-align center
      border-top 1px solid #eee
      button
        margin 0 5px

.help_group_detail_area
.help_editor_area
  .help_item
    position relative
    margin-bottom 10px
    border-top 1px solid #ccc
    border-bottom 1px solid #ccc
    .question
      width 86%
      padding 10px 20px
      box-sizing border-box
      background-color #ccc
      border-bottom 1px solid #ddd
    .answer
      width 86%
      padding 10px 20px
      line-height 22px
      box-sizing border-box
      background-color #eee
      overflow hidden
      img
        max-width 100%
      ul
        margin-left 15px
        list-style-type disc
        list-style-position inside
        ol
          list-style-type lower-latin
          list-style-position inside
          margin-left 15px
        ul
          list-style-type circle
          list-style-position inside
          margin-left 15px
      ol
        margin-left 15px
        padding 0
        list-style-type decimal
        list-style-position inside
        ol
          list-style-type lower-latin
          list-style-position inside
          margin-left 15px
        ul
          list-style-type circle
          list-style-position inside
          margin-left 15px
    .created_at
      position absolute
      top 40px
      right 5px
    .btns
      position absolute
      top 5px
      right 5px
      button
        margin-left 5px




