

html.df_sms_creator_product_group
  .mp_shop_sms
    width 500px
    margin 0 auto
    .form
      .head
        margin 10px 0
        padding 10px
        background-color #eee
      input
      textarea
        width 100%
        padding 10px
        box-sizing border-box
        border 1px solid #eee
        background-color #fff
      textarea
        min-height 180px
    .buttons
      margin-top 10px
      text-align center
      .btn_send_sms
        padding 10px 20px
        border 0 none
        background-color #0157ff
        color #fff
        font-weight bold


.projection_sms_area
  position relative
  min-height 600px
  padding-left 600px
  .send_editor
    position absolute
    top 0
    left 0
    width 600px
  .ks_send_area
    width 100%
    textarea
      height 60px
  .send_list
    padding 0 20px
    min-height 600px
    h3
      margin 0
      padding 0 0 10px
      border-bottom 1px solid #999
      span
        font-size 12px
    ul
      margin 0
      padding 0
      list-style none
      li
        padding 10px
        margin-top 10px
        background-color #eee
        .caller
          span.number
            margin-right 10px
        .sms_text
          margin-top 5px
          .text
            margin-top 5px
            padding 5px 10px 6px
            background-color #fff

.sms_area
  .ks_send_area
    width 800px
    margin 50px auto

.ks_send_area
  &[sms_type=""]
  &[sms_type="line"]
    .caller_number_area
      display none
  &[sms_type="direct"]
    .caller_number_area
      display block
  h3
    margin 0
    padding 0 0 10px
    border-bottom 1px solid #999
  .caller_number_area
    display none
  .control
    padding 0 0 10px
    margin-top 30px
    label.radio
      display inline-block
      margin-right 20px
      input
        width auto
    span.head
      display flex
      justify-content space-between
      align-items center
      padding 10px
      margin-bottom 10px
      background-color #eee
      .btn_add_img
        margin 0 0 0 15px
    input
    textarea
      padding 5px
      width 90%
      border 1px solid #999
      &::placeholder
        color #ccc
    input.caller_number1
    input.caller_number2
    input.caller_number3
      width 20%
    span.hyphen
      margin 0 5px
    p
      margin 5px 0 0
      padding 0
      color #777
      .txt1
        margin-left 10px
        font-weight bold
    &.receive_area
      input[name="receive_num"]
        width 200px
        margin 0 5px 10px
      ul
        margin 0
        padding 0
        list-style none
        li
          display inline-block
          margin-right 10px
          background-color #eee
          padding 5px 10px
          .btn_del
            cursor pointer
            margin-left 5px
    .msg_select_boxes
      display flex
      select.msg_select_box
        cursor pointer
        width 100%
        height 25px
  .buttons
    margin-top 20px
    text-align center
    button
      padding 10px 20px
      border 0 none
      background-color color_active
      color #fff
      font-weight bold



.kakao_surem_logs
  width 1400px
  margin 50px auto
  h2
    margin 0
    padding 0 0 10px
    text-align center

  .table_head
    display table
    table-layout fixed
    border-spacing 0
    border-collapse collapse
    width 100%
    text-align center
    .tr
      display table-row
    .th
      display table-cell
      padding 10px
      border 1px solid #000
      background-color #bbb
      &:nth-child(1)
        width 120px
      &:nth-child(2)
        width 120px
      &:nth-child(3)
        width 775px
      &:nth-child(4)
        width 140px
      &:nth-child(5)
        width 140px
  .table
    width 100%
    .infi_wrapper
    .infi_items
      width 100%
      > div
        width 100%
        display table
        table-layout fixed
        border-spacing 0
        border-collapse collapse
        text-align center
    .tr
      display table-row
    .td
      display table-cell
      padding 10px
      border 1px solid #000
      border-top 0 none
    .tr
      &:hover
        .td
          background-color #eee
      .td
        padding 10px
        span
          display block
          overflow hidden
          word-break break-all
      .td.text
        text-align left
    .td
      &:nth-child(1)
        width 120px
      &:nth-child(2)
        width 120px
      &:nth-child(3)
        width 775px
      &:nth-child(4)
        width 140px
      &:nth-child(5)
        width 140px
