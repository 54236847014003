body.cate_list_info
  #body
    margin auto
    padding 50px 0
    .cate_list
      margin 16px 0 24px
      padding 16px
      position relative
      background-color #ccc
      >.body
        .cate_item
          position relative
          display inline-block
          vertical-align top
          width 25%
          .img
            width 100%
            img
              max-width 100%
              max-height 300px
