// modules/BpOption/SelectModal/F/Style/Pc/index.styl


.SelectModalView__container
  &-body
    &__card-container
      display flex
      flex-wrap wrap
      gap 16px

    &__card
      background-color white
      border 1px solid #ddd
      border-radius 10px
      box-shadow 0 4px 8px rgba(0, 0, 0, 0.1)
      padding 16px
      flex: 0 0 calc(25% - 16px)
      box-sizing border-box
      display flex
      flex-direction column
      align-items center
      justify-content center
      cursor pointer
      position relative
      transition transform 0.3s, box-shadow 0.3s

      &:hover
        transform translateY(-5px)
        box-shadow 0 6px 12px rgba(0, 0, 0, 0.15)


      &.selected
        border-color #007bff
        box-shadow 0 0 10px rgba(0, 123, 255, 0.5)

        &:after
          content '선택'
          color white
          background-color #007bff
          position absolute
          top 8px
          right 8px
          padding 4px 8px
          border-radius 4px
          font-size 12px
          z-index 3

      &-meta
        margin-top 10px
        text-align left
        width 100%
        padding 20px 0

      &-image-upload
        position relative
        overflow hidden
        display flex
        flex-direction column
        align-items center
        justify-content center
        width 100%
        height 150px
        background-color #f0f0f0
        border-radius 10px
        border 2px dashed #ccc
        text-align center
        cursor pointer

        & input[type="file"]
          position absolute
          left 0
          top 0
          opacity 0
          cursor pointer
          width 100%
          height 100%
          z-index 9

        & img
          width 100%
          z-index 2

        &-text
          color #888
          font-size 16px
          position absolute


.d-none
    display none
