$img-editor-panel-color = rgb(58, 57, 55)
$img-editor-border = 2px solid rgb(12, 12, 12)
$img-editor-header-color = rgb(50, 50, 50)
$img-editor-work-area-color = rgb(40, 40, 40)
$img-editor-artboard-margin = 0.4rem
$img-editor-font-color = floralwhite

.don_frame[frame_name='/modules/Df/ImageEditor/S/Mui/frame.js']
  height 100vh
  width 100vh
  overflow hidden
  >.don_wrapper >.header
    background-color $img-editor-header-color
    border-bottom $img-editor-border
    box-shadow 0 2px 6px rgba(0, 0, 0, 0.9)
    display flex
    justify-content center
    align-items center
    font-size 1.5rem
    color $img-editor-font-color
    >button.don_hide_frame
      filter invert(1)
      top 7px

  .don_page
    height 100%
    > .don_wrapper
      height 100%
      > .body
        height 100%
    .don_tab
      height 100%
      > .don_wrapper
         height 100%
         .image-editor
           display flex
           flex-direction column
           height 100%
           div.property-panel
             user-select none
             z-index 1
             width 100%
             height 3rem
             font-size 1.2rem
             color $img-editor-font-color
             background-color $img-editor-panel-color
             box-shadow 0 2px 6px rgba(0, 0, 0, 0.9)
             section
               display none
               &.selected
                 display flex
               .double-partition
                 border-left 1px solid gray
                 border-right 1px solid gray
                 width 3px
                 margin 0 1.1rem
               div.properties
                 display flex
                 div.property
                   display flex
                   align-items center
                   gap 0.8rem
                   &:not(:last-child)
                     padding-right 1.5rem
                   &:not(:first-child)
                     border-left 1px solid gray
                     padding-left 1.5rem
                   label
                     margin-right 0.5rem
                     font-size: 0.85rem
                   &.press-type
                     select
                       height 1.7rem
                       padding-left 0.25rem
                   .brush
                     display flex
                     align-items center
                     gap 0.8rem
                   div.slider
                     width 8rem
                     height 0.3rem
                     background-color #E5E5EA
                     border none
                     cursor pointer
                     .noUi-connect
                       background-color #777
                     .noUi-handle
                       cursor pointer
                       width 1.1rem
                       height 1.1rem
                       right -0.5rem
                       border-radius 50%
                       background-color #666
                       border none
                       box-shadow 0 2px 6px rgba(0, 0, 0, 0.3)
                       &:hover
                         box-shadow 0 2px 6px rgba(0, 0, 0, 0.5)
                       &:active
                         transform scale(1.1)
                       &::before, &::after
                         display none
                     .noUi-tooltip
                       background-color lightgrey
                       border none
                       font-size 0.9rem
                       padding 2px
                       bottom -180%
                     .noUi-pips-horizontal
                       height 1rem
                       top 0
                       .noUi-marker-horizontal.noUi-marker-large, .noUi-marker
                         width 1px
                         height 0.5rem
                     &.wide
                       width 45rem
                   input.indicator
                       width 2rem
                       height 1rem
                       border none
                       border-radius 0.2rem
                       padding 0.25rem
                       text-align center
                       outline none
                       &::-webkit-outer-spin-button, &::-webkit-inner-spin-button
                         -webkit-appearance none
                         margin 0
                   input.indicator.wide
                     width 3rem
                   input[type="color"]
                     background-color transparent
                     height 2rem
                     border none
                     padding 0
                   div.custom-bpcf-picker
                     position relative
                     div.bpcf-window
                       background-color floralwhite
                       position absolute
                       border-radius 0.3rem
                       top 2rem
                       left -3rem
                       display block
                       &.hidden
                         display none
                       button.close
                         background-image url("https://s3.marpple.co/files/u_193535/2018/12/original/f_18632_1545117841977_a8X6NA3p8T4cNCvpowk4t.png")
                         width 1.3rem
                         height 1.3rem
                         position absolute
                         top 1rem
                         right 0.7rem
                       canvas.bpcf
                         z-index 99
                         width 350px
                         height 350px
                         cursor none
                   select
                     border-radius 0.5rem
                     outline none
                   .color-controls
                     display flex
                     align-items center
                     height 100%
                     gap 0.3rem
                     button
                       height 70%
                       width 3rem
                       border 1px solid black
                       &[name="transparent"]
                         background-image linear-gradient(45deg, #b9b9b9 25%, transparent 25%), linear-gradient(-45deg, #b9b9b9 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #b9b9b9 75%), linear-gradient(-45deg, transparent 75%, #b9b9b9 75%)
                         background-size 6px 6px
                         background-position 0 0, 0 3px, 3px -3px, -3px 0px
                       &[name="auto"]
                         background linear-gradient(to right, #f79ec8, #f7a28e, #f7b54c, #b6e687, #8cd9f7, #b18cf7, #e68cd9)
                       &[name="black"]
                         background-color black
                       &[name="gray"]
                         background-color gray
                       &[name="white"]
                         background-color white
               div.sub-tools
                 gap 0.1rem
                 button
                   background -webkit-gradient(linear, left top, left bottom, from(#eaeaea), to(#c5c5c5))
                   border-radius 0.25rem
                   &.selected
                       border-style inset
                       background #696969
               div.controls, div.button-controller, div.custom-bpcf-picker
                 display flex
                 justify-content center
                 align-items center
                 button
                   background-color transparent
                   border none
                   width 1.5rem
                   height 1.5rem
                   background-size cover
                   background-position center
                   margin 0 0.3rem
                 input
                   width 2rem
                   height 1rem
                   border none
                   border-radius 0.2rem
                   padding 0.25rem
                   text-align center
                   outline none
                   &::-webkit-outer-spin-button, &::-webkit-inner-spin-button
                     -webkit-appearance none
                     margin 0
           div.content
             display flex
             height 100%
             overflow hidden
             aside.tool-panel
               display flex
               flex-shrink 0
               flex-direction column
               user-select none
               box-sizing border-box
               padding 1.5rem 0
               width 7rem
               height 100%
               background-color $img-editor-panel-color
               border-right $img-editor-border
               box-shadow: 2px 0 6px rgba(0, 0, 0, 0.9)
               section
                 display flex
                 flex-direction column
                 justify-content center
                 gap 0.25rem
                 margin 0 auto 0.8rem
                 &:not(:last-child)
                   border-bottom 1px solid gray
                   padding-bottom 0.8rem
                 button
                   padding 0.3rem 0
                   font-size 0.9rem
                   color $img-editor-font-color
                   width 5.5rem
                   border-radius 0.25rem
                   vertical-align top
                   background -webkit-gradient(linear, left top, left bottom, from(#4B4947), to(#32302D))
                   &.selected
                     border-style inset
                     background #333
                 &.basic
                   button
                     background #333
                 &.advanced
                   button
                     background #333
                 &.file-handle
                   button
                     background forestgreen
             section.work-area
               position relative
               display flex
               flex 1 1 auto
               justify-content center
               align-items center
               height 100%
               background-color $img-editor-work-area-color
               .size-indicator
                 display flex
                 justify-content center
                 position absolute
                 top 3rem
                 left 3rem
                 width 8rem
                 padding 0.5rem 1rem
                 background-color rgb(100, 100, 100)
                 color $img-editor-font-color
                 border-radius 1rem
                 span
                   width 2.5rem
                   text-align center
               .artboard
                 height calc(100% - 3rem)
                 width calc(100% - 3rem)
                 &[color="transparent"]
                   background linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0), linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0), white
                   background-repeat repeat, repeat
                   background-position 0 0, 10px 10px
                   -webkit-transform-origin 0 0 0
                   transform-origin 0 0 0
                   -webkit-background-origin padding-box, padding-box
                   background-origin padding-box, padding-box
                   -webkit-background-clip border-box, border-box
                   background-clip border-box, border-box
                   -webkit-background-size 20px 20px, 20px 20px
                   background-size 20px 20px, 20px 20px

           button
             &.undo
               background-image url('https://s3.marpple.co/files/u_1187078/2023/6/original/795fa901afc5cd4112872372e0b8c58f31950e0a4.png')
             &.redo
               background-image url('https://s3.marpple.co/files/u_1187078/2023/6/original/1ee578842001fa1bba2999070ed0434e090e927e3.png')
             &.apply
               background-image url('https://s3.marpple.co/files/u_1187078/2023/6/original/88e13cec831daf4c0544597e40d6a6cb157ecc601.png')
             &.apply-with-level
               &.no1
                 background-image url('https://s3.marpple.co/files/u_1187078/2023/8/original/889fa572f167c3f60257cea6d66b847f7a48df791.png')
                 &.selected
                   background-image url('https://s3.marpple.co/files/u_1187078/2023/8/original/7731d1e233d89206f99329cc7e0f355d97fa19d91.png')
               &.no2
                 background-image url('https://s3.marpple.co/files/u_1187078/2023/8/original/9b8f11fede8f55ead82ea28aa0c331a529ad72482.png')
                 &.selected
                     background-image url('https://s3.marpple.co/files/u_1187078/2023/8/original/cd785268631dd9d81adcd3c80295da30a4598db72.png')
               &.no3
                 background-image url('https://s3.marpple.co/files/u_1187078/2023/8/original/a8c9b043161d47584f97598dd2495979826b12923.png')
                 &.selected
                   background-image url('https://s3.marpple.co/files/u_1187078/2023/8/original/f09e7a1e06543d3e48051f2fb8cb2891d03ec3993.png')
               &.no4
                 background-image url('https://s3.marpple.co/files/u_1187078/2023/8/original/78cf20dedd2fa6291cc7b950719c9da19812e4ef4.png')
                 &.selected
                   background-image url('https://s3.marpple.co/files/u_1187078/2023/8/original/8f5adfb060bafebcab511700a2e67edef60433864.png')
               &.no5
                 background-image url('https://s3.marpple.co/files/u_1187078/2023/8/original/0c1e4f08a80a8196e79f84600ac70b6ae1d57c725.png')
                 &.selected
                   background-image url('https://s3.marpple.co/files/u_1187078/2023/8/original/91c4d29042acf3dfce1b67e53217a9d8b7ed5e3d5.png')
               &.no6
                 background-image url('https://s3.marpple.co/files/u_1187078/2023/8/original/fcba3d83e467407fa4b5f1b6290c5beb2cd837c92.png')
                 &.selected
                   background-image url('https://s3.marpple.co/files/u_1187078/2023/8/original/3f1ec218e6d02790140ff521a32b05aefa1d87d31.png')
               &.no7
                 background-image url('https://s3.marpple.co/files/u_1187078/2023/8/original/4bd2ee11c4a246528ecb58c6ee23e1237074d3bf4.png')
                 &.selected
                   background-image url('https://s3.marpple.co/files/u_1187078/2023/8/original/a091e9ae04c2fc3132f9dd0622101896285803013.png')
             &.cancel
               background-image url('https://s3.marpple.co/files/u_1187078/2023/6/original/c7cf92c8c7f113b627667aa0e4bf6de7381efa2f1.png')
             &.refresh
               background-image url('https://s3.marpple.co/files/u_1187078/2023/6/original/17a3f602e747cecbecd11976d802cd00532b176c1.png')
             &.plus
               background-image url('https://s3.marpple.co/files/u_1187078/2023/6/original/d0c076782fd63e8577937a7fac1c633916c73d4a2.png')
             &.minus
               background-image url('https://s3.marpple.co/files/u_1187078/2023/6/original/93c701c0d00d3b5f614a064c63f272b3dcc922b41.png')
             &.open-bpcf-color-picker
               background-image url('https://s3.marpple.co/files/u_1187078/2023/6/original/a9252ee779f2a7192452e6ec9b586bf26b5707aa1.png')
             &.leftmost
               background-image url('https://s3.marpple.co/files/u_1187078/2023/6/original/0504adf2d150189f5d556487109f3c9413de4b4c2.png')
             &.rightmost
               background-image url('https://s3.marpple.co/files/u_1187078/2023/6/original/bda484867e481a0558c0af077ddff0f5aff638433.png')
             &.left
               background-image url('https://s3.marpple.co/files/u_1187078/2023/6/original/3afe2f676ff7e700bb62585ddc3e2be2f2e461461.png')
             &.right
               background-image url('https://s3.marpple.co/files/u_1187078/2023/6/original/3e559d902fd3ec329d357d11230f05b3e55af3844.png')
             &.delete
               background-image url('https://s3.marpple.co/files/u_1187078/2023/6/original/7720cdb7b94ebe3d316f8565eddc3a5aa01a96501.png')
             &.upload
               background-image url('https://s3.marpple.co/files/u_1187078/2023/6/original/05878a150e362a193b20ed5254cb79d2342fcdee1.png')
             &.margin-upload
               background-image url('https://s3.marpple.co/files/u_1187078/2023/6/original/303ee322b3aede485990e90561d9ac699443ef742.png')
               width 2.5rem !important
             &.cut-upload
               background-image url('https://s3.marpple.co/files/u_1187078/2023/7/original/efdbe8ce3d9734691f1cfe4e14959dfba9b3457b1.png')
               width 2.5rem !important
