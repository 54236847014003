.basic_main_wrapper_332
  padding 32px
  .title
    font-size 20px
    margin-bottom 16px
  .wrapper
    border 1px solid #000
    border-radius 2px
    padding 18px
  select
    width 200px
  section
    margin-bottom 32px
  .content
    margin-bottom 16px
    &:last-child
      margin-bottom 0
  .table_row
    display grid
    grid-template-columns 1fr 1fr 1fr 1fr
    align-items center
    justify-items center
    margin-bottom 8px
    border 1px solid #000
    padding 4px
