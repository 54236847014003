.normal_tooltip
  z-index 1000
  >.body
    position absolute
    top 0
    left 0
  >.bg
    z-index 1
    position fixed
    top 0
    left 0
    right 0
    bottom 0
  >.body
    z-index 2
  .content
    cursor pointer
    padding 5px 10px
    left 0
    box-shadow 0 3px 8px rgba(0,0,0,0.3)
    background #fff
    &:hover
      background #000
      color #fff


.don_frame[frame_name="df.task.detail"][frame_index="0"]
  select
    padding 0 0 6px
    vertical-align top
  .don_page >.wrapper >.head .close, .don_page >.wrapper >.head .close_all
    visibility hidden

html.task_set_manage
  .don_page[page_name="task.detail"]
    .related_tasks.content, >.wrapper >.option
      display none
html.task_set_manage
  .task_set[task_set_id="75"]
  .task_set[task_set_id="76"]
  .task_set[task_set_id="77"]
  .task_set[task_set_id="78"]
  .task_set[task_set_id="79"]
  .task_set[task_set_id="80"]
  .task_set[task_set_id="81"]
    display none
html.task_set_manage.jip
  .task_set[task_set_id="75"]
  .task_set[task_set_id="76"]
  .task_set[task_set_id="77"]
  .task_set[task_set_id="78"]
  .task_set[task_set_id="79"]
  .task_set[task_set_id="80"]
  .task_set[task_set_id="81"]
    display block
body.task_list
  .nav_container
    width 100%
    nav
      display flex
      >a
        padding 10px 60px
        background-color #484848
        text-align center
        border 1px solid #484848
        border-bottom none
        color #fff
        letter-spacing 3px
        &.selected
          background #fff
          color color_black
          position relative
          &:before, &:after
            position absolute
            width 2000px
            height 1px
            bottom 0
            content ""
            background #484848
          &:before
            right 100%
          &:after
            left 100%

.don_page[page_name="task.list"], .don_page[page_name="issue.my_list"]
  .don_tab >.don_wrapper
    overflow hidden
    padding 20px
    >div
      margin-bottom 20px
  ul.prj, .prj_item
    margin 0 !important
    padding 0 !important
  .prj_item_inner
    box-shadow none !important
  .task_item
    display grid
    grid-template-columns 200px 6fr
    margin-bottom 50px
    box-shadow 0 0 .4em rgba(0, 0, 0, 0.4)
    min-width 1350px
    transition opacity 0.3s
    >.wrapper
      border-right 1px solid #ccc
      position relative
      font-size 12px
      div
        box-sizing border-box
      .task >.head
        display grid
        grid-template-columns 1fr 1fr
        align-items space-between
        background-color #ffffb6
        >div
          padding 6px 10px
        .title
          width 140px
        .status
          text-align right
          line-height 17px
          font-weight 500
      .task >.option
        .issue
        .created_at
        .due_at
        .task_id
          padding 5px 15px 5px
        .issue
          color #fff
          background #fc5926
        .due_at
          color #fc5926
        .task_id
          color #ccc
      .task >.workers
        padding 15px
        .manager
          margin-bottom 10px
          display flex
          label
            font-weight 400
            width 70px
      .task > .task_body
        //box-shadow 0 3px 8px rgba(0,0,0,0.3)
        margin 0px 10px 10px
        padding 5px
        border 1px solid #ccc
        border-radius 8px
        >label
          font-weight 500
          display block
          margin-bottom 8px
      .task >.files
        border 1px solid #ccc
        border-radius 8px
        //box-shadow 0 3px 8px rgba(0,0,0,0.3)
        margin 10px
        padding 5px
        >label
          font-weight 500
          display block
          margin-bottom 8px
        .file_list
          word-break break-all
          color -webkit-link !important
          text-decoration underline !important
          >li
            margin-bottom 8px
  [prj_product_mode="bp_stocks"]
    .task_item
      grid-template-columns 270px 6fr !important
      .grid.prj_item
        grid-template-columns 5fr 1fr !important
      .grid.prj_item_inner
        grid-template-columns 426px 3fr !important
      .tasks_container
        display none !important
    .check_bp_stock_option
      display block !important
    .check_bp_stock:not(.no_need)
      display block !important
    .check_bp_stock
      label
        position relative
        width 100%
        height 100%
        display inline-block
        span
          position absolute
          top 50%
          left 50%
          transform translate(-50%, -50%)
          width 50px
          height 50px
          border 1px solid color_active
          border-radius 5px
      input
        display none
        &:checked + span
          background url(//s3.marpple.co/files/u_193535/2019/2/original/f_37814_1550478900042_q2SooKsDk7GvzH5yvo.png) no-repeat 50% 50%
    .check_bp_stock_option
      position absolute
      top 0
      right 120px
      > *
        margin-right 4px
.don_tab[tab_name="tasks.task_set.list"]
  .btn.add
    display none
.don_page[page_name="task.task_set.create"]
  select[name="original_task_id"]
    width 100px
    margin-left 8px
  .task_items
    .head label
      width 100px

.don_page[page_name="task.detail"], .don_page[page_name="task.task_set.create"]
  select
    padding-bottom 5px
  .don_wrapper
    {jip-don_page_head}
  .press_type_select
    .selected
      border 1px solid #ccc
      padding 6px 0
      width 90px
      text-align center
  .select_original_task
    position relative
    ul.body
      display none
      position absolute
      top 28px
      left 50%
      transform translateX(-50%)
      width 390px
      box-shadow 0 3px 8px rgba(0,0,0,0.3)
      background #fff
      flex-wrap wrap
      >li
        padding 10px
        width 110px
        cursor pointer
        &:hover
          background #ccc
.press_type_select_ui
  li:hover
    background #ccc

.don_page[page_name="task.task_set.create"]
  .don_wrapper >.head
    grid-template-columns 100px 1fr 150px
    margin-bottom 32px
  .task_set_body
    margin 16px
  .content
    display flex
    margin-bottom 20px
    >*
      margin-right 16px
    &.default
      display none
    .input
      width 300px

.don_page[page_name="task.task_set.list"]
  padding-top 16px
  .due_at, .completed_at
    display none !important
  .create_task_set .btn_add
    cursor pointer
    margin 0 auto
    padding 10px
    text-align center
    width 120px
    border 1px solid #000
    &:hover
      background #000
      color #fff
  .task_set_list
    padding-top 16px
    padding-bottom 16px
  .don_wrapper >.option, .task_set_list
    max-width 600px
    margin 0 auto
  .task_set
    max-width 600px
    margin-bottom 16px
    border 1px solid #cc
    position relative
    &[is_auto="true"]
      >.title
        background deepskyblue
    >.option
      position absolute
      right 7px
      top 10px
    >.title, >.body
      font-size 16px
      font-weight 400
      padding 8px 0
      display grid
      grid-template-columns 60px 1fr
      border-bottom 1px solid #cc
      >.head
        padding-left 8px
        position relative
        &:after
          content ':'
          position absolute
          right 9px
          bottom 2px
    >.title
      background #ccc

//.don_page[page_name="task.list"]
//  .task_item[is_canceled="true"]
//    opacity 0.5
.don_page[page_name="task.list"]
.don_page[page_name="task.detail"]
  .files[has_item=false]
    display none
.projection_detail
.don_page[page_name="task.detail"]
.don_page[page_name="projection.list"]
.don_page[page_name="task.list"]
.don_page[page_name="task.task_set.list"]
.don_page[page_name="task.task_set.create"]
.don_page[page_name="issue.my_list"]
  .file_list
    flex-shrink 10
  .task
    position relative
    >.head
      background-color #ffffb6
      cursor pointer
    .title
      font-weight 500
      font-size 14px
      padding-left 4px
    &[is_canceled="true"]
      >.head
        background #adadad !important
        color #fff !impotant
      .title
        cursor no-drop !important
  .issue_shape
    width 20px
    height 37px
    background red
    color white
    position absolute
    display none
    text-align center
    right 0
    top 0
    font-size 13px
    .deco
      position absolute
      width 20px
      height 20px
      bottom -15px
      left 0
      box-sizing border-box
      border-top: 10px solid red;
      border-right: 10px solid red;
      border-bottom: 10px solid transparent;
      border-left: 10px solid red;
  [status]
    >.head
      background #ffffb6
      color color_black
  [status="completed"]
    >.head
      background #4cc37f !important
      color white !important
  [status="on"]
    >.head
      background #5bb7ff !important
      color white !important
  [is_hidden="true"]
    >.head
      background #ffaed7 !important
  [is_hidden="true"]
  [is_canceled="true"]
    >.head
      color color_black !important
  [has_issue="true"]
    .issue_shape
      display block
    .task_issue
      cursor pointer

.don_page[page_name="task.detail"]
.don_page[page_name="projection.list"]
.don_page[page_name="task.list"]
.don_page[page_name="task.task_set.create"]
.don_page[page_name="task.task_set.list"]
.projection_detail
.don_page[page_name="issue.my_list"]
  .task
    [has_item="false"]
      display none
  .task_issue_wrapper[task_issue_length="0"]
    .task_issue
      display none
  .table.task_items
    width 100%
    display grid
    grid-template-columns repeat(4, 1fr)
    .cell
      &.head
        z-index 2
      &.body
        padding 7px !important
        z-index 1
    .type
      grid-template-rows grid-with-header
      border-right 1px solid #cc
      >.head
        {flex-center-left}
        padding 5px 10px !important
    .col:last-child
      border none
    .btn.add
      width 20px
      height 20px
      margin-left 10px
      border 1px solid #ccc
      background i_plus no-repeat center
      cursor pointer
    .task
      margin-bottom 10px
      font-size 13px
      > div:not(.task_issue)
        padding 5px 8px
        font-size 13px
        display flex
        justify-content space-between
      >.title
        border-top-left-radius 5px
        border-top-right-radius 5px
      >.completed_at
        border-bottom-left-radius 5px
        border-bottom-right-radius 5px
      .manager
      .manager, .due_at
        background-color #ebebeb
      .due_at, .completed_at
        display flex
      .completed_at
        background-color #666
        color #fff
      .completed_at, .due_at
        > div
          flex-grow 2
          text-align center
      .due_at
        border-top 1px solid #cc
      .up_c_s_item
        border 1px solid #cc
        margin-bottom 20px
      .product-position-status
        display inline-flex
        align-items center
        gap 4px
        float right
        position relative
        margin-right 16px
        width 20px
        min-width 20px
        &:hover
          .time
            display inline-block
        .time
          position absolute
          top -45px
          right 0
          width fit-content
          display none
          background-color #dddddd
          border-radius 8px
          padding 8px 16px
          z-index 10
        .icon
          position absolute
          width 20px
          height 20px
          svg
            width 100%
            height 100%
          &.is-import
            svg
              path
                fill green
          &.is-export-to-delivery
            svg
              path
                fill blue



#body
  .projection_list_container
  .task_list_container
    .table.task_items
      .cell.body
        padding 2px !important
      .task
        margin-top 3px
        margin-bottom 6px

#body
  .projection_detail
    .user_projections
      .warning_message
        display none !important
      .tasks_container
        display block
        >.head
        .btn.add
          display none !important

.tooltip_task_items
  z-index 1000
  position absolute
  width 70px
  .auto_assignment
    display none
  &[data-task_set_type="printing"]
    .auto_assignment
      display block
  .bg
    position fixed
    top 0
    left 0
    right 0
    bottom 0
  .add_task_option
    position absolute
    top 22px
    width 180px
    left 0
    box-shadow 0 3px 8px rgba(0,0,0,0.3)
    color color_black
    background #fff
    >div
      padding 5px
      cursor pointer
      &:hover
        background #ccc
    .task_set_list
      position relative
      &[sets_length="0"]
        display none
      &[sets_length="1"]
        ul.body
          width 130px
      &[sets_length="2"]
        ul.body
          width 260px
      &[type="as"]
        ul.body
          left initial
          right 0
          transform initial
      ul.body
        display none
        position absolute
        top 28px
        left 50%
        transform translateX(-50%)
        width 390px
        box-shadow 0 3px 8px rgba(0,0,0,0.3)
        background #fff
        flex-wrap wrap
        >li
          padding 10px
          width 110px
          cursor pointer
          &:hover
            background #ccc
      //&:hover
      //  ul.body
      //    display block
.don_page[page_name="task.detail"]
  #files
    .file_content
      display flex
      justify-content space-between
  button
    border 1px solid #ccc
    &:hover:not(:disabled)
      background #ccc !important
  .task_detail_inline_block
    width 50%
  .not_rowm
    margin-bottom 20px
    .files
      margin-top 10px
  .rowm
    display flex
    margin-bottom 20px
    justify-content space-between
    .content
      align-items center
      input
        height 100%
        min-height 30px
    >.title.content
      display flex
    .press_type
      .label
        width 70px
        margin-right 12px
    .assigned_at
      label
        font-size 13px !important
    >div
      display flex

  .attached_file_wrap
    display flex
    flex-direction column
    width 100%
    margin-right 10px
  .margin_bottom
    margin-bottom 40px
  .files
    display flex
  .label
    display inline-block
    font-weight 400
    width 120px
    font-size 16px
  .label_content
    width 170px
  .label_content2
    width 100%
  div
    box-sizing border-box
  .don_wrapper >.wrapper >.head
    grid-template-columns 100px 1fr 150px 150px
    z-index 10
    .create, .create_task
      display none
  .don_wrapper >.wrapper[is_draft="true"]
    >.head
      .create, .close, .create_task
        display block
      grid-template-columns 100px 1fr 150px
      .delete, .cancel, .delete_task
        display none
  .don_wrapper >.wrapper
    {jip-don_page_head}
    //display grid
    //grid-template-columns 43% 57%
    //min-width 1000px
    >.head
      grid-column 1/3
      grid-row 1/2
  border 1px solid #cc
  .manager
    >.body
      border 1px solid #cc
      width 120px
      cursor pointer
      padding 1px
    >.body:hover
      background #ccc
    .manager_blank
      text-align center
      line-height 24px
    .option
      display none
      flex-grow 1
      text-align center
  //.worker.manager
  //  .add
  //    height 100%
  //    background i_plus no-repeat 9% 49%
  //    background-size 15px
  //.task_body > .head
  //  font-size 20px
  //  padding 8px
  //  display flex
  //  justify-content center
  //  border 2px solid #ccc
  //  > div
  //    font-weight 500
  //  .title
  //    margin-right 40px
  .task_body >.head
    font-size 20px
    border-radius 5px
    display flex
    justify-content space-between
    padding 8px 20px
    .status
      display flex
      align-items center
      label
        margin-right 8px
      select
        cursor pointer
  .body_wrapper
    display flex
    .task_body
      width 630px
    >.option
      width 100%
      flex-shrink 10
  .task_body
    button
      outline none
    >.head
      margin 7px 7px 0
    .related_tasks
      margin 0 12px
    .title
      label
        line-height 22px
      input
        background #ccc
        padding-left 8px
        font-size 13px
        box-sizing border-box
        width 120px
      .option
        margin-left 8px
        button
          cursor pointer
          margin-right 4px
          padding 2px 6px
          border-radius 5px
          background #fff
          &:hover:not(:disabled)
            background #ccc
    .wrapper
      margin 16px 20px
      //> div
      //  display flex
      //  margin-bottom 20px
      //  &.title
      //    margin-bottom 40px
      //  &.manager
      //    margin-top 20px
      //  &.start_at
      //    margin-top 20px
      //  &.body.content
      //    margin-top 20px
      .rowm >div
        a
          color -webkit-link !important
          text-decoration underline !important
    .colm
      width 50%
      flex-row 1
      >div
        display flex
        margin-bottom 20px
    .body.content
    .body.check_box
      textarea
        flex-shrink 10
        height 50px
        width 280px
      .option
        height 32px
        display flex
        margin-left 8px
      .save, .cancel
        width 52px
        margin-right 4px
        height 25px
        background #fff
        border-radius 5px
  .date_time
    .time
      margin-left 8px
  .task_body[is_canceled=true]
    opacity 0.3
  .don_wrapper >.wrapper .body_wrapper >.option
    background #ffffb6
    border-right 1px solid #ccc
    border-bottom 1px solid #ccc
    >div:not(.tab)
      display none
    &[is_show="issue_list"]
      .issue_list
        display block
        padding 8px
        .issue_create
          cursor pointer
      .tab_issue_list
        background #fff
        color black
    &[is_show="issue_unread"]
      .issue_unread
        display block
    &[is_show="projection"]
      .projection
        display block
        padding 8px
        select.prj_product_mode
          margin 8px 0
        .prj_item_inner
          >.col
            background #fff
            border 1px solid #ccc
            margin-bottom 8px
      .tab_projection
        background #fff
        color black
    &[is_show="history"]
      .history
        display block
    &[is_show="task_logs"]
      .tab_tasks_log
        background #fff
        color black
      .task_logs
        display block
        padding 20px
        font-size 16px
        table
          margin 20px 0
          width 100%
          border-collapse collapse
          text-align center
          background #fff

          th
          td
            padding 12px
            color #333
            border 1px solid #e9ecef
            font-weight 200
          th
            font-weight bold
            text-align center
            color color_black
            background-color #fafafa
          td
            color color_black
            &.rowrow
              font-size 12px
    &[is_show="scanner_setting"]
      .scanner_setting
        display block
        padding 8px
        .body
          margin-bottom 5rem
          &[is_show="task_auto_complete"]
            .sub-tab
              .option[key="task_auto_complete"]
                background-color #fff
                color #000
            .sub-tab-contents
              .content[key="task_auto_complete"]
                display block
          &[is_show="task_prod_deliver_scan"]
            .sub-tab
              .option[key="task_prod_deliver_scan"]
                background-color #fff
                color #000
            .sub-tab-contents
              .content[key="task_prod_deliver_scan"]
                display block
          .sub-tab
            display flex
            border 1px solid #cc
            border-bottom none
            justify-content flex-start
            background #355f72
            .option
              padding 1rem
              cursor pointer
              color #fff
          .sub-tab-contents
            .content
              display none
          .scan-codes
            display flex
            flex-direction column
            align-items center
            gap 1rem
            section.reset
              .reset-codes
                display flex
            section
              display flex
              flex-direction column
              align-items center
              h1.title
                margin-bottom 0
              h2.description
                font-size 1.2rem
              a.link
                margin-top 1rem
                font-weight bold
                text-decoration underline
                &:hover
                  color #1f5ea8
              .aztec-code
                display flex
                gap 1rem
                align-items center
                justify-content flex-end
                width 15rem
                &:nth-child(even)
                  flex-direction row-reverse
                  margin-right 25%
                &:nth-child(odd)
                  flex-direction row
                  margin-left 25%
                &.center
                  flex-direction column
                  margin 0
                .code
                  width 5rem
                  height 5rem
                  background-size contain
                  &[key="0"]
                    background-image url("https://s3.marpple.co/files/u_1187078/2023/6/original/8b24cab088411afc1545bfecf57896f892484b0b3.png")
                  &[key="1"]
                    background-image url("https://s3.marpple.co/files/u_1187078/2023/6/original/44b732d827b8dcd069cfbeca0703f29c07bbafe04.png")
                  &[key="2"]
                    background-image url("https://s3.marpple.co/files/u_1187078/2023/6/original/5983eb4e2badbc8785df909ecec99957a854d0325.png")
                  &[key="3"]
                    background-image url("https://s3.marpple.co/files/u_1187078/2023/6/original/a2c304df2475ef56f546f265c834c76801e3ed866.png")
                  &[key="4"]
                    background-image url("https://s3.marpple.co/files/u_1187078/2023/6/original/eb0541b1ee1871837b3c52bc567019f83db881cf7.png")
                  &[key="5"]
                    background-image url("https://s3.marpple.co/files/u_1187078/2023/6/original/f1d70395e432a977984bb6b1dcdc9dd84b58c91c8.png")
                  &[key="6"]
                    background-image url("https://s3.marpple.co/files/u_1187078/2023/6/original/5e4fda38c5603ce075b3c4cad322e1c59a1a2ffe9.png")
                  &[key="7"]
                    background-image url("https://s3.marpple.co/files/u_1187078/2023/6/original/27b51403e2968fb540f47fe4ab1869f9ec2a309210.png")
                  &[key="8"]
                    background-image url("https://s3.marpple.co/files/u_1187078/2023/6/original/cef029296e2478a5ec97606dc075f34b60c50fa211.png")
                  &[key="9"]
                    background-image url("https://s3.marpple.co/files/u_1187078/2023/6/original/d48770af78ea967f69f393fc6c5907205609a7e212.png")
                  &[key="!"]
                    background-image url("https://s3.marpple.co/files/u_1187078/2023/6/original/c684f2d121211b8c416e226426ce3617d42a855714.png")
                  &[key="prefix-add"]
                    background-image url("https://s3.marpple.co/files/u_1187078/2023/6/original/2dc4d060ed6a5bdf7495981ba18c0ee5685195d015.png")
                  &[key="suffix-add"]
                    background-image url("https://s3.marpple.co/files/u_1187078/2023/6/original/82348133254a62a7836869d49113026f4707a56216.png")
                  &[key="["]
                    background-image url("https://s3.marpple.co/files/u_1187078/2023/6/original/fd83af6d3aeb19fc01dcc75114e24c3fa885fb9a1.png")
                  &[key="]"]
                    background-image url("https://s3.marpple.co/files/u_1187078/2023/6/original/62326e223a0c3747b435bb86d10ec0007999d2202.png")
                  &[key="amp"]
                    background-image url("https://s3.marpple.co/files/u_1187078/2023/6/original/67fd4bd33f3d88dd22f96e228ea757b60e5c133413.png")
                  &[key="test-task-mode"]
                    background-image url("https://s3.marpple.co/files/u_1187078/2023/6/original/faa051f510c4b078a99eaf4e051fee3944f7b77f1.png")
              &.mode-setting
                display flex
                .nls-barcode
                  &:nth-child(even)
                    flex-direction column
                    margin-right 0
                  &:nth-child(odd)
                    flex-direction column
                    margin-left 0
              &.reset
                .reset-codes
                  display flex
                  gap 18px
                  .nls-barcode
                    flex-direction column
                    margin 0
              .nls-barcode
                display flex
                gap 1rem
                align-items center
                justify-content flex-end
                width 25rem
                &:nth-child(even)
                  flex-direction row-reverse
                  margin-right 25%
                &:nth-child(odd)
                  flex-direction row
                  margin-left 25%
                &.center
                  flex-direction column
                  margin 0
                .code
                  width 15rem
                  height 8rem
                  background-repeat no-repeat
                  background-size contain
                  object-fit fill
                  &[key="0"]
                    background-image url("https://s3.marpple.co/files/u_2947328/2024/1/original/3712885733003720d17c93680199c703e87a7d881.png")
                  &[key="1"]
                    background-image url("https://s3.marpple.co/files/u_2947328/2024/1/original/cf2fa9d9ffb1ee118816767c965d7aae7455d4842.png")
                  &[key="2"]
                    background-image url("https://s3.marpple.co/files/u_2947328/2024/1/original/62d145a477d01d619c488372d8b6e38ccfca50fa3.png")
                  &[key="3"]
                    background-image url("https://s3.marpple.co/files/u_2947328/2024/1/original/f6f5584a5217cb3e8b3c2aab7a31cebeb6ba458d4.png")
                  &[key="4"]
                    background-image url("https://s3.marpple.co/files/u_2947328/2024/1/original/48776068f69e721f2251118343951ef58e4677375.png")
                  &[key="5"]
                    background-image url("https://s3.marpple.co/files/u_2947328/2024/1/original/57c811e729be3058edd2f6e91491af49c3579ba46.png")
                  &[key="6"]
                    background-image url("https://s3.marpple.co/files/u_2947328/2024/1/original/e330b10f24d6481c2ef9aab68af387f8ea915c0b7.png")
                  &[key="7"]
                    background-image url("https://s3.marpple.co/files/u_2947328/2024/1/original/52a9030d8fc6285dea8268859ba3002dc2f5d70d8.png")
                  &[key="8"]
                    background-image url("https://s3.marpple.co/files/u_2947328/2024/1/original/fd8abe5a132e00d5233196d81d4865ccf084dda79.png")
                  &[key="9"]
                    background-image url("https://s3.marpple.co/files/u_2947328/2024/1/original/355e463eb5afeae48c44ba8d8d6a12a5041de63b10.png")
                  &[key="a"]
                    background-image url("https://s3.marpple.co/files/u_2947328/2024/1/original/6eddd9410ec5283d53f0a1512369bda9ee88611611.png")
                  &[key="b"]
                    background-image url("https://s3.marpple.co/files/u_2947328/2024/1/original/3f10a504d3fd105c03639312baf5bf813646594c13.png")
                  &[key="c"]
                    background-image url("https://s3.marpple.co/files/u_2947328/2024/1/original/c961e451d8c48b495a4f2ab4a95eab5429a7dbe515.png")
                  &[key="d"]
                    background-image url("https://s3.marpple.co/files/u_2947328/2024/1/original/d8b3d52250e50e1d084020301463dd69f210f86c17.png")
                  &[key="e"]
                    background-image url("https://s3.marpple.co/files/u_2947328/2024/1/original/240a9c10abc9065c68a35b186ea3930f9abb34cf18.png")
                  &[key="f"]
                    background-image url("https://s3.marpple.co/files/u_2947328/2024/1/original/f8f11a1ede15463c5d34c7859f1a927ff638997423.png")
                  &[key="enter-setup"]
                    background-image url("https://s3.marpple.co/files/u_2947328/2024/1/original/cc211a1cecd3973a7300ce3ba9cc0d00069a539421.png")
                  &[key="exit-setup"]
                    background-image url("https://s3.marpple.co/files/u_2947328/2024/1/original/4ffad2e27efc466091c4cdc1212ba27a7a9d1c1f22.png")
                  &[key="set-volume"]
                    background-image url("https://s3.marpple.co/files/u_2947328/2024/1/original/a451e909d6448ee09f3864542aaf77f4bab3241216.png")
                  &[key="enable-aztec"]
                    background-image url("https://s3.marpple.co/files/u_2947328/2024/1/original/2f63912b8accdecb4ee4ab2e7a1a55683eb17ca312.png")
                  &[key="enable-bluetooth"]
                    background-image url("https://s3.marpple.co/files/u_2947328/2024/1/original/56b525e45672c8e99234d9de90943bac879dc5a514.png")
                  &[key="set-prefix"]
                    background-image url("https://s3.marpple.co/files/u_2947328/2024/1/original/b2c9970411c85c54620c2ec9f1ae2427c62a526e25.png")
                  &[key="enable-prefix"]
                    background-image url("https://s3.marpple.co/files/u_2947328/2024/1/original/428c948471d4a746bd24f847900b0670a7ce6e8619.png")
                  &[key="set-suffix"]
                    background-image url("https://s3.marpple.co/files/u_2947328/2024/1/original/8ea3cc76c0270adc68cd702d76549128a267119526.png")
                  &[key="enable-suffix"]
                    background-image url("https://s3.marpple.co/files/u_2947328/2024/1/original/bcbd07ef2f30edbbe94837de55fc296c5bfa362c20.png")
                  &[key="save"]
                    background-image url("https://s3.marpple.co/files/u_2947328/2024/1/original/272fb98926de8dfeda8419fe5a80f6216dff064824.png")
                  &[key="reset-factory"]
                    background-image url("https://s3.marpple.co/files/u_2947328/2024/1/original/794551f278d9ee1ce67e16b4068f78fffc6a0abf2.png")
      .tab_scanner_setting
        background #fff
        color black
  .tab
    top -44px
    left 0
    display flex
    border 1px solid #cc
    border-bottom none
    background #4f6084
    > div
      padding 10px 15px
      background #4f6084
      color #fff
      border-right 1px solid #cc
      cursor pointer
    > div:last-child
      border none

  .issue_list
    >.wrapper >.head
      margin-bottom 16px
    .issue
      margin-bottom 16px
    .issue_create.btn
      width 90px
      height 30px
      display flex
      justify-content center
      align-items center
      border 1px solid red
      background-color: red; /* For browsers that do not support gradients */
      //background-image: linear-gradient(red, orange); /* Standard syntax (must be last) */
      color #fff
      font-size 13px
      font-weight bold
      border-radius 5px


#body
  .task_list_container
    #mode_selector
      height 50px
      position relative
      margin 0 16px
      select.prj_product_mode
        position absolute
        top 0
        right 0
        padding 0 0 6px
        vertical-align top
    .bp_stocks
      .bp_stock
        grid-template-columns auto 130px 110px 60px
