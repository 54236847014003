#DfTechPackMode
  position relative
  height calc(100vh - 50px)
  .highlight
    color #03A9F4

.tech-pack-mode-ready
  font-size 20vw
  margin-top 200px
  text-align center

.tech-pack-mode
  margin-top 30px
  &__header
    text-align center
  &__title
    text-align center
    font-size 4vw
  &__signature
    margin-top 40px
    font-size 6vw
    display inline-block
    margin-right 30px
  &__service
    font-size 6vw
    display inline-block
    margin-right 30px
  &__prj
    font-size 4vw
    display inline-block
  &--red
    color #d60000
  &--green
    color #00a919

.tech-pack-mode-board
  display flex
  font-size 8vw
  margin-top 40px
  &__left
    flex 3
    padding 20px 0
  &__bar
    flex-basis 20px
    background-color #4c4c4c
  &__right
    flex 3
    padding 20px 0


.tech-pack-quantity-block
  justify-content center
  font-size 5vw
  display flex
  &__title
    font-size 4vw
    margin-right 50px
    color gray
  &__quantity_description
    font-size 3vw
    text-align center
  &--large
    font-size 10vw

.tech-pack-mode-warning
  position fixed
  top 0
  left 0
  width 100vw
  height 100vh
  display flex
  justify-content center
  align-items center
  align-content center
  background-color black
  color yellow
  font-size 10vw

.tech-pack-mode-complete
  position absolute
  top 15%
  bottom 15%
  left 15%
  right 15%
  border 1px solid #545454
  display flex
  justify-content center
  align-items center
  &__blur
    position absolute
    top 0
    left 0
    bottom 0
    right 0
    opacity 0.9
    background-color white
  &__message
    position relative
  &__main-message
    font-size 5vw
    font-weight bold
    display flex
    justify-content center
  &__next
    font-size 3vw
    text-align center


