grid-with-header = 50px auto
border-cc = 1px solid #cc

//ul
//  padding 0
//  li
//    list-style none
html.projection
html.task
  body
    .prj_task_pagination
      text-align center
      margin 20px 0
      a
      span
        font-size 18px
        margin 0 10px
      a:hover
        text-decoration underline
      span
        color #aaa
  .shopfreeca-phonecase
    margin 10px 0
    button
      background red
      color #fff
      border-radius 5px
      font-size 18px
      border none
      padding 4px 8px

.don_frame[frame_name="projection.all"]
  [prj_product_mode="bp_stocks"]
    .grid.prj_item_inner
      grid-template-columns 1.2fr 2.4fr 2fr !important
      margin 0 8px 22px !important
    .col.prj_summary
      //height 194px !important
      .projection_id .quantity
        top 4px !important
      >.cell
        padding 4px 8px 4px !important
      grid-template-rows initial !important
      .cell.order_info .info_wrap
        display flex !important
        > *:not(:first-child)
          padding-left 4px
        .email:before
          content "/"
          padding-right 6px
      .cell.due_at input
        vertical-align top
    .tasks_container
      display none !important

#body
  button.download_sop
    color transparent
    background-repeat no-repeat
    width 20px
    height 20px
    background-size 16px
    background-position center
    background-image url('https://s3.marpple.co/files/u_1187078/2023/8/original/c13db11d5b46c4f05578f4c7d92beb5fb71f1a461.png')
    &:hover
      background-color orange
  button.biz_requests
    color #ffe200
    background-color #3385ff
  .df-image-work-tag
    display none !important
    border-radius 2px
    padding 2px
    font-size 12px
    margin-left 4px
    background #fff !important
    &[data-work_type="자동화"]
      border 2px solid #D000FF !important
      color #D000FF !important
    &[data-work_type="검수"]
      border 2px solid #00bb5e !important
      color #00bb5e !important
    &[data-work_type="작업"]
      border 2px solid #0e2ee2 !important
      color #0e2ee2 !important
  .postcode_layer
    margin-bottom 16px
    display none
    height 470px
    width 100%
    overflow hidden
    z-index 100
    border 5px solid #000
    padding-top 16px
    position relative
    .postcode_close
      cursor pointer
      position absolute
      right -3px
      top -3px
      z-index 1
  .df_grid
    .grid, .col, .row
      display grid
    .row
      > *
        grid-row 1
      .cell
        border-right 1px solid #cc
        &:last-child
          border-bottom none
    .col
      .cell
        border-bottom 1px solid #cc
        &:last-child
          border-bottom none
  .prj
    .title
      font-weight 500
    .btn
      {flex-center-center}
    .grid
      &.prj_item
        margin 0 8px 40px
      &.prj_item_inner
        grid-template-columns 230px 2.4fr 475px
        margin 0
        border-radius 2px
        height 100%
        box-shadow 0 0 4px rgba(0, 0, 0, 0.4)
      &.ship_item:not(.inhouse)
        grid-template-columns auto 50px
        padding 8px
        grid-gap 8px
        input
          font-size 13px
        select
          font-size 14px
        .mobile2
          position relative
          .orderer_sms
            position absolute
            top 0
            right 0
        button.delivery_tracking
          width 110px
          height 30px
          margin auto
        button.inhouse_toggler
          display none
          width 150px
          height 30px
          margin auto
      .ship_item.inhouse
        display flex
        flex-direction column
        gap 16px
        margin-bottom 14px
        .header
          margin 12px 0 0 12px
          display flex
          align-items center
          gap 12px
          > span
            font-size 16px
            font-weight bold
          button.inhouse_label_print
            display flex
            align-items center
            background-color #fff6e5
            gap 5px
            &:has(div.img.created)
              box-shadow inset 1px 2px 3px #6f6f6f
              background-color #e5fff1
            div.img
              background center / contain no-repeat
              width 1rem
              height 1rem
              &.created
                background-image url('//s3.marpple.co/files/u_1187078/2023/9/original/6422aaabcdd67137481df5d45da4d42a885c41821.png')
              &.null
                background-image url('//s3.marpple.co/files/u_1187078/2023/9/original/93a1b43734fc940b3c4688ce01c123f686d4a9572.png')
        .ship_info table
          width 100%
          th
            padding 10px
        .controllers
          display flex
          justify-content space-around
          button
            background-color #f5f5f5
            width 150px
            height 30px
            &.inhouse_toggler
              display none
      &.all_ups
        border border-cc
        margin-bottom 8px
      &.up_c_ss
        margin-top 8px
        grid-gap 8px
        grid-template-columns 120px auto
      &.up_item
        margin-bottom 12px
        &[quantity="0"]
          display none
        &[merged_type="child"]
          .task_stat_label
            display none !important
        .product_no
          display flex
          align-items center
          gap 3px
          .partial-delivery-wrap
            border-radius 5px
            padding 0 8px
            margin 0 8px
            cursor pointer
            &.checked
              padding 2px 10px
              background lightseagreen
            > label
              cursor pointer
              display flex
              align-items center


    .col
      &.ship_and_ups
        border-left 1px solid #ccc
        border-right 1px solid #ccc
        position relative
        >.head
          display block
          position relative
          .price
            display inline-block
            position relative
            top 3px
      &.btn_wrap, &.prd_cs, &.info_wrap
        grid-gap 8px
    .row
      &.edit_ship,
      &.edit_address,
      &.to_waybill_from_type
        grid-gap 8px
        .type
          &.parcel:after
            content '택배'
          &.combined:after
            content '묶음택배'
          &.quick:after
            content '퀵배송'
          &.visit:after
            content '방문수령'
          &.partition:after
            content '개별배송'
      &.to_waybill_from_type
        grid-template-columns 72px 72px 136px auto
      &.edit_address
        grid-template-columns 0.85fr 3fr 1fr 3fr
      &.labels
        position absolute
        top 8px
        right 8px
        grid-gap 8px
        .label
          {flex-center-center}
          border-width 1px
          border-style solid
          border-radius 8px
          padding 4px 8px
          font-size 8px
          font-weight 400
          &.status
            &.ordering:after
              content '주문완료'
            &.before_print:after
              content '제작준비중'
            &.printing:after
              content '제작중'
            &.before_deliver:after
              content '제작완료'
            &.delivering:after
              content '배송중'
            &.before_confirm:after
              content '배송완료'
            &.confirmed:after
              content '구매확정'
            &.canceled:after
              content '주문취소'
          &.is_cancel_req.true
            cursor pointer
          &.is_cancel_req, &.is_canceled
            color #f97868
            border-color #f97868
          &.is_refunded, &.is_refund_req
            color #ff8b0c
            border-color #ff8b0c
          &.false
            color #ccc
            border 1px dashed #ccc
    .cell
      padding 8px 8px 7px
      &.user_name
        {flex-center-left}
      &.head_between
        {flex-center-between}
        .right
          display flex
          align-items center
          .pdf_printing_file_issue
            display flex
            align-items center
            gap 8px
            & .pdf-issue-report
              cursor pointer
              padding 5px 16px
              border-radius 8px
              background #d93420
              color #fff
              &:not(:disabled):hover
                background-color red
            & .pdf-issue-reorder-waiting
              cursor pointer
              padding 5px 16px
              border-radius 8px
              background-color #ff8b0c
              color #fff
              &:not(:disabled):hover
                background-color orange
      &.product_total
        font-size 24px
        font-weight 500
      &.order_info
        {flex-center-between}
        .btn
          height 36px
          width 36px
      &.ups_and_ships
        padding 8px
        background #f5f5f5
        &[merged_type="child"]
          .task_stat_noticer
            display none !important
          .task_stat_label
            display none !important
        .shipping_list
          background #fff
          border border-cc
          border-radius 8px
        .task_stat_noticer
          position absolute
          top -2.4rem
          left 0px
          font-weight bold
          background #77dc15
          padding 0.5rem 2rem 0.5rem 1rem
          border-radius 0.5rem 0.5rem 0rem 0rem
          box-shadow 0 -0.1em 0.4em rgba(0, 0, 0, 0.4)
      &[is_cancel_req='true']
        opacity 0.7
        background #ff523a
      &[is_canceled='true']
        opacity 0.3
        background #000
      &.up_c_item
        &[quantity="0"]
          display none
        .title
          display flex
          gap 5px
          align-items center
          button.print_upcs_labels, button.print_sku_labels
            width 36px
            height 27px
            background url("//s3.marpple.co/files/u_1187078/2023/9/original/065aba1c7e2c50155b86fedee20de7c28825187d1.png") center no-repeat
            background-size 20px
            background-color #eee
        .up_c_s_item
          border border-cc
          border-top 0
          &.total
            border-top border-cc
            margin-top 4px
            font-weight bold
          &:not(.total)
            cursor pointer
          &.selected
            background #a8dcff
          &:first-child
            border-top border-cc
          .double_check
            cursor crosshair
            width 13px
            background-color white
            border-right border-cc
            &.checked
              background-color #25b925
            &:hover
              box-shadow inset 0 0 3px #949494
          .size, .quantity
            padding 5px 0
            {flex-center-center}
        .size
          border-right border-cc
          width 95px
          font-size 10px
          word-break break-all
        .quantity
          width 28px
          font-size 11px
        .current_item_order_status
          cursor pointer
          box-shadow 0 0 0 1px #000bff !important
          background-color #000bff !important
          color white !important
          padding 3px 5px !important
          margin-right 4px
        .title
          span
            padding 2px
            display inline-block
            border-radius 4px
            .task_stat_label
              padding 2px 6px
              margin-left 6px
              &.auto_task
                background #68cedf
                color black
              &.manual_task
                background #77dc15
                color black
          &.orange
            span
              background #ff7b23
              box-shadow 0 0 0 2px #ff7b23
              color #fff
          &.lightgreen
            span
              background #23FF00
              box-shadow 0 0 0 2px #23FF00
              color #000
          &.pink
            span
              background #ffa5ad
              box-shadow 0 0 0 2px #ffa5ad
          &.purple
            span
              background #b204d8
              box-shadow 0 0 0 2px #b204d8
              color #fff
        .title span[draggable="true"]
        .edit_sizes
          display none
    .link
      cursor pointer
    .tasks_container
      grid-template-rows grid-with-header
      &[show_hidden_tasks="false"]
        .task[is_hidden="true"]
          display none
      >.head.cell
        {flex-center-right}
        >.col
          margin-left 8px
          cursor pointer
          padding 5px 10px
          word-break keep-all
          text-align center
        >.show_hidden_tasks
          background #ffaed7
          color color_black
          border-radius 5px
        >.cancel_all_tasks
          background #666
          color #fff
          border-radius 5px
        >.create_all_issues
          background red
          color #fff
          border-radius 5px
        >.task_history
          background #cdcdcd
          //color #fff
          border-radius 5px

    .bp_stocks
      table
        width 100%
      .bp_stock
        border 0 solid #ccc
        border-top-width 1px
        &:last-child
          border-bottom-width 1px
        > *
          padding 8px
          border 0px solid #ccc
          border-left-width 1px
          text-align right
          &:last-child
            border-right-width 1px
        .code
          position relative
          top -2px
          display inline-block
          width 16px
          height 16px
          box-sizing border-box
          border 1px solid #eee
          margin-right 8px
          vertical-align middle
          border-radius 8px
    .warning_message2
      background #000 !important
      cursor initial !important
      a
        color #fff !important
        &:hover
          text-decoration underline
    .warning_message2
    .warning_message
      position relative
      background #FFC758
      padding 0 12px 0 8px
      line-height 28px
      border-radius 20px
      display inline-block
      font-size 13px
      margin-bottom 8px
      cursor pointer
      span
        display inline-block
        width 18px
        height 18px
        margin-right 4px
        border-radius 9px
        background #fff
        text-align center
        line-height 18px
        color #FF5923
    .spo_text_option_values
      margin-top 6px
      padding 10px
      line-height 20px
      border 1px solid #bbb
      background-color #eee
    .spo_text_option_values_title
      margin-bottom 6px
      font-weight 500
  .wow_ordnum
    cursor pointer
    display flex
    align-items center
  .holding_order_wrap
    cursor pointer
    display flex
    align-items center
    &.checked
      background orange
      border-radius 4px
      padding 4px
#body
  [prj_product_mode]
    .prj_item
      .up_list
      .bp_stocks
        display none
  [prj_product_mode="up_list"] .prj_item .up_list
    display grid
  [prj_product_mode="bp_stocks"] .prj_item .bp_stocks
    display inline-block
    width 100%
  select.prj_product_mode
    font-size 14px
  [prj_product_mode="up_list"]
  [prj_product_mode="bp_stocks"]
    .prj_item
      .payment_info_projection
      .payment_info_payments
        display none
  [prj_product_mode="payment"]
    .grid.prj_item_inner
      grid-template-columns 230px 444px 2fr !important
      .ship_and_ups
      .tasks_container
        display none
      .payment_info_projection
      .payment_info_payments
        display block
        table
          th
          td
            padding 8px
          th
            font-size 12px
          td
            font-size 13px
      .payment_info_projection
        border-left 1px solid #ccc
        border-right 1px solid #ccc
        >.left
        >.right
          display inline-block
          vertical-align top
          padding 8px 0 0 8px
        table
          margin-bottom 8px
          th
            width 74px
            text-align left
          td
            text-align right
            width 100px
      .payment_info_payments
        padding 10px
        .additional_payment
          padding 8px
          margin-bottom 8px
          border 1px dashed #000
          text-align center
          font-weight bold
        .payment
          position relative
          padding 8px
          margin-bottom 16px
          background #fafafa
          border 1px solid #999
          border-radius 4px
          h4
            margin 12px 0 8px
          &:not([pay_method="without_bank"]):not([pay_method="offline_card"]):not([pay_method="offline_cash"]) .without_bank
          &:not([pay_method="card"]):not([pay_method="trans"]):not([pay_method="samsung"]):not([pay_method="payco"]):not([pay_method="naverpay"]):not([pay_method="ssgpay"]):not([pay_method="lpay"]):not([pay_method="alipay"]):not([pay_method="kakaopay"]):not([pay_method="tosspay"]):not([payment_type="naverpay"]):not([payment_type="PAYMENTWALL_CREDIT_CARD"]):not([payment_type="KOMOJU_BANK_TRANSFER"]):not([payment_type="KOMOJU_PAY_EASY"]):not([payment_type="KOMOJU_KONBINI_DAILY_YAMAZAKI"]):not([payment_type="KOMOJU_KONBINI_LAWSON"]):not([payment_type="KOMOJU_KONBINI_FAMILY_MART"]):not([payment_type="KOMOJU_KONBINI_MINISTOP"]):not([payment_type="KOMOJU_KONBINI_SEICOMART"]) .imp
          &:not([pay_method="card_ini"]):not([pay_method="trans_ini"]) .ini
            display none
          >.title
            position relative
            margin-top 2px
            margin-bottom 8px
            > div
              display inline-block
            .id
              position absolute
              top -1px
              right 0
              display: inline-block;
              padding: 0 10px;
              height: 20px;
              line-height: 20px;
              border-radius: 15px;
              background: #000;
              color: #fff;
              text-align: center;
            .type
              font-size 16px
              font-weight bold
            .type.free:after
              content '전액 할인'
            .type.trans:after
              content '실시간계좌이체'
            .type.trans_ini:after
              content '이니시스 실시간계좌이체'
            .type.card:after
              content '신용카드'
            .type.samsung:after
              content '삼성페이'
            .type.payco:after
              content 'PAYCO'
            .type.naverpay:after
              content '네이버페이'
            .type.ssgpay:after
              content 'SSGPAY'
            .type.lpay:after
              content 'LPAY'
            .type.alipay:after
              content '알리페이'
            .type.card_ini:after
              content '이니시스 신용카드'
            .type.without_bank:after
              content '무통장 입금'
            .type.offline_card:after
              content '오프라인 카드'
            .type.offline_cash:after
              content '오프라인 현금'
            .type.vbank:after
              content '가상계좌'
            .type.kakaopay:after
              content '카카오페이'
            .type.tosspay:after
              content '토스페이'
            .type.kakao_shopping:after
              content '카카오쇼핑 주문'
            .type.PAYMENTWALL_CREDIT_CARD:after
              content 'Credit Card'
            .type.KOMOJU_BANK_TRANSFER:after
              content 'Bank transfer(지연결제)'
            .type.KOMOJU_KONBINI_SEICOMART:after
              content 'Seicomart(지연결제)'
            .type.KOMOJU_KONBINI_MINISTOP:after
              content 'Ministop(지연결제)'
            .type.KOMOJU_KONBINI_FAMILY_MART:after
              content 'FamilyMart(지연결제)'
            .type.KOMOJU_KONBINI_LAWSON:after
              content 'Lawson(지연결제)'
            .type.KOMOJU_KONBINI_DAILY_YAMAZAKI:after
              content 'Daily Yamazaki(지연결제)'
            .type.KOMOJU_PAY_EASY:after
              content 'PayEasy(지연결제)'
#body .projection_detail[frame_index="0"]
  margin-top 8px !important
  .projection_sidebar
    top 49px !important
  .don_page
    >.don_wrapper
      >.header
        top 49px

#body .projection_detail
  position fixed !important
  top 0
  left 0
  right 0
  bottom 0
  &:not([collabo_type="creator"])
    .tab_button[tab_name="projection_detail_creator_parent_tab"]
    .tab_button[tab_name="projection_detail_creator_sample_tab"]
    .tab_button[tab_name="projection_detail_creator_child_tab"]
      display none
  &:not([projection_type="merged_parent"])
    .tab_button[tab_name="projection_detail_creator_parent_tab"]
    .tab_button[tab_name="projection_detail_creator_sample_tab"]
    .tab_button[tab_name="projection_detail_creator_child_tab"]
      display none
  &[projection_type="merged_parent"]
    .projection_sidebar .options
    .tab_button[tab_name="projection.detail_user_projections"]
    .no_merged_parent
    button.add_color
    button.edit_sizes
    .prj_and_pay_controls
      display none !important
  button
    outline none
  .top_id
    position absolute
    top -5px
    right 10px
    font-size 22px
    font-weight bold
  .remove_repress
    position relative
    top -3px
    margin-left 10px
    border 0 none
    background #de513e
    color #fff
    border-radius 4px
    padding 8px
  .print_file_manager
    position fixed
    bottom 0
    left 262px
    right 0
    height 40px
    padding-top 8px
    padding-left 16px
    padding-right 16px
    border-top: 1px solid #ddd;
    background: #f5f5f5;
    overflow-y auto
    .btn
    button
      margin 0 4px 8px
    .btn
      display inline-block
    label
    div.btn
      background #000
      color #fff
      input
        display none
    div.btn
      margin-right 16px !important
      &.dragenter
        border-color red
        background-color #ff837b
  .don_page
    >.don_wrapper
      position absolute
      top 0
      left 0
      right 0
      bottom 0
      overflow auto
      >.header
        position fixed
        top 50px
        right 0
        left 0
        z-index 10
        margin-left 262px
      >.body
        margin-left 262px
        padding-top 50px
  .don_tab
    padding 30px 16px 60px
    box-sizing border-box
    h2
      margin 16px 0 !important
  .don_tab[tab_name="projection_detail_info"]
    .prj_item
      position relative
      min-height 80px
      .btn
        {flex-center-center}
        border border-cc
        &:hover
          background-color #ccc
          color white
      .shipping_list
        width 800px
      .ship_item:not(.inhouse)
        grid-template-columns auto 50px
        border border-cc
        padding 8px
        grid-gap 8px
        .col
          &.btn_wrap, &.prd_cs, &.info_wrap
            grid-gap 8px
        input
          font-size 13px
        select
          font-size 14px
        button.delivery_tracking
          width 110px
          height 30px
          margin auto
        button.inhouse_toggler
          display none
          width 150px
          height 30px
          margin auto
        .edit_ship,
        .edit_address,
        .to_waybill_from_type
          grid-gap 8px
          .type
            &.parcel:after
              content '택배'
            &.combined:after
              content '묶음택배'
            &.quick:after
              content '퀵배송'
            &.visit:after
              content '방문수령'
            &.partition:after
              content '개별배송'
        &.to_waybill_from_type
          grid-template-columns 72px 72px 136px auto
        &.edit_address
          grid-template-columns 0.85fr 3fr 1fr 3fr
      .ship_item.inhouse
        display flex
        flex-direction column
        gap 16px
        margin-bottom 14px
        .header
          margin 12px 0 0 12px
          display flex
          align-items center
          gap 12px
          > span
            font-size 16px
            font-weight bold
          button.inhouse_label_print
            display flex
            align-items center
            background-color #fff6e5
            gap 5px
            &:has(div.img.created)
              box-shadow inset 1px 2px 3px #6f6f6f
              background-color #e5fff1
            div.img
              background center / contain no-repeat
              width 1rem
              height 1rem
              &.created
                background-image url('//s3.marpple.co/files/u_1187078/2023/9/original/6422aaabcdd67137481df5d45da4d42a885c41821.png')
              &.null
                background-image url('//s3.marpple.co/files/u_1187078/2023/9/original/93a1b43734fc940b3c4688ce01c123f686d4a9572.png')
        .ship_info table
          width 100%
          th
            padding 10px
        .controllers
          display flex
          justify-content space-around
          button
            background-color #f5f5f5
            width 150px
            height 30px
            &.inhouse_toggler
              display none
    .orderer
      table
        margin-bottom 4px
        width 800px
        tr
          th
          td
            padding 8px
          td
            width 160px
          td.name
            width 90px
            input
              width 80px
          td.save
            width 100px
            padding 0
          input
            font-size 13px
    .special_request
      padding 10px
      max-height 120px
      overflow auto
      margin-bottom 8px
      font-style italic
      color #ffff00
      font-weight bold
      font-size 18px
      background: -webkit-linear-gradient(top, #5f5f5f 0%, #424242 12%, #000000 100%); /* Chrome10-25,Safari5.1-6 */ /* Chrome10-25,Safari5.1-6 */
      a
        color #ffff00
    .up_item_price
      table-layout fixed
      th.up_c
        width 480px
      td.up_c
        padding 0
      .product_color
        width 480px
        text-align center
        .product_face
          display inline-block
          width 120px
          cursor pointer
          .name
            padding-bottom 8px
  .bp_stocks
    table
      width 100%
    .bp_stock
      border 0px solid #ccc
      border-top-width 1px
      &:last-child
        border-bottom-width 1px
      > *
        padding 8px
        border 0px solid #ccc
        border-left-width 1px
        text-align right
        &:last-child
          border-right-width 1px
      .code
        position relative
        top -2px
        display inline-block
        width 16px
        height 16px
        box-sizing border-box
        border 1px solid #eee
        margin-right 8px
        vertical-align middle
        border-radius 8px

  .detail_payment
    &[lang="en"] .kr
    &[lang="en"] .jp
    &[lang="null"] .kr
    &[lang="null"] .jp
    &[lang="kr"] .en
    &[lang="kr"] .jp
    &[lang="jp"] .kr
    &[lang="jp"] .en
      display none
    .grid_wrapper
      display grid
      grid-gap 16px
    .grid
      border border-cc
      grid-gap 8px
      padding 16px
      &.prj_and_pay_controls
        grid-gap 32px
        .prj_control
          .row
            grid-template-columns 57px auto
    .row
      grid-gap 16px
      &.price_info_prices
        grid-template-columns 300px auto 430px
    .input_wrap
      > *:not(:last-child)
        margin-right 8px
    table
      tr
        th
          font-weight bold !important
        td
          color color_black
        td
        th
          padding 8px
          height 20px
          font-size 13px !important
    button
    .btnx
      font-size 13px !important
    .price_info
      tr
        th
          text-align left
          width 94px
        td
          text-align right
          font-size 14px
          div.order
            position relative
            width 100%
            height 28px
            line-height 28px
          input
            width 90px
            font-size 14px
            margin-right 4px
          button
            margin-left 8px
      tr.cancel_price
        display none
    &[is_cancel_req="true"]
    &[is_canceled="true"]
      tr.cancel_price
        display table-row
    .prj_and_pay_controls
      textarea
        width 315px
        height 130px

    .with_title
      .title
        padding-top 4px
      .input_wrap
        > div
          padding 0 0 8px
          button
            width 130px
          .btnx
            display inline-block
            border 1px solid #ccc
            border-radius 4px
            padding 4px 8px
            color color_black
            border-style dashed
            &.refund_done
            &.refund_req
              &:after
                content '' !important
              &.false:hover
                background-color #fff !important
                color color_black !important
              &.true
                background-color #000
                color #fff
              &.true:hover
                background-color #000 !important
                color #fff !important
          span
            margin-left 8px
    button
      outline none
      border 1px solid #ccc
      border-radius 4px
      padding 4px
      color color_black
      &:hover
        cursor pointer
        background-color #ccc
      &.imp_cancel
        color white
        background-color red
        &.canceled
          background-color #f1f3f5
        &:after
          content '됨'
      &.false
        &:after
          content '하기'
      &.true
        background-color #000
        color #fff
        &:hover
          cursor pointer
          background-color #ccc
          color color_black
        &:after
          content '됨'
    .projection_payments
      position relative
      select
        font-size 11px
      button
        font-size 13px
      h3
        margin 4px 0
        font-size 20px
      >.options
        position absolute
        top 16px
        right 16px
      .additional_payment
        position relative
        border 2px dashed #000
        font-weight bold
        padding 16px
        button
          display inline-block
        button
          position absolute
          top 11px
          right 16px
          margin-left 16px
      .payment
        margin-top 8px
        margin-bottom 24px
        background #fafafa
        border-color #999
        border-radius 8px
        .payment_body
          margin-top 4px
          label span
            display inline-block
          input
            display none
          button
            display none
        &:not([pay_method="without_bank"])
          .payment_body
            .without_bank
              display none
        &[pay_method="without_bank"]
          td.deposit_user_name
            label span
              display none
            input
              display inline-block
            button
              display inline-block
        &[pay_method="offline_card"]
        &[pay_method="offline_cash"]
        &[pay_method="without_bank"]
          td.paid_amount
            label span
              display none
            input
              display inline-block
            button
              display inline-block
        .remove
          margin-left 20px
        .pay_method_changer
          display none !important
        &[pay_method="without_bank"]
        &[pay_method="offline_card"]
        &[pay_method="offline_cash"]
          .pay_method_changer
            display inline-block !important
        >.title
          position relative
          margin-top 2px
          > div
            display inline-block
          .id
            position absolute
            top -1px
            right 0
            display: inline-block;
            padding: 0 10px;
            height: 20px;
            line-height: 20px;
            border-radius: 15px;
            background: #000;
            color: #fff;
            text-align: center;
          .type
            font-size 16px
            font-weight bold
          .type.free:after
            content '전액 할인'
          .type.trans:after
            content '실시간계좌이체'
          .type.trans_ini:after
            content '이니시스 실시간계좌이체'
          .type.card:after
            content '신용카드'
          .type.samsung:after
            content '삼성페이'
          .type.payco:after
            content 'PAYCO'
          .type.naverpay:after
            content '네이버페이'
          .type.ssgpay:after
            content 'SSGPAY'
          .type.lpay:after
            content 'LPAY'
          .type.alipay:after
            content '알리페이'
          .type.paypal:after
            content '페이팔'
          .type.card_ini:after
            content '이니시스 신용카드'
          .type.without_bank:after
            content '무통장 입금'
          .type.offline_card:after
            content '오프라인 카드'
          .type.offline_cash:after
            content '오프라인 현금'
          .type.vbank:after
            content '가상계좌'
          .type.kakaopay:after
            content '카카오페이'
          .type.tosspay:after
            content '토스페이'
          .type.kakao_shopping:after
            content '카카오쇼핑 주문'
          .type.PAYMENTWALL_CREDIT_CARD:after
            content 'Credit Card(지연결제)'
          .type.KOMOJU_BANK_TRANSFER:after
            content 'Bank transfer(지연결제)'
          .type.KOMOJU_KONBINI_SEICOMART:after
            content 'Seicomart(지연결제)'
          .type.KOMOJU_KONBINI_MINISTOP:after
            content 'Ministop(지연결제)'
          .type.KOMOJU_KONBINI_FAMILY_MART:after
            content 'FamilyMart(지연결제)'
          .type.KOMOJU_KONBINI_LAWSON:after
            content 'Lawson(지연결제)'
          .type.KOMOJU_KONBINI_DAILY_YAMAZAKI:after
            content 'Daily Yamazaki(지연결제)'
          .type.KOMOJU_PAY_EASY:after
            content 'PayEasy(지연결제)'
          .pay_method_changer
            margin-left 20px
            span
              font-size 12px
              font-weight bold
              margin-right 8px
          .move_to_other_projection
            margin-left 20px
            margin-right 10px
        &[pay_method="without_bank"]
          >.payment_body
          >.bks
            td:first-child
              input
                width 80px
                margin-right 8px
              button
                margin-left 8px
        &:not([pay_method="card_ini"]):not([pay_method="trans_ini"]) .refunds
          th.ini
          td.ini
            display none
        &:not([pay_method="card"]):not([pay_method="trans"]):not([pay_method="samsung"]):not([pay_method="payco"]):not([pay_method="naverpay"]):not([pay_method="ssgpay"]):not([pay_method="lpay"]):not([pay_method="alipay"]):not([pay_method="tosspay"]):not([pay_method="kakaopay"]):not([pay_method="vbank"]):not([payment_type="naverpay"]):not([pay_method="PAYMENTWALL_CREDIT_CARD"]):not([is_global_vbank_style="true"]):not([pay_method="PORTONE_PAYMENTWALL_CREDIT_CARD"]) .refunds
          th.imp
          td.imp
            display none
        &:not([is_global_vbank_style="true"])
          td.global_port_one_vbank_style
          th.global_port_one_vbank_style
            display none
        &:not([pay_method="vbank"]):not([pay_method="without_bank"]):not([pay_method="offline_card"]):not([pay_method="offline_cash"]) .refunds
          th.without_bank
          td.without_bank
            display none
      .refunds
        .global_port_one_vbank_style
          >*
            margin-right 8px
        .bank_code
        .bank_account
        .bank_user_name
          border-right 0 none
          padding 8px 0
        .bank_account
        .bank_user_name
        th.bank_save
        td.bank_save
          padding 8px 0
          border-left 0 none
      .refunds
      .bks
        h4
          margin 14px 0 10px
        tr[is_complete="false"]
          td
            background #fff8cc
        th
        td
          padding 6px
          button
            display inline-block !important
    .receipt
      padding-top 24px
      &[receipt_status="done"]
        .receipt_status:after
          content "발행 완료"
          color green
      &[receipt_status="need_issue"]
        .receipt_status:after
          content "발행 요청"
          color red
      &[receipt_status="need_cancel"]
        .receipt_status:after
          content "취소 필요"
          background yellow
          color red
      &[receipt_status="is_canceled"]
        .receipt_status:after
          content "취소 완료"
          background yellow
          color green
      &[receipt_status="receipt_error"]
        .receipt_status:after
          content "발행 실패"
          background red
          color black
      &[receipt_status="need_reissue"]
        .receipt_status:after
          content "재발행 필요"
          color red
      &[receipt_status=""]
        .receipt_status:after
          content "-"
      .self_receipt_wrap
        margin-bottom -4px
        padding-bottom 20px
        border-bottom 1px solid #d5dbe0
        max-width 477px
      h4
        display inline-block
        margin-right 16px
      .input_self_receipt
        margin-right 5px
        padding 5px
        width 180px
        border 1px solid #d5dbe0
      .input_self_receipt.price
        width 120px
      >button
        margin-right 16px
        display none
      .self_receipt_list
        width 478px
      .input_self_receipt
        display none
      &[receipt_status="need_issue"]
      &[receipt_status="is_canceled"]
        button.request_receipt
          display: inline-block;
      &[receipt_status="need_issue"][receipt_type="3"]
      &[receipt_status="is_canceled"][receipt_type="3"]
        button.request_receipt_manual
          display inline-block
        button.self_receipt
          display inline-block
        .input_self_receipt
          display inline-block
      &[receipt_status="done"]
        &[receipt_type="1"]
        &[receipt_type="2"]
          .show_receipt
            display inline-block
          .cancel_receipt_done
            display inline-block
        &[receipt_type="3"][old_invoice="false"][self_invoice="false"]
          .self_cancel
            display inline-block
      &[receipt_status="need_cancel"]
        .cancel_receipt
          display inline-block
      &[receipt_status="need_reissue"][receipt_type="3"]
        button.request_reissue
          display inline-block
        button.request_receipt_manual
          display inline-block
      &[receipt_status="need_reissue"][receipt_type="3"][self_invoice="true"]
        button.request_reissue
          display none
        button.self_receipt,
        .input_self_receipt
          display inline-block
      &[receipt_status="receipt_error"]
        button.request_receipt
          display inline-block
        &[invoice_modify="true"]
          button.request_reissue
            display inline-block
          button.request_receipt
            display none
      &.need_save
        .save_receipt
        .cancel_act
          display inline-block
        .request_receipt
        .request_reissue
          display none !important
      .iframe_wrapper_receipt
        width 500px
        height 700px
        margin-bottom 10px
        iframe
          width 100%
          height 100%
          box-sizing border-box
          padding 15px
      .tax-invoice
        display none
        justify-content space-between
        align-items center
        margin 20px 0
        padding-bottom 20px
        border-bottom 1px solid #d5dbe0
        max-width 480px
        input[type=date]
          box-sizing border-box
          padding 8px 16px
          height 40px
          border solid 1px #d5dbe0
          background-color #fff
          -webkit-appearance none
          outline none
          font-size 14px
        .radio-wrap
          label:not(:first-child)
            margin-left 10px

      &[receipt_status="need_issue"][receipt_type="3"],
      &[receipt_status="need_reissue"][receipt_type="3"]
        .tax-invoice
          display flex
      .radio_box.receipt_type
        >label
          margin-right 16px
      .hidden
        display none
      .input_file
        display inline-block
      .receipt_body
        margin-top 24px
      .postcode_layer
        width 500px
      >.body
        > div
          display none
        select
          padding 10px 5px
          margin-right 16px
        .input_wrap
          >label
            width 80px
            display inline-block
        select
        input[type="text"]
        .input
          font-size 14px
          border solid 1px #d5dbe0
          height 40px
          padding 8px 16px
          box-sizing border-box
          margin-bottom 8px
          html[lang="kr"] &
            margin-bottom 16px
          background-color #ffffff
          outline none
          -webkit-appearance none
          border-radius 0
      .img
        margin-top 10px
        width 100%
        max-width 500px
        img
          width 100%
      &[receipt_type ="0"]
        >button
          display none !important
        .save_receipt
        .cancel_act
          display inline-block !important
        .receipt_type > label:not(.none)
          opacity 0.5
      &[receipt_type ="1"]
        .receipt_type > label:not(.cash)
          opacity 0.5
        .cash.receipt_body
          display block
      &[receipt_type ="2"]
        .receipt_type > label:not(.company)
          opacity 0.5
        .company.receipt_body
          display block
      &[receipt_type ="3"]
        .receipt_type > label:not(.taxBill)
          opacity 0.5
        .taxBill.receipt_body
          display block
      >.option
        display none
        margin-top 24px
      .radio_box
        .row:nth-child(1)
          margin-bottom 8px
        .row.last
          display flex
          flex-wrap wrap
          label
            width 175px
            &:last-child
              margin-bottom 0
      .company
      .cash
        select
        input
        .input
          margin-bottom 8px !important
          &:last-child
            margin-bottom 0 !important
        select
          margin-bottom 8px
      .taxBill
        .wrapper
          display flex
          > button
            flex-grow 1
            width 50%
            font-size 14px
            padding 12px 0
            text-align center
            &:nth-child(1)
              margin-right 8px
.don_frame[frame_name="df.projection.detail.edit_sizes"]
.don_frame[frame_name="df.projection.detail.add_color"]
  > .don_wrapper
    width 500px
    height 700px
    text-align center
    padding-bottom 40px
    .title
      height 80px
      line-height 80px
    h4
      margin-top 0px
    table
      margin 0 auto
      width 430px
      input
        width 100px
        height 20px
        font-size 14px
    .options
      padding-top 23px
      button
        text-align center
        height 40px
        background-color #000
        font-size 14px
        color #ffffff
        border solid 1px #000
        font-weight 200
        cursor pointer
        font-weight bold
        &.reset
        &.cancel
          color color_black
          margin-right 16px
          background #fff
          border solid 1px #dee2e6

.don_frame.projection
  table
    width 100%
    background #fff
    border-collapse collapse
    th
    td
      padding 16px 8px 14px
      text-align center
      color color_black
      border 1px solid #ccc
    th
      background #f2f2f2
      &.discounted_price
        background #e6eff5
      &.total_price
        background #ffe9ed
    td
      border 1px solid #ccc
      &.discounted_price
        background #eef7fe
      &.total_price
        background #fff0f3
      &.name
        width 226px
        text-align left

#body
  .projection_detail
    >.don_wrapper
      >.header
        height 50px
        background #fff
        .title
          text-align center
          height 50px
          line-height 50px
          font-size 16px
          font-weight bold
        button
          position absolute
          display none !important
          text-align center
          padding 0
          top 8px
          border-radius 8px
          width 8px * 8
          height 48px - 16px
          line-height @height
        .delete
          display none !important
          background red
          color #fff
          right 8px * 10
          span:after
            content '삭제'
        .don_hide_frame
          display block !important
          left 8px !important
          background black
          color #fff
          span:after
            content '닫기'
      &[frame_index="0"]
        .don_head
          .done
            display none !important
    .don_page
      >.don_wrapper
        >.header
          height 40px
          .tab_buttons
            display flex
            width 100%
            border-top 1px solid #b3b3b3
            button
              border 0 none
              padding 0
              flex 1
              height 40px
              background #484848
              color #fff
              font-size 16px
              text-align center
              cursor pointer
              &[selected="true"]
                background #fff
                color #424140

    .don_tab[tab_name="products"]
      .fill_all_printable
        position relative
        top -2px
        margin-left 16px
      .grid.all_ups
        border 0 none
      .up_list
        .up_item
          border 1px solid #ccc
          border-radius 8px
          &[is_printable_product_shared="true"] .on_printable_product_shared
          &[is_printable_product_shared="false"] .off_printable_product_shared
            display none
          .head_between
            padding 8px 16px
          &[quantity="0"]
            display block
            border 1px dashed #777
            background #f0f0f0
            .add_color:after
              content '색상 변경'
            .options
              height 18px
              button
                display none
              &:before
                position absolute
                top 8px
                left 8px
                content '수량을 모두 없앤 상품입니다. 주문서 목록, 태스크, 고객 화면에 노출되지 않습니다. 복구하시려면 색상 변경 혹은 수량 변경을 해주세요.'
                font-weight bold
            .grid.up_c_ss
              grid-template-columns auto
            .up_c_s_list
            .printable.product_color
              display none
          .product_face_list
            grid-template-columns repeat(5, 1fr)
            @media screen and (max-width: 1720px)
              grid-template-columns repeat(4, 1fr)
          >.options
            position relative
            padding 8px
            text-align right
            button
              margin 0 4px
          .add_color
            margin-left 13px
            margin-right 8px
            &:after
              content '색상 추가'
          .up_memo
            {flex-top-left}
            >*
              flex 0.5
              display flex
            textarea
              height 120px
              font-size 13px
            textarea.memo_value, .text
              flex 1
              margin 0 8px 0 0
              padding 8px
              box-sizing border-box
            .text
              border 1px solid #ccc
              overflow-y auto
              font-size 13px
            .buttons
              width 50px
              button
                margin-bottom 8px
        .add_up_memo_worker
          background #f4e9b7
        .add_up_memo_seller
          background #d7e8f4
        .add_up_memo_seller_all
          background #f4e2ec
        .up_item[has_memo_worker="true"]
          .add_up_memo_worker
            display none
          .up_memo_worker
            &[mode="read"]
              .edit_mode
                display none
            &[mode="edit"]
              .read_mode
                display none
        .up_item[has_memo_seller="true"]
          .add_up_memo_seller
            display none
          .up_memo_seller
            &[mode="read"]
              .edit_mode
                display none
            &[mode="edit"]
              .read_mode
                display none
        .up_item[has_memo_seller_all="true"]
          .add_up_memo_seller_all
            display none
            background #f4e2ec
          .up_memo_seller_all
            &[mode="read"]
              .edit_mode
                display none
            &[mode="edit"]
              .read_mode
                display none
        .up_item[has_memo_worker="false"] .up_memo_worker
        .up_item[has_memo_seller="false"] .up_memo_seller
        .up_item[has_memo_seller_all="false"] .up_memo_seller_all
          display none
        .up_c_item
          padding 16px
          .title span[draggable="true"]
            display inline-block
            .name
              display none
            &:hover
              cursor grab
              background #ddd
            &:active
              cursor grabbing
          .current_item_order_status
            cursor pointer
            box-shadow 0 0 0 1px #000bff !important
            background-color #000bff !important
            color white !important
            padding 3px 5px !important
            margin-right 4px
          .edit_sizes
            margin-left 13px
            display inline-block

    .detail_price
      .up_item_price
        &[quantity="0"]
          display none
        .header
          margin 24px 0 8px
          font-weight bold
          font-size 16px

    &.repress_dragging
      .don_tab[tab_name="products"]
        .up_list
          .up_c_item
            .title > span:not([draggable="true"])
              display none
            .title span[draggable="true"]
              .name
                display inline-block

html#dream_factory #pc_preview.don_frame.pc_preview
  position fixed
#pc_preview.don_frame.pc_preview
  > .don_wrapper
    >.header
      height 50px
      background #efefef
      border-bottom 1px solid #ccc
      .title
        text-align center
        height 50px
        line-height 50px
        font-size 16px
        font-weight bold
      button
        position absolute
        display none !important
        text-align center
        padding 0
        top 8px
        border-radius 8px
        width 8px * 8
        height 48px - 16px
        line-height @height
        &.reverse
          right 10px
          display block !important
      .delete
        display none !important
        background red
        color #fff
        right 8px * 10
        span:after
          content '삭제'
      .don_hide_frame
        display block !important
        left 8px !important
        background black
        color #fff
        span:after
          content '닫기'

  &.reversed
    button.reverse
    .don_page .product_color.left .product_face .img
      color #fff
      background #555


  .don_page
    height 100%
    .don_wrapper
    >.don_wrapper > .body
    >.don_wrapper .don_tab
      position absolute
      top 0
      left 0
      right 0
      bottom 0
    .product_color.left
      position absolute
      top 0px
      left 0
      right 600px
      min-width 860px
      bottom 0
      text-align center
      .view_list
        > *
          display none
        > *.selected
          display block
      .product_face
        .img
          position absolute
          top 0
          left 0
          right 0
          bottom 0
          margin auto
          .line
            position absolute
            margin-top 4px
            border-radius 30px
            border dashed 1px #f1580f
      .design
        position absolute
        top 0
        left 0
        right 0
        bottom 0
        img
          position absolute
          top 0
          left 0
          bottom 0
          right 0
          margin auto
          max-width 90%
          max-height 90%
        .down
        .original_down
        .type
        .children
        .size_info
          display none
    .product_color.right
      position absolute
      top 0
      width 600px
      right 0
      bottom 0
      background #eee
      overflow auto
      padding-bottom 30px
      .view_list
        border-top 1px solid #ccc
        margin-bottom 16px
        a
        button
          margin 0
          padding 2px 10px
          border 1px solid #ccc
          border-radius 4px
          background #fff
          &:hover
            background #efefef
          &:active
            color color_black
            border-color #000
        a
          position relative
          top 4px
        > *
          position relative
        > *.selected
          background rgba(59,139,198,0.8)
          color #fff
        .view:first-child
          border-bottom 1px solid #ccc
          button
            margin-left 16px
          .img
            border-left 1px solid #ccc
            border-right 1px solid #ccc
          .infos
            position absolute
            top 19px
            left 80px
        .view
          cursor pointer
        .design
          margin-left 61px
          border-bottom 1px solid #ccc
          &[is_low_quality_agree="true"]
            .name
              background #ffd3db
          >.row
            grid-template-columns 50px 170px 1fr 1fr
            .size_info
              padding-top 2px
              font-size 12px
              > div
                text-align center
                padding 1px 0
              .request_bg_removal
              .flip
                font-weight bold
                color #ff7b2c
          .down
            border-left 1px solid #ccc
          .children
            .design
              margin-left 51px
              &:last-child
                border-bottom 0
              >.row
                grid-template-columns 50px 119px 1fr 1fr
          >.children
            display none
          &[cv_type="cv_group"]
            >.children
              display block
              border 1px solid #ccc
              border-left 0 none
              border-bottom 0 none
            .size_info .px
              display none
          .type
            font-size 12px
            text-align center
            border-right 1px solid #ccc
            div
              height 50px
              line-height 50px
            .color
              display none
              width 100%
              overflow hidden
            .name:after
              content '그림'
          &[is_embro="true"]
            .name
              overflow hidden
              padding 0 4px
              text-overflow ellipsis
              background #FC713E
              color color_black !important
            .name:after
              content ''
            >.row .size_info .px
              display none
          &[is_marpple_image="true"][is_embro="false"]
          &[cv_type="cv_text"]
          &[cv_type="cv_pure_text"]
          &[cv_type="cv_text_image"]
            .name
              overflow hidden
              text-overflow ellipsis
              padding 0 4px
              background #fcfc8a
              word-break break-all
              color color_black !important
            .name:after
              content ''
          &[cv_type="cv_group"]
            .name:after
              content '그룹'
            >.row .size_info .px
              display none
          &[cv_type="cv_text_image_pattern"]
          &[cv_type="cv_pattern"]
            .size_info .px
              display none
            .type
              .name:after
                width 80px
                content '패턴'
                font-size 16px
          &[cv_type="cv_old_text"]
          &[cv_type="cv_text"]
          &[cv_type="cv_pure_text"]
          &[cv_type="cv_text_image"]
            .size_info .px
              display none
          &[cv_type="cv_old_text"]
          &[cv_type="cv_text"]
          &[cv_type="cv_pure_text"]
          &[cv_type="cv_text_image"]
          &[cv_type="printable"]
          &[is_marpple_image="true"]
            .type
              text-align center
              grid-template-columns 70px 1fr
              .color
                border-left 1px solid #ccc
                display block
                font-size 16px
              .name:after
                width 80px
                content '글씨'
                font-size 16px
          &[cv_type="printable"]
            >.row
              grid-template-columns 50px 170px 1fr
            .type
              .name:after
                content '인쇄용'
          div.down
            text-align center
            padding-top 13px
            .original_down
              display none
              margin-left 8px
          &[cv_type="cv_image"][is_embro="false"]
            div.down
              .original_down
                display inline-block
          &[original_is_ai="true"]
            div.down
              .original_down
                background #281302
                border-color #FF7D27
                color #FF7D27
                font-weight bold
          .img
            position relative
            width 50px
            height 100%
            border-left 1px solid #ccc
            border-right 1px solid #ccc
            img
              position absolute
              top 0
              left 0
              bottom 0
              right 0
              margin auto
              max-width 90%
              max-height 90%
      .infos
        text-align left
      .name
        font-size 16px

    &[is_printable="false"]
      .name:after
        content ' (고객 시안)'

    &[is_printable="true"]
      .product_color.right
        button.dci
          display inline-block

.prj
  .special_request
  .up_memo
    padding 10px
    .body
      padding 10px
      max-height 120px
      overflow auto
      font-style italic
      background: -webkit-linear-gradient(top, #f4e9b7 0%,#f7e46a 12%,#f7e46a 100%); /* Chrome10-25,Safari5.1-6 */
  .special_request
    .body
      color #ffff00
      font-weight bold
      font-size 18px
      background: -webkit-linear-gradient(top, #5f5f5f 0%, #424242 12%, #000000 100%); /* Chrome10-25,Safari5.1-6 */
      a
        color #fff
  .up_memo_seller
    .body
      background: -webkit-linear-gradient(top, #d7e8f4 0%, #bed8f7 12%, #87b9f7 100%); /* Chrome10-25,Safari5.1-6 */
  .up_memo_seller_all
    .body
      background: -webkit-linear-gradient(top, #f4e2ec 0%, #f7dbe0 12%, #f7afc1 100%); /* Chrome10-25,Safari5.1-6 */
  .up_memo + .up_memo
    padding-top 0
  .special_request
    padding 0 !important
    margin-bottom 8px
    border-bottom 0 none !important
    word-break break-word
    .body
      color #ffff00
      font-weight bold
      font-size 18px
      background: -webkit-linear-gradient(top, #5f5f5f 0%, #424242 12%, #000000 100%) /* Chrome10-25,Safari5.1-6 */; /* Chrome10-25,Safari5.1-6 */
  .up_item
    border 1px solid #ccc
    border-radius 8px
    background #fff
    .auto_print_img
      &.by_customer
        background #506eff
        color #fff
        border-color #3e79ff
        margin-left 16px
    .product_color
      padding 0 8px
      .product_face_list
        grid-gap 8px
        grid-template-columns repeat(3, 1fr)
        @media screen and (max-width: 1720px)
          grid-template-columns repeat(2, 1fr)
      .no_front
        position relative
        border 1px solid #ff8f73
        background #ff8f73
        width 120px + 130px
        text-align center
        .name
          padding-top 6px
        &:before
        &:after
          content ''
          display block
          position absolute
          width 60px
          height 8px
          left 0
          bottom 0
          right 0
          margin auto
          background-color #fff;
          border-radius 2px
          top 16px
        &:before
          transform rotate(45deg)
        &:after
          transform rotate(-45deg)
      .product_face
        position relative
        border border-cc
        width 120px + 130px
        text-align center
        overflow hidden
        .left
          width 120px
          border-right 1px solid #ccc
          .name
            padding 6px 0 0
        .left
        .right
          overflow hidden
        .right
          width 130px
          box-sizing border-box
          margin-bottom -1px
          .designs
            a
            button
              margin 0
              padding 2px 4px
              border 1px solid #ccc
              border-radius 4px
              background #fff
              position relative
              top -3px
              &:hover
                background #efefef
              &:active
                color color_black
                border-color #000
            .design
              border-bottom 1px solid #ccc
              text-align center
              .size_info
                padding 6px 4px 6px 0
                font-size 12px
                text-align right
                .position
                .cm
                .price_mm
                  font-size 13px
                  span
                    font-size 11px
                    padding-right 4px
                .request_bg_removal
                .flip
                  font-weight bold
                  color #ff7b2c
                  font-size 11px
                .marpple_image_name
                  display none
              >.row
                grid-template-columns 40px 45px 45px
              .img
                position relative
                width 40px
                height 50.5px
                background #eee
                border-right 1px solid #ccc
                border-bottom 1px solid #ccc
                img
                  border 1px solid darkgray
                  position absolute
                  top 0
                  left 0
                  right 0
                  bottom 0
                  margin auto
                  max-width 90%
                  max-height 90%
              .type
                font-size 12px
                text-align center
                border-right 1px solid #ccc
                div
                  height 50px
                  line-height 50px
                .name
                  border-bottom 1px solid #ccc
                .color
                  display none
                  width 100%
                  border-bottom 1px solid #ccc
                  overflow hidden
                .name:after
                  content '그림'
              div.down
                padding-top 6px
                gap 1.5px
                display flex
                flex-direction column
                justify-content center
                align-items center
                border-bottom 1px solid #ccc
                button
                  position relative
                  left -1px
                  width 36px
                  font-size 12px
                .original_down
                  display none
              .has_not_bold
                display none
              &[cv_type="cv_image"][is_embro="false"]
                .img
                  height 72.5px
                .type
                  .name
                    height 72.5px
                    line-height 72.5px
                div.down
                  .original_down
                    display inline-block
              &[original_is_ai="true"]
                div.down
                  gap 3px
                  .original_down
                    background #281302
                    border-color #FF7D27
                    color #FF7D27
                    font-weight bold
              &[is_embro="true"]
              &[is_marpple_image="true"]
              &[cv_type="cv_text"]
              &[cv_type="cv_pure_text"]
              &[cv_type="cv_text_image"]
              &[cv_type="cv_text_image_pattern"]
                .marpple_image_name
                  display block
                  text-overflow: ellipsis;
                  overflow: hidden;
                  font-size 11px
              &[is_low_quality_agree="true"]
                .name
                  background #ffd3db
              &[cv_type="cv_text"]
              &[cv_type="cv_pure_text"]
              &[cv_type="cv_text_image"]
              &[cv_type="cv_text_image_pattern"]
                .marpple_image_name:before
                  content '폰트: '
              &[is_marpple_image="true"][is_embro="false"]
                background #fcfc8a
                .name:after
                  font-weight bold !important
                  content '마플' !important
              &[is_embro="true"]
                background #FC713E
                .name:after
                  content '패치'
                .size_info .px
                  display none
              &[cv_type="cv_group"]
                .name:after
                  content '그룹'
                .size_info .px
                  display none
              &[cv_type="cv_text"]
              &[cv_type="cv_pure_text"]
              &[cv_type="cv_text_image"]
              &[cv_type="cv_text_image_pattern"]
                .has_not_bold[has_not_bold="true"]
                  display block
                  color red
                  background blue
              &[cv_type="cv_text_image_pattern"]
              &[cv_type="cv_pattern"]
                .type
                  .name:after
                    content '패턴'
              &[cv_type="cv_text_image_pattern"]
                .size_info .px
                .marpple_image_name
                  display none
              &[cv_type="cv_pattern"]
                .marpple_image_name
                  display none
              &[cv_type="cv_old_text"]
              &[cv_type="cv_text"]
              &[cv_type="cv_pure_text"]
              &[cv_type="cv_text_image"]
              &[is_marpple_image="true"]
                .type
                  div
                    height 24.5px
                    line-height 24.5px
                  .color
                    height 25px
                    display block
                  .name:after
                    content '글씨'
                .size_info .px
                  display none
              &[is_marpple_image="true"]
                .type
                  div
                    height 36px !important
                    line-height 36px !important
                  .color
                    height 36px !important
              &:not([cv_type="cv_image"])
                .original_down
                  display none
          .down
            .edit
              display none
        .thumb
          position relative
          &:hover
            button.open_podshop.print_image
              z-index 99
              display block !important
              position absolute
              left calc(50% - 40px)
              opacity 70%
              bottom 50px
              width 80px
              height 35px
        .pf_loc_optimization
          font-weight 700
          color limegreen
          font-size 11px
          margin-bottom 3px
        .justify-size-info
          text-align center
          font-size 13px
          font-weight bold
          background-color #dadada
          padding 0.5rem 0
          margin 0 0.5rem
          margin-bottom 0.5rem
          border-radius 0.5rem
        .download_ci
          border-bottom 1px solid #ccc
          padding-bottom 7px
        .download_ci_case
          border-top 1px solid #ccc
          padding 7px 0
          button
            background #506eff
            color #fff
            border-color #3e79ff
            &:hover
              border-color blue
        .printable_files
          .options
            border-radius 4px
            padding 6px 0
            background #000000
            color #fff
          label
            display flex
            justify-content center
            align-items center
            font-size 15px
            user-select none
            input[type="checkbox"]
              margin-left -4px
          .only_print_type
          .only_print_type_all
          .upload
          .upload_all
            display inline-block
            background #eee
            width 80px
            color #333
            font-size 12px
            border-radius 8px
            padding 4px 6px 3px
            border none
            cursor pointer
            input
              display none
            &:hover
              text-decoration underline
              background #ddd
          .only_print_type_all
          .upload_all
            margin-bottom 4px
            background #666
            color #fff
            &:hover
              background #555
          .file
            gap 5px
            display flex
            justify-content center
            flex-direction column
            position relative
            margin 8px 0 0
            box-sizing border-box
            width: 100%;
            padding: 30px 8px 8px;
            background-color: #eee;
            border-radius: 3px;
            min-height 210px
            &[_url=""]
              min-height 169px
              .id
              .download
                display none !important
              .img
                background #cdf4cd
            &[_is_copied="true"]
              .img
                &:after
                  display inline-block
                  padding-top 34px
                  content '**사본**'
                  font-size 18px
                  font-weight bold
                  color #ee619b
                img
                  display none
            .select_press_type
              display flex
              justify-content center
              align-items center
              position absolute
              top 0
              left 8px
              right 8px
              text-align center
              min-height 30px
              line-height 14px
              font-size 13px
              margin-bottom 5px
              padding 0
              cursor pointer
              font-weight bold
              text-decoration: underline;
              color #ff1000
            .remove
            .remove_all
              display: inline-block;
              height: 15px;
              line-height: 12px;
              font-size: 11px;
              margin: 0;
              padding: 0;
              cursor: pointer;
              text-decoration: underline;
              &:hover
                font-weight bold
                color red
            .remove
              display none
            .download
              outline none
              background-color #fff
              border-color #dbdbdb
              border-style solid
              border-width 1px
              color #000
              cursor pointer
              justify-content center
              padding-bottom calc(0.375em - 1px)
              padding-left 0.75em
              padding-right 0.75em
              padding-top calc(0.375em - 1px)
              text-align center
              white-space nowrap
              border-radius 4px
              &:hover
                background-color dodgerblue
                font-weight bold
            .img
              position: relative;
              width 100%
              height: 100px;
              cursor: pointer;
              background-color: #d1d1d1;
              border-radius: 3px;
              border 1px solid darkgrey
              .id
                position absolute
                top 0
                left 0
                right 0
                height 20px
                line-height 20px
                font-size 11px
                text-align center
                background rgba(255, 255, 255, 0.2)
              img
                position absolute
                top 20px
                left 0
                right 0
                bottom 0
                margin auto
                max-width 90%
                max-height 80px
                border 1px solid darkgray
            .justify-size
              text-align center
              font-weight bold
              font-size 13px
              border-radius 3px
              padding 4px 0
              background-color yellow
    .printable
      margin-top 16px
      padding 8px
      background-color gainsboro
      border 1px solid #666
      box-sizing border-box
      .product_face
        border 1px solid #666
        background #fff
        .down .edit
          display block !important
      h4
        padding 0 0 6px
        margin 0
    .printable_files .file[is_pd_land="true"]
      .download
        display none !important
      .original_name_download
        margin 0 auto
        background none
        display block !important

.projection_list_container
  #mode_selector
    height 50px
    position relative
    margin 0 16px
    select.prj_product_mode
      position absolute
      top 0
      right 0
      padding 0 0 6px
      vertical-align top

#body .don_tab:not([tab_name="products"])
  .prj .up_item .product_color .product_face
    .printable_files .file
      min-height 190px
    .download_ci
    .printable_files .options
    .printable_files .file .remove
    .printable_files .file .remove_all
      display none
    .printable_files .file .download
      bottom 6px
    .printable_files .file .select_press_type
      text-decoration none
      cursor auto
      &:after
        content ''
        position absolute
        top 0
        left 0
        right 0
        bottom 0
        display block

.don_frame[frame_name="projection.all"]
.don_frame[frame_name="df.task.list"]
.don_frame[frame_name="df.task.detail"]
  .prj .up_item .product_color .product_face[has_more_design="true"] .right .designs
    max-height 260px
    overflow hidden
  .prj .up_item .product_color .product_face .right .show_all_design
    display none
    margin 4px
    padding 4px
    font-size 12px
    background #333
    border-radius 4px
    color #fff
    cursor pointer
  .prj .up_item .product_color .product_face[has_more_design="true"] .right .show_all_design
    display block
    &:after
      content '디자인 더 보기'
  .prj .up_item .product_color .product_face.showed_all_design .right .designs
    max-height none !important
  .prj .up_item .product_color .product_face.showed_all_design .right .show_all_design
    &:after
      content '디자인 가리기'

html.projection#dream_factory
html.task_list#dream_factory
html.checkpoint#dream_factory
html.advance_reservation#dream_factory
  #body
    .don_frame[frame_name="projection.all"]
      .don_page
        >.don_wrapper
          >.header
            margin 30px
    .don_frame[frame_name="projection.all"]
    .don_frame[frame_name="df.task.list"]
    .don_frame[frame_name="@FRAME/Df/AdvanceReservation/list"]
    #checkpoint
      .don_tab
        margin-bottom 100px
      #filter
        margin 0 20px
        padding 24px 0
        width auto
        z-index 0
        .title
          h1
            &.all:before
              content '모든 '
            &.before_print:before
              content '제작준비중 '
            &.printing:before
              content '제작중 '
            &.before_deliver:before
              content '제작완료 '
            &.delivering:before
              content '배송중 '
            &.before_confirm:before
              content '배송완료 '
            &.confirmed:before
              content '구매확정 '
            &.refund_cancel:before
              content '취소/환불 '
            &.ing:before
              content '작업중 '
        form#search_bar
          input
          select
            padding 4px 8px
            border-radius 4px
            border 1px solid #d5dbe0
            font-size 14px
          h3
            margin 0
          .search_result
            button
              width 200px
              height 32px
              background-color #fff
              border 1px solid #d5dbe0
              font-size 16px
              font-weight 500
              &.go
                border-color #000
                background-color #000
                color #fff
          .sd
            padding 4px 10px 4px 0
            background-color #f6f6f6f6
            margin-bottom 8px
            line-height 27px
            >.s_col.title:first-child
              min-width 94px
              margin 0 16px
            .s_col
              display inline-block
              vertical-align top
              margin-right 8px
              &.m
                margin-right 16px
              &.title
                font-weight bold
                margin 0 16px 0 32px
                font-size 15px
              label
                display flex
                align-items center
              input[type="text"]
              input[type="number"]
                width 100px
                &[name="user_info"]
                  width 150px
              input[type="checkbox"]
                width 16px
                height 16px
                flex-shrink 0
              input.due_at
                width 150px
      #list_loader
        text-align center

#body .don_frame #filter form#search_bar
  .title[value]:not([value=""])
  input[type="date"]:not([value=""])
  input[type="number"]:not([value=""])
  input[type="text"]:not([value=""])
    color #1b77ff
    font-weight bold
  .search_due_at
    position relative
    min-height 27px
    .title
      position absolute
      top 6px
      left 0px
    .dates
      margin-left 230px
      .s_col:not(.due_at)
        margin 0 !important
      .due_at_filter_item
        display inline-block
        margin 2px 4px 2px 0
        padding 0 6px 1px 2px
        border-radius 3px
        background #fff7a4
        &[value="old_day"]
        &[value="today"]
          background #ffc679
      .due_at_filter_item
        //&[count="0"]
        //  display none
        input
          position relative
          top 1px
          &:checked + span
            color #1b77ff
            font-weight bold
    &.important
      .dates
        .due_at_filter_item
          &[value="old_day"]
          &[value="today"]
            background #ff9886

.don_select_list.press_types
  margin-top -140px
  margin-left 5px
  .selected_true
    display none
  ul
    padding-bottom 0

.projection_sidebar
  padding 8px
  &[merged_type="sample"]
    [merged_type="parent"]
      display none
  [status="ordering"] .status:after
    content '입금대기중'
  [status="before_print"] .status:after
    content '제작준비중'
  [status="printing"] .status:after
    content '제작중'
  [status="before_deliver"] .status:after
    content '제작완료'
  [status="delivering"] .status:after
    content '배송중'
  [status="before_confirm"] .status:after
    content '배송완료'
  [status="confirmed"] .status:after
    content '구매확정'
  [status="canceled"] .status:after
    content '주문취소'
  //[type="parent"] .type:before
  //  content '주문서'
  [shipping_type="parcel"]
    .type:after
      content '택배'
  [lang="en"][shipping_type="parcel"]
    .type_status
      background #d283ff !important
  [lang="jp"][shipping_type="parcel"]
    .type_status
      background #95FFF7 !important
  &[has_combined="true"] [shipping_type="parcel"]
    .type_status
      background #ff6ea5 !important
  [shipping_type="visit"]
    .type_status
      background #b7ffb5 !important
    .type:after
      content '방문수령'
  [shipping_type="quick"]
    .type_status
      background #ffec6a !important
    .type:after
      content '퀵'
  [shipping_type="partition"]
    .type_status
      background #7ccfff !important
    .type:after
      content '개별배송'
  [type="combined"]
    .type_status
      background #ff83b2 !important
    .type:after
      content '택배(묶음)'
  [shipping_type="quick"][type="combined"]
    .type:after
      content '퀵(묶음)'
  [shipping_type="visit"][type="combined"]
    .type:after
      content '방문(묶음)'
  [merged_type="parent"]
    .type_status
      background #ffa664 !important
    .type:after
      content '병합 '
  [is_repress="true"] .type:before
    content '재제작 '
  [is_repress="true"][status="ordering"] .status:after
    content '생성중'
  [is_repress="true"]
    .user
    .price
      display none
  .payment_type.free:after
    content '전액 할인'
  .payment_type.trans:after
    content '실시간계좌이체'
  .payment_type.trans_ini:after
    content '이니시스 실시간계좌이체'
  .payment_type.card:after
    content '신용카드'
  .payment_type.samsung:after
    content '삼성페이'
  .payment_type.payco:after
    content 'PAYCO'
  .payment_type.naverpay:after
    content '네이버페이'
  .payment_type.ssgpay:after
    content 'SSGPAY'
  .payment_type.lpay:after
    content 'LPAY'
  .payment_type.alipay:after
    content '알리페이'
  .payment_type.card_ini:after
    content '이니시스 신용카드'
  .payment_type.without_bank:after
    content '무통장 입금'
  .payment_type.offline_card:after
    content '오프라인 카드'
  .payment_type.offline_cash:after
    content '오프라인 현금'
  .payment_type.vbank:after
    content '가상계좌'
  .payment_type.kakaopay:after
    content '카카오페이'
  .payment_type.tosspay:after
    content '토스페이'
  .payment_type.point:after
    content '포인트(이니시스)'
  .payment_type.kakao_shopping:after
    content '카카오쇼핑 주문'
  .projection_item
    position relative
    background #fff
    border-radius 8px
    border 2px solid #ddd
    top 1px
    left 1px
    margin-bottom 14px
    width 208px
    .small_info
      display none
    &:last-child
      margin-bottom 0
    .check
      display none
      position absolute
      top 0
      bottom 0
      margin auto 0
      left -34px
      border 2px solid #aaa
      border-radius 50%
      width 22px
      height 22px
      cursor pointer
      &:hover
        background #88BCC6
    &.selected
      margin-bottom 12px
      top 0px
      left 0px
      border 3px solid #386ac6
      box-shadow 0px 0px 10px rgba(59,139,198,0.8)
      .check
        border 2px solid #386ac6
      .check:after
        content ''
        display block
        position absolute
        top 4px
        left 4px
        right 4px
        bottom 4px
        background #386ac6
        border-radius 50%
      .type
        font-weight bold
      .type_status
        border-bottom 1px solid #386ac6
      tr.user
        display table-row
        html.projection.list.line[mp_worker_policy="false"] &
          display none
    tr.user
      display none
    .type_status
      display flex
      align-items center
      position relative
      height 27px
      border-radius 4px 4px 0 0
      background #eee
      border-bottom 1px solid #ddd
      cursor pointer
      .shop-waybill-status
        border none
        background url('//s3.marpple.co/files/u_1187078/2023/12/original/20f14539e9e2e22af915a2fa9655df6bf6a408b01.png') center/contain no-repeat
        margin-left 3px
        width 30px
        height 16px
        transition: transform 0.3s ease
        &:hover
          transform translateX(3px)
    .type
      padding 5px 15px 4px 5px
      border-radius 4px 4px 0 0
      display inline-block
      font-size 14px
    .status
      position absolute
      top 0
      right 0
      padding 5px 5px 4px
      font-size 13px
    .cancel_refund
      padding 4px
      font-size 13px
      text-align center
      border-bottom 1px solid #ddd
      div
        display inline-block
        width 20.5%
        color: #ccc;
        border: 1px dashed #ccc;
        font-size 10px
        font-weight normal
        border-radius 8px
        padding 4px 1%
        background #fff
    &[is_cancel_req="true"] .cancel_refund .is_cancel_req
      color #fff
      background #ff523a
      border-color #ff523a
      border-style solid
    &[is_refund_req="true"] .cancel_refund .is_refund_req
      color #fff
      background #d22bff
      border-color #d22bff
      border-style solid
    &[is_refunded="true"] .cancel_refund .is_refunded
    &[is_canceled="true"] .cancel_refund .is_cancel_req
    &[is_canceled="true"] .cancel_refund .is_canceled
      color #fff
      background #000
      border-color #000
      border-style solid
    &[is_cancel_req="false"][is_canceled="false"][is_refund_req="false"][is_refunded="false"]  .cancel_refund
      display none
    &[is_canceled="true"]
      .type_status
      .cancel_refund
      .for_task
      .for_projection
      .price
      .small_info
        opacity 0.4
    .store_sales_period
      display none
    &[merged_type="parent"]
      .user
        display none !important
      .store_sales_period
        display table-row
        th
          color #13b318
        &.red
          th
            color #ff2727
          th
          td
            background #ffd9d9

    .quick_cancel
      margin-top 16px
      width 100%
      padding 8px 0 6px
      background-color saddlebrown
      color #fff
      font-weight bold
      display none
      &:hover
        background darkred
        color yellow
      html:not([master_admin="true"]) &
        display none !important
    &[is_can_quick_cancel="true"][is_refund_req="true"][is_refunded="false"][is_cancel_req="true"][is_canceled="false"] .quick_cancel
      display block



.don_page[page_name="projection.detail"]
  >.don_wrapper
    >.projection_sidebar
      .projection_item
        .quick_cancel
          display none !important
        &[receipt_status="done"]
        &[receipt_status="need_issue"]
        &[receipt_status="need_cancel"]
        &[receipt_status="need_reissue"]
          span.link
            display block
          .link:after
            margin-left 0 !important

.projection_sidebar
  .projection_item
    .link:after
      position relative
      top -1px

.projection_sidebar
  .projection_item
    &[type="merged_parent"][is_shopify="false"] .price
    &[type="parent"][is_shopify="true"] .price
      display none
      table
        border-radius 0 0 8px 8px
      tr.user:last-child
        th
        td
          border-bottom 0 none
          border-radius 0 0 8px 8px


.projection_sidebar
  &[data-is_partial_delivery="true"]
    .partial-delivery__text
      display table-cell !important
  .projection_item
    &[receipt_status="done"]
      .link:after
        content "증빙 완료"
        font-size 12px
        background #4cc37f
        color #fff
        padding 2px 5px
        margin-left 10px
        border-radius 4px
    &[receipt_status="need_issue"]
    &[receipt_status="need_reissue"]
      .link:after
        content "증빙 필요"
        font-size 12px
        background #fc5926
        color #fff
        padding 2px 5px
        margin-left 10px
        border-radius 4px
    &[receipt_status="need_cancel"]
      .link:after
        content "취소 필요"
        font-size 12px
        background #fc5926
        color #fff
        padding 2px 5px
        margin-left 10px
        border-radius 4px
    &[receipt_status="is_canceled"]
      .link:after
        content "취소 완료"
        font-size 12px
        background #4cc37f
        color #fff
        padding 2px 5px
        margin-left 10px
        border-radius 4px
    .for_projection
      display table
      position relative
    .for_task
      display none
    table
      border 0 none
      table-layout fixed
      width 208px
      tr.admin
        html[master_admin="false"] &
          display none
      tr.no_admin
        html[master_admin="true"] &
          display none
      th
      td
        border 0 none
        border-bottom 1px solid #ddd
        padding 4px 2px 4px 8px
        text-align left
        vertical-align middle
      th
        padding 4px 0 4px 8px
        font-size 11px
        color #666
        background #fff
      .th
        position relative
        top 1px
        font-size 11px
        vertical-align top
        color #666
        font-weight bold
        padding-left 8px
        padding-right 2px
      td
        padding-left 0
        font-size 13px
        &.text_projection_id
          .id_container
            font-size 18px
            display flex
            align-items center
            .projection_code_image
              image-rendering pixelated
              width 40px
              margin 0 auto
              cursor pointer
              &:hover
                filter drop-shadow(0 0 1px gray)
        &.shop_title
          border-bottom none
          text-align center
          span
            display inline-block
            vertical-align middle
        &.quantity
          font-size 18px
        &.quantity
          .th
            padding-left 0
            padding-top 3px
            display inline-block
        div
          word-break break-all
          font-size 12px
          &.name
            font-size 13px
      input.due_at
        font-size 13px
        width 100px
      .due_at[internal-important="true"]
        th
        td
          background #eca506
      .due_at[important="true"]
        th
        td
          background #ff382f
          color #fff
      a:hover
        text-decoration underline
    .price
      text-align center
      height 30px
      line-height 30px
      font-weight bold
      &[paid_amount="0"] span
        color #fc5926 !important
      &[paid="false"] span
        color #0052b3
      &[paid="true"] span
        color #32c365 !important
    &[is_cancel_req="true"] .price span
    &[is_canceled="true"] .price span
      color #333 !important
    &[is_refund_req="true"] .price span
      color #d22bff !important
    .link
      display block
      border-top 1px solid #ddd
      text-align center
      height 30px
      line-height 30px
      background #f5f5f5
      border-radius 0 0 4px 4px
    a.link
      &:hover
        background #dddddd
    span.link
      display none

.projection_list_container
.task_list
.task_detail_page
  .projection_sidebar
    .cancel_refund
      display none

.projection.list.all
.projection.list.refund_cancel
  .projection_list_container
    .projection_sidebar
      .cancel_refund
        display block

.repress_dragging
  .projection_item
    .droparea
      position absolute
      top 0
      left 0
      right 0
      bottom 0
      opacity 0.6
      z-index 2
      &.dragenter
        background #ffec6a

.don_frame:not(.projection_detail)
  .projection_item
    .type
    .price
      cursor pointer
      &:hover
        text-decoration underline
    .type_status
    .check
      cursor initial
    &:not(.selected) .check
      display none

#body
  .don_frame.projection_detail
    .prj .grid.prj_item_inner
      grid-template-columns 230px auto 440px
    .user_projections
      .product_face_list
        grid-template-columns repeat(3, 1fr)
        @media screen and (max-width: 1900px)
          grid-template-columns repeat(2, 1fr)
      .task
        font-size 12px !important

#body
  .projection_sidebar:not(.in_projection_detail)
    .projection_item:not(.selected)
      > table
      > .price
        display none
      .link
        font-size 12px
        height: 26px;
        line-height: 26px;
      .small_info
        display block
        text-align center
        padding 4px
        .price
          height auto
        *
          line-height 1.4
          font-size 13px
          font-weight normal

#body .don_frame.projection_detail
  a[target="_blank"]:hover
    text-decoration underline
  .don_page >.don_wrapper >.projection_sidebar
    position fixed
    top 50px
    left 0
    bottom 0
    overflow auto
    width 244px
    border 1px solid #ccc
    padding-top 20px
    padding-bottom 60px
    .projection_item
      margin-left 30px
      .check
        display block
    a.link
      display none
    .options
      position fixed
      width 241px
      bottom 0
      left 0
      right 0
      padding 10px
      border-top 1px solid #ddd
      background #f5f5f5
      text-align center
      z-index 2

[prj_product_mode="bp_stocks"]
.task_detail_page
  .projection_item
    width 404px
    .for_projection
      display none
    .for_task
      display table
      width 404px
      td
        font-size 12px
    table
      tr.admin
        display none !important
      tr.no_admin
        display table-row !important
.task_detail_page
  .projection_item
    .for_task
      width 398px


.prj_item[merged_type="parent"]
  .shipping_list
    display none !important

.prj_item[is_seller_support="true"]
  .up_list *
    background-color #5f93ff
  .shipping_list
    display none !important

#shipping_task_statuses
  .aztec_code
    box-shadow rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px
    cursor pointer

#biz_product_customer_request_info
  display flex
  flex-direction column
  gap 16px
  .item
    display flex
    .header
      display flex
      align-items center
      text-align right
      margin-right 12px
    .body
      display flex
      width 680px
    &.req_memo
      textarea
        outline none
        padding 8px
        font-size 14px
    &.ref_file
      button.download
        width 100%
        height 28px
        text-align left
