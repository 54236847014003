// modules/Df/FileIssueModal/F/Style/Pc/index.styl
.file-issue-modal.don_frame
  > .don_wrapper
    width 800px
    height 652px
  .pdf-file-error-modal-wrapper
    > .header
      display flex
      align-items center
      padding 8px 10px
      .title
        flex 1
        text-align center
        font-size 21px
        line-height 150%
        font-weight bold
      .close
        padding 4px
        cursor pointer
        width 40px
        height 40px
        display flex
        justify-content center
        align-items center
        font-size 21px
        line-height 100%
        font-weight bold
      .empty
        width 32px
    > .body
      padding 8px 10px
      label
        font-size 16px
        line-height 150%
        font-weight bold
      textarea
        box-sizing border-box
        width 100%
        height 245px
      .btn-area
        display flex
        justify-content flex-end
        gap 4px

