
issue_list =
  .issue
    border 1px solid #cc
    background #fff
    position relative
    margin-bottom 8px
    >.option label
      display none
    >.option .confirm
      cursor pointer
      display none
    &[is_read="false"]
      label
        display none
      >.option .confirm
        background #e25453
        color #fff
        display block
    &[is_read="true"]
      opacity 0.5
      label
        display block
      >.option .confirm
        display none
    >.option
      position absolute
      border none !important
      padding 0 !important
      top 3px
      right 10px
      .confirm
        padding 5px 16px
        border-radius 8px
    >div:not(.info)
      border-bottom 1px solid #cc
      padding 8px
    .title
      background-color #ccc
      min-height 20px
      box-sizing initial
    .info
      display grid
      border none
      grid-template-columns 0.5fr 0.5fr 0.5fr 0.5fr
      grid-gap 1px
      background #ccc
      >div
        padding 8px
        background #fff
      >div:not(.issues_users)
        {flex-center-center}
      .issues_users
        padding-left 16px
      .thumb_issue_user, .issues_users, .add_issue_user
        grid-row 1/3
      .cancel_issue
        grid-row 1/2
      .add_issue_user, .cancel_issue
        cursor pointer
      .add_issue_user:hover, .cancel_issue:hover
        background #ccc
      .created_at
        grid-column 4/5
        grid-row 2/3
    .issue_user
      display grid
      grid-template-columns 1fr 1fr

.don_page[page_name="issue.create_mult"]
  .user_body
    display none !important

.don_page[page_name="issue.my_list"]
  .grid.prj_item_inner
    grid-template-columns 0.5fr 1fr !important
    height 100% !important
  .issue_list
    >.head
      background #f00
      color #fff
      padding 10px
      font-size 18px
    >.body.issues
      padding 10px
      {issue_list}
  .task_item
    grid-template-columns 600px 1fr !important
//.don_page[page_name="issue.my_list"]
//  >.wrapper
//    {jip-don_page_head}
//    >.head
//      grid-template-columns 1fr 4fr 1fr
//      margin-bottom 24px
//    {issue_list}

.don_page[page_name="issue.create_one"], .don_page[page_name="issue.create_mult"]
  .don_wrapper >.wrapper
    {jip-don_page_head}
    >.head
      grid-template-columns 1fr 4fr 1fr
      margin-bottom 24px
  div
    box-sizing border-box
  .add_btn
    width 20px
    height 20px
    background i_plus no-repeat center
  .issuees_wrapper, .issuer_wrapper
      border 1px solid #cc
  .issue_body, .user_body
    width 600px
    margin 0 auto
    >div
      margin-bottom 16px
      font-size 18px
  .user_body
    .add_issues_users
      display flex
      cursor pointer
      width 80px
      height 30px
      border-radius 10px
      padding 5px 0 3px 0
      position absolute
      color #fff
      justify-content center
      right 8px
      top 5px
      background-color create_color
    display grid
    grid-template-columns 1fr 1fr
    grid-gap 3px
    > div
      border 1px solid #ccc
      >.head
        border-bottom 1px solid #ccc
        padding 8px
        position relative
      >div:nth-child(2)
        height 200px
        padding 8px
  .issue_body
    > div
      //display grid
      //grid-template-columns 1fr 444px
      display flex
      border-top 1px solid #cc
      padding-top 20px
    textarea, input
      font-size 18px
      width 444px
    label
      margin-top 10px
      width 156px
    .title
      height 60px
    .body
      height 300px


.img_collabo
  display flex
  img
    height 400px
    width auto

.modal_man
  position fixed
  width 600px
  height 500px
  margin auto auto
  top 0
  right 0
  left 0
  z-index 2
  bottom 0
  background #fff
  box-shadow 0 0 3.4em rgba(0, 0, 0, 0.4)
  overflow scroll
  .wrapper
    {jip-don_page_head}
    >.head
      grid-template-columns 90px 1fr 90px
      margin-bottom 16px
    padding 8px
    {issue_list}
    .info
      grid-template-columns 1fr 1fr 1fr !important
    .add_issue_user
      display none !important
    .cancel_issue
      display none !important
    .info > div
      grid-row 1/2 !important
    .thumb_issue_user
      grid-column 1/2 !important
    .add_issue_user
      grid-column 2/3 !important
    .created_at
      grid-column 3/4 !important

.don_page[page_name="task.detail"]
  {issue_list}
