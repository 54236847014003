// modules/Df/OMP/PaperPriceSync/F/Style/Pc/index.styl
.paper-price-sync
  padding 16px
  &__detail-wrap
    margin-top 20px
  &__wow-title
    display flex
    gap 8px
  &__effected-list
    display flex
    gap 8px
  &__item
    padding 9px 0
    border-bottom 1px solid #ededed
  .row-flex
    display flex
    gap 8px
    align-items center
  .chip
    border-radius 8px
    background-color #f2f2f2
    padding 4px 8px
