.don_frame[frame_name="/modules/Df/Outsourcing/Company/UserProductExpense/S/Mui/frame.js"]
  .download-excel
    font-size 15px
    font-weight bold
    padding 9px 47px
    text-align center
    color white
    background-color #3c89f9
    border-width 0
    border-radius: 4px
  .df-outsource-expense
    min-width 1400px
    max-width 1800px
    width 100%
    margin 0 auto
    display flex
    flex-direction column
    justify-self center
    padding 50px
    box-sizing border-box
    .title
      font-size 2em
      margin-bottom 24px
      font-weight bold
    .sub-title
      font-size 18px
      margin-bottom 12px
      font-weight bold
      margin-top 32px
    .expense-condition
      padding 24px
      background-color #f6f6f6
      border-radius 4px
      display flex
      align-items center
      gap 56px
      &__outsource-company
        background-color #fff
        select
          font-size 15px
          padding 5px 24px 5px 10px
          border solid 1px #d5dbe0
          -webkit-appearance none
          -moz-appearance none
          border-radius 2px
          background url(//s3.marpple.co/files/u_193535/2022/11/original/e462add35987941d476946a8ee136154b8923dd11.svg) no-repeat 98% center
          background-size 18px 18px
      &__date
      &__select
      &__price
        display flex
        gap 16px
        align-items center
      &__month
        font-size 15px
        padding 5px 10px
        border solid 1px #d5dbe0
        min-width 100px
      &__title
        font-size 15px
        font-weight 500
        line-height 0.1
      &__submit
        font-size 15px
        font-weight bold
        padding 9px 47px
        text-align center
        color white
        background-color #3c89f9
        border-width 0
        border-radius: 4px
    .result
      display flex
      flex-direction column
      gap 24px
      padding 24px
      border-radius 4px
      background-color #f6f6f6
      .numbers
        display flex
        gap 40px
        align-items center
        box-sizing border-box
        width 100%
        padding 18px 24px
        border-radius 4px
        border solid 1px #3c89f9
        background-color #edf4ff
        &__wrapper
          display flex
          align-items center
          gap 10px
        &__value
          display flex
          align-items center
          .icon
            width 24px
            height 24px
            margin-right 10px
          .blue-title
            padding-left 8px
            padding-right 2px
            color #0e2ee2
            font-weight bold
            font-size 16px

      &__info
        display flex
        gap 12px
        flex-wrap wrap
        &__quantity-confirm
          & > .blue-text
            color #0e2ee2
        & > .info-box
          padding 8px 16px
          border-radius 19px
          border solid 1px #3c89f9
          background-color #edf4ff
          font-size 14px
          &.confirm_completed
            background-color darkgreen
            border solid 1px green
            color white
          &.no_confirm
            background-color darkred
            border solid 1px red
            color white
      &__table-section
        overflow auto
        background #fff
        border 1px solid #ddd
        height 800px
        .bold
          font-weight 600
        table
          border-collapse collapse
          width 100%
        thead
          th
            font-size 14px
            font-weight 600
            border-bottom 1px solid #000
          tr
            height 58px
        tbody
          tr
            height 58px
            text-align center
            &:hover
              background-color #ebf3fe
            td
              border-bottom 1px solid #ddd
              div
                box-sizing border-box
                padding 10px 0
                a
                  font-weight 600
                  text-decoration underline
        [data-key="생성일"]
          width 60px
        [data-key="주문번호"]
        [data-key="상품번호"]
        [data-key="상품명"]
        [data-key="수량"]
        [data-key="기본 가격"]
        [data-key="옵션"]
        [data-key="구간"]
        [data-key="구간 할인"]
        [data-key="단가"]
        [data-key="세액"]
        [data-key="공급 가액"]
        [data-key="총 금액"]
        [data-key="배송 방법"]
        [data-key="택배회사"]
        [data-key="배송 시작일"]
        [data-key="도착일"]
        [data-key="배송비"]
        [data-key="송장번호"]
        [data-key="생성일"]
          word-break: break-all; // 모든 문자를 자른다. (단어 구분 없는 줄바꿈)
          white-space: nowrap;
          text-align: center;
          padding 0 20px

