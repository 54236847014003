// modules/Df/OMP/SharedProducts/F/Style/Pc/index.styl
.shared_products
  .don_tab[tab_name='/modules/Df/OMP/SharedProducts/S/Mui/tab.js']
    display flex
    justify-content center
    .don_wrapper
      width 70%
      min-width 1500px
      .sharch_wrap
        padding 30px
        background #dbdbdb
        .sharch_form
          display flex
          flex-direction column
          gap 15px
          &__sort
            select
              margin-right 15px
    .shared_products_table
      width 100%
      border-collapse collapse
      margin 20px 0
      th,td
        border 1px solid #dddddd
        text-align left
        padding 8px
      td
        > input
         width 200px
      th
        background-color #f2f2f2
      .disabled
        color #d1d1d1
        > td:not(.buttons)
          pointer-events none
          > input
            color #d1d1d1
          > button
            color #d1d1d1
        .buttons
          .shared_products__cancel
            display none
          .shared_products__extend
            display none
      .go_to_shared_product
        display inline-block
        cursor pointer

