// modules/Tagmanagement/F/Style/Pc/index.styl

.TableView
  &_wrapper
    margin 20px auto
    background-color #fff
    padding 20px
    border-radius 8px

  &_header
    display flex
    justify-content space-between
    margin-bottom 40px

  &_body
    width 100%
    border-collapse collapse

    & th, td
      padding 10px
      text-align center
      border-bottom 1px solid #ddd

    & th
      background-color #f2f2f2
      text-align center

    & th:nth-child(1),
    & th:nth-child(2)
        width 15%

    & td
      vertical-align top;

  &_check_all
    width 20px
    height 20px

  & .check
    width 20px
    height 20px

  &_total_count
    font-size 16px
    margin-bottom 5px


.PaginationBar
  &_pagination
    margin-top 30px
    display flex
    justify-content center
    align-items center

    & .pagination_btn
      margin 0 5px
      padding 10px 15px
      border none
      border-radius 5px
      background-color #f2f2f2
      cursor pointer

      &:hover
        background-color #ddd

      &.active
        background-color #007bff
        color white

      &.disabled
        background-color #f2f2f2
        color #ccc
        cursor not-allowed


.TagListPage

  margin 0 auto;
  padding 0 20px

  &_items_clear_btn
    background-color #f35d5d
    color #fff
    border none
    padding 10px 20px
    font-size 16px
    cursor pointer
    border-radius 4px
    margin 20px 0

  &_register_button
    background-color #007bff
    color #fff
    border none
    padding 10px 20px
    font-size 16px
    cursor pointer
    border-radius 4px

    &:hover
      background-color #0056b3

  &_search_input
    height 100%
    padding 10px
    font-size 16px
    border none
    border-bottom 1px solid #ccc


  &_card_container
    display flex
    flex-wrap wrap
    justify-content: flex-start;
    gap 10px

  &_card
    width 15%
    min-height 150px
    margin 1% 0.5%
    box-shadow 0 4px 8px rgba(0, 0, 0, 0.1)
    border-radius 8px

    background-color #fff
    text-align center
    position relative

    &_delete_btn
      position absolute
      padding 2px 5px
      border-radius 5px
      right -10px
      top -10px
      color white
      background-color #f35d5d
      cursor pointer

    & img
      width 100%
      height auto
      display block

  &_card_info
    padding 10px

    & p
      margin 5px 0
      font-size 12px


  &_card_add
    cursor pointer

  &_card_plus
    position absolute
    top 50%
    left 50%
    transform translate(-50%, -50%)
    font-size 50px

  &_card_info_is_use_list, &_tags, &_tag_items
    display flex
    gap 10px
    flex-wrap wrap
    justify-content flex-start

    & .tag
      padding 4px 8px
      border-radius 5px
      color #000
      border 1px solid #ccc

      &.kr
        color #fff
        border-color #56aaff
        background #56aaff
      &.en
        color #fff
        border-color #5bce74
        background #5bce74
      &.jp
        color #fff
        border-color #f35d5d
        background #f35d5d

  &_tag_action_btn
    background-color #007bff
    color #fff
    border none
    font-size 14px
    cursor pointer
    border-radius 4px
    padding 4px

    &[data-action='edit']
      background-color #5bce74

    &[data-action='save']
      background-color #007bff

// RegisterModal
.TagRegisterModal
  &_container-body
    margin 0 auto

    &_content
      & input, select
        padding 5px

      &__data-table
        width 100%
        border-collapse collapse

        & th, td
          padding 10px
          text-align left
          border-bottom 1px solid #ddd

        & th
          background-color #f2f2f2

  &_add_rows_list
    margin-top 10px
    display grid
    gap 5px
    grid-template-columns repeat(5, auto)
    justify-content start

    & div
      border 1px solid #ccc
      padding 5px

      & .delete_tag_btn
        color red
        cursor pointer

  &_add_rows_button
    width 100%
    background #fff
    color #28a745
    border 1px solid #28a745
    padding 10px 20px
    font-size 16px
    cursor pointer
    border-radius 4px
    margin-top 20px

    &:hover
      background-color #5bce74
      color #fff

  &_search_base_product
    color #007bff
    cursor pointer
    margin-bottom 10px

.BaseProductModalView
  // baseModal
  &__wrapper
    width 100%
    height 100%


  &__container
    position absolute
    left 50%
    top 50%
    background #fff
    z-index 9
    overflow hidden
    border-radius 20px
    box-shadow 0 4px 8px rgba(0, 0, 0, 0.1)
    transform: translate(-50%, -50%)
    animation none !important


    &-header
      width 100%
      height 70px
      background #fff
      border-bottom 1px solid #ccc

      padding 0 20px
      box-sizing border-box
      display flex
      flex-direction row
      flex-wrap nowrap
      justify-content space-between
      align-items center

      &__title
        font-size 20px
        font-weight bold

      &__cancel_btn
        cursor pointer
        padding 10px


    &-body
      padding 20px
      overflow-y scroll
      height calc(100% - 180px);

    &-footer
      position absolute
      bottom 0
      left 0
      display flex
      justify-content flex-end
      background #fff
      border-top 1px solid #ccc
      width 100%
      height 70px
      padding 0 20px
      box-sizing border-box
      align-items center

      &-btn
        padding 10px 20px
        border none
        border-radius 4px
        cursor pointer
        font-size 16px
        margin-left 10px
        transition background-color 0.3s ease

        &:hover
          opacity 0.9

        &.close
          background-color white
          color #454343
          border: 1px solid #454343

        &.ok
          background-color #007bff
          color white

        &.processing
          pointer-events none
          cursor not-allowed
          background-color #ccc

    &-dim
      width 100%
      height 100%
      background-color rgba(0,0,0,0.5)
      position absolute
      left 0
      top 0


  &_model_thumb_url
    height 100px

  &_category_tabs, &_category_items_tabs, .Tabs
    display flex
    overflow-x auto
    margin-bottom 20px
    border-bottom 1px solid #ddd

  &_category_tab, &_category_item_tab, .Tabs_tab
    padding 10px 20px
    cursor pointer
    background-color #f9f9f9
    flex 0 0 auto

    &.active
      background-color #4CAF50
      color white

  &_search_input
    height 100%
    padding 10px
    font-size 16px
    border none
    border-bottom 1px solid #ccc
    margin-bottom 20px
