@import "../mp/event.styl"
body.event
  .dream_factory
    .don_tab
      .buttons
        text-align center
        margin-bottom 40px
        > *
          text-align center
          margin 10px
        a
          display inline-block
          vertical-align top
          width 100px
          line-height 42px
          box-sizing border-box
          height 42px
          font-weight bold
          border 1px solid #d5dbe0
        button
          height 42px
          background-color #000000
          font-weight bold
          color #ffffff
          border 0
        form
          display inline-block
          vertical-align top
      #event_list
      #event_detail
        html.event.update &
          text-align center
        >.footer
          display none !important
        >.header
          input[type="text"]
            width 1000px
            text-align center
          .title
            input
              font-size 30px
          .sub_title
            input
              color #495057
              font-size 20px
        .thumbnail_url_wrap
          width 350px
          margin 0 auto
          .thumbnail_url
            width 350px
            height 350px
            overflow hidden
            img
              width 100%
        .img
          width 100%
          font-size 0
          img
            width 100%
            margin 0 auto
            display block
        .url_mobile
          img
            width 600px
            margin 0 auto
            display block
        .content.wrap
          width 992px - 32px
          margin 40px auto
          padding 16px
          text-align left
          border 1px solid #d5dbe0
        .video_wrap
          width 992px
          margin 40px auto
          text-align left
        .upload_video
        .upload_youtube
          display inline-block
          margin-right 16px
          vertical-align top
          textarea
            display block
            width 314px
            height 160px
            font-size 15px
            color #333
            line-height 1.6
            margin-bottom 16px
        .event_video
          width 100%
          height 0
          padding-bottom 56.25%
          position relative
          margin-bottom 24px
          iframe
            position absolute
            width 100%
            height 100%
            top 0
            left 0
            right 0
            bottom 0
            margin auto
        &.mobile
          mobile_event_detail_style()
          width 375px
          height 700px
          margin 50px auto
          border 1px solid #000
          overflow auto
          .content_wrap
            .url
              min-width auto !important
            .img
              width 100%
