.don_frame[frame_name="/modules/Df/Stock/Labeling/S/Mui/frame.js"]
  .don_tab[tab_name="/modules/Df/Stock/Labeling/S/Mui/tab.js"]
    h1
      margin 45px 0 0 50px
      user-select none
    .color_danger
      color #f64747 !important
      fill #f64747 !important
      &:hover
        background-color #f69c47 !important
    .button.first
      margin-left 20px
      border-left 1px solid #bbb !important
    .label_preview_items
      display flex
      flex-direction column
      justify-content center
      align-items center
      .label_preview_header
        overflow hidden
        display flex
        justify-content right
        align-items center
        width 95%
        margin 20px 0 25px 0;
        @media screen and (max-width: 1024px)
          width 90%
        .selection
          display flex
          justify-content center
          .date_picker
            display flex
            flex-flow column
            margin 0 40px
            .date_box
              display flex
              flex-flow row
              flex-wrap nowrap
            input[type="text"]
              cursor pointer
              transition all 0.5s
              width 150px
              border 1px solid #c3c3c3
              max-width 200px
              height 18px
              line-height 20px
              padding 8px
              border-radius 3px
              background #fff
            a.input-button
              text-decoration none
              border 1px solid #bbb
              padding 7.5px 12px
              border-left 0
              cursor pointer
              align-self center
              justify-content center
              line-height 1
              border-radius 3px
              &:hover
                background-color #88bbee
              svg
                width 16px
                height 16px
                path
                  fill inherit
          @media screen and (max-width: 1280px)
            flex-wrap wrap
            .date_box
              margin-bottom 10px
          @media screen and (max-width: 1024px)
            flex-wrap wrap
            .date_box
              margin-bottom 10px
          &.single_label
            justify-content flex-end
            align-items center
            @media screen and (max-width: 1280px)
              height 126px
            .input_box
              display flex
              flex-flow wrap
              span
                width 100%
              input
                outline none
                width 180px
                height 18px
                line-height 20px
                padding 8px
                border 1px solid #c3c3c3
                border-radius 3px
              .input_button
                text-decoration none
                border 1px solid #bbb
                padding 7.5px 12px
                border-left 0
                cursor pointer
                align-self center
                justify-content center
                line-height 1
                border-radius 3px
                &.add
                  margin-left 20px
                &:hover
                  background-color #88bbee
                svg
                  width 16px
                  height 16px
                  path
                    fill inherit
          &.hidden
            display: none
        .controller
          display flex
          justify-content center
          margin-right 30px
          @media screen and (max-width: 1024px)
            flex-flow column
        .date_control_buttons
          display flex
          justify-content center
          align-content center
          margin-top 16px
          @media screen and (max-width: 1024px)
            justify-content right
            margin-bottom 4px
          &.hidden
            display none
        .control_buttons
          display flex
          justify-content center
          align-content center
          margin-top 16px
        .button
          display flex
          justify-content center
          align-items center
          box-sizing border-box
          bottom 0
          width 41px
          height 36px
          text-decoration none
          border 1px solid #bbb
          cursor pointer
          line-height 1
          border-radius 3px
          border-left 0
          &.mode_switch
            svg
              transition transform 0.1s
          &.rotate
            svg
              transition transform 0.1s
              transform rotate(180deg)
              .switched_path
                fill #0071BC !important
          &.table_delete:hover
            background-color #f4661e
          &.hidden
            display none
          &:hover
            background-color #68c671
          svg
            width 23px
          &.selected
            background-color #AEB6BF
            &:hover
              background-color #68c671
          &.expand_collapse
            &.expanded
              .path_arrow, path_remove
                transition all 0.2s
            &:not(.expanded)
              .path_arrow
                transition all 0.2s
                fill #F4661E !important
                stroke #F4661E !important
                transform translateY(87px) scale(1,-1)
              .path_remove
                transition all 0.2s
                opacity 0

    svg.cell_icon
      height 14px
    #label_list_table
      width 95%
      min-width 600px
      .tabulator-tableHolder
        background-color #f7f7f7
      .tabulator-tree-level-0
        font-weight bolder
        border-top 2px solid #a4a8ad
      .tabulator-cell
        &.cancel
          color red
          text-decoration line-through
        &.link
          color blue
          text-decoration underline
          &.cancel
            text-decoration line-through
      @media screen and (max-width: 1024px)
        width 90%
        margin-bottom 30px
    #print_progress_bar
      position absolute !important
      transform translateX(-50%)
      top 50%
      left 50%
      margin 10px
      padding 10px
      width 100px
      height 100px
  .flash_green
    animation flash_green 1.5s
  .blink
    animation blink 0.8s
    background-color #f8e16c
  @keyframes blink {
    0% {
      background-color: #fb8f67;
    }
    20% {
      background-color: #f8e16c;
    }
    40% {
      background-color: #fb8f67;
    }
    60% {
      background-color: #f8e16c;
    }
    80% {
      background-color: #fb8f67;
    }
    100% {
      background-color: #f8e16c;
    }
  }
  @keyframes flash_green {
    0% {
      background-color: #36d236
    }
    100% {
      background-color: #ffffff
    }
  }
