.chart-wrapper
  width 100%
  position relative
  display -ms-flexbox
  display flex
  -ms-flex-direction column
  flex-direction column
  min-width 0
  word-wrap break-word
  background-color #fff
  border 1px solid rgba(24,28,33,0.06)
  border-radius .25rem
  flex-shrink 1
  background-clip padding-box
  box-shadow 0 1px 4px rgba(24,28,33,0.012)

  > .chart
    border-collapse collapse
    width 100%
    color #4E5155
    border 0 !important
    margin-bottom 0
    border-top 1px solid rgba(24,28,33,0.06) !important
    border-top-width 1px !important
    > thead
      > tr > th
        padding .625rem
        border 1px solid #eee
        font-weight 500
        border-bottom 2px solid #e8e8e9
        vertical-align bottom
        border-top 0
        text-align left
        &:first-child
          padding-left 1.5rem
          border-left 0
        &:last-child
          padding-left 1.5rem
          border-right 0

    &.chart-stripe
      > tbody > tr
        &:nth-of-type(odd)
          background-color rgba(24,28,33,0.025)

    > tbody
      > tr
        &.total
          background-color #8897AA !important
          > td
            color white
            font-weight 500
        > th
          border 1px solid #eee
          &:first-child
            padding-left 1.5rem
            border-left 0
          &:last-child
            padding-left 1.5rem
            border-right 0
        > td
          vertical-align middle !important
          padding .625rem
          border 1px solid
          padding-top .5rem !important
          padding-bottom .5rem !important
          border-color #eee
          &:first-child
            padding-left 1.5rem
            border-left 0
          &:last-child
            padding-left 1.5rem
            border-right 0
          &.sns_url
            text-align center
            .img_wrap
              img
                vertical-align middle
                border-style none
                height 30px
          .product_info
            align-items center
            display flex
            flex-shrink 1
            max-width 300px
            .img_wrap
              width 40px
              height 40px
              border 1px solid #eee
              border-radius 2px
              display flex
              align-items center
              justify-content center
              img
                vertical-align middle
                border-style none
                max-width 90%
                max-height 90%
            span.name
              margin-left 1rem
              color #4e5155
              display block
              flex 1

          &.bonus-profit[data-bonus_profit="true"]
            color #28c3d7

// 부분 한정적인 거는 가져가서 추가하기
