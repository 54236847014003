html.bp_stock.list
  body.bp_stock.list
    #body
      .ml8
        margin-left 8px
      .don_frame[frame_name="df.bp_stock.item"]
        .don_page[page_name="df.bp_stock.item1"]
        .don_page[page_name="df.bp_stock.item2"]
          >.don_wrapper
            >.header
              height 64px
              position fixed
              top 0
              left 0
              right 0
              width 100%
              z-index 1
            .don_tab
              h2
                margin 10px 0 30px
      #bp_stock_list
        margin 50px
        h2
        #excel_down
          display inline-block
          vertical-align top
        #search
          margin-top 20px
        #base_products_result
          margin-top 20px
          .bp
            margin 0 16px 24px
            cursor pointer
            position relative
            display inline-block
            vertical-align top
            width 80px
            .thumb
              position relative
              width 100%
              font-size 0
              height 0
              padding-bottom 125%
              img
                position absolute
                margin auto
                top 0
                left 0
                right 0
                width 100%
      .bp_stock_list
        margin 100px auto
        width 1280px
        .left
        .right
          display inline-block
          vertical-align top
          position relative
          &.left
            margin-right 50px
            .thumb
              margin-top 50px

        .bp_stock_item
          margin 20px 0
          display flex
          >div
            padding 20px 40px 20px 0
            input[type="number"]
              width 50px

      .color_selector
        margin 100px auto
        width 1280px
        .discontinued_all
          margin-left 10px
        .base_product_color
          margin 20px
          cursor pointer
          display inline-block
          vertical-align top
          width 100px
          padding 8px
          .color_code
            border-radius 0.25rem
          .name
            margin-top 4px
            font-weight 700
          .badge
            margin-top 4px
            border 1px solid #000
            width fit-content
            padding 2px
            border-radius 0.25rem
            color hsl(210,8%,98%)
          .not_stock
            margin-top 4px
            background-color orange
          .discontinued
            background-color gray
          &[is_not_stock="true"]
            background #ffe45c
            border-radius 0.25rem
          .product_thumb_url
            height 150px
            img
              width 100%
          .color_code
            border 1px solid #000
