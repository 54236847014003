.don_frame[frame_name="auto_cv_print_area_task"]
  >.don_wrapper
    width 80%
  .don_page >.don_wrapper >.header
    height 58px
    display flex
    justify-content center
    align-items center
  .main
    .option
      display flex
      justify-content center
      margin 16px 0
    .heads
      display flex
      >label
        width 50%
        font-size 20px
        text-align center
    >.wrapper >.body
      display flex
      .canvas_wrapper
        width 50%
        height auto
        img
          width 100%
          background #912020
  button
    border 1px solid #424140
    background #424140
    border-radius 5px
    color #fff
    font-size 16px
    width 60px
    height 32px
    display flex
    justify-content center
    align-items center
