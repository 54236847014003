/* 공통 */
body.product.list, html.maker_page
  #is_not_use_links
    text-align center
    a
      margin 0 10px
  .selected_true
    position relative
    padding 11px 25px 12px 16px
  #ordering_select
    z-index 1
  .don_frame[frame_name="mp.product.list"]
  .don_frame[frame_name="mp.product.list.filter"]
    ul
      padding 0
      margin 0
      list-style none
    .product_filter_list
      ul
        li.filter_list
          padding-right 16px
          border-bottom 1px solid #f5f5f5
          .filter_list_name
            padding 16px 0
            position relative
            cursor pointer
            &[count="0"]
              >.count_wrap
                display none
            >.plus
              position absolute
              top 20px
              right 0
              height 6px
              width 10px
              background-image url(//s3.marpple.co/files/u_29089/2019/1/original/f_23323_1548149511325_E7k6e5MW2e9GR4TD5sxmEm.png)
              background-size 10px 6px
          .filter_items
            display none
            &:before
              content ''
              display block
              height 8px
            &:after
              content ''
              display block
              height 16px
            ul
              li.filter_item
                position relative
                margin-bottom 16px
                label
                  cursor pointer
                  display flex
                  input
                    position absolute
                    top 0
                    left 0
                    visibility hidden
                    margin 0
                  .checkbox
                    width 16px
                    height 16px
                    position relative
                    background-size 100%
                    background-image url(//s3.marpple.co/files/u_29089/2019/1/original/f_23320_1548149201830_o6H7T0uQ0kx3e5Q7mlUN0VL.png)
                  .filter_item_name
                    position relative
                    margin-left 16px
                    color color_black
                    font-size 12px
                    @media screen and (min-width 1025px)
                      width 150px
                &[selected="true"]
                  label
                    .checkbox
                      border 0
                      background-image url(//s3.marpple.co/files/u_29089/2019/1/original/f_23321_1548149221044_VHH0t9w5r2lEFP8PD4OlA.png)
          &[filter_list_id="color"]
            .filter_items
              ul
                li.filter_item
                  label
                    cursor pointer
                    .checkbox
                      background-image none
                      border-radius 16px
                      &[color_name="White"]
                      &[color_name="#fff"]
                        border 1px solid #dee2e6 !important
                        box-sizing border-box
                  &[selected="true"]
                    label
                      cursor pointer
                      .checkbox
                        background-image url(//s3.marpple.co/files/u_18050/2018/10/original/f_6303_1540802109245_P2m8RtM2Zt4OLwAA6nmp.svg)
                        &[color_name="Yellow"]
                        &[color_name="White"]
                        &[color_name="#fff"]
                        &[color_name="Beige"]
                          background-image url(//s3.marpple.co/files/u_18050/2018/10/original/f_6304_1540802123878_vq3s9Xb7X3o0TI5bIz7HN1R.svg)
          &[selected="true"]
            .filter_list_name
              >.plus
                background-image url(//s3.marpple.co/files/u_29089/2019/1/original/f_23322_1548149497872_xwu2vhmFdp2slFhci.png)
            .filter_items
              display block
    .don_tab[tab_name="mp.product.list"]
      #product_list_body
        &[total_count="0"]
          .product_list_wrap
            .base_product_list
              display none
            #not_found_results
              display block
        .product_list_wrap
          position relative
          .product_list
            position relative
            .count_sort
              margin-bottom 16px
              position relative
              .ordering_cut_img
                position absolute
                right 0
                #ordering
                  .selected
                    padding 13px 18px 13px 17px
                    position relative
                    margin -13px 0
                    &:after
                      content ''
                      position absolute
                      top 19px
                      right 0
                      width 10px
                      height 6px
                      background-size 100%
                      background-image url("//s3.marpple.co/files/u_18050/2018/9/original/f_5875_1537284759381_Ud4eH8zur3ghvrGV2v2q.png")
              .total_count
                height 16px
                color color_gray3
                display inline-block
                vertical-align top
                font-weight 300
              .cate_list_total_count
                display none
                h2
                  margin 0
                  font-size 20px
                  height 24px
                  .total_count2
                    color color_gray3
            .base_product_list
              .base_product
                position relative
                display inline-block
                vertical-align top
                .bp_inner
                  position relative
                  overflow hidden
                  .img
                    font-size 0
                    height 0
                    padding-bottom 125%
                    position relative
                    background-color #f6f6f8
                    img
                      position absolute
                      top 0
                      left 0
                      bottom 0
                      right 0
                      margin auto
                      width 100%
                .spec
                  position relative
                  margin-top 16px
                  /* TODO 카테고리 하드코딩
                       --- 폰케이스*/
                  &[cate_list_id="12"]
                    .colors
                    .sizes
                      display none
                  &[is-size-for-list="false"]
                    .sizes
                      display none
                  &[is-color-for-list="false"]
                    .colors
                      display none
                  .name
                    word-break keep-all
                    margin-bottom 16px
                  .colors
                    height 10px
                    margin-bottom 8px
                    overflow hidden
                    &:after
                      content ''
                      position absolute
                      top 0
                      right 0
                      background-image url(//s3.marpple.co/files/u_29089/2019/4/original/f_75488_1554970833703_VQeu5cuVNLAERFU5C.png)
                      background-size 100%
                      width 10px
                      height 10px
                      display none
                    .bp_color
                      display inline-block
                      vertical-align top
                      margin-right 6px
                      width 10px
                      height 10px
                      border-radius 10px
                      &[color_code="#ffffff"]
                      &[color_code="#fefefe"]
                        border 1px solid #dee2e6 !important
                        box-sizing border-box
                  .sizes
                    margin-bottom 8px
                    position relative
                    line-height 1.14
                  .tags
                    display inline-block
                    vertical-align top
                    li.tag
                      display inline-block
                      vertical-align top
                      font-size 12px
                      height 16px
                      line-height 16px
                      color #fff
                      padding 0 4px
                      margin-right 1px
                      &[type="BEST"]
                        background-color #c8ced3
                      &[type="NEW"]
                        background-color color_gray3
                      &[type="SALE"]
                        background-color #000000
                  .price_info
                    line-height 1.14
                    float right
                    .line2
                      margin-top -2px
                      margin-left 2px
                      display inline-block
                      vertical-align top
                    .price
                      display inline-block
                      vertical-align top
                      position relative
                      &:before
                        margin-left 1px
                      &:after
                        margin-right 1px
                      &.o_price
                        display none
                    &[discount="true"]
                      .price
                        &.d_price
                          margin-left 8px
                        &.o_price
                          display inline-block
                          color color_gray3
                          &:after
                            content ''
                          .line
                            position absolute
                            left 0
                            right 2px
                            top 0
                            bottom 0
                            margin auto
                            height 1px
                            background-color color_gray3
  .selected_filter_items
    padding 16px
    display none
    .unselected
      font-size 12px
      line-height 1.33
      color color_gray3
    .selected
      font-size 12px
      line-height 1.33
      color color_black
      position relative
      .clear
        display inline-block
        vertical-align top
        cursor pointer
        &:before
          content ''
          position relative
          display inline-block
          vertical-align top
          width 16px
          height 16px
          background-image url(//s3.marpple.co/files/u_29089/2019/1/original/f_23319_1548148716392_OO0Ds1XXH9XHpor7g4t1E.png)
          background-size 100%
          margin-right 4px
      ul
        white-space nowrap
        overflow-y hidden
        overflow-x auto
        -webkit-overflow-scrolling touch
        position absolute
        top 0
        left 56px
        right 0
        padding-bottom 8px
        display flex
        li.selected_filter_item
          color color_gray3
          margin-left 24px
          position relative
          display flex
          align-items center
          label
            display block
            position absolute
            top 0
            left 0
            bottom 0
            right 0
            cursor pointer
            input
              position absolute
              top 0
              left 0
              visibility hidden
              margin 0
          &:first-child
            margin-left 16px
          &:last-child
            margin-right 16px
          span.close
            position relative
            width 14px
            height 14px
            background-image url(//s3.marpple.co/files/u_29089/2019/2/original/f_23340_1549526630083_qP0lko6HIO0tces7ET8B.png)
            background-size 100%
            margin 0 0 0 8px
    @media screen and (min-width 1025px)
      padding 16px 28px
      .selected
        .clear
          margin-bottom 4px
        ul
          position relative
          top auto
          left auto
          right auto
          padding-bottom 0
          flex-flow row wrap
          li.selected_filter_item
            margin 4px 24px 4px 0
            &:first-child
              margin-left 0
            &:last-child
              margin-right 0
    &[selected="true"]
      border-top 1px solid #d5dbe0
      display block
      .unselected
        display none
    &[selected="false"]
      padding 0
      .selected
        display none

html:not([lang="jp"]) body.product.list .don_frame[frame_name="mp.product.list"] .don_tab[tab_name="mp.product.list"] #product_list_body .product_list_wrap .product_list .base_product_list .base_product .spec .name, body.product.detail .don_frame[frame_name="mp.product.list"] .don_tab[tab_name="mp.product.list"] #product_list_body .product_list_wrap .product_list .base_product_list .base_product .spec .name, body.product.list .don_frame[frame_name="mp.product.list.filter"] .don_tab[tab_name="mp.product.list"] #product_list_body .product_list_wrap .product_list .base_product_list .base_product .spec .name, body.product.detail .don_frame[frame_name="mp.product.list.filter"] .don_tab[tab_name="mp.product.list"] #product_list_body .product_list_wrap .product_list .base_product_list .base_product .spec .name
  line-height 1.14

html.product
html.maker_page
/* pc 상품 디자인 */
  @media screen and (min-width 1025px)
    .don_frame[frame_name="mp.product.list"]
      .don_tab[tab_name="mp.product.list"]
        #product_list
          #product_list_body
            .product_list_wrap
              .product_list
                .base_product_list
                  .base_product
                    width 312px
                    margin 0 8px 40px
                    .spec
                      .colors[is_pc_over="true"]:after
                        display block
                    .hover_design
                      padding 8px
                      position absolute
                      bottom 0
                      left 0
                      right 0
                      background-color rgba(255,255,255,0.9)
                      transition transform .3s ease-out
                      transform translateY(56px)
                      .txt
                        padding 11px 0 12px
                        height 16px
                        color color_active
                        text-align center
                        border dashed .5px color_active
                        position relative
                        .dot
                          position absolute
                          width 3px
                          height 3px
                          border .5px solid color_active
                          background-color #fff
                          &:nth-child(1)
                            top -1.5px
                            left -1.5px
                          &:nth-child(2)
                            top -1.5px
                            right -1.5px
                          &:nth-child(3)
                            bottom -1.5px
                            left -1.5px
                          &:nth-child(4)
                            bottom -1.5px
                            right -1.5px
                    &:hover
                      .hover_design
                        transform translateY(0px)
/* 모바일 상품 디자인 */
  @media screen and (max-width 1024px)
    .don_frame[frame_name="mp.product.list"]
      .don_tab[tab_name="mp.product.list"]
        #product_list
          #product_list_body
            .product_list_wrap
              .product_list
                .base_product_list
                  .base_product
                      width 50%
                      margin-bottom 32px
                      .spec
                        font-size 12px
                        margin 8px 16px 0
                        .name
                        .sizes
                        .colors
                          position relative
                          line-height 1.33
                          margin-bottom 8px
                        .sizes
                          margin-bottom 0
                        .colors
                          height 8px
                          .bp_color
                            position relative
                            width 8px
                            height 8px
                            margin-right 4px
                          &[is_mobile_over="true"]:after
                            display block
                            width 8px
                            height 8px
                        .tags
                          margin-bottom 8px
                          display block
                          ul
                            li.tag
                              font-size 10px
                        .price_info
                          float none
                          .line2
                            margin-top -1px
                          .o_price
                            .line
                              top 1px
                      .hover_design
                        display none !important


/*
pc버전의 리스트페이지만
*/
html.product.list:not(.maker_page)
  @media screen and (min-width 1025px)
    .don_frame[frame_name="mp.product.list"]
      .don_tab[tab_name="mp.product.list"]
        position relative
        .product_list_banner
          width 100%
          .banner
            position relative
            height 320px
            font-size 0
            overflow hidden
        .video_wrap
          height 600px
          video
            position absolute
            top -10000px
            left -10000px
            bottom -10000px
            right -10000px
            margin auto
            min-width 100%
            min-height 100%
        .selected_filter_items
          display inline-block
          vertical-align top
          width 800px
          padding 0
          border-top 0
          &[selected="false"]
          .unselected
          .selected .clear
            display none
          .selected
            font-size 14px
            ul
              li
                line-height 1.14
                span.close
                  width 16px
                  height 16px


        #product_list
          width 1280px
          position relative
          margin 0 auto 120px
          min-height calc(100vh - 639px)
          > .header
            display inline-block
            position relative
            margin-left 16px
            width 200px
            #side_bar
              padding-bottom 40px
              position relative
              will-change min-height
              height 0
              #side_bar_inner
                transform translate3d(0, 0, 0)
                will-change position, transform
            >.filter_lists_wrap
              h3
                margin 0
                padding 40px 0 24px
                font-size 20px
                line-height 1.2
                font-weight 600
                html[lang="en"] &
                  font-family CircularStd
              h4
                margin 0
                font-weight bold
                padding-bottom 16px
                font-size 14px
                height 16px
                border-bottom 1px solid #d5dbe0
                line-height 1.14
              .cate_items
                .cate_item
                  padding 8px 0
                  a
                    position relative
                  &[selected="true"]
                    a:before
                      content ''
                      position absolute
                      bottom 0
                      right 0
                      left 0
                      height 7px
                      background-color #ffff80
                      z-index -1
                  .total_count2
                    margin-left 4px
              .filter_selector_wrap.pc_v
                width 200px
                left 0
                position relative
                margin-top 40px
                .filter_title
                  position relative
                  .clear
                    position absolute
                    right 0
                    top 0
                    font-size 12px
                    line-height 1.33
                    cursor pointer
                    &:before
                      content ''
                      position relative
                      display inline-block
                      vertical-align top
                      width 16px
                      height 16px
                      background-image url(//s3.marpple.co/files/u_29089/2019/1/original/f_23319_1548148716392_OO0Ds1XXH9XHpor7g4t1E.png)
                      background-size 100%
                      margin-right 4px
          #product_list_body
            position relative
            display inline-block
            margin-left 72px
            min-height 500px
            vertical-align top
            width 984px
            .product_list_wrap
              .product_list
                margin-top 34px
                .product_list_header
                  min-height 19px
                  margin 0 8px
                  position relative
                .count_sort
                  height 19px
                  margin-bottom 0
                  position absolute
                  right 0
                  top 0
                  width 168px
                  .ordering_cut_img
                    bottom 0
                .base_product_list
                  margin-top 16px
                  .base_product
                    .hover_design
                      padding 8px
                      position absolute
                      bottom 0
                      left 0
                      right 0
                      background-color rgba(255,255,255,0.9)
                      transition transform .3s ease-out
                      transform translateY(56px)
                      .txt
                        padding 11px 0 12px
                        height 16px
                        color color_active
                        text-align center
                        border dashed .5px color_active
                        position relative
                        .dot
                          position absolute
                          width 3px
                          height 3px
                          border .5px solid color_active
                          background-color #fff
                          &:nth-child(1)
                            top -1.5px
                            left -1.5px
                          &:nth-child(2)
                            top -1.5px
                            right -1.5px
                          &:nth-child(3)
                            bottom -1.5px
                            left -1.5px
                          &:nth-child(4)
                            bottom -1.5px
                            right -1.5px
                    &:hover
                      .hover_design
                        transform translateY(0px)
      >.footer
        margin-bottom 300px


/*
모바일 리스트페이지와
디테일에서 pc모달과 모바일프레임
*/

html.product.list[is_mobile_size="true"]
html.maker_page
  .don_frame[frame_name="mp.product.list"]
    .mobile_v
      display block !important
    .don_tab[tab_name="mp.product.list"]
      position relative
      .product_list_banner
        width 100%
        .banner
          position relative
          padding-bottom 37.5%
          height 0
          font-size 0
      .video_wrap
        height 0
        padding-bottom 120%
        video
          width 100%
      #product_list
        padding-bottom 80px
        .pc_v
          display none !important
        #product_list_body
          width 100%
          position relative
          min-height 300px
          .product_list_wrap
            .product_list
              .product_list_header
                overflow hidden
              .count_sort
                margin 16px
                text-align right
                #selected_cate_list
                  position absolute
                  left 0
                  top 0
                  font-weight 500
                  height 16px
                  font-size 16px
                  line-height 1.07
                  margin 0
                  text-align center
                  cursor pointer
                  //display inline-block !important
                  vertical-align top
                  margin-top 1px
                  >span
                    width 10px
                    height 6px
                    position relative
                    display inline-block
                    vertical-align top
                    margin-left 8px
                    margin-top 5px
                    background-image url("//s3.marpple.co/files/u_18050/2018/9/original/f_5875_1537284759381_Ud4eH8zur3ghvrGV2v2q.png")
                    background-size 100%
                  &.open
                    >span
                      background-image url("//s3.marpple.co/files/u_18050/2018/9/original/f_5873_1537284696901_Hu1LJm8fTExZNo9SVE.png")
                .cate_list_wrap.mobile_v
                  height 0
                  overflow hidden
                  //transition height .55s cubic-bezier(0.77, 0, 0.175, 1)
                  text-align left
                  ul.cate_lists
                    padding 16px 24px 24px 1px
                    background #fff
                    li.cate_list
                      display inline-block
                      vertical-align top
                      width 50%
                      margin-bottom 40px
                      &:nth-child(2n)
                        width auto
                        margin-left 14px
                      &:last-child
                        margin-bottom 0
                      h3
                        font-size 14px
                      ul.cate_items
                        margin 0
                        li.cate_item
                          position relative
                          margin 16px 0 0
                          &[selected="true"]
                            a
                              position relative
                              z-index 0
                            a:before
                              content ''
                              position absolute
                              bottom 0
                              right 0
                              left 0
                              height 7px
                              background-color #ffff80
                              z-index -1
                    @media screen and (min-width: 1025px)
                      display flex
                      flex-shrink 0
                      flex-wrap wrap
                      li.cate_list
                        &:nth-child(2n)
                          width 50%
                          margin-left 0
                        h3
                          font-size 16px
                          margin 16px 0 4px
                        ul.cate_items
                          display flex
                          flex-shrink 0
                          flex-wrap wrap
                          li.cate_item
                            width 50%
                .filter
                  line-height 16px
                  display inline-block !important
                  vertical-align top
                  height 16px
                  padding 0
                  border 0
                  background #fff
                  position relative
                  right -4px
                  &:before
                    content ''
                    position relative
                    display inline-block
                    vertical-align top
                    width 15px
                    height 14px
                    background-image url(//s3.marpple.co/files/u_29089/2019/2/original/f_23341_1549529474170_BH8o3OZ5q0bSmGN2w5dav.svg)
                    background-size 100%
                    margin-right 8px
                    margin-top 2px
                  .count
                    margin-left 4px
                    font-weight 500
                .ordering_cut_img
                  right auto
                  display inline-block
                  vertical-align top
                  position relative
              .base_product_list
                position relative
  .don_frame[frame_name="mp.product.list.filter"]
    >.don_wrapper
      >.header
        background rgba(255,255,255,0.9) !important
        height 64px
        .title
          font-size 15px
          margin 24px 0 24px 26px
          line-height 1.07
          color color_black
          text-align left
          .don_back_page
            top 12px
          .title2
            margin-left 25px
      >.footer
          position fixed
          left 0
          right 0
          bottom 0
          background #000
          .buttons
            position relative
            button#list_changer
              cursor pointer
              width 100%
              background #000
              padding 0
              height 48px
              font-size 14px
              font-weight bold
              color #fff
              border 0
    .don_page[page_name="mp.product.list.filter"]
    .don_page[page_name="mp.product.list.filter2"]
      background #fff
      .don_tab[tab_name="mp.product.list.filter"]
        .filter_items
          display none !important
        .filter_list_wrap
          position relative
          padding 0 0 104px
          .selected_filter_items
            padding 0 24px 16px
            border-top none
            display block
          .filter_lists_body
            border-top 1px solid #d5dbe0
            .product_filter_list
              ul
                li.filter_list
                  padding 0 24px
                  border-bottom 1px solid #e9ecef
                  &:active
                    background #f5f5f5
                  .filter_list_name
                    .plus
                      background-image url(//s3.marpple.co/files/u_29089/2019/2/original/f_23339_1549521378632_woDNrz7XGMzEb6u1tr.png) !important
                      width 8px
                      height 14px
                      top 16px
                      background-size 8px 14px

      .don_tab[tab_name="mp.product.list.filter2"]
        .filter_item_wrap
          &[filter_list_type="color"]
            .filter_items
              .filter_item
                label
                  cursor pointer
                  .filter_item_name
                    line-height 16px
                    vertical-align top
                    display inline-block
                  .checkbox
                    width 16px
                    height 16px
                    display inline-block
                    vertical-align top
                    position relative
                    background-size 100%
                    border-radius 16px
                    margin-right 8px
                    &[color_name="White"]
                    &[color_name="#fff"]
                      border 1px solid #dee2e6 !important
                      box-sizing border-box
          .filter_items
            display block !important
            border-top 1px solid #d5dbe0
            padding 0 0 104px
            .filter_item
              height 50px
              position relative
              border-bottom 1px solid #f5f5f5
              &:active
                background #f5f5f5
              label:after
                content ''
                position absolute
                z-index 0
                top 16px
                right 24px
                height 16px
                width 16px
                background-size 100%
                border 1px solid #d5dbe0
              &[selected="true"]
                font-weight 600
                label:after
                  background-image url(//s3.marpple.co/files/u_29089/2019/2/original/f_23343_1549552080700_bkba7R9b0D1B8ft5ncnlM.png)
              label
                display block
                cursor pointer
                position absolute
                top 0
                left 0
                bottom 0
                right 0
                padding 16px 24px
                .filter_item_name
                  display inline-block
                input
                  position absolute
                  top 0
                  left 0
                  visibility hidden
                  margin 0
                .checkbox
                  display none
/*
디테일에서의 또 다른부분 (pc 모바일 둘다)
*/
html.maker_page
  #ordering_select
    z-index 10
  .don_frame.product_detail_list[frame_name="mp.product.list"]
    padding-top 42px !important
    >.don_wrapper
      >.header
        height 56px !important
        background rgba(255,255,255,0.9) !important
        z-index 10
      .don_tab[tab_name="mp.product.list"]
        #product_list >.header #cate_list_selector #selected_cate_list
          padding 0 16px 16px
    &[is_modal="true"]
      padding-top 0 !important
      .don_tab[tab_name="mp.product.list"]
          #product_list
            #product_list_body
              .product_list_wrap
                .product_list
                  .count_sort
                    margin 24px 30px
                  .base_product_list
                    width 968px + 16px
                    margin 0 auto
      >.don_wrapper
        padding-top 42px !important
        >.header
          top 0
          left 0
          right 0
          position absolute
  .don_frame[frame_name="mp.product.list.filter"][is_modal="true"]
    padding-top 0 !important
    >.don_wrapper
      width 500px
      height 600px
      padding-top 64px !important
      >.header
        top 0
        left 0
        right 0
        position absolute
      >.footer
        position absolute
