.don_frame[frame_name="/modules/Df/TechPack/List/S/Mui/frame.js"]
  min-width 1200px
  .tech_pack[data-is_complete="true"]
    background Df-TechPack-status-complete-background !important
  .don_tab >.don_wrapper
    margin 32px 16px
    > *
      margin-bottom 32px
  .get_tech_pack_pdf_file_name
  .down_projection_pdf
  .up_done
    display none
  .projection
    .service
    .signature
    .status
      display none
  .tech_pack_filter
    .barcode
      display none
  .don_tab[masking_sw_policy="true"]
    .tech_pack_filter
      .barcode
        display block
    .projection
      .service
      .signature
      .status
        display block
    .complete_tech_pack_group
      display none
    .get_tech_pack_pdf_file_name
    .down_projection_pdf
    .up_done
      display inline-block
  .tech_pack_wrapper
    *
      font-size 18px
  .tech_pack
    *
      font-size 16px
  .tech_pack_projection
    *
      font-size 16px
  .tech_pack_title
    font-size 20px
  .tech_pack_filter
    *
      font-size 20px
  [data-status="PENDING"]
    .status
      background Df-TechPack-status-pending
      &:before
        color #fff
        content '진행중'
  [data-status="COMPLETE"]
    .status
      background Df-TechPack-status-complete
      &:before
        color #fff
        content '완료'
  .tech_pack_groups > ul:not(.pagination-mp) >li
    background Df-TechPack-background1
    {Df-TechPack-box-shadow}
  .tech_pack_groups > ul >li
    margin-bottom 16px
  .tech_pack
    cursor pointer
  .tech_pack_filter >.flex_body
    display flex
    form.flex_body
      display flex
      > div
        {Df-TechPack-margin-right16}
    label
      margin-right 8px
    > div
      {Df-TechPack-margin-right16}
  .tech_pack_projections_wrapper > ul:not(.pagination) >li
    background Df-TechPack-background1
    {Df-TechPack-box-shadow}
    margin 8px
  .tech_pack_group
    >div:nth-child(1)
      background Df-TechPack-background2
      padding 8px
    >.flex_body
      display flex
      >div
       width 50%
    .tech_pack
      padding 8px
      span.option_names
        color #ff1e1e
        font-size 14px
  .tech_pack_group
    >div:nth-child(1)
      border-bottom 1px solid Df-TechPack-border
    >div:nth-child(2)
      .tech_packs
        border-right 1px solid Df-TechPack-border
        .tech_pack
          margin 8px 8px 0 8px
          background Df-TechPack-background2
          {Df-TechPack-box-shadow}
          &:last-child
            margin-bottom 8px
          &.selected
            background #0059ff
            *
              color #fff
  .tech_pack.flex_body
    display flex
    .projection_quantity
    .total_quantity
      width 120px
    >.option
      width 50px
      text-align right
    >.flex_body
      flex 1
      display flex
      justify-content space-between
      margin-right 8px
      >div
        display flex
        justify-content center
        align-items center
        padding 0 16px
        &.tag
          padding 0
          width 60px
        &.bp_name
          flex 8
          justify-content initial
  .tech_pack_wrapper.flex_body
    display flex
    justify-content space-between
    >.option
      width 400px
      text-align right
      >*
        margin-right 16px
        &:last-child
          margin-right 0
    >.flex_body
      display flex
      margin-right 8px
      >div
        margin-right 32px
        justify-content space-between
        align-items center
        padding 0 16px
        &:last-child
          margin-right 0
        &:not(.none_block)
          display flex
  .tech_pack_wrapper
    .none_block
      display none
    &[data-status="COMPLETE"]
      .complete
        display flex
    &[data-status="PENDING"]
      .pending
        display flex
    .created_at
      border 1px solid Df-TechPack-border
  .tech_pack_projection.flex_body
    display flex
    background #fff
    border-bottom 1px solid Df-TechPack-border
    .projection
      width 180px
      display flex
      flex-direction column
      padding 8px
      >div
        margin-bottom 8px
      .flex_body
        display flex
      .id
        margin-right 8px
    .ups
      flex 1
      .up.flex_body
        border-bottom 1px solid Df-TechPack-border
        padding 8px
        display flex
        align-items center
        &[data-is_sticker="true"]
          .up_pdf_download
            display none
          .up_done
            margin-bottom 0
        &:last-child
          border-bottom none
        .product_faces2
          width 100px
          height 100px
          canvas
            cursor pointer
        .id.bp_name
          flex 1
        .quantity
          width 80px
        .id.bp_name
          margin-left 16px
        .quantity
          margin-right 16px
          text-align right
        .option
          display flex
          flex-direction column
          > button
            &.up_done
              background Df-TechPack-status-complete
          > button:first-child
            margin-bottom 8px
  .tech_pack_projection
    .projection
      border-right 1px solid Df-TechPack-border
      .status
        padding 8px
        width 80px
        text-align center
    .up[data-tech_pack_owned="false"]
      { Df-TechPack-blur }

.adjustment-excel
  .excel-download
    margin 40px 80px
