.omp-biz-product-relation-modal
  .omp-biz-product-relation-modal__head-section
    padding 20px 0
    display flex;
    justify-content space-between
    .notice
      color red
      visibility hidden
      &.visible
        visibility visible
  .omp-biz-product-relation-modal__filter-list
    & li
      margin-bottom 16px

    label
    input
      cursor pointer