.df-trend-editor-frame
  > .don_wrapper
    width 535px !important
    height 820px !important
    max-height 100% !important
    > .header
      position absolute !important
      background-color #fff
      .title
        display flex
        align-items center
        padding-left 20px
        height 100%
        font-size 20px
        font-weight bold
  .don_page
    min-height auto !important


.df-trend-editor
  margin-top 60px
  padding 0 20px 10px
  &[data-type="MAIN_SIMPLE"]
    .df-trend-editor__controls-bp
      display none
    .df-trend-editor__title
    .df-trend-editor__desc
      textarea
        height 70px
  &__header
    margin-bottom 14px
    display flex
    align-items center
    justify-content space-between
    gap 10px
    label:nth-child(2)
      flex 1
  &__btn-save
    padding 8px 20px
    color #fff
    border 1px solid #000
    background-color #000
  &__img-area
    margin-bottom 14px
    height 280px
    background-color #ccc
    &[data-status="on"]
      .df-trend-editor__img
        display flex
        align-items center
        justify-content center
        height 100%
        img
          max-width 100%
          max-height 100%
      .df-trend-editor__img-btn
        display none
  &__img
    display none
  &__thumb-item
    position relative
    width 100%
    height 100%
    overflow clip
  &__thumb-item-img
    display flex
    align-items center
    justify-content center
    width 100%
    height 100%
  &__thumb-item-del
    position absolute
    top 0
    right 0
    border 1px solid #ccc
    border-radius 99px
    cursor pointer
  &__img-btn
    height 100%
    display flex
    align-items center
    justify-content center
  &__upload-txt
    margin-top 20px
  &__controls
    margin-top 20px
    padding-top 20px
    border-top 1px solid #ccc
  &__control
    margin-bottom 8px
  &__link
  &__title
  &__desc
    position relative
    textarea
    input[type="text"]
      padding 9px 13px 9px 38px
      width 100%
      box-sizing border-box
    textarea
      height 40px
      resize none
      padding-top 12px
    &::before
      position absolute
      top 50%
      left 10px
      transform translateY(-50%)
      width 18px
      height 18px
      background-image url(//s3.marpple.co/files/u_1396787/2020/11/original/ecd38341eb6b9e0e820a28f1f2203956a23a0ad61.png)
      background-repeat  no-repeat
      background-size 100%
      content ''
    &--en
      &::before
        background-image url(//s3.marpple.co/files/u_1396787/2020/11/original/ce04b2abdf2d685ed86edc7f2d6390c5e6fe1f8f1.png)
    &--jp
      &::before
        background-image url(//s3.marpple.co/files/u_1396787/2020/11/original/4a057f84e5d2e2a9dde8f481a75b70581835940c1.png)
  &__bp
    margin-bottom 10px
    input[type="text"]
      padding 9px 13px 9px
      width 100%
      box-sizing border-box
  &__bp-head
    margin-bottom 6px
  &__bp-control
    display flex
    align-items center
    justify-content space-between
    gap 10px



