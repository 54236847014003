#dream_factory.outsourcing-register
  overflow-y scroll
.don_frame[frame_name="/modules/Df/Outsourcing/Company/Release/Register/S/Mui/frame.js"]
  &[work_type="task"]
    .tech_pack_group_created_at
      display none
    .checkbox__wrapper
      width 58px
    .product-id
      width 100px
    .task-status
      width 160px
    .prj-id
    .shipping-no
    .product-name
    .product-color
    .product-option
    .product-quantity
      width 120px
  &[work_type="tech_pack"]
    .selected-prjs
    .all-prjs
    .all-tasks
      display none
    .tech_pack_group_created_at
      width 120px
    .register-table
      .shipping-no
      .product-img
      .task-status
      .confirmed-at
        display none
  padding 50px
  box-sizing border-box
  min-width 1400px
  max-width 1800px
  margin 0 auto
  h1
    margin-top 0
    margin-bottom 24px
  h2
    margin-top 0
    margin-bottom 12px
  select
    width 150px
    padding 4px 8px
    border-radius 4px
    border 1px solid #d5dbe0
    font-size 15px
  input
    &[type="text"]
    &[type="number"]
      width 150px
      padding 4px 8px
      border-radius 4px
      border 1px solid #d5dbe0
      font-size 15px
    &[type="checkbox"]
      width 18px
      height 18px
      cursor pointer
      border-radius 2px
    &[type="submit"]
      width 120px
      background-color #3c89f9
      color #fff
      font-size 15px
      font-weight bold
      border none
      border-radius 4px
      padding 9px 0
      cursor pointer
    &[type="text"]:disabled
      background-color #fff
      color rgb(170, 170, 170)
      opacity 0.7
  .red
    color red
  .bold
    font-weight bold
  .release-form
    padding 29px 24px
    background-color #f6f6f6
    border-radius 4px
    display flex
    flex-wrap wrap
    gap 24px 50px
    align-items center
    margin-bottom 32px
    &__label
      font-size 15px
      font-weight bold
      margin-right 16px
    select
      &#delivery-type
        width 100px
      &#outsourcing-company
        width 200px

  .releasable-list
    padding 24px
    background-color #f6f6f6
    border-radius 4px
    &__summary
      display flex
      gap 12px
      margin-bottom 16px
      article
        display inline-flex
        width fit-content
        background-color #edf4ff
        border solid 1px #3c89f9
        border-radius 19px
        padding 8px 16px
        align-items center
        gap 6px
        p
          margin 0
        .blue
          color #0e2ee2
      //.selected-projections
      //.selected-products
      //  &__count
      //    color #0e2ee2
    .register-table__wrapper
      width 100%
      border-bottom 1px solid #ddd
      &::-webkit-scrollbar
        display: none
      .product-face
        position relative
        z-index 1
        width 100px
        height 100px
        margin 0 auto
        canvas
          width 200px
          height 200px
          transform scale(0.5)
          transform-origin 0 0
          background-color #fff
      table
        width 100%
        background-color #fff
        border-left 1px solid #ddd
        border-right 1px solid #ddd
        border-collapse separate
        border-spacing 0
        box-sizing border-box
        .checkbox__wrapper
          text-align center
          border-right 1px solid #ededed
          label
            padding 16px
            cursor pointer
        a
          text-decoration underline !important
        thead
          position sticky
          top 50px
          height 58px
          background-color #fff
          z-index 2
          th
            border-top 1px solid #ddd
            border-bottom 1px solid #000000
        tbody
          .register-table__row
            height 100px
            text-align center
            &.super-important
              background-color rgba(255, 0, 0, 0.2)
            &:hover
              background-color #ebf3fe
            &.checked
              background-color #ffffb6
              &:hover
                filter brightness(0.96)
            td
              border-bottom 1px solid #ddd
              &.checkbox
                border-right 1px solid #ddd
            &:last-child
              td
                border-bottom none
            &.no-data
              height 58px

  .date-range
    display flex
    gap 12px
    align-items center
    margin-bottom 16px
    padding 29px 24px
    background-color #f6f6f6
    border-radius 4px
    &__label
      font-size 15px
      font-weight bold
    &__picker
      padding 5px 8px
      border-radius 4px
      border solid 1px #d5dbe0
      background-color #fff
      font-size 14px
      flex-shrink 0

