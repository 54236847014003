@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@300&display=swap');
  #body
    #search_bar
      .search_title
        .s_col
          label
            padding 2px
            display inline-block
            width 200px
            word-break break-all
      .tasks_group
        .group_name
          margin 0 10px
          cursor pointer
          text-decoration underline
    #checkpoint
      display none
      margin 80px 30px
      position relative
      >.body
        #calendar
          max-width 1600px
          margin-bottom 20px
          .fc-day-grid-event
            cursor pointer
            background-color #4cc37f
            border-color #4cc37f
            color color_black
            &.fc-day-grid-event[is_complete="false"]
              border-color #ffffb6
              background-color #ffffb6
            &.fc-day-grid-event[selected="true"]
              border 3px solid #000 !important
          .fc-event .fc-content
            font-size 14px
            .head
            .sub
              padding 0 8px
            .head
              padding-top 8px
            .sub1
              padding-top 4px
              padding-bottom 4px
            .sub2
              padding-bottom 8px
        .filter
          label
            padding 0 16px 0 0
            input
              margin-right 8px
.iv
  display inline-block
  vertical-align top
.task_titles
  margin-bottom 50px
  background-color #f6f6f6
  &.inhouse
    margin 50px 100px !important
    .task_title
      .task_worker
        .rows
          max-height calc(100vh - 250px)
  .task_title_contents
    display flex
    flex-wrap wrap
    justify-content space-evenly
  .dates
    background-color #ddd
    filter drop-shadow(0 0 2px gray)
    line-height 40px
    margin 20px 0
    text-align center
    font-size 18px
    font-weight 600
  .task_title
    position relative
    cursor default
    width auto
    margin 20px 0
    box-sizing border-box
    background-color #ecf5f5
    box-shadow 2px 2px 4px #bebebe
    border-radius 6px
    transition all 0.3s
    &:hover
      box-shadow 3px 3px 4px #888
    &.selected
      filter drop-shadow(black 3px 3px 12px)
      box-shadow none
      &:hover
        box-shadow none

    h2
    h3
      margin 0
      padding 8px 0
      text-align center
    h2
      user-select none
      font-size 20px
    h3
      padding-left 20px
      display inline-block
      vertical-align top
      font-size 16px
    .toggle_task_complete_mode
      user-select none
      position absolute
      top 5px
      left 12px
      height 24px
      width 50px
      margin 2px
      cursor pointer
      border-radius 15px
      overflow hidden
      box-shadow -8px -4px 8px 0px #ffffff, 8px 4px 12px 0px #d1d9e6, 4px 4px 4px 0px #c4ccd8 inset, -4px -4px 4px 0px #ffffff inset
      .toggle_state
        display none
      .indicator
        text-align center
        height 100%
        width 100%
        background #ecf0f3
        border-radius 15px
        cursor pointer
        transform translate(-50%, 0)
        transition transform 0.3s cubic-bezier(0.85, 0.05, 0.18, 1.35)
        box-shadow -8px -4px 8px 0px #ffffff, 8px 4px 12px 0px #d1d9e6
        svg
          width 10px
          height 10px
          fill none
          transition all 0.3s linear
          transform scale(1.77) translate(7px, 2px)
          path
            transition all 0.3s linear
            stroke #eb2f06
            stroke-dasharray 25
            stroke-dashoffset 25
            stroke-width 2
      .toggle_state:checked~.indicator
        transform translate(50%, 0)
        svg
          transition all 0.3s linear
          transform  scale(1.77) translate(-7px, 2px);
          path
            stroke #52d66b
            stroke-dasharray 24
            stroke-dashoffset 0
            stroke-linecap round
            stroke-linejoin round
    span.title_task_complete_mode
      user-select none
      position absolute
      top 13px
      left 74px
      font-size 0.9em
    span.title_refresh_btn
      user-select none
      position absolute
      top 13px
      right 40px
      font-size 0.9em
    .refresh_btn
      cursor pointer
      position absolute
      top 7px
      right 6px
      &:hover
        filter drop-shadow(0px 0px 2px gray)
      svg
        height 26px
    .t_statuses
      user-select none
      display flex
      align-items center
      justify-content space-evenly
      width 100%
      .t_status
        display flex
        justify-content center
        align-items center
        margin 4px
        font-size 18px
        padding 8px 20px
        font-weight bold
        cursor pointer
        border-radius 8px
        transition all 0.2s
        height 40px
        box-sizing border-box
        box-shadow 2px 2px 7px #b8b8b8, -2px -2px 7px #fafafa
        &:hover
          text-decoration underline
          font-size 25px
          box-shadow 2px 2px 5px #989898, -2px -2px 5px #cacaca
        &.important a
          color #dd2820
        &.internal-important a
          color #fba74f
        &[status="ready"]
          background-color #ffffb6
        &[status="on"]
          background-color #00bfff
        &[status="completed"]
          background-color #4cc37f
    .task_worker
      display flex
      flex-direction column
      text-align center
      margin 0 6px
      margin-bottom 6px
      &:not(:last-child)
        margin-bottom 10px
      >.header
        border-radius 6px
        background-color #ddd
        display flex
        flex-direction row
        align-items center
        padding 2px 0
        html.list &
          border-bottom none
        h3
          user-select none
          width 13%
      .rows
        overflow-y auto
        max-height 600px
        table
          cursor default
          width 100%
          border-collapse separate
          .hidden
            display none !important
          thead
            background-color #e7e7e7
            line-height 22px
            tr
              position sticky
              top 0
              background-color #f1f1f1
            .label_print_title
              display flex
              justify-content center
              align-items center
              svg
                margin-bottom 2px
                height 15px
                margin-left 4px
                cursor pointer
                &:hover
                  filter drop-shadow(0px 0px 2px rgb(0 0 0 / 0.4))
                &:first-child
                  margin-left 7px
            //.header_label_print
            //  width 130px
            //.header_task_change_mode
            //  display flex
            //  justify-content center
            //  align-items center
            //  width 130px
            //.header_due_at
            //  width 45px
            //.header_ordered_at
            //  width 150px
            //.header_shipping
            //  width 80px
            //.header_projection_id
            //  min-width 75px
            //.header_qty
            //  width 80px
            //.header_projection_status
            //  width 125px
            //.header_task
            //  min-width 65px
            //.header_issue
            //  width 40px
          tbody
            tr
              height 24px
            tr.blink_ready
              animation blink_ready 0.2s alternate
              animation-iteration-count 8
            tr.blink_on
              animation blink_on 0.2s alternate
              animation-iteration-count 8
            tr.blink_completed
              animation blink_completed 0.2s alternate
              animation-iteration-count 8
            tr:not([status='before_deliver'],[status='delivering'],[status='before_confirm'],[status='confirmed']):hover
              background-color #fdf1c4
            td
              border-radius 3px
            .status_switch
              width 125px
              .rating-form
                height 100%
              .rating
                margin 0 auto
                background-color rgba(0, 0, 16, 0.8)
                width 90px
                height 22px
                border-radius 3px
                input
                  cursor pointer
                input[type="radio"] + svg
                  -webkit-transition all 0.2s
                  transition all 0.2s
                input[type="radio"]
                  position absolute
                  opacity 0
                  margin 0
                  width 22px
                  height 22px

                svg
                  fill rgb(242, 242, 242)
                  height 20px
                  width 20px
                  margin 1px

                input[value="ready"]:hover + svg,
                input[value="ready"]:checked + svg,
                input[value="ready"]:focus + svg
                  fill #e8d600
                input[value="on"]:hover + svg,
                input[value="on"]:checked + svg,
                input[value="on"]:focus + svg
                  fill #00bfff
                input[value="completed"]:hover + svg,
                input[value="completed"]:checked + svg,
                input[value="completed"]:focus + svg
                  fill #4cc37f

            .label_print_btn
              width 125px
              cursor pointer
              svg
                fill #666
                display flex
                align-items center
                height 18px
                justify-content center
                margin 0 auto
              &:hover
                svg
                  fill #111

          td
          th
            border-bottom 1px solid #d5dbe0
            padding 0 7px
            font-size 14px
          tr[status="before_print"]
          tr[status="printing"]
            background-color #ffffea
          tr[status="before_deliver"]
          tr[status="delivering"]
          tr[status="before_confirm"]
          tr[status="confirmed"]
            background-color #4cc37f

          td[status="ready"]
            background-color #ffffb6
          td[status="on"]
            background-color #00bfff
          td[status="completed"]
            background-color #4cc37f
          td[internal-important="true"]
            background #ffcc55
          td[important="true"]
            color white
            background-color #ff382f
          td:not([t_issue_count="0"]).t_issue_count
            background-color #ff382f
            a
              color white
              &:hover
                color blue
                text-decoration underline
          td.cell_projection_id
            width 75px
            a:hover
              color blue
              text-decoration underline
          td.cell_task
            width 65px
            a:after
              content attr(task_status)
            a:hover
              color blue
              text-decoration underline
              &:after
                content attr(task_id)
          td.cell_quantity
            width 35px

          td.private
            background-color greenyellow
          td.cell_projection_id, td.cell_due_at, td.cell_ordered_at, td.cell_quantity
            font-family 'JetBrains Mono', monospace
            font-weight bold
          td.cell_projection_status
            width 80px
          td.cell_before_deliver_at[is_72="2day"]
            background #f0ff00
          td.cell_before_deliver_at[is_72="3day"]
            background #ff382f
    &:last-child
      margin-bottom 50px
html.projection.waybill
  display block
  .task_title
    width 100%
html.checkpoint.ing
  #body
    #checkpoint
      >.body
        .task_titles
          .task_title
            display flex
            align-items center
            width 100%
            h2
              display inline-block
              vertical-align top
              width 15%
              margin 0 10px
              font-size 18px
            .task_workers
              display inline-block
              vertical-align top
              width 85%
            .task_worker
              text-align left
              margin -1px
              display block
              >.header
                box-shadow inset 0 -1px 2px 0.5px #ccc
                padding 8px 0
                filter drop-shadow(-1px 1px 1px #ccc)
                h3
                  font-size 15px
                  width 150px
                  padding-left 10px
              .t_status
                width 90px
                height 36px
                line-height 36px
                padding 0
                text-align center
                position relative
                a
                  border-radius 5px
                  display block
                  width 100%
                  height 100%
                &.important a
                  color #ff382f
                &.internal-important a
                  color #f8ba2b
                &[status="ready"] a
                  background-color #ffffb6
                &[status="on"] a
                  background-color #00bfff
                &[status="completed"] a
                  background-color #4cc37f
                &[len="0"]
                  visibility hidden
                  a
                    display none

html.checkpoint.list
  .status_popup
    border-radius 100%
    opacity 0.9
    svg
      stroke-width 0.5px
      stroke white
      fill white
      animation turn 1.1s cubic-bezier(0.16, 1, 0.3, 1)
  .refresh_btn
    user-select none
    svg.rotate
      animation rotate_btn 0.9s cubic-bezier(0.16, 1, 0.3, 1)

  @keyframes turn{
    0%{
      transform: rotateY(180deg);
    }
    100%{
      transform: rotateY(-360deg);
    }
  }

  @keyframes blink_ready{
    0%{
      background-color: #ffffb6;
    }
    100%{
      background-color: #e8d600;
    }
  }
  @keyframes blink_on{
    0%{
      background-color:#00bfff;
    }
    100%{
      background-color: rotateY(-360deg);
    }
  }
  @keyframes blink_completed{
    0%{
      background-color: #ffffb6;
    }
    100%{
      background-color: #4cc37f;
    }
  }

  @keyframes rotate_btn{
    0%{
      transform: rotate(180deg);
    }
    100%{
      transform: rotate(-360deg);
    }
  }
