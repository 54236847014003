.cursor_pointer
  cursor pointer

.df_select
  padding 5px 30px
span.mp_input
  input
    display none
    &:checked + span
      &:after
        position absolute
        content ""
        width 8px
        height 8px
        background color_active
        border-radius 50%
        top 4px
        left 4px
  span
    width 16px
    height 16px
    border 1px solid #d5dbe0
    border-radius 50%
    position relative
    display inline-block
    vertical-align bottom
    margin-right 8px
  input[type="checkbox"] + span
    border-radius 0
    border 1px solid #000
    width 15px
    height 15px
  input[type="checkbox"]
    &:checked + span
      &:after
        position absolute
        content ""
        width 15px
        height 15px
        border-radius 0
        background url(//s3.marpple.co/files/u_193535/2019/2/original/f_38200_1550649552107_I2xqer6Q0bbl6x6HAy2c3r.png) no-repeat 0 0
        top -1px
        left -1px

.don_frame[frame_name="df.receipt_document"]
  .don_tab[tab_name="df.receipt_document"]
    .don_wrapper
      margin 30px auto
      max-width 900px
      *
        box-sizing border-box
      >.header
        font-size 18px
        font-weight bold
        margin-bottom 16px
      .projection_id
        text-decoration underline
      >.filter
        .search
          width 70px
        font-size 16px
        font-weight bold
        margin-bottom 16px
        .row
          display flex
          .colm
            margin-right 16px
            margin-bottom 8px
            span
              margin-right 8px
          select
            padding 0 0 6px
            vertical-align  top
          .and
            margin-right 8px
            margin-left 8px
      >.option
        margin-bottom 16px
        text-align right
        >button
          margin-left 8px
      .for_receipt
        .mp_input
          display none
        display flex
        margin-bottom 16px
        &[receipt_status="done"]
          .receipt_status:after
            content "발행 완료"
            color green
        &[receipt_status="need_issue"]
          .receipt_status:after
            content "발행 요청"
            color red
        &[receipt_status="need_cancel"]
          .receipt_status:after
            content "취소 필요"
            background yellow
            color red
        &[receipt_status="is_canceled"]
          .receipt_status:after
            content "취소 완료"
            background yellow
            color green
        &[receipt_status="receipt_error"]
          .receipt_status:after
            content "발행 실패"
            background red
            color black
        &[receipt_status="need_reissue"]
          .receipt_status:after
            content "재발행 필요"
            color red
        &[receipt_status=""]
          .receipt_status:after
            content "-"
        >label
          display flex
          align-items center
        >.wrapper
          border 1px solid #878b8f
          border-radius 4px
          padding 10px
      .rowm
        font-size 14px
        display flex
        margin-bottom 8px
        &:last-child
          margin-bottom 0
      .projection_status >span
        color grey
      .info_1
        justify-content space-between
        .colm:first-child
          display flex
          >*
            margin-right 16px
        .colm:last-child
          >button
            margin-left 8px
      .info_2
        justify-content space-between
      .info_3
        table
          width 100%
          table-layout fixed
        table, td
          border 1px solid #878b8f
          border-collapse collapse
        td
          padding 10px
        .head
          width 110px
          background-color #dddcdd
          text-align center
        .won:after
          content "원"
