html.df-trend
  font-size 16px !important

.df-trend-admin
  width 1380px
  margin 20px auto
  padding-bottom 50px
  &[data-status="ordering"]
    .df-trend-admin__btn-modify
    .df-trend-admin__btn-add
    .df-trend-admin__public-list .df-trend-admin__btn-order
    .df-trend-admin__btn-order-change
      display none

    .df-trend-admin__public-list .df-trend-admin__btn-order
    .df-trend-admin__btn-order-save
    .df-trend-admin__btn-order-cancel
      display inline-block
  &__btn-order
  &__btn-order-save
  &__btn-order-cancel
    display none
  &__buttons
    text-align right
  &__btn-add
    width 10
  &__lang
    margin 0 0 10px
    padding 0
    list-style none
    display flex
    align-items center
    gap 10px
  &__lang-li
    font-size 16px
  &__h3
    margin 20px 0
    padding 10px
    font-size 18px
    border-bottom 1px solid #ccc
    background-color #ccc
  &__list
    display grid
    grid-template-columns 1fr 1fr 1fr
    grid-gap 10px
  &__list-li
    width 100%
  &__list-li-buttons
    margin-bottom 10px
    text-align right


