Df-We-TechPack-border = #ccc
Df-We-TechPack-box-shadow = {
  border-radius: 4px,
  box-shadow: 0 0 4px rgba(0,0,0,0.4)
}
Df-We-TechPack-margin-right16 = {
  margin-right: 16px,
  '&:last-child': {
    margin-right: 0
  }
}
Df-We-TechPack-background1 = #f5f5f5
Df-We-TechPack-background2 = #fff

Df-We-TechPack-status-complete = #4cc37f
Df-We-TechPack-status-complete-background = #aedfc5
Df-We-TechPack-status-pending = #5bb7ff

Df-We-TechPack-blur = {
  background: #d8d8d8,
  '*': {
    color: #8a8a8a
  },
  'button': {
    cursor: default
  }
}

//Df-We-TechPack-Complete = {
//  border: 1px solid Df-We-TechPack-status-complete !important
//}


.don_frame.we_tech_pack_styl
  .we_h_flex
    display flex
  .we_v_flex
    display flex
    flex-direction column
  *
    box-sizing border-box
  .data-wrapper
    >.header
      display none
  .option
    display flex
    justify-content flex-end
    align-items center
  .is_marpple_combined
  .is_we_combined
  .is_large
    visibility hidden
  a.a_button
  button.we_button
    background #000
    border none
    color #fff
    padding 8px
    &.disabled
      text-decoration line-through
      pointer-events none
      cursor default
      background #bababa
  [data-is_marpple_combined="true"]
    .is_marpple_combined
      visibility visible
      background #00bb00
  [data-is_we_combined="true"]
    .is_we_combined
      visibility visible
      background #ff26b6
  [data-is_large="true"]
    .is_large
      visibility visible
      background #8563ff
  .pagination
    overflow-x auto
    display flex
    margin 0
    list-style none
    button
      border none
      background none
    box-sizing initial !important
    *
      box-sizing initial !important
    .pagination-item
      cursor pointer
      color #828282
      font-size 16px
      font-weight 600
      display block
      padding 9px 9px 6px
      height 18px
      min-width 15px
      text-align center
      &:hover
        z-index: 2;
        color #000
      &.disabled
        color: #d1d2d3;
        pointer-events: none;
        cursor: auto;
        background-color: rgba(0,0,0,0);
        border-color: rgba(24,28,33,0.06);
      &.current
        background-color: #f7f7f7;
        color: #000;
        border-radius 16.5px
      &[data-page_type]
        background-repeat no-repeat
        background-size 8px 12px
        background-position center
      &[data-page_type="prev"]
        background-image url("//s3.marpple.co/files/u_29089/2020/11/original/e3d6d615c8155f47af684cdedb4d87151d2f0e421.png")
        &.disabled
          background-image url("//s3.marpple.co/files/u_29089/2020/11/original/4ade5b4186472dd521691e2272c1bea38a856e621.png")
      &[data-page_type="next"]
        background-image url("//s3.marpple.co/files/u_29089/2020/11/original/0aa2d8ac7a63447c139e0f5d040fddf1af70a2fc1.png")
        &.disabled
          background-image url("//s3.marpple.co/files/u_29089/2020/11/original/c583f178e87219dd57a044095f3f6b93e42b9efc2.png")

@import "we_tech_pack.styl"
@import "we_tech_pack_pdf.styl"
@import "we_mode.styl"
@import "we_stat.styl"
@import "../chart.styl"
