.don_frame[frame_name="/modules/Df/Stock/Supplier/S/Mui/frame.js"]
  .don_tab[tab_name="/modules/Df/Stock/Supplier/S/Mui/tab.js"]
    h1
      margin 30px 0 0 50px
      cursor default
    .button.first
      border-left 1px solid #bbb !important
    .supplier_manage_tables
      display none
      flex-direction row
      margin 0 auto
      max-width 1760px
      justify-content space-evenly
      .procurement
        display flex
        flex-direction column
        justify-content center
        span
          height 32px
          border-radius 5px
          font-size 1.5rem
          text-align left
          padding-left 20px
      .tabulator
        background-color #f7f7f7
        width 660px
        @media screen and (max-width: 1400px)
          width 550px
        @media screen and (max-width: 1200px)
          width 440px
        .tabulator-cell
          height 31px
          svg
            height 100%
        .tabulator-row.tabulator-selected
          background-color #ffee32
    .transfer_buttons
      display flex
      justify-content center
      flex-direction column
      min-width 60px
      .button
        border-left 1px solid #bbb
        margin 20px auto
        width 40px
        height 50px
        &:hover
          background-color #49a8d7
    .control_buttons
      display flex
      justify-content center
      align-items center
      margin 15px 0
      .control_buttons
        display flex
        justify-content center
        align-items center
        margin 20px 30px
    .button
      display flex
      justify-content center
      align-items center
      box-sizing border-box
      bottom 0
      width 50px
      height 36px
      text-decoration none
      border 1px solid #bbb
      cursor pointer
      line-height 1
      border-radius 3px
      border-left 0
      svg
        width 16px
        height 16px
      &.return:hover
        background-color #9c9c9c
      &.download:hover
        background-color #74e785
      &.upload:hover
        background-color #f763c6
  .flash_red
    animation flash_red 1s
  .flash_yellow
    animation flash_yellow 0.7s
  .flash_yellow_twice
    animation flash_yellow_twice 0.7s
  .flash_green
    animation flash_green 0.5s
  @keyframes flash_red {
    0% {
      color: #e03615;
    }
    20% {
      color: #ffee32;
    }
    40% {
      color: #e03615;
    }
    60% {
      color: #ffee32;
    }
    80% {
      color: #e03615;
    }
    100% {
      color: black;
    }
  }
  @keyframes flash_yellow {
    0% {
      background-color: #ffee32;
    }
    100% {
      background-color: white;
    }
  }

  @keyframes flash_yellow_twice {
    0% {
        background-color: #ffee32;
    }
    33% {
          background-color: white;
    }
    66% {
      background-color: #ffee32;
    }
    100% {
      background-color: white;
    }
  }

  @keyframes flash_green {
    0% {
        background-color: #70e000;
    }
    100% {
          background-color: white;
    }
  }