.don_frame[frame_name="/modules/Df/We/TechPack/List/S/Mui/frame.js"]
  [data-show="false"]
    display none

  .we_tech_pack_pdf[data-is_complete="true"]
    background Df-We-TechPack-status-complete-background !important
  .don_tab >.don_wrapper
    margin 32px 16px
    > *
      margin-bottom 32px
  .we_tech_pack_wrapper
    *
      font-size 18px
  .we_tech_pack_pdf
    *
      font-size 16px
  .we_tech_pack_pdf_projection
    *
      font-size 16px
  .tech_pack_title
    font-size 20px
  .we_tech_pack_filter
    *
      font-size 20px
  [data-status="PENDING"]
    .status
      background Df-We-TechPack-status-pending
      &:before
        color #fff
        content '진행중'
  [data-status="COMPLETE"]
    .status
      background Df-We-TechPack-status-complete
      &:before
        color #fff
        content '완료'
  .we_tech_packs > ul:not(.pagination-mp) >li
    background Df-We-TechPack-background1
    {Df-We-TechPack-box-shadow}
  .we_tech_packs > ul >li
    margin-bottom 16px
  .we_tech_pack_pdf
    cursor pointer
  .we_tech_pack_filter >.flex_body
    display flex
    form.flex_body
      display flex
      > div
        {Df-We-TechPack-margin-right16}
    label
      margin-right 8px
    > div
      {Df-We-TechPack-margin-right16}
  .we_tech_pack_pdf_projections_wrapper > ul:not(.pagination) >li
    background Df-We-TechPack-background1
    {Df-We-TechPack-box-shadow}
    margin 8px
  .we_tech_pack
    >div:nth-child(1)
      background Df-We-TechPack-background2
      padding 8px
    >.flex_body
      display flex
      >div:nth-child(1)
        width 50%
      >div:nth-child(2)
        width 50%
    .we_tech_pack_pdf
      padding 8px
  .we_tech_pack
    >div:nth-child(1)
      border-bottom 1px solid Df-We-TechPack-border
    >div:nth-child(2)
      .we_tech_pack_pdfs
        border-right 1px solid Df-We-TechPack-border
        .we_tech_pack_pdf
          margin 8px 8px 0 8px
          background Df-We-TechPack-background2
          {Df-We-TechPack-box-shadow}
          &:last-child
            margin-bottom 8px
          &.selected
            background #0059ff
            *
              color #fff
  .we_tech_pack_pdf.flex_body
    display flex
    >.option
      width 100px
      text-align right
    >.flex_body
      flex 1;
      display flex
      justify-content space-between
      margin-right 8px
      >div
        display flex
        justify-content center
        align-items center
        padding 0 16px
        &.tag
          padding 0
          width 60px
        &.bp_name
          justify-content initial
        &:nth-child(1)
        &:nth-child(2)
        &:nth-child(3)
          flex 1
        &:nth-child(4)
          flex 5
        &:nth-child(5)
        &:nth-child(6)
          flex 2
  .we_tech_pack_wrapper.flex_body
    display flex
    justify-content space-between
    >.option
      width 400px
      text-align right
      >*
        margin-right 16px
        &:last-child
          margin-right 0
    >.flex_body
      display flex
      margin-right 8px
      >div
        margin-right 32px
        justify-content space-between
        align-items center
        padding 0 16px
        &:last-child
          margin-right 0
        &:not(.none_block)
          display flex
  .we_tech_pack_wrapper
    .none_block
      display none
    &[data-status="COMPLETE"]
      .complete
        display flex
    &[data-status="PENDING"]
      .pending
        display flex
    .created_at
      border 1px solid Df-We-TechPack-border
  .we_tech_pack_pdf_projection.flex_body
    display flex
    background #fff
    border-bottom 1px solid Df-We-TechPack-border
    .projection
      width 180px
      display flex
      flex-direction column
      padding 8px
      >div
        margin-bottom 8px
      .flex_body
        display flex
      .id
        margin-right 8px
      .exclude
        background-color #d9534f
        color #fff
        text-align center
        width 100px
        padding 8px 5px
        cursor pointer
        font-size 14px
    .ups
      flex 1
      .up.flex_body
        border-bottom 1px solid Df-We-TechPack-border
        padding 8px
        display flex
        align-items center
        canvas
          cursor pointer
        &[data-is_sticker="true"]
          .we_up_pdf_download
            display none
          .we_up_done
            margin-bottom 0
        &:last-child
          border-bottom none
        .product_faces2
          width 100px
        .id.bp_name
          flex 1
        .quantity
          width 80px
        .id.bp_name
          margin-left 16px
        .quantity
          margin-right 16px
          text-align right
        .option
          display flex
          flex-direction column
          > button
            &.we_up_done
              background Df-We-TechPack-status-complete
          > button:first-child
            margin-bottom 8px
  .we_tech_pack_pdf_projection
    .projection
      border-right 1px solid Df-We-TechPack-border
      .status
        padding 8px
        width 80px
        text-align center
    .up[data-pdf_owned="false"]
      { Df-We-TechPack-blur }
