// modules/Df/Task/History/F/Style/Pc/index.styl

.task-history
  box-sizing border-box
  width 100%
  padding 1.5rem
  & *
    box-sizing border-box
    ::before
    ::after
      box-sizing border-box

  tbody
  td
  tfoot
  th
  tr
  thead
    border-color inherit
    border-style solid
    border-width 0
  .table
    --bs-table-bg #fff
    width 100%
    margin-bottom 1rem
    vertical-align top
    border-color #dee2e6
    & > :not(caption) > * > *
      padding 0.5rem 0.5rem
      color initial
      background-color var(--bs-table-bg)
      border-bottom-width 1px
      box-shadow inset 0 0 0 9999px initial

  .table-bordered > :not(caption) > *
    border-width 1px 0
  .table-bordered > :not(caption) > * > *
    border-width 0 1px
  .table-light
    --bs-table-bg #f8f9fa
    color #000
    border-color #c6c7c8
  .text-center
    text-align center
  .align-middle
    vertical-align middle
  .mt-3
    margin-top 3

  .accordion-item
    overflow hidden
    &.no-content
      .head-content
        justify-content center
        cursor auto
    &.active
      .head-content
        .accordion-icon
          transform rotate(0deg)
      .spread-row
        max-height max-content
  .head-content
    display flex
    align-items center
    gap 8px
    cursor pointer
    padding 0 16px
    border solid 1px #ddd
    .accordion-icon
      transform rotate(180deg)
      width 20px
      height 20px
  .spread-row
    max-height 0
    transition all 0.3s ease-in-out


  .header
    display flex
    justify-content center
    align-items center
    position relative
    .btn-group
      position absolute
      right 1.5rem
      & > .btn
        position relative
        flex 1 1 auto
        padding 10px
        font-size 1rem
