@media screen and (max-width: 1024px)
  .don_tab[tab_name="/modules/SVGEditor/Sticker/Single/S/Mui/tab.js"]
  .mp_maker
    .circle_icon
      padding 10px 10px 17px 10px
      .icon
        background-color #ffffff
        box-shadow 0 4px 7px 0 rgba(0, 0, 0, 0.1)
        z-index 100
        position: relative
        width 45px
        height 45px
        background-repeat no-repeat
        border-radius 50%
        cursor pointer
      .text
        position absolute
        width 73px
        left 50%
        box-sizing border-box
        padding-top 5px
        transform translateX(-50%)
        text-align center
        color color_black
        font-size 11px
      &.add_img
        .icon
          background-image url(//s3.marpple.co/file/guest/2018/7/original/f_5277_1531138399151_abB2rx6FMQ8R2HQD7Q2K0R.png)
          background-size 24px
          background-position 11px 11px
      &.my_lib .icon
        background-image url(//s3.marpple.co/files/u_18052/2018/10/original/f_6033_1539321455471_Ctb4M8KJlTuThg7Q9Q5U.png)
        background-size 24px
        background-position 11px 11px
      &.add_sticker2 .icon
      &.add_sticker_line .icon
      &.change_img .icon
      &.add_sticker_custom .icon
        background-image url(//s3.marpple.co/files/u_18052/2018/10/original/f_6034_1539321513991_A5oxMw1Al7oq4r7pOzT8o.png)
        background-size 24px
        background-position 11px 11px
      &.add_text .icon
        background-image url(//s3.marpple.co/files/u_18052/2018/10/original/f_6032_1539321455471_ehp6LG6WbWm3uE8LTP0C.png)
        background-size 24px
        background-position 11px 11px
      &.open_cv_background_frame .icon
        background-image url(//s3.marpple.co/files/u_193535/2022/1/original/e3c9a52e62c3f71c61d40bda01f2a3bec34618ec1.svg)
        background-size 24px
        background-position 11px 11px
      &.font
        .icon
          background-image url(//s3.marpple.co/files/u_193535/2018/10/original/f_12388_1540988872334_N2Bf2Po8U8iNE0gOtyFo.png)
          background-size 22px
          background-position 13px 11px
        &:active
          .icon
            background-color color_active
            background-image url(//s3.marpple.co/files/u_193535/2018/11/original/f_15055_1543293065268_gTHCr1q6r8d8D9M2K6lt1HG.png)
          .text
            color color_active
      &.keyboard
        .icon
          background-image url(//s3.marpple.co/files/u_193535/2018/10/original/f_12387_1540988872334_ok5M5ov7cw2b5cI8K4MD2zE.png)
          background-size 22px
          background-position 12px 11px
        &:active
          .icon
            background-color color_active
            background-image url(//s3.marpple.co/files/u_193535/2018/11/original/f_15052_1543293065268_EW2Ra0sMBP0lIP5v4qXt.png)
          .text
            color color_active
      &.color
      &.dosu_image_color
        .icon
          background-image url(//s3.marpple.co/files/u_193535/2018/10/original/f_12386_1540988872334_ZTU8Uki6q9qgl7QSsTm.png)
          background-size 22px
          background-position 12px 12px
        &:active
          .icon
            background-color color_active
            background-image url(//s3.marpple.co/files/u_193535/2018/11/original/f_15054_1543293065268_eKRaxJxu6hOTt0R3gd.png)
          .text
            color color_active
      &.filter
        .icon
          background-image url(//s3.marpple.co/files/u_1187078/2021/1/original/da16fc8b198a37bc92807282d6d4b6abd3cae52f1.png)
          background-size 22px
          background-position 12px 12px
        &:active
          .icon
            background-color color_active
            background-image url(https://s3.marpple.co/files/u_1187078/2021/1/original/b0a0e252b5eb03a3740a1a8f047f01e5e4f528201.png)
          .text
            color color_active
      &.adjustment
        .icon
          background-image url(//s3.marpple.co/files/u_1187078/2021/1/original/b25d52d1dbacaae890a4df3716362d8059235e161.png)
          background-size 22px
          background-position 12px 12px
        &:active
          .icon
            background-color color_active
            background-image url(https://s3.marpple.co/files/u_1187078/2021/1/original/a66f1f953edac5ec717103ecaf3f0b7bb2c0483f1.png)
          .text
            color color_active
      &.crop
        .icon
          background-image url(//s3.marpple.co/files/u_1187078/2021/1/original/b4c6aaa243f693a4fac2068f6ecd9f090a7e81131.png)
          background-size 22px
          background-position 12px 12px
        &:active
          .icon
            background-color color_active
            background-image url(//s3.marpple.co/files/u_1187078/2021/1/original/273880595e88f5329fe9316d85c2a48ca64dcfc41.png)
          .text
            color color_active
      &.duplicate
        .icon
          background-image url(//s3.marpple.co/files/u_193535/2021/3/original/41f650ebaa0bccfcb437534d9140d7543b3173e31.svg)
          background-size 22px
          background-position 12px 12px
        &:active
          .icon
            background-color color_active
            background-image url(//s3.marpple.co/files/u_193535/2021/3/original/41f650ebaa0bccfcb437534d9140d7543b3173e31.svg)
          .text
            color color_active
      &.delete
        .icon
          background-image url(//s3.marpple.co/files/u_193535/2021/3/original/6412a9ded1cc5e2d16805400ab9e5df0a7308e4f2.svg)
          background-size 22px
          background-position 12px 12px
        &:active
          .icon
            background-color color_active
            background-image url(//s3.marpple.co/files/u_193535/2021/3/original/6412a9ded1cc5e2d16805400ab9e5df0a7308e4f2.svg)
          .text
            color color_active
      &.pattern
        .icon
          background-image url(//s3.marpple.co/files/u_193535/2018/10/original/f_12384_1540988872334_b9lDSKHIpzT8BS2umo.png)
          background-size 22px
          background-position 12px 12px
        &:active
          .icon
            background-color color_active
            background-image url(//s3.marpple.co/files/u_193535/2018/11/original/f_15056_1543293065268_ke3u8FT9IT0g2XBVp1KX2f.png)
          .text
            color color_active
      &.request_bg_removal
      &.fit_to_print_width
        .option.check_box
          position absolute
          top 50%
          left 50%
          transform translate(-50%, -50%)
          width 16px
          height 16px
          line-height 12px
          margin-right 8px
          background transparent url(//s3.marpple.co/files/u_193535/2018/11/original/f_13773_1542340312547_B1sxK4u0da6HtS6qA7h4l5a.png) no-repeat 50% 50%
          background-size 16px
        &.checked
          .option.check_box
            background transparent url(//s3.marpple.co/files/u_193535/2018/11/original/f_13772_1542340312547_FoQ1mCFOcEzGsim0h.png) no-repeat 50% 50%
            background-size 16px
      &.add_path
        .icon
          background-image url(//s3.marpple.co/files/u_1187077/2021/4/original/f8caea3055fa8e50077d85bf36f5d91d062554082.svg)
          background-size 24px
          background-position 11px 11px
      &.edit_path
        .icon
          background-image url(//s3.marpple.co/files/u_1187077/2021/4/original/f8caea3055fa8e50077d85bf36f5d91d062554082.svg)
          background-size 24px
          background-position 11px 11px
        &:active
          .icon
            background-color color_active
          .text
            color color_active
      &.add_shape
        .icon
          background-image url(//s3.marpple.co/files/u_1187077/2021/4/original/ded9c4461d399b5e19050bbb4fb2f2441ce7aebc1.svg)
          background-size 24px
          background-position 11px 11px
        &:active
          .icon
            background-color color_active
          .text
            color color_active
      &.shape_color
        .icon
          background-image url(//s3.marpple.co/files/u_1187077/2021/4/original/31719e9274cc0961c6efc216bf07eb4cdcceb9691.svg)
          background-size 24px
          background-position 11px 11px
        &:active
          .icon
            background-color color_active
          .text
            color color_active
      &.shape_border
        .icon
          background-image url(//s3.marpple.co/files/u_1187077/2021/4/original/317e871a5d2f0f6bd7f4385a23e6d6ac033e4cbd1.svg)
          background-size 24px
          background-position 11px 11px
        &:active
          .icon
            background-color color_active
          .text
            color color_active
