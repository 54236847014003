.don_frame[frame_name="composite_template_test_frame"]
  .don_page
    >.don_wrapper >.header
      .filter
        > *
          visibility hidden
        label
          margin-right 10px
        justify-content center
        display flex
        > *
          margin-right 16px
        >.wrapper[filter_type="composite_templates"]
          display flex
    &[current_tab="composite_thumbnail_test_tab"]
      .filter
        >.option
          visibility visible
        >.wrapper[filter_type="base_product_colors"]
        >.wrapper[filter_type="composite_templates"]
        >.wrapper[filter_type="composite_template_change"]
          display none
    &[current_tab="watch_real_thumbnails"]
    &[current_tab="color_test_tab"]
    &[current_tab="map_test_tab"]
    &[current_tab="thumbnail_migration_test_composite_tab"]
      .filter
        >.wrapper[filter_type="base_product_colors"]
        >.wrapper[filter_type="composite_templates"]
        >.option
          visibility visible
        >.wrapper[filter_type="composite_template_change"]
          display none
    &[current_tab="thumbnail_migration_test_composite_tab"]
      .filter
        >.wrapper[filter_type="composite_template_change"]
          display block
          visibility visible
    &[current_tab="thumbnail_migration_test_normal_composite_tab"]
      .filter
        >.wrapper[filter_type="base_product_colors"]
        >.option
          visibility visible
        >.wrapper[filter_type="composite_templates"]
        >.wrapper[filter_type="composite_template_change"]
          display none !important
  .tab_button
    &[tab_name="untitled_tab"]
      display none
  &:not(.has_composite)
    .tab_button
      &[tab_name="color_test_tab"]
      &[tab_name="map_test_tab"]
      &[tab_name="thumbnail_migration_test_composite_tab"]
        display none
  .don_tab[tab_name="color_test_tab"]
    .option
      margin-bottom 16px
      button
        margin-right 16px
    .img_wrapper
      width 300px !important
      canvas
        width 300px
        height 300px !important
    .composite_mask_contrast_form
      display none
      margin-top 16px
      margin-left 16px
      > *
        margin-right 16px
    &[data-view_type="big"]
      .img_wrapper
        width 900px !important
        canvas
          width 900px
          height 900px !important
  .don_page >.don_wrapper >.header
    margin-bottom 16px
    .title
      font-size 30px
      padding 20px 0
      text-align center
    .don_hide_frame
      top 20px
      right 30px
    .tab_buttons
      text-align center
    .tab_button
      font-size 20px
      background #fff
      margin 12px 40px
      border 1px solid transparent
      &[selected="true"]
        border-bottom 1px solid black
  .don_tab >.don_wrapper
    padding 0 10px
  .go_to_the_site
    visibility hidden
  .item[data-is_public="true"]
    .go_to_the_site
      visibility visible
      background #1b77ff
  .more
  .migration
    position fixed
    background #ccc
    border-radius 50%
    border 1px solid #000
    left 50%
    transform translateX(-50%)
  .more
    width 50px
    height 50px
    bottom 50px
  .migration
    width 100px
    height 50px
    bottom 150px
  .option
    text-align center
  .start
    background #ccc
    border-radius 50%
    width 100px
    height 100px
    border 1px solid #000
    font-size 20px
    position fixed
    top 50%
    left 50%
    transform translate(-50%, -50%)
  .option2
    position fixed
    top 0
    right 50px
    background #1b77ff
    border-radius 4px
    text-align center
    margin-bottom 16px
    select
    input
      width 200px
      font-size 20px
  .list_wrapper
    display flex
    justify-content center
    flex-wrap wrap
    >.img_wrapper
      .thumbnails
        img[is_thumb="true"]
          border 1px solid #ff8888
      border 1px solid #00bfff
      label
        margin-bottom 8px
        display block
      > .wrapper
        margin-bottom 16px
      box-sizing border-box
      width 900px
      margin 0 0.5% 50px
      padding 10px
      canvas
      img
        width 100% !important
        height auto !important
        border 1px solid #919191
        background #e2e2e2
      img
        width 50% !important
      .thumbnails
        img
          width 100% !important
      .row_wrapper
        display flex
        .wrapper
          width 50%
      .mockup
        display flex
        > .wrapper
          width 50%
