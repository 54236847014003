.enter_composite_templates_list
  margin-top 32px
.btn_composite
  button
    margin 0 10px 10px
    border 1px solid #346e24
    background-color #479731
    color #fff
    text-align left
    padding 7px
    width 180px
    border-radius 3px
.bp_label_register
  display flex
  padding 7px
  box-sizing border-box
  align-items center
  height 35px
  width 180px
  margin 0 10px 10px
  background-color #479731
  color #fff
  justify-content space-between
  input
    display none
  .control_buttons
    display flex
    align-items center
    gap 8px
    button
      transition transform 0.3s ease
      background-color transparent
      border none
      background-size contain
      background-repeat no-repeat
      &[name="test-print"]
        filter invert()
        width 20px
        height 20px
        background-image url('//s3.marpple.co/files/u_1187078/2024/4/original/5f1e9f838a0d02c2720b2c1610da493371acb9ad1.png')
      &[name="upload"]
        filter invert()
        width 20px
        height 20px
        background-image url('//s3.marpple.co/files/u_193535/2018/11/original/f_14213_1542857353845_CgLRkP5nbTcq7T7v5kb.svg')
      &[name="delete"]
        filter invert()
        width 18px
        height 18px
        background-image url('//s3.marpple.co/files/u_193535/2018/11/original/f_14140_1542785966321_LwoLs3Z6XDalCRw3wR.svg')
      &:hover
        filter none

.don_frame[frame_name="composite_templates_list"]
  .neo_border
    margin 10px auto
    padding 17px
    width 100%
    display flex
    flex-wrap wrap
    justify-content space-evenly
    border-radius: 14px;
    background #ffffff;
    box-shadow 6px 6px 12px #e6e6e6, -6px -6px 12px #fafafa
  .text_input
    border-radius 11px
    background #ffffff
    box-shadow inset 6px 7px 11px #dedede, inset -9px -9px 11px #fafafa
    border none
    padding 7px 13px 7px 15px
    vertical-align -webkit-baseline-middle
  .composite_template[data-is_published="true"]
    border 1px solid green
    .delete
      display none
  .don_page >.don_wrapper >.header
    height 58px
    display flex
    justify-content center
    align-items center
    >.title
      font-size 25px
  select
    border-color transparent
    border-radius 7px
    background #e29c19
    color #fff
    height 32px
    font-size 16px
  button
    border 1px solid #424140
    background #424140
    color #fff
    font-size 16px
    height 32px
    display flex
    justify-content center
    align-items center
    border-radius 5px
    &.delete
      border 1px solid #e25453
      background #e25453
      color #fff
      float right
  .composite_templates_wrapper
    margin 0 80px 0 50px
    >.option
      display flex
      margin-bottom 16px
      flex-direction row-reverse

  .composite_template
    border 1px solid transparent
    display flex
    align-items center
    justify-content space-around
    .is_done
    .user
      //width 50px
    .title
      width 150px
      font-size 17px
      height 16px
      padding 13px 0
      padding-left 13px
    .no
      input
        margin-left 2px
        width 16px
      input::-webkit-inner-spin-button
        -webkit-appearance: none;
        margin: 0;
    >*
      //margin-right 16px
      &:last-child
        margin-right 0
    .enter_wrapper
      display flex
      align-items center
      >*
        margin-right 16px
        &:last-child
          margin-right 0
    .img
      position relative
      width 200px
      height 200px
      img
        max-width 190px
        max-height 190px
        position absolute
        top 0
        left 0
        right 0
        bottom 0
        margin auto
        width auto
        height auto
        background repeating-linear-gradient(45deg, #eee 0, #eee 10px, #fff 10px, #fff 20px), #fff
