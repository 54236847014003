// modules/BpOption/DefaultModal/F/Style/Pc/index.styl
.base_modal__wrapper
  width 100%
  height 100%


.base_modal__container
  position absolute
  left 50%
  top 50%
  background #fff
  z-index 9
  overflow hidden
  border-radius 20px
  box-shadow 0 4px 8px rgba(0, 0, 0, 0.1)
  animation modalOpen 0.3s forwards
  transform-origin center

  &-header
    width 100%
    height 70px
    background #fff
    border-bottom 1px solid #ccc

    padding 0 20px
    box-sizing border-box
    display flex
    flex-direction row
    flex-wrap nowrap
    justify-content space-between
    align-items center

    &__title
      font-size 20px
      font-weight bold

    &__cancel_btn
      cursor pointer
      padding 10px


  &-body
    padding 20px

  &-footer
    position absolute
    bottom 0
    left 0
    display flex
    justify-content flex-end
    background #fff
    border-top 1px solid #ccc
    width 100%
    height 70px
    padding 0 20px
    box-sizing border-box
    align-items center

    &-btn
      padding 10px 20px
      border none
      border-radius 4px
      cursor pointer
      font-size 16px
      margin-left 10px
      transition background-color 0.3s ease

      &:hover
        opacity 0.9

      &.close
        background-color white
        color #454343
        border: 1px solid #454343

      &.ok
        background-color #007bff
        color white

      &.processing
        pointer-events none
        cursor not-allowed
        background-color #ccc

  &-dim
    width 100%
    height 100%
    background-color rgba(0,0,0,0.5)
    position absolute
    left 0
    top 0


@-webkit-keyframes modalOpen {
  from { -webkit-transform: translate(-50%, 100%)  }
  to { -webkit-transform: translate(-50%, -50%) }
}

@keyframes modalOpen {
  from { transform: translate(-50%, 100%) }
  to { transform: translate(-50%, -50%) }
}
