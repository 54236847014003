[frame_name="/modules/NewMaker/BpsTemplateMeta/S/Mui/frame.js"]
  .bps_template_meta_editor
    form > label
      text-decoration underline
    padding 32px
  form
    padding 16px
    > .wrapper
      margin-top 16px
    border 1px solid black
    margin-bottom 16px
    input
      margin-bottom 16px
