#maker_frame
  .maker_menu
    z-index 2
    display flex
    background-color #fff
    html[collabo_type="line"] &
      top 1px
    .done
    .list
    .menu_down_list
      z-index 1
    .text
      font-size 10px
      text-align center
    .list
      display flex
      width 100%
      .text
        display none
    .menu_down_list
      display none
    &[data-down_list_show="true"]
      .menu_down_list
        display flex
    .list > div
    .menu_down_list > div
      span
        display block
        width 18px
        height 18px
        background-repeat no-repeat
        background-size 18px auto
        position absolute
        left 50%
        top 50%
        transform translate(-50%, -50%);
        background-position 50% 50%
    .done
    .list > div
    .menu_down_list > div
      &:active
        span
          background-color #ced4da !important
    .trash
    .forward
    .backward
    .flip_h
    .flip_v
    .ungroup
    .group
    .group_left
    .group_center_h
    .group_right
    .group_top
    .group_center_v
    .group_bottom
    .undo
    .reset
    .redo
    .lock
    .unlock
      opacity 0.2
    .reset span
      background-image url(//s3.marpple.co/files/u_193535/2018/11/original/f_14145_1542787212811_xmAL1O8D4igwnzzy4K0t.svg)
    .undo span
      background-image url(//s3.marpple.co/files/u_193535/2018/11/original/f_14141_1542785966321_lnwh4I8XM4TLCe5q4RaM.svg)
    .redo span
      background-image url(//s3.marpple.co/files/u_193535/2018/11/original/f_14137_1542785966321_Av8T8pAUJenhaTSEn.svg)
    .trash span
      background-image url(//s3.marpple.co/files/u_193535/2018/11/original/f_14140_1542785966321_LwoLs3Z6XDalCRw3wR.svg)
    .copy span
      background-image url(//s3.marpple.co/file/guest/2017/11/original/f_857_1510910850079_SK8CTW5Ozn8dEF7v5ZS6H.svg)
    .paste span
      background-image url(//s3.marpple.co/file/guest/2017/11/original/f_858_1510910984057_tlpwL9TKfzZKw4HlM.svg)
    .duplicate span
      background-image url(//s3.marpple.co/file/guest/2017/11/original/f_859_1510911001772_IKBz4EqtbuLu3AUJQ.svg)
    .cut span
      background-image url(//s3.marpple.co/file/guest/2017/12/original/f_1465_1513843087595_gN1sxRm8RK3Tws9T7rzx.svg)
    .group span
      background-image url(//s3.marpple.co/files/u_193535/2018/11/original/f_14130_1542785966321_H6dp9s7g0yPeIh2r5EmiV.svg)
    .ungroup span
      background-image url(//s3.marpple.co/files/u_193535/2018/11/original/f_14142_1542785966321_TgMCJXc9osod7xlIi.svg)
    .flip_h span
      background-image url(//s3.marpple.co/files/u_193535/2018/11/original/f_14131_1542785966321_mmmQk3F0s0SqC2ASNQk.svg)
    .flip_v span
      background-image url(//s3.marpple.co/files/u_193535/2018/11/original/f_14132_1542785966321_B9PL3RVV0BXJm7sVBN7A.svg)
    .forward span
      background-image url(//s3.marpple.co/files/u_193535/2018/11/original/f_14127_1542785966321_lF3zJNo7LiLUkKhFP.svg)
    .backward span
      background-image url(//s3.marpple.co/files/u_193535/2018/11/original/f_14134_1542785966321_SQ7V4qV1Ne5zQW1aaWXU.svg)
    .center_h span
      background-image url(//s3.marpple.co/files/u_193535/2018/11/original/f_14129_1542785966321_yk4C8s3V5hTHnItx0UZs.svg)
    .center_v span
      background-image url(//s3.marpple.co/files/u_193535/2018/11/original/f_14135_1542785966321_MGkGwg2cHJGWthb3x.svg)
    .group_left span
      background-image url(//s3.marpple.co/files/u_193535/2018/11/original/f_14133_1542785966321_M7MDsU2rZcnMwcf5T3B.svg)
    .group_center_h span
      background-image url(//s3.marpple.co/files/u_193535/2018/11/original/f_14129_1542785966321_yk4C8s3V5hTHnItx0UZs.svg)
    .group_right span
      background-image url(//s3.marpple.co/files/u_193535/2018/11/original/f_14136_1542785966321_PCh4sduh0Tr6uzvFu6V.svg)
    .group_top span
      background-image url(//s3.marpple.co/files/u_193535/2018/11/original/f_14139_1542785966321_CusPzBHtvpfVt9Qd.svg)
    .group_center_v span
      background-image url(//s3.marpple.co/files/u_193535/2018/11/original/f_14135_1542785966321_MGkGwg2cHJGWthb3x.svg)
    .group_bottom span
      background-image url(//s3.marpple.co/files/u_193535/2018/11/original/f_14128_1542785966321_vD5cOvgB9QzTl6x6t0D5x.svg)
    .lock
      html.is_pc_size &
      html#dream_factory &
        display none
      span
        background-image url(//s3.marpple.co/file/guest/2017/12/original/f_1472_1513927807785_qcOeMGVLkd5vB4k7M9L.svg)
    .unlock
      html.is_pc_size &
      html#dream_factory &
        display none
      span
        background-image url(//s3.marpple.co/file/guest/2017/12/original/f_1473_1513927810774_zc1KEKIw6N4Bll0JDbm.svg)
    .unlock i
      display none
      position absolute
      top 3px
      font-style normal
      width 15px
      height 15px
      opacity 0.9
      background-color #0157ff
      border-radius 50%
      text-align center
      color #fff
      font-size 10px
      line-height 14px
    .more_btn span
      background-image url(//s3.marpple.co/files/u_18052/2018/10/original/f_6011_1539261895470_ldcN4kKeJLqN6cG3to.png)
    .pinch_btn
      span
        background-image url(//s3.marpple.co/file/guest/2017/12/original/f_1466_1513843092780_I2w8nwy7SFyx1Izd1IJW.svg)

  @media screen and (min-width: 1025px)
    .maker_menu
      height 66px
      position absolute
      left 0
      right 462px
      .text
        padding-top 29px
      .done
        display none
      .list
        justify-content center
        align-items center
        .pinch_btn
          display none
        span
          padding 6px
      .undo
      .trash
      .forward
      .group
      .lock
      .flip_h
      .group_left
        margin-left 4%
      .list >div
        height 34px
        padding-bottom 5px
        width 18px
        position relative
        cursor pointer
        &.undo
        &.forward
        &.group
        &.flip_h
        &.group_left
        &.group_center_h
        &.group_right
        &.group_top
        &.group_center_v
          margin-right 2%

  @media screen and (max-width: 1024px)
    .maker_menu
      position absolute
      top 0
      left 0
      height 48px
      right 0
      box-shadow 0 3px 4px rgba(230, 230, 230, 0.4)
      .done
        flex-grow 0
        flex-shrink 0
        margin 8px 14% 8px 6px
        margin-right 10%
        width 58px
        height 32px
        border-radius 2px
        text-align center
        box-sizing border-box
        span
          width 26px
          height 32px
          line-height 32px
          font-size 14px
          font-weight 500
          color color_black
      .list
        span
          padding 8px
      .text
        padding-top 26px
        html[collabo_type="line"] &
          padding-top 37px
      .more_btn
        position relative
        &:before
          position absolute
          width 33px
          height 33px
          background-color #ffff80
          top 50%
          left 50%
          transform translate(-50%, -50%)
          border-radius 5px
          content ""
          opacity 0
          transition opacity 0.2s
        &.tip_expression
          &:before
            opacity 1 !important
      .unlock i
        right 6px
      .list
        margin-right 6px
        >div
          &.sm_more_btn
          &.copy
          &.paste
          &.forward
          &.backward
          &.duplicate
          &.center_h
          &.center_v
          &.group_left
          &.group_center_h
          &.group_right
          &.group_top
          &.group_center_v
          &.group_bottom
          &.group
          &.ungroup
          &.undo_btn
          &.trash_btn
          &.forward_btn
          &.group_btn
          &.align_btn
          &.lock_btn
          &.lock
          &.unlock
          &.center_h_btn
          &.flip_h
          &.flip_v
            display none !important
      .list
      .menu_down_list
        >div
          flex-grow 1
          position relative
          cursor pointer
      .menu_down_list
        position absolute
        top 8px
        left 16px
        right 16px
        height 160px
        text-align center
        box-sizing border-box
        border-radius 4px
        background-color rgba(255, 255, 255, 1)
        box-shadow 0 0 10px 0 rgba(33, 37, 41, 0.2)
        flex-wrap wrap
        padding 10px 2% 4px
        >div
          width 15%
          span
            top 40% !important
            padding 13px !important
            background-position 50% 25% !important
            &:after
              position absolute
              top 28px
              width 50px
              left 50%
              transform translateX(-50%)
              text-align center
              font-size 10px
              color color_black

  @media screen and (min-width: 1400px)
    .maker_menu
      .list
        > div
          width auto !important
          span
            top 0 !important
            left 50%
            transform translateX(-50%) !important
      .list
        .undo
          margin-right 24px
        .forward
          margin-right 7px
        .group
          margin-right 15px
        .flip_h
          margin-right 7px
        .group_left
          margin-right 25px
        .group_center_h
          margin-right 21px
        .group_right
          margin-right 29px
        .group_top
          margin-right 25px
        .group_center_v
          margin-right 16px
        .text
          display block !important
        .undo
        .trash
        .forward
        .group
        .lock
        .flip_h
        .group_left
          margin-left 4%
        .reset
          margin-left 2%
        .group_bottom
          margin-right 2%

  @media screen and (min-width: 1580px)
    .maker_menu .list
      .undo
      .trash
      .forward
      .group
      .lock
      .flip_h
      .group_left
        margin-left 6%
      .reset
        margin-left 4%
      .group_bottom
        margin-right 4%

#maker_frame
  html.all_faces_mode &
    .btn_tip
      display none
  &.cv_object_selected
    @media screen and (max-width: 1024px)
      .btn_tip
        display none
  &[redo_is_able="true"]
    .redo
      opacity 1
  &[undo_is_able="true"]
    .undo
    .reset
      opacity 1
