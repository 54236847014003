html#dream_factory
  body.df.logs
    #body
      text-align center
      padding 40px

      form#search
      table[data-name="log_list"]
        width 80%
        margin 0 auto

      form#search
        margin-bottom 60px

        .search-form-item
          display inline-flex
          justify-content space-between
          align-items center

          &:not(:last-child)
            margin-right 60px

          label
            font-size 16px
            font-weight 600
            margin-right 20px

        [type="submit"]
          display block
          margin 32px auto 0

      table[data-name="log_list"]
        border 1px solid #cccccc
        margin-bottom 40px

        th
        td
          padding 6px 8px
          border 1px solid #cccccc
          text-align center

        td
          &[data-name="url_path"]
          &[data-name="ip_address"]
            text-align left

          &[data-name="detail"]
            text-decoration underline
            &:hover a
              font-weight 600
              color #0e3a95

      .df-pagination
        font-size 16px
        & > *
          margin 0 8px
          padding 4px

          &:hover
            color #0e3a95

        .page-number.current
          color #0e3a95

        [data-direction="prev"]
        [data-direction="next"]
          &.disabled
            color: #cccccc


  body.df.log_detail
    #body
      text-align center

      #content
        padding 40px
        display inline-flex
        flex-direction column
        max-width 40%

        .title
          text-align center

        .horizontal
          display flex
          align-items center
          justify-content space-between

          .column-name
            margin-right 40px

        .vertical
          display flex
          flex-direction column
          text-align left