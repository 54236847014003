.don_frame[frame_name="/modules/Df/IssueMemoModal/S/Mui/frame.js"]
  >.don_wrapper
    width 800px
    height 900px
    h1, h2, h3
      margin 0
  .issue-memo-modal
    box-sizing border-box
    position relative
    &__header
      height 100px
      display flex
      align-items center
      justify-content center
      h1
        text-align center
    h2
      padding 0 0 20px
    h3
      padding 0 0 10px
    &__body
      > *
        padding-bottom 20px
      padding 0 40px
      height 700px
      overflow-y scroll
      li
        padding-bottom 16px
    .body-content
      padding-left 8px
    &__footer
      height 100px
      display flex
      justify-content center
      align-items center
      .close
        background white
      .done
        background #000
        color #fff
      button
        height 50px
        border 1px solid #000
        width 120px
        display flex
        justify-content center
        align-items center
        margin-right 20px
        &:last-child
          margin-right 0
