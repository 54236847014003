//<div class="table">
  //<div class="table__wrapper">
    //<div class="table__head">
      //<div class="table__row">
        //<div class="table__th
    //<div class="table__body">
      //<div class="table__row">
        //<div class="table__td
        /*
        table_wrapper
          height, width
        */
.ms-table-st
  .table
    border 1px solid #ddd
    &__wrapper
      position relative
      height calc(100vh - 48rem)
      overflow scroll
      min-height 16.3rem
      background #fff
    &__head
      display flex
      position sticky
      top 0
      background #fff
    &__body
      display flex
    &__row
      display flex
      min-height pxToRem(58px)
    &__th
    &__td
      font-size pxToRem(14px)
      padding 0 4px
      display flex
      justify-content center
      align-items center
    &__head
      .table__row
        border-bottom 1px solid #000
        height pxToRem(58px)
    .table__th
      font-weight 600
    &__body
      .table__row
       border-bottom 1px solid $gray-s1
    .checkbox
    .checkbox_all
      width 57px
