@import "../../../BizFilter/F/Style/Pc/index.styl"
@import "../../../BizSort/F/Style/Pc/index.styl"
@import "../../../BizFilterRelationModal/F/Style/Pc/index.styl"

.mp-wrapper-modal-layout
  padding 40px
.mp-layout-section
  padding 20px
  &.fill-gray
    background-color #f6f6f6
.omp-biz-product
  padding: ptr(40)
  .padding-left
    padding-left ptr(16)
    background-color #f6f6f6
    border-left 1px solid
  hr
    margin-top ptr(10)
    margin-bottom ptr(10)
  &__spacing-40
    padding-top: ptr(40)
  &__title
    display flex
    justify-content flex-start
    align-items center
  &__search-options
    padding 20px
    background-color #f6f6f6
    .search-options-sub-title
      font-width: bold;
      margin-right ptr(8)
    li.search-option
      margin 0 5px
      display inline-block
      label
        display flex
        flex-direction row-reverse
        align-items flex-start
        cursor pointer
        .search-option-filter
          display inline-flex
          flex-direction column
          margin-left ptr(8)
      input[type="checkbox"]
        cursor pointer

  &__relations
    padding 20px
  &__relations-register-form
    display flex
    gap 8px
    align-items center
    label
      flex 1
      display flex
      align-items center
      & > span
        width 100px
      & input
        flex 1
  &__top-btn-area
    padding 20px
  &__list-wrapper
    background-color #f6f6f6
    padding 0 20px 20px
  &__list
    .omp-biz-product__list-body .omp-biz-product__list-item-empty
      height 62px
      position relative
      div
        position absolute
        width calc(100% - 22px)
        height 42px
        display flex
        justify-content center
        align-items center
        background-color #fff

    li
      background-color #fff
    & label.check-label
      cursor: pointer
      display flex
      align-items center
      justify-content center
      width 40px
      height 40px

      & > input[type="checkbox"]
        cursor pointer
  &__list-actions
    display flex
    gap 16px
    padding 16px 0
    & li
      visibility hidden
      &.visible
       visibility visible

  &__checkbox-cell
    vertical-align middle
    width 40px

.omp-biz-product__list-item
  .omp-biz-product__checkbox-cell
    &:target
      scroll-margin-top ptr(120)

  & > div
    vertical-align middle
  .text-center
    text-align center
  .flex-cell
    display flex
    flex-wrap wrap
    gap 8px
    max-width: 600px;
    .chip
      padding 8px
      border-radius 8px
      background-color #f6f6f6

.omp-biz-product__list-cell-original_id
  &>.view-mode
  &>.editor-mode
    display none
    &.view
      display block


