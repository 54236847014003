.maker_test2
  background #00fffa
  .products
    width 100%
    display grid
    grid-template-columns repeat(2, 1fr)
    img
      width 100%
      height auto
    .imgs
      display grid
      grid-template-columns repeat(2, 1fr)
