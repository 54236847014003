.don_page[page_name="projection.list"], .don_page[page_name="projection.detail"], .don_page[page_name="task.list"]
  .shipping_list
    section.shipping_item
      display flex
      flex-direction column
      align-items flex-start
      border-radius 8px
      border 1px solid #CCC
      overflow hidden
      .header
        overflow-x scroll
        scrollbar-width none
        -ms-overflow-style none
        display flex
        height 50px
        width calc(100% - 32px)
        padding 0 16px
        justify-content space-between
        align-items center
        border-bottom 1px solid #CCC
        .domain
          display flex
          align-items center
          gap 6px
          margin-right 16px
          .icon
            background url(//s3.marpple.co/files/u_1187078/2023/12/original/c45c2e1e8ff2d8f45def36a59fa4b4e6fd5c37601.png) center no-repeat
            background-size 24px
            width 24px
            height 24px
          span
            color #111
            font-weight 700
            font-size 16px
            text-wrap nowrap
            &.is_edit
              display none
          button.inhouse_label_print
            display flex
            align-items center
            background-color #fff6e5
            gap 5px
            margin-left 8px
            &:has(div.img.created)
              box-shadow inset 1px 1px 2px #7f7f7f
              background-color #e5fff1
            div.img
              background center / contain no-repeat
              width 1rem
              height 1rem
              &.created
                background-image url('//s3.marpple.co/files/u_1187078/2023/9/original/6422aaabcdd67137481df5d45da4d42a885c41821.png')
              &.null
                background-image url('//s3.marpple.co/files/u_1187078/2023/9/original/93a1b43734fc940b3c4688ce01c123f686d4a9572.png')
        .carrier
          display flex
          align-items center
          gap 20px
      .body
        overflow-x auto
        scrollbar-width thin
        width calc(100% - 32px)
        margin 16px
        display flex
        flex-direction column
        .flex-row
          display flex
          > div.field:first-child
            flex 0 0 260px
            min-width 260px
          > div.field:nth-child(2)
            flex 5
          > div.field:nth-child(3)
            flex 3
            min-width 260px
          &:not(:last-child)
            margin-bottom 8px
          .field
            min-width 420px
            label
              width 80px
            &.receiver_postcode
              position relative
              button.address_search
                display none
                height 33px
                margin-left 12px
                &.open
                  background-color #0B65C8
                  color #ddd
      .footer
        display flex
        align-items center
        justify-content flex-end
        margin 0 16px 16px
        width calc(100% - 32px)
        .buttons
          overflow-x auto
          scrollbar-width thin
          display flex
          gap 20px
          button
            color #4e4e4e
            box-sizing border-box
            border 1px solid #ddd
            width 160px
            height 48px
            cursor pointer
            &:hover
              box-shadow 1px 1px 1px #ccc
            &[name="lgl_manager"]
              color #3C89F9
              border 1px solid #3C89F9
          &.editing
            display none
            button
              width 120px
              &[name="save"]
                background-color #111
                color white
              &[name="cancel"]
                color #4e4e4e
      .field
        display flex
        align-items center
        flex-wrap nowrap
        label
          display flex
          justify-content center
          align-items center
          user-select none
          text-align center
          text-wrap nowrap
          font-weight 700
          font-size 14px
          margin-right 8px
          &.is_combined
            cursor not-allowed
            pointer-events none
            input[type="checkbox"]
              transform scale(1.3)
              margin-left 0.5rem
              opacity 0.5
        select
          width 160px
          pointer-events none
          font-size 14px
          padding 0 12px
          height 33px
          -moz-appearance none
          -webkit-appearance none
          -ms-expand none
          -ms-appearance: none
          appearance none
          background none
          border 1px solid transparent
          background-size 12px 12px
          &:focus
            background-color greenyellow !important
        input
          background-color transparent
          height 33px
          user-select none
          outline none
          cursor default
          border 1px solid transparent
          padding 6px 12px
          font-size 14px
          box-sizing border-box
          border-radius 4px
          &[type="number"]
            -webkit-appearance none
            -moz-appearance textfield
            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button
              -webkit-appearance none
              margin 0
          &:focus
            background-color greenyellow !important
        &.input_short
          input
            width 85px
        &.input_grow
          input
            flex 1
        &.full
          flex 1 !important
          input
            flex 1 !important
      &.editable
        background-color #fff8f8
        .header
          .domain
            .icon
              background url(//s3.marpple.co/files/u_1187078/2023/12/original/0e6273ffc68acb6c1d420584bcd5fe12bdca535e1.png) no-repeat center
              background-size 24px
              width 24px
              height 24px
            span
              color #3C89F9
              &.is_edit
                display inline
        .footer
          .buttons
            &.normal
              display none
            &.editing
              display flex
        .field
          select
            pointer-events auto
            border-radius 4px
            border 1px solid #ddd
            outline none
            cursor pointer
            background url('//s3.marpple.co/files/u_1187078/2023/12/original/57cee59dfa571d84068fb2602fd30436b6423f531.png') no-repeat right 12px center;
            background-size 12px 12px
          input
            cursor text
            background-color white
            border 1px solid #ddd
            border-radius 4px
          label.is_combined
            pointer-events all
            cursor pointer
            input
              cursor pointer
              opacity 1
          &.receiver_postcode
            button.address_search
              display block !important
      .address_search_layer
        transition: height 0.1s ease
        overflow hidden
        height 0
        background-color white
        margin-bottom 0
        &.open
          height 450px
          margin-bottom 8px
      &[is_parcel_type="true"]
        .field
          &.is_combined
            display none
          &.shipping_company_id, &.waybill_number
            display flex
      &[is_parcel_type="false"]
        .field
          &.shipping_company_id, &.waybill_number, &.shipping_company_service_id
            display none

.don_tab[tab_name="projection_detail_info"]
  .shipping_list
    width 70% !important
