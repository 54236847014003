.don_page[page_name="/modules/Df/ImageWorkReview/S/Mui/page.js"]
  &.basic-frame-like-modal-style
    >.don_wrapper
      >.header
        height 60px
        display flex
        justify-content center
        align-items center
        .title
          font-size 20px
    .footer
      height: 80px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      button
        padding 10px 200px
        font-size 16px
        &.cancel
          border 2px solid #000
          background #fff
        &.done
          border 2px solid #0000ff
          background #0000ff
          color #fff
  .image-work-review
    padding 16px
  .special-request
    margin-bottom 32px
    text-align center
    &__label
      font-size 40px
      font-weight bold
      margin-bottom 16px
    &__memo
      padding 10px
      font-size 30px
      font-weight bold
      background #000
      color #ffff00
  .issues
    margin-bottom 32px
    >*
      font-size 16px
    &__label
      text-align center
      font-size 40px
      font-weight bold
      margin-bottom 16px
    &-issue
      font-weight bold
      &__read
        color #ff0000
        &__is_read
          color #0000ff
    table
      margin 0 auto
      thead
        background #ccefff
      td
      th
        border 1px solid #ddd
        padding 8px
        &:nth-child(2)
        &:nth-child(3)
          width 400px
        &:nth-child(4)
          width 200px
          text-align center
        &:nth-child(1)
        &:nth-child(5)
          width 120px
          text-align center
  .image-work
    &__check
      cursor pointer
      text-align center
      input[type="checkbox"]
        cursor pointer
        width 40px
        height 40px
        text-align center
    &__df-work-memo
      font-size 24px
      background #000
      color #ffff00
    &__description
      td
        padding 16px !important
      *
        font-size 24px
      &__row
        margin-bottom 8px
      &__df-work-memo
      &__df-work-name
        padding 4px
        border 2px solid #D000FF
        color #D000FF
      &[data-df_work_id="1"]
        .image-work__description__df-work-name
          border 2px solid #0e2ee2
          color #0e2ee2
      &[data-df_work_id="2"]
        .image-work__description__df-work-name
          border 2px solid #00bb5e
          color #00bb5e
    &__result
      &__face
        position relative
        width 400px
        height 400px
        cursor pointer
      &__quality
        &[data-status="none"]
          color #000
        &[data-status="high"]
          color #0000ff
        &[data-status="mid"]
          color #ffff00
        &[data-status="low"]
          color #ff0000
        text-align center
        font-size 30px
        padding 8px 0
        font-weight bold
        background #000
    img
      width 400px
      height 400px
      object-fit contain
  .image-works
    margin-bottom 16px
    table
      margin 0 auto
    thead
      background #fff04e
    th
      > *
        font-weight bold
    td
    th
      > *
        font-size 16px
      border 1px solid #ddd
      padding 8px
      &:nth-child(1)
        width 70px
