ptr(value)
  unit(value/16, 'rem')

html, body
  margin 0
  padding 0
  font-size 14px
  color color_black
  font-family AppleSDGothicNeo, -apple-system, BlinkMacSystemFont, "Droid Sans", "Roboto", "Segoe UI", "Helvetica", Arial, sans-serif
  font-weight 400
  width 100%
  height 100%
  button
    font-size 14px
    font-weight 400
    font-family AppleSDGothicNeo, -apple-system, BlinkMacSystemFont, "Droid Sans", "Roboto", "Segoe UI", "Helvetica", Arial, sans-serif
    cursor pointer
  html[data-os="Windows"] &
    font-weight 300
  table
    border-spacing 0
    border-collapse collapse
  img:not([src])
    visibility hidden
  a
    &:visited
    &:hover
    &:active
    &:link
      color #222
      text-decoration none
  ul
    padding 0
    margin 0
    list-style none

#dream_factory
  min-width 1100px
  @media screen and (max-width 1024px)
    min-width auto

#dream_factory
  .don_frame[frame_name="df_frame"]
    position relative !important

body.no_scroll
  overflow hidden

@media screen and (max-width 1024px)
  html[mobile_possible="true"]
    #header
      .menu
        white-space nowrap;
        overflow-y hidden
        overflow-x scroll
        li.list .menu2
          position fixed
@media screen and (max-width 1500px)
  #dream_factory #header
    .menu
      > li
        padding 0 6px
        font-size 12px
//입고
html.tpl-admin #header ul.menu li.list.tpl-admin
//태스크
html.task #header ul.menu li.list.task
//쳌포인트
html.checkpoint #header ul.menu li.checkpoint
//운송장
html.projection.waybill #header ul.menu li.list.waybill
//주문서
html.projection.all #header ul.menu li.list.projection.all
html.projection.before_confirm #header ul.menu li.list.projection.all
html.projection.confirmed #header ul.menu li.list.projection.all
html.projection.refund_cancel #header ul.menu li.list.projection.all

html.projection.ing #header ul.menu li.list.projection.ing
html.projection.before_print #header ul.menu li.list.projection.ing
html.projection.printing #header ul.menu li.list.projection.ing
html.projection.before_deliver #header ul.menu li.list.projection.ing

html.projection.delivering #header ul.menu li.list.projection.delivering
html.projection.visit #header ul.menu li.list.projection.visit
//재고
html.stock_list #header ul.menu li.list.stock
html.order_list #header ul.menu li.list.stock
//상품 & 이미지
html.base_product #header ul.menu li.list.base_product
html.bp_cate_list #header ul.menu li.list.base_product
html.cate_list_info #header ul.menu li.list.base_product
html.folder_list_free #header ul.menu li.list.base_product
html.folder_list_free #header ul.menu li.list.base_product
html.folder_list_embro #header ul.menu li.list.base_product
html.folder_list_shape #header ul.menu li.list.base_product
html.folder_detail #header ul.menu li.list.base_product
html.folder2_list #header ul.menu li.list.base_product
html.folder2_detail #header ul.menu li.list.base_product
html.bp_stock #header ul.menu li.list.base_product
//html.ec_base_product_category.category #header ul.menu li.list.base_product
//html.product.list #header ul.menu li.list.base_product
//html.samsung_base_product.list #header ul.menu li.list.base_product

//사이트
html.help_groups_buying_shipping #header ul.menu li.list.site
html.help_groups_order_pay_kr #header ul.menu li.list.site
html.event #header ul.menu li.list.site
html.promotion:not(.line) #header ul.menu li.list.site
html.banner_items:not(.line) #header ul.menu li.list.site

//기타
html.user.list:not(.worker) #header ul.menu li.list.other
html.kakao_surem_send #header ul.menu li.list.other
html.kakao_surem_logs #header ul.menu li.list.other
html.sign_up_count #header ul.menu li.list.other
//프로모션
html.promotion #header ul.menu li.promotion
//이벤트
html.event #header ul.menu li.event
//결산
html.settlement:not(.sign_up_count):not(.line_settlement) #header ul.menu li.list.settlement

html.settlement_youtube #header ul.menu li.list.settlement_youtube

html.iamport_list #header ul.menu li.list.settlement
html.sold_products #header ul.menu li.list.settlement

//리뷰
html.df_review_list #header ul.menu li.list.review
// we
html.we-group #header ul.menu li.list.we_group

// 직원관리
html.simple_group.datas #header ul.menu li.list.worker_admin
html.worker #header ul.menu li.list.worker_admin
html.df-admin #header ul.menu li.list.worker_admin

//개발팀
html.dev_image #header ul.menu li.list.dev
html.simple_group:not(.datas) #header ul.menu li.list.dev
html.font_manager #header ul.menu li.list.dev
//테크팩
html.we-tech-pack #header ul.menu li.list.we-tech-pack

//라프주문서
html.projection.line #header ul.menu li.list.projection.line
//라프결산
html.line_settlement #header ul.menu li.list.line_settlement
//라프 관리
html.folder_list_bnf #header ul.menu li.list.folder_list_bnf
html.folder_list_bt21 #header ul.menu li.list.folder_list_bnf
html.banner_items.line #header ul.menu li.list.folder_list_bnf
html.help_groups_lf_order_pay_kr #header ul.menu li.list.folder_list_bnf
html.help_groups_lf_order_pay #header ul.menu li.list.folder_list_bnf
html.promotion.line #header ul.menu li.list.folder_list_bnf
  // 선택
  background #000
  > a
    color #fff

#header
  z-index 2
  filter drop-shadow(0 1px 1px gray)
  position fixed
  top 0
  left 0
  right 0
  height 50px
  &:before
    content ''
    display block
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    background #eee
  &:hover
    z-index 9999
  .logo
    position absolute
    top 8px
    left 10px
    margin 0
    padding 0
    font-size 16px
    &:hover
      text-decoration underline
  ul
    list-style none
    margin 0
    padding 0
    font-weight normal
  .menu
    display flex
    justify-content flex-start
    position absolute
    top 0
    left 130px
    right 0
    bottom 0
    li.list
      display flex
      align-items center
      position relative
      vertical-align top
      padding 0 10px
      font-size 14px
      font-weight bold
      box-sizing border-box
      a
        color #333
      ul
        li
          line-height 44px
          a
            color #333
      .menu2
        display none
        position absolute
        width 160px
        top 50px
        left 0
        background #dfdfdf
        &.wide
          width 200px
        >li
          a
            display block
        .list2
          padding 0 10px
        .menu3
          margin-bottom 20px
          padding 0 10px
        .list3
          padding 0
      &:hover
        background #dfdfdf
        a
          text-decoration underline
          color color_black
        li
          a
            text-decoration none
            color #333
          &:hover
            a
              text-decoration underline
              color color_black
            li
              a
                text-decoration none
                color #333
              &:hover
                a
                  text-decoration underline
                  color color_black
        .menu2
          display block
  .user_menu
    position absolute
    bottom 6px
    left 10px
    font-size 12px
    *
      display inline-block
      vertical-align top
    .login_user
      span
        max-width 54px
        overflow hidden
        text-overflow ellipsis
        white-space nowrap
    a:hover
      text-decoration underline

#body
  position relative
  #error
    padding 100px
  #df_login_form
    form
      padding 100px

#not_found_results
  display none
  text-align center
  &:before
    content ''
    width 80px
    height 80px
    display block
    margin 0 auto 24px
    background-image url(//s3.marpple.co/files/u_29089/2018/11/original/f_12439_1541417929892_ee1z2Lp6ee7s1SzW4E9i3G4D.png)
    background-size 80px 80px
  @media screen and (min-width 1025px)
    margin 216px auto
    font-size 16px
html#dream_factory
  body
    .don_frame
      z-index 3
    .don_frame[frame_index="0"]
      z-index 1
      margin-top 50px
html#dream_factory:not([don_frame_type="mobile"])
  body.fixed
    position fixed !important
    overflow-y scroll
    top 0
    left 0
    right 0
    bottom 0
html#dream_factory[don_frame_type="mobile"]
  body
    .don_frame
      background #fff
      .don_wrapper
        background #fff

html[don_frame_type="mobile"]
  don_frame_mobile()
@media (min-width: 1025px)
  .mp_table
    display table
    width 100%
    > ul.mp_tbody
      >li[is_thumb_samsung="true"]
        >div:not(.id)
          background lightblue
      >li[is_public="false"]
        >div:not(.id)
          background #ffc0cb
          opacity 0.5
    > ol.mp_tbody
      list-style none
      margin 0
      padding 0
    > *
      display table-row-group
      &.mp_thead
        > * /* tr */
          > * /* td */
            font-weight bold
            text-align center
      &.mp_tbody
        > *:last-child /* tr */
          > *
            border-bottom-width 1px
      > * /* tr */
        display table-row
        > * /* td */
          display table-cell
          border 1px solid #ccc
          border-width 1px 1px 0px 0
          padding 10px
          &:first-child
            border-left-width 1px

.mp_tbody
  > li >.id
    color #fff
  >li
    >.id[seperate_id="1"]
      background-color red
    >.id[seperate_id="2"]
      background-color orange
    >.id[seperate_id="3"]
      background-color yellow
    >.id[seperate_id="4"]
      background-color green
    >.id[seperate_id="5"]
      background-color blue
    >.id[seperate_id="6"]
      background-color navy
    >.id[seperate_id="7"]
      background-color purple
    >.id[seperate_id="8"]
      background-color red
    >.id[seperate_id="9"]
      background-color orange
    >.id[seperate_id="10"]
      background-color yellow
    >.id[seperate_id="11"]
      background-color green
    >.id[seperate_id="12"]
      background-color blue
    >.id[seperate_id="13"]
      background-color navy
    >.id[seperate_id="14"]
      background-color purple
    >.id[seperate_id="15"]
      background-color red
    >.id[seperate_id="16"]
      background-color orange
    >.id[seperate_id="17"]
      background-color yellow
    >.id[seperate_id="18"]
      background-color green
    >.id[seperate_id="19"]
      background-color blue
    >.id[seperate_id="20"]
      background-color navy
    >.id[seperate_id="21"]
      background-color purple
.kakao_surem
  .kakao_surem_area
    position fixed
    top 0
    left 0
    right 0
    bottom 0
    .buttons
      position absolute
      top 0
      left 0
      right 0
      bottom 0
      margin auto
      width 50%
      height 30px
      text-align center

.don_frame[is_modal="true"]
  >.don_wrapper
    position fixed !important


#dream_factory
  table.df_table
    width 100%
    text-align center
    border-collapse collapse
    tr
      &:first-child
        td
        th
          background-color #bbb !important
      &:hover
        td
        th
          background-color #eee
    th
    td
      padding 5px 10px
      border 1px solid #ccc
html[no_price_view_black_policy="true"]
  .mp_currency
  .price
    display none !important
[lang="kr"] .mp_currency:after
  content '원'
[lang="kr"] .mp_currency:before
  content ''
.mp_currency_kr:after
  content '원'
[lang="en"] .mp_currency:before
  content '$'
[lang="en"] .mp_currency:after
  content ''
.mp_currency_en:before
  content '$'
[lang="jp"] .mp_currency:before
  content '¥'
[lang="jp"] .mp_currency:after
  content ''
.mp_currency_jp:before
  content '¥'

[lang="kr"] .mp_currency_minus:after
  content '원'
[lang="kr"] .mp_currency_minus:before
  content '- '
[lang="en"] .mp_currency_minus:before
  content '- $'
[lang="en"] .mp_currency_minus:after
  content ''
[lang="jp"] .mp_currency_minus:before
  content '- ¥'
[lang="jp"] .mp_currency_minus:after
  content ''

.flex-center
  display flex
  justify-content center
  align-items center

#bks_list
.don_tab[tab_name="products"]
.projection_sidebar
.up_item
.orderer
.shipping_list
.print_file_manager
  .btn
  a.button
  button
    outline none
    background-color: #fff;
    border-color: #dbdbdb;
    border-style solid
    border-width: 1px;
    color color_black
    cursor: pointer;
    justify-content: center;
    padding-bottom: calc(.375em - 1px);
    padding-left: .75em;
    padding-right: .75em;
    padding-top: calc(.375em - 1px);
    text-align: center;
    white-space: nowrap;
    border-radius 4px
    &:hover
      border-color: #b5b5b5;
      color: #363636;
    &:active
      border-color: #333;
      box-shadow none
      transform translateY(1px)

.projection_item
  button
    font-size 12px

#df_login_form
  form > div
    margin 0 auto
    width 400px
    padding 50px
    border 2px solid #666
    border-radius 8px
    background-color #fff
    h1
      margin 0 0 20px
      padding 0 0 8px
      border-bottom 1px solid #999
    > div
      margin-bottom 10px
    input
      width 100%
      padding 12px
      font-size 14px
      border-radius 4px
      background-color #fff
      border 1px solid #999
      box-sizing border-box
    .btn
      button
        width 100%
        padding 15px
        border-radius 4px
        color #fff
        font-size 14px
        font-weight bold
        background-color #000
        border 1px solid #000
        box-sizing border-box

.df_verify_login
  .form
    margin 100px auto
    width 300px
    padding 50px
    border 2px solid #666
    border-radius 8px
    background-color #fff
    h1
      margin 0 0 20px
      padding 0 0 8px
      border-bottom 1px solid #999
    > div
      margin-bottom 10px
    input
      width 100%
      padding 12px
      font-size 14px
      border-radius 4px
      background-color #fff
      border 1px solid #999
      box-sizing border-box
    .btn
      button
        width 100%
        padding 15px
        border-radius 4px
        color #fff
        font-size 14px
        font-weight bold
        background-color #000
        border 1px solid #000
        box-sizing border-box


#dream_factory .image_upload_desc
#dream_factory .loc_opt_desc
  display none !important

#dream_factory
  #marpplizer
    .delivery-price
      display none
  .filtering
    padding 30px
    .df_cate_lists
      margin 30px 0
      a
        margin-right 8px
        text-decoration underline
  .filtering-base-product
    margin-bottom 40px
    display flex
    align-items center
    position relative
    &__flex-group
      flex-shrink 0
      width 150px
      margin-right 8px
      height 187.5px
      position relative
    &__images
      width 100%
      img
        width 100%
      &--9:hover
        .filtering-base-product__image1
          display none
        .filtering-base-product__image2
          display block
    &__image2
      display none
    &__name
      width 100%
      word-break break-all
      text-decoration underline
    &__filter
      display flex
      align-items center
      margin-bottom 8px
    &__f-name
      margin 0 8px 0 0
      width 82px
      flex-shrink 0
    &__f-items
      display flex
      align-items center
      flex-wrap wrap
    &__f-item
      margin-right 4px
      display flex
      align-items center
    &__color
      width 14px
      height 14px
      border-radius 100%
      display inline-block
      border 1px solid #000


.dayContainer
  >:not(.prevMonthDay):not(.nextMonthDay)
    &.flatpickr-day:nth-child(7n)
      color blue
    &.flatpickr-day:nth-child(7n-6)
      color red

.df-login
  &__label
    display block
    margin-bottom 4px
  &__control
    margin-bottom 6px
  &__body
    &[data-status="base"]
      .df-login__base
        display block
    &[data-status="cert"]
      .df-login__cert
        display block
    &[data-status="error"]
      .df-login__error
        display block


  &__otp-timer
    margin-bottom 10px
    text-align right
  &__base
  &__cert
  &__error
    display none
    margin-bottom 10px

  &__error-txt1
    margin 0 0 10px
    padding 0
    color red

@import "../../../modules/GlobalPortOne/RefundBank/F/Style/Pc/index.styl"
