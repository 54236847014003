// modules/Df/OMP/CustomBadge/RelationBP/F/Style/Pc/index.styl
.custom-badges-relation-pop
  & > .don_wrapper
    width auto
    height auto
    max-width 800px
    max-height 650px
    border-radius 8px
    overflow hidden
    position relative
  .custom-badges__relation-bp-container
    box-sizing border-box
    height 650px
    padding 1.5rem
    .btn-area
      height 40px
      display flex
      justify-content space-between
    .text-center
      text-align center
    .align-middle
      vertical-align middle
    .checkbox-label
      display flex
      justify-content center
      align-items center
      width 40px
      height 40px
      cursor pointer
    tbody
    tfoot
    thead
    tr
    th
    td
      border-color: inherit;
      border-style: solid;
      border-width: 0;
    tr
      & th:first-child
      & td:first-child
        width 40px

    & > *
      box-sizing border-box
    .table
      --bs-table-bg #FFF
      --bs-border-width 1px
      --bs-border-color #dee2e6
      --bs-emphasis-color #000
      //--bs-emphasis-color-rgb  0,0,0
      --bs-table-color-type initial
      --bs-table-bg-type initial
      --bs-table-color-state initial
      --bs-table-bg-state initial
      --bs-table-color var(--bs-emphasis-color)
      //--bs-table-bg var(--bs-body-bg)
      --bs-table-border-color var(--bs-border-color)
      --bs-table-accent-bg transparent
      //--bs-table-striped-color var(--bs-emphasis-color)
      //--bs-table-striped-bg rgba(var(--bs-emphasis-color-rgb), 0.05)
      //--bs-table-active-color var(--bs-emphasis-color)
      //--bs-table-active-bg rgba(var(--bs-emphasis-color-rgb), 0.1)
      //--bs-table-hover-color var(--bs-emphasis-color)
      //--bs-table-hover-bg rgba(var(--bs-emphasis-color-rgb), 0.075)
      width 100%
      margin-bottom 1rem
      vertical-align top
      border-color var(--bs-table-border-color)
      & > :not(caption) > * > *
        padding 0.5rem 0.5rem
        color var(--bs-table-color-state,var(--bs-table-color-type,var(--bs-table-color)))
        background-color var(--bs-table-bg)
        border-bottom-width var(--bs-border-width)
        box-shadow inset 0 0 0 9999px var(--bs-table-bg-state,var(--bs-table-bg-type,var(--bs-table-accent-bg)))
    .table-light
      --bs-table-color #000
      --bs-table-bg #f8f9fa
      --bs-table-border-color #c6c7c8
      --bs-table-striped-bg #ecedee
      --bs-table-striped-color #000
      --bs-table-active-bg #dfe0e1
      --bs-table-active-color #000
      --bs-table-hover-bg #e5e6e7
      --bs-table-hover-color #000
      color var(--bs-table-color)
      border-color var(--bs-table-border-color)
    .table-bordered>:not(caption)>*
      --bs-border-width 1px
      border-width var(--bs-border-width) 0
      & > *
        border-width 0 var(--bs-border-width)
    .btn
      display inline-block
      padding 0.375rem 0.75rem
      font-size 1rem
      font-weight 400
      line-height 1.5
      border 1px solid #0d6efd
      border-radius .375rem
      background-color #0d6efd
      color #fff
      &:hover
        background-color #0b5ed7
        border-color #0b5ed7

