body.df-plus-apply-list
  .df_apply_list
    .filter_search
      margin 0 20px
      padding 24px 0
      width auto
      z-index 0
      form#search_bar
        input
        select
          padding 4px 8px
          border-radius 4px
          border 1px solid #d5dbe0
          font-size 14px
        h3
          margin 0
        .search_result
          button
            width 200px
            height 32px
            background-color #fff
            border 1px solid #d5dbe0
            font-size 16px
            font-weight 500
            &.go
              border-color #000
              background-color #000
              color #fff
        .sd
          padding 4px 10px 4px 0
          background-color #f6f6f6f6
          margin-bottom 8px
          line-height 27px
          >.s_col.title:first-child
            min-width 94px
            margin 0 16px
          .s_col
            display inline-block
            vertical-align top
            margin-right 8px
            &.m
              margin-right 16px
            &.title
              font-weight bold
              margin 0 16px 0 32px
              font-size 15px
            label
              display flex
              align-items center
            input[type="text"]
            input[type="number"]
              width 100px
              &[name="user_info"]
                width 150px
            input[type="checkbox"]
              width 16px
              height 16px
            input.due_at
              width 150px
        .search
          input
            width 300px !important

    >.header
      padding 24px
    >.body
      padding 24px
      margin-bottom 50px
      table.list
        width 100%
        margin-bottom 20px
        tbody tr
          cursor pointer
          &:hover
            background-color #eee
          &.pass
            .status
              color #fff
              background-color #1b77ff
          &.fail
            .status
              color #fff
              background-color #666
          &.complete
            .status
              color #fff
              background-color #00d664
          &[data-is_mail_fail="true"]
            .company_name
              color #fff
              background-color #e06e2d
        th
        td
          position relative
          padding 10px
          height 20px
          border 1px solid #f0f0f0
          &.id
            widht 30px
            text-align center
          &.status
          &.type
            max-widht 60px
            text-align center
          &.mobile
            text-align center
          &.company_name
            max-width 150px
          &.company_name
          &.name
          &.file
          &.created_at
            text-align center
          &.email
            max-width 200px
            overflow hidden
            text-overflow ellipsis
            white-space nowrap
          &.description
          &.memo
            max-width 230px
            cursor pointer
            text-decoration underline
          &.description
            max-width 300px
          &.description
          &.memo
            overflow hidden
            text-overflow ellipsis
            white-space nowrap

  #mp_shop_plus_apply_detail
    padding 30px
    >div
      margin-bottom 16px
      .title
      .content
        display inline-block
        vertical-align top
        margin-right 12px
        font-size 16px
        > a
          display inline-block
          margin-bottom 4px
        input
          display block
          width 480px
          font-size 14px
      .title
        width 140px
      .content
        width 500px
        padding 5px 10px
        word-break keep-all
      &.email[is_mail_fail="true"]
        .content
          color #fff
          background-color #e06e2d
      &.files
        a
          color #1b77ff
      &.patch
        button
          margin 0 5px
          width 200px
          height 32px
          font-size 16px
          font-weight 500
          background-color #000
          color #fff
          border 0 none
          &.btn_fail
            background-color #e06e2d
      &.type
        select
          font-size 14px
      &.memo
        textarea
          font-size 14px
          resize none
          width 500px
          height 100px











