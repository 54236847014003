// modules/Df/ConfirmPopup/F/Style/Pc/index.styl
.don_tab[tab_name="/modules/Df/ConfirmPopup/S/Mui/tab.js"]
  .no
    cursor: pointer;
    text-decoration underline
  tr.show
    .ConfirmPopupListPage_card_container
      height initial !important
      overflow initial !important
.ConfirmPopupListPage
  margin 0 auto
  padding 0 20px
  &_action_btn
    display flex
    gap 16px

  &_detail_view_btn
    border 1px solid #007bff
    color #007bff
    padding 5px 10px
    font-size 16px
    cursor pointer
    border-radius 4px

  &_delete_btn
    border 1px solid #e06e2d
    color #e06e2d
    padding 5px 10px
    font-size 16px
    cursor pointer
    border-radius 4px

  &_register_button
    background-color #007bff
    color #fff
    border none
    padding 10px 20px
    font-size 16px
    cursor pointer
    border-radius 4px

    &:hover
      background-color #0056b3

  &_card_container
    display flex
    flex-wrap wrap
    justify-content: flex-start;
    gap 10px
    height 300px
    overflow hidden

  &_card
    width 150px
    box-shadow 0 4px 8px rgba(0, 0, 0, 0.1)
    border-radius 8px

    background-color #fff
    text-align center
    position relative

    &_delete_btn
      position absolute
      padding 2px 5px
      border-radius 5px
      right -10px
      top 0
      color white
      background-color #f35d5d
      cursor pointer

    & img
      width 100%
      height auto
      display block

  &_card_info
    padding 10px

  & p
    margin 5px 0
    font-size 12px


  &_card_add
    cursor pointer
    min-height 130px
  &_card_plus
    position absolute
    top 50%
    left 50%
    transform translate(-50%, -50%)
    font-size 50px


.AddConfirmPopupModal, .UpdateConfirmPopupModal
  &__container
    display flex
    flex-direction column

    &-body
      overflow-y auto

    &-footer
      z-index 2
      position relative;
      padding 20px

  & table
    width 100%

  & table, td, th
    border 1px solid #ccc
    border-collapse collapse

  & th, td
    padding 5px
    text-align left

  & th
    background-color #f2f2f2

  &_form_input
    height 40px
    border none
    padding 5px
    width 100%
    box-sizing border-box
    &:disabled
      color #ccc


  &_form_image-upload
    position relative
    overflow hidden
    display flex
    flex-direction column
    align-items center
    justify-content center
    width 100%
    height 150px
    background-color #fff
    border-radius 10px
    border 2px dashed #ccc
    text-align center
    cursor pointer

    & input[type="file"]
      position absolute
      left 0
      top 0
      opacity 0
      cursor pointer
      width 100%
      height 100%
      z-index 9

    & img
      height 100%
      z-index 2

    &-text
      color #888
      font-size 16px
      position absolute



.d-none
    display none
