.marpplizer_wrapper
  .biz-option
    display none
  .dosu-colors
    margin-top 8px
    .color-pickers
      display flex
      flex-wrap wrap
      gap 13.5px
      .color-picker
        display flex
        justify-content center
        cursor pointer
        border-radius 100%
        span
          box-sizing border-box
          width 24px
          height 24px
          border-radius 100%
          margin 1px
          &.too_bright
            border 1px solid #CED4DA
        &.original_tag
          span
            background url(//s3.marpple.co/files/u_1187078/2023/10/original/4b0a81febbcf4ebb28d05033ce040cb71b9e32e71.png) center / 27px no-repeat
            border none
            border-radius 0
          &.selected
            box-shadow unset
        &.selected
          box-shadow: 0 0 0 1px #ff6b00
          border none
        &.used
          &::before
            content ""
            background-color #555
            width 4px
            height 4px
            border-radius 100%
            margin-top -8px
    html[marpplizer_mode="short"] &
      .color-pickers
        gap 9.5px
      .color-pickers
        .color-picker
          span
            width 22px
            height 22px
