div.bp_cate_list_filter
  .cate_lists
  .filter_lists
    margin-left 20px
    .filter_list
      margin-bottom 20px
      .add_type_category
        display inline-block
        position relative
        margin-left 10px
html.bp_cate_list
  #bp_cate_list
    padding 50px
    h2
    h3
    h4
    h5
    form
      display inline-block
      margin 0
    form
    form1
      margin-left 5px
      input
        margin-right 5px
    h3
      span
        margin-left 16px
    input[type="text"]
      width 84px
    .cate_lists
    .bp_filter_group
    .filter_lists
      margin-bottom 50px
      .add_cate_list
      .add_filter_list
        margin 10px 0 20px

      input[type="number"]
        width 40px
        &[name="volume"]
        &[name="weight"]
          width 60px
      .mp-thumbnail-url
        width 24px
        height 22px
        margin 0 8px
        padding 0
        object-fit contain
        img
          width 18px
          height 18px
      ul
        display inline-block
        li
          display inline-block
          vertical-align top
          margin 0 30px 30px 0
          .info
            display flex
            align-items center
          span.title
            margin-right 5px
          label.delete
            float right
            margin-right 0 !important
          & li.vertical
            display block
            margin 10px 0
            .name2
              input
                width 50px
            &:not([type="bp_color_filters"])
              .name2
                display none
          .cate_items
          .filter_items
          .bp_filter_items
            margin-left 20px
          label
            margin-right 5px
