.df-outsource-companies-list
  margin 100px
  padding-bottom 100px


  &__features
    text-align right


  &__chart_container
    width 100%
    margin auto
    margin-top 30px

.df-outsource-companies-detail
  margin 100px
  padding-bottom 100px

  &__form
    width 40%
    margin auto

  &__form_item
    display flex
    font-size 20px
    margin-top 20px
    align-items flex-start
    p
      font-size 12px
      margin 2px 0 0 0

  &__title
    width 100px
    height 30px
    box-sizing border-box

  &__input[type="text"]
    flex-grow 1
    height 30px
    margin 0
    padding 0
    box-sizing border-box

  &__submit
    background-color #4cc37f
    color white
    margin 40px auto 0
    text-align center
    display block
    width 10%
    padding 8px 5px
    cursor pointer
