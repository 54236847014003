// modules/DF/Lgl/ShippingDetail/F/Style/Pc/index.styl
$bg-color = #F2F2F2
$border-color = #CCCCCC
$primary-color = #0E2EE2
$gray-100 = #F8F8F8
$gray-200 = #F2F2F2
$gray-300 = #DDDDDD
$gray-400 = #CCCCCC
$gray-500 = #A2A2A2
$gray-600 = #828282
$gray-700 = #4E4E4E
$black = #111111
$white = #FFFFFF
$sky = #3C89F9
$sky-100 = #F5F9FE
$sky-200 = #EBF3FE
$sky-400 = #B1D0FD
$red-400 = #F7A0AD
$red-600 = #F2687C
$red-700 = #F0415A
$ms-blue = #1F5EFF
$yellow-400 = #FFEF9A
$orange-400 = #FFC799
$purple-300 = #D5C2F8

body.swal2-shown:has(.df_lgl_shipping_detail)
  overflow-y auto !important
.lgl_alerts
  width 100% !important
  padding 0 24px !important
  > button
    border-radius 4px !important
    padding 12px !important
    flex 1 1 0px
  .confirm_button_danger
    order 2
    background-color $red-700 !important
  .confirm_button
    order 2
  .cancel_button
    color $black !important
    border 1px solid $black !important
.don_frame[frame_name="/modules/Df/Lgl/ShippingDetail/S/Mui/frame.js"]
  background transparent !important
  > *
    box-sizing boder-box
  > .don_wrapper
    background $bg-color !important
    border-radius 0 0 8px 8px
  > .don_wrapper > .header
    background-color #111111
    display flex
    align-items center
    justify-content space-between
    border-radius 8px 8px 0 0
    > .title
      display flex
      gap 16px
      color #ffffff
      padding-left 24px
    > button.don_hide_frame
      position static
      filter invert(1)
      margin-right 24px
  &.df_lgl_shipping_detail
    >.don_wrapper
      width 1440px
      > .body
        overflow unset !important
    .don_page
      background-color $bg-color
      border-radius 0 0 8px 8px
      > .don_wrapper
        display flex
        flex-direction column
        border-radius 0 0 8px 8px
        > .header
          background-color $bg-color
          padding 32px 40px 0 40px
      .tab_buttons
        margin-top 30px
        button
          width 160px
          background-color $gray-300
          padding 12px
          margin-right 6px
          color $black
          font-weight 700
          border-radius 8px 8px 0 0
          box-shadow 0 0 20px 0 #0000000D
          &.disabled
            color $gray-500
            background-color #E5E5E5
            cursor not-allowed
            pointer-events none
            font-weight 400
          &[selected="true"]
            background-color $white
          &.error
            > span
              position relative
              &:after
                display inline-block
                content " "
                width 8px
                height 8px
                border-radius 50%;
                background-color $red-700
                position absolute
                top -4px
                right -10px
      .steps
        display flex
        border-left 1px solid $border-color
        border-right 1px solid $border-color
        border-radius 8px
        font-weight 500
        &:has(> .step:first-child:is(.active))
          border-color $primary-color
          border-right 1px solid $border-color
        &:has(> .step:last-child:is(.active))
          border-color $primary-color
        .step
          position relative
          display flex
          justify-content center
          flex 1 0 auto
          padding 18px 10.44px
          background-color #ffffff
          border 1px solid $border-color
          border-left none
          &:not(.active)
            color $gray-500
          &.active
            border-color $primary-color
            color $primary-color
            &:after
              border-color $primary-color
          &.error
            border-color $red-600
            color $red-600
            &:after
              border-color $red-600
          &:after
            display block
            position absolute
            z-index 2
            content ''
            top 50%
            right 0
            background-color #FFFFFF
            width 8px
            height 8px
            border-style solid
            border-color $border-color
            border-width 0 1px 1px 0
            transform translateY(-50%) translateX(60%) rotate(-45deg)
          &:first-child
            border-radius 8px 0 0 8px
          &:last-child
            border-right none
            border-radius 0 8px 8px 0
          &:last-child:after {
            display: none
          }
    .don_tab
      border-radius 0 0 8px 8px
    .don_tab > .don_wrapper
      background-color $bg-color !important
      border-radius 0 0 8px 8px
    .tab_wrapper
      display flex
      flex-direction column
      .toggle_container
        cursor pointer
        position relative
        display inline-block
        width 186px
        height 56px
        pointer-events none
        user-select none
        > input
          cursor pointer
          opacity 0
          width 100%
          height 100%
          pointer-events all
        > input:checked + .slider
          background-color $sky
        > input:checked + .slider:before
          transform translate(125px, -50%)
        > input:checked + .slider:after
          content '자동 출고 가능'
          left 20px
        > input:active + .slider:before
          width 50px
        .slider
          position absolute
          top 0
          left 0
          right 0
          bottom 0
          background-color $red-700
          transition 0.4s
          pointer-events none
          &:before
            position absolute
            content ''
            height 45px
            width 45px
            left 8px
            top 50%
            transform translateY(-50%)
            background-color $white
            transition 0.5s
            pointer-events none
          &:after
            position absolute
            content '자동 출고 중지'
            right 20px
            top 50%
            transform translateY(-50%)
            transition 0.2s
            color $white
            pointer-events none
            font-weight 600
            font-size 18px
        .slider.round
          border-radius 34px
        .slider.round:before
          border-radius 50%

      .outbound_fail_title
        font-weight 600
        font-size 20px
        color $gray-600
        margin-top 16px
        margin-bottom 10px
      .outbound_fail_description
        color $gray-600
        text-align center
      .mr-1
        margin-right 2px
      .mr-2
        margin-right 4px
      .column
        flex-direction column
      .h-100
        height 100%
      .h-fit
        height fit-content
      .text_center
        text-align center
      > .header
        display flex
        justify-content space-between
        background-color #fff
        margin 0 40px
        padding-top 24px
        padding-left 24px
        .title
          color $black
          font-weight 600
          font-size 20px
          line-height 30px
          margin 0
        button.primary
          background-color $sky
          border none
          color $white
          border-radius 4px
          padding 13.5px 28px
          margin-right 38px
          margin-top 24px
      .body
        margin 0 40px
        background-color $white
        display flex
        flex-wrap nowrap
        overflow-x auto
        flex 1 0 auto
        position relative
        border-radius 0 8px 8px 8px
        .backdrop
          height 100%
          width 100%
          position absolute
          top 0
          left 0
          background rgba(17, 17, 17, 0.25)
          border-radius 0 8px 8px 8px
          z-index 1
        .backdrop + .table_wrapper
          .badge
            background-color $gray-600 !important
        .table_wrapper
          display flex
          width 100%
          padding 0 24px 24px 24px
          margin-top 24px
          .header
            margin-bottom 10px
            height 30px
            .title
              color $black
              font-weight 600
              font-size 20px
              line-height 30px
              margin 0
          table
            width 100%
            border-radius 1em
            overflow hidden
            border-collapse collapse
            height 450px
            box-shadow 0 0 0 1px $gray-500
            table-layout fixed
            flex-grow 1
            .tbody
              overscroll-behavior contain
            &:has(.no_data)
              display flex
              flex-direction column
              height 100%
              .tbody
                display flex
                align-items center
                justify-content center
                tr.no_data
                  display flex
                  align-items center
                  justify-content center
                  height 450px
            input[type="checkbox"]
              accent-color #3C89F9
            thead
              display block
              background-color $gray-200
              color $gray-700
              tr
                width 100%
              th
                padding 14.5px
                box-sizing border-box
              th:has(input)
                width 60px
                text-align center
            tbody
              display block
              width 100%
              overflow-y auto
              height 450px
              &:has(.no_data)
                height 100%
              tr
                width 100%
                &.no_data
                  display table
                button
                  background none
                  border none
                  cursor default
                  padding 0
                  pointer-events none
                .input_number_wrapper
                  input[type="number"]
                    color $gray-500
                    pointer-events none
                &.active
                  background-color $sky-100
                  .input_number_wrapper
                    input[type="number"]
                      color $primary-color
                      pointer-events auto
                    button
                      cursor pointer
                      pointer-events auto
                    svg
                      rect
                        fill $gray-700
                      path
                        fill $gray-700
                &.no_data
                  height 100%
                  color $gray-400
                  font-size 20px
              .table_wrapper:not(.return)
                tr:has(.return_detail)
                  background-color $gray-100
              tr:not(:last-child)
                border-bottom 1px solid $gray-200
              td
                padding 14.5px
                box-sizing border-box
                > .product_info
                  display flex
                  align-items center
                  gap 12px
                  > .thumb
                    width 60px
                    height 60px
                    background-color $gray-200
                    border-radius 4px
                  > .description
                    > .options
                      color $gray-600
                &:has(> .input_number_wrapper)
                  padding-left 0
                  padding-right 0
                .input_number_wrapper
                  display flex
                  align-items center
                  > input[type="number"]
                    width 40px
                    height 32px
                    text-align center
                    justify-content center
                    border-radius 4px
                    border 1px solid $gray-400
                  > input[type="number"]::-webkit-inner-spin-button
                  > input[type="number"]::-webkit-outer-spin-button
                    -webkit-appearance none
                    margin 0
                button.line
                  position relative
                  font-weight 600
                  cursor pointer
                  pointer-events auto
                  transition 0.35s
                  padding-bottom 4px
                  &:before,
                  &:after
                    content ''
                    width 100%
                    border-bottom 2px solid $black
                    position absolute
                    bottom 0
                    left 0
                    opacity 1
                    transform scale(1, 1)
                    transform-origin right center 0
                  &:after
                    opacity 0
                    transform scale(0, 1)
                    transform-origin left center 0
                  &:hover:before
                    opacity 0
                    transform scale(0, 1)
                    transition opacity 0.2s ease-in-out 0.2s, transform 0.2s ease-in-out 0s
                  &:hover:after
                    opacity 1
                    transform scale(1, 1)
                    transition transform 0.2s ease-in-out 0.25s
                button.danger
                  color $red-600
                  &:before,
                  &:after
                    border-bottom 2px solid $red-600
                button.disabled
                  color $gray-500
                  pointer-events none
                span.badge
                  padding 4px 8px
                  border-radius 4px
                  &.badge_packaging,
                  &.badge_before_deliver
                    background-color $gray-300
                  &.badge_inbound
                    background-color $yellow-400
                  &.badge_inspection
                    background-color $orange-400
                  &.badge_return
                    background-color $red-400
                  &.badge_outbound
                    background-color $sky-400
                  &.badge_local
                    background-color $purple-300
            tr
              .checkbox_wrapper
                position relative
                width 18px
                height 18px
                margin 0 auto
                input[type="checkbox"]
                  position absolute
                  top 0
                  left 0
                  opacity 0
                  width 18px
                  height 18px
                label:has(input:checked)::after
                  opacity 1
                label:has(input:checked)::before
                  background-color $sky
                  border none
                label
                  display flex
                label::before
                  content ''
                  height 18px
                  width 18px
                  border 1px solid $gray-300
                  border-radius 2px
                  position absolute
                  top 0
                  left 0
                  background-color $white
                label::after
                  content: ''
                  border: 2px solid $white;
                  border-left: 0;
                  border-top: 0;
                  height: 7px;
                  width: 3px;
                  transform: rotate(45deg);
                  position: absolute;
                  top: 3px;
                  left: 7px;
                  opacity: 0;
                  transition 0.2s ease-in-out
          .tabs
            display flex
            width 100%
            height 100%
            > nav
              display flex
              width 100%
              overflow-x auto
            > *
              box-sizing border-box
            nav
              border-bottom 1px solid $gray-300
              overflow-x auto
              scrollbar-width thin
            .tab_item
              display flex
              flex 0 0 auto
              align-items center
              justify-content center
              gap 4px
              padding 0 8px 8px 8px
              height 30px
              color $gray-500
              border none
              background-color $white
              .strong
                font-weight 700
              &.selected
                border-bottom 2px solid $black
                color $black
              .badge
                width 20px
                height 20px
                background-color $ms-blue
                text-align center
                border-radius 50%
                color $white
                font-size 12px
                display flex
                align-items center
                justify-content center
              .badge.danger
                background-color $red-700
            .tab_content
              flex-grow 1
              margin-top 10px
              display none
              &.selected
                display block
              .request_info
                margin-top 8px
                > span
                  margin-right 20px
            .tab_content:has(.backdrop)
              position relative
            .tab_content > .backdrop
              border-radius 1em
          .table_left
            display flex
            width 47%
          .table_right
            width 47%
          .transfer_tool
            width 6%
            display flex
            justify-content center
            align-items center
            gap 20px
            button
              width 48px
              height 48px
              border-radius 4px
              border none
              display flex
              align-items center
              justify-content center
              background-color $sky
              svg
                path
                  stroke $white
              &.disabled
                background-color: $gray-200
                border 1px solid $gray-300
                cursor default
                pointer-events none
                svg
                  path
                    stroke $gray-500
          &.return
            .inbound
              display flex
              width 100%
            .space-20
              width 20px
            .outbound
              width 100%
            .title
              margin 0 0 6px 0
        .error
          display flex
          width 100%
          align-items center
          justify-content center
          color $gray-400
          > h2
            font-weight 600
            font-size 20px
        .empty
          display flex
          width 100%
          height 500px
          align-items center
          justify-content center
          color $gray-400
          > h2
            font-weight 400
            font-size 20px
      .footer
        width 100%
        //height 88px
        display flex
        justify-content space-between
        align-items center
        border-radius 0 0 8px 8px
        //background-color $gray-300
        &.primary
          background-color $sky
          .description
            color $white
        .wrapper
          width 100%
          display flex
          padding 26.5px 40px
          justify-content space-between
        .description
          font-size 18px
          color $gray-700
        .buttons
          display flex
          gap 23px
        button
          width 200px
          height 56px
          font-size 18px
          font-weight 600
          border none
          border-radius 4px
        button.button-lg
          width 264px
        button.primary
          color $white
          background-color $sky
        button.secondary
          background-color $white
          border 1px solid $sky
          color $sky
        button.danger
          background-color $white
          border 1px solid $red-600
          color $red-600
        button.danger_alt
          background-color $red-700
          color $white
        button.dark
          background-color $black
          color $white
        button.gray
          color $gray-500
          border 1px solid $gray-500
        button.normal
          color $gray-700
          background-color $white
          border 1px solid $gray-700
        button.disabled
          cursor not-allowed
          pointer-events none
