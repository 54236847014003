$bucket-nothing = "//s3.marpple.co/files/u_1187078/2023/9/original/7279938a51f7a12b0c343e8fa800318b88e74f891.png"
$boxes-nothing = "//s3.marpple.co/files/u_1187078/2023/9/original/90195b9fe54bb6a8aa112ea334bd8b7e6e4b332d1.png"
$bucket-init = "//s3.marpple.co/files/u_1187078/2023/9/original/8da3b6a5261b5a5e3faeac6aea8e425ee4187cd71.png"
$bucket-packing-little = "//s3.marpple.co/files/u_1187078/2023/9/original/dcb6823dca0ea6163f48352a74dd6038a9a542e41.png"
$bucket-packing-many = "//s3.marpple.co/files/u_1187078/2023/9/original/93d95b5575cb6e1f0adde0cbffd2ef4a766973911.png"
$bucket-packed = "//s3.marpple.co/files/u_1187078/2023/9/original/63d29d0bbb9fc700709bb4695c7a209d6129659d1.png"
$locker-unlock = "//s3.marpple.co/files/u_1187078/2023/9/original/b477a1493c2741131f8721208d945284022179ff1.png"
$locker-lock = "//s3.marpple.co/files/u_1187078/2023/9/original/3b98d4b37d691146e1ed19c4fa84b4622c079d3e1.png"
$status-bar-1 = "//s3.marpple.co/files/u_1187078/2023/9/original/0a640d1902ee1914b988f4386aa7f4edadc43de01.png"
$status-bar-2 = "//s3.marpple.co/files/u_1187078/2023/9/original/616dd2e87975e941a1d42319e8a16d03a2813fa92.png"
$status-bar-3 = "//s3.marpple.co/files/u_1187078/2023/9/original/4d3b0e0c61585d882d936e34203a3ca0577317853.png"
$status-bar-4 = "//s3.marpple.co/files/u_1187078/2023/9/original/da5309cfc14ee77b081538a8f5a90d6b6bc4a8904.png"
$status-dot-1 = "//s3.marpple.co/files/u_1187078/2023/8/original/0a77709c1d822a981a434c375f33a1caba828f571.png"
$status-dot-2 = "//s3.marpple.co/files/u_1187078/2023/8/original/fce47190a873e2a4ee9f7faf70bb93492cb87e602.png"
$status-dot-3 = "//s3.marpple.co/files/u_1187078/2023/8/original/6198f2a1a45512d871a0fb85ece5790cbd80837e3.png"
$status-dot-4 = "//s3.marpple.co/files/u_1187078/2023/8/original/a5066aad9a30dcf1bc9482a3e8830ec938b1bdce4.png"
$status-dot-gray = "//s3.marpple.co/files/u_1187078/2023/8/original/a52ed747cac10cb62513145ce2e6461952eaf6ae1.png"
$button-search = "//s3.marpple.co/files/u_1187078/2023/9/original/138b1b0ad798c21ee5b496923b389609a10c4b6b1.png"
$button-reset = "//s3.marpple.co/files/u_1187078/2023/10/original/c9b2a7f268158a48f7dc90d9efcec961359148b01.png"
$cell-canceled = "//s3.marpple.co/files/u_1187078/2023/9/original/bdff0e7a6a8fbf7df2c090416cbd6c9eafc4f4971.png"
$cell-not-canceled = "//s3.marpple.co/files/u_1187078/2023/9/original/738cbffa3493b6cddb85d1b407f8c1ad8df2edd81.png"
$scroll-thumb-color=#d3d8dc
$scroll-thumb-hover-color=#adb3b8

.don_tab[tab_name="/modules/Df/Inhouse/S/Mui/tab.js"]
  width calc(100% - 6rem) !important
  min-width 115rem
  padding 2rem 3rem
  h1
    margin 0
  .inhouse-shipment
    position relative
    display flex
    flex-direction column
    height calc(100vh - 8rem) !important
    .bucket-statuses-info
      position absolute
      display flex
      gap 1rem
      top 2rem
      right 0
      .status-item
        display flex
        gap 0.25rem
    section
      margin-top 2rem
      .containers
        display flex
        &.half
          gap 3rem
        .container
          flex-grow 1
          .title
            font-size 1.5rem
            font-weight bold
            margin 1rem
          .items
            display flex
            align-items center
            justify-content space-evenly
            padding 0 2.5rem
            gap 2rem
            background-color #f6f6f6
            border-radius: 0.3rem
            height 5rem
            .item
              display flex
              align-items center
              label
                margin-right 0.5rem
                font-size 1rem
                font-weight bold
              input
                outline none
                width 8rem
                height 1.5rem
                margin-right 0.5rem
                font-size 1rem
                padding 0.25rem 0.5rem
                border-radius 0.3rem
                border 1px solid #d5dbe0
                &:focus
                  background-color aliceblue
                &.date-picker
                  width 10rem
                  text-align center
                  color white
                &.search
                  width 7.5rem

    section.controllers
      margin-top 1rem
      .waybill
        button
          transition background-color 0.05s linear
          &:active
            background-color #edc218
      .mode
        button
          transition background-color 0.1s linear
          &.selected
            color white
            &[name="PACK"]
              background-color #58a888
            &[name="UNPACK"]
              background-color #d15b49

    section.buckets
      .dashboard
        display flex
        flex-wrap wrap
        justify-content flex-start
        padding 2rem
        height 22.5rem
        background-color #f6f6f6
        border-radius: 0.3rem
        gap 1.5rem
        overflow auto
        &:not(:has(div))
          background center / contain no-repeat
          background-image url($bucket-nothing)
          background-size 50rem
          background-color #f6f6f6
          margin 0 auto
        .bucket-entity
          position relative
          cursor pointer
          display flex
          flex-direction column
          align-items center
          width 16rem
          height 20.5rem
          padding 1rem 0.5rem
          background-color #f5eef7
          border-radius 0.3rem
          transition box-shadow 0.15s linear
          .bucket-loader
            position absolute
            width 6rem
            top 8rem
            z-index 9
          .company-label
            position absolute
            width 4.5rem
            text-align center
            top 8.7rem
            left 3.9rem
            z-index 9
            font-weight bold
            transform rotate(31deg)
          &[is_packed="true"]
            .company-label
              transform skew(0deg, 32deg)
              top 10.1rem
              left 4.4rem
          &.selected
            box-shadow inset 2px 2px 6px #73517c
          button.location-name
            font-weight bold
            background-color #eae9f4
            margin-bottom 0.7rem
          button.waybill-no
            background-color #e4d7ffaa
          &:hover:not(.selected)
            box-shadow 1.5px 1.5px 4px #856e8a
            button
              box-shadow none
              user-select none
              pointer-events none
          .refresh
            display none
          .bucket-image
            position relative
            width 12rem
            height 10rem
            background center / contain no-repeat
            &[status="INITIAL"]
              background-image url($bucket-init)
            &[status="PACKING-LITTLE"]
              background-image url($bucket-packing-little)
            &[status="PACKING-MANY"]
              background-image url($bucket-packing-many)
            &[status="PACKED"]
              background-image url($bucket-packed)
          .description
            text-align center
            margin 0.7rem 0
            p
              margin 0
          .print-bucket-item
            position absolute
            right 16px
            bottom 16px
            width 26px
            height 26px
            background url(https://s3.marpple.co/files/u_1187078/2024/4/original/5f1e9f838a0d02c2720b2c1610da493371acb9ad1.png) center/contain no-repeat
            &:hover
              transform scale(1.1)

        &::-webkit-scrollbar
          width 10px
        &::-webkit-scrollbar-thumb
          background-color $scroll-thumb-color
          border-radius 5px
        &::-webkit-scrollbar-thumb:hover
          background-color $scroll-thumb-hover-color
    section.box-table
      position relative
      height calc(100% - 43rem)
      .don_loader_wrap
        position absolute
      .table
        margin 0 !important
        .tabulator-header
          .tabulator-col
            background-color #91bfef
            color black
        .tabulator-tableholder
          &:has(> div:only-child):not(:has(> div > div))
            background-size auto !important
            background center / contain no-repeat
            background-image url($boxes-nothing)
            background-color #f6f6f6
          &::-webkit-scrollbar
            width 10px
          &::-webkit-scrollbar-thumb
            background-color $scroll-thumb-color
            border-radius 5px
          &::-webkit-scrollbar-thumb:hover
            background-color $scroll-thumb-hover-color
        .tabulator-tree-level-0
          height 40px
          font-weight bold
          background-color #e5f6fc
          border-bottom 2px solid #7aa2d9
          border-top 2px solid #7aa2d9
          &.highlight_green
            animation blink_green 0.5s linear infinite
          &.highlight_red
            animation blink_red 0.5s linear infinite
          .tabulator-cell
            height 40px !important
        .tabulator-tree-level-1
          height 30px
          font-size 0.9rem
          &:last-child
            border-bottom 1px solid lightgray
          &.tabulator-row-even
            background-color white
          .tabulator-cell
            height 30px !important
        .tabulator-row.tabulator-selectable:hover
          background-color #eeeeee !important
        .tabulator-cell
          &.link
            a
              color #0077B6
              text-decoration underline
          &.canceled
            background center / 1.5rem no-repeat
            background-image url($cell-canceled)
          &.not-canceled
            background center / 1.5rem no-repeat
            background-image url($cell-not-canceled)

    .status-bar
      display flex
      margin 0.5rem 0
      justify-content space-between
      width 8rem
      height 1rem
      background center / contain no-repeat
      &[step="1"]
        background-image url($status-bar-1)
      &[step="2"]
        background-image url($status-bar-2)
      &[step="3"]
        background-image url($status-bar-3)
      &[step="4"]
        background-image url($status-bar-4)
    .dot
      position relative
      width 1rem
      height 1rem
      background center / contain no-repeat
      &[step="1"]
        background-image url($status-dot-1)
      &[step="2"]
        background-image url($status-dot-2)
      &[step="3"]
        background-image url($status-dot-3)
      &[step="4"]
        background-image url($status-dot-4)
      &[is_done="false"]
        background-image url($status-dot-gray)

    .buttons
      display flex
      background-color #ddd
      padding 0.7rem
      margin-left 0.5rem
      border-radius 0.3rem
      gap 0.5rem
    button
      border none
      outline none
      border-radius 0.3rem
      padding 0 0.75rem
      height 2rem
      transition box-shadow 0.1s linear
      &:hover:not(.selected)
        box-shadow 1px 1px 3px #666
      &:active
        box-shadow inset 1px 1px 3px #666 !important
      &.selected
        background-color #f5e9ff
        box-shadow inset 1px 1px 3px #666
      &.reset
        background center / contain no-repeat
        background-color #efefef
        width 2rem
        height 2rem
        background-size 1.5rem
        background-image url($button-reset)
      &.search
        background center / contain no-repeat
        background-color #efefef
        width 2rem
        height 2rem
        background-size 1.5rem
        background-image url($button-search)

@keyframes blink_green
  0%
    background-color #c7e8db
  50%
    background-color #58a888
  100%
    background-color #c7e8db
@keyframes blink_red
  0%
    background-color #eec2bb
  50%
    background-color #d15b49
  100%
    background-color #eec2bb

.swal2-container
  z-index 9999999999 !important
  .inhouse_company_radios
    label
      cursor pointer
      display flex
      align-items center
      font-size 1.5rem
      input[type="radio"]
        width 18px
        height 18px
  .status-ats
    display flex
    flex-direction column
    align-items center
    gap 0.5rem
    .status-at
      display flex
      align-items center
      height 2rem
      gap 2rem
      p
        margin 0
        &.name
          text-align right
          font-weight bold
          width 10rem
        &.time
          text-align left
          width 15rem

#inhouse-address-list-container
  display flex
  flex-direction column
  padding 8px
  .header
    border 1px solid #c7c7c7
    border-top-left-radius 8px
    border-top-right-radius 8px
    background-color #c1daf8
    display flex
    padding 0 16px
    gap 16px
    height 40px
    font-weight bold
    justify-content center
    align-items center
    input[type="checkbox"]
      cursor pointer
      margin 0
      width 20px
      height 20px
      border-radius 3px
    .company-location-name
      width 224px
    .company-address
      flex-grow 1
  label
    cursor pointer
    border 1px solid #c7c7c7
    border-bottom none
    padding 0 16px
    height 38px
    gap 16px
    display flex
    justify-content center
    align-items center
    transition box-shadow 0.1s ease
    &:last-child
      border-bottom 1px solid #c7c7c7
      border-bottom-left-radius 8px
      border-bottom-right-radius 8px
    &:hover
      box-shadow 0 1px 4px #888
    &:has(input[type="checkbox"]:checked)
      background-color #f0f2ff
    input[type="checkbox"]
      cursor pointer
      margin 0
      width 20px
      height 20px
      border-radius 3px
    div
      user-select none
    div.company-location-name
      font-weight bold
      width 224px
    div:last-child
      flex-grow 1
