.don_frame[frame_name="/modules/Df/UpsTasksProgress/List/S/Mui/frame.js"]
  .search-all-function
    display none
  &[worker_type="manager"]
    .search-all-function
      display block
  .don_tab[tab_name="/modules/Df/UpsTasksProgress/List/S/Mui/tab.js"]
    &.loaded
      .progresses
        display flex !important
  .progress__header__projection_id
    cursor pointer
    text-decoration underline
  .df-outsource-ups_task_progresses_info
    max-width 1800px
    min-width 1400px
    width 100%
    margin 0 auto
    display flex
    flex-direction column
    justify-self center
    padding 50px 50px 0 50px
    box-sizing border-box
    .title
      font-size 2em
      margin-bottom 24px
      font-weight bold
    .sub-title
      font-size 18px
      margin-bottom 12px
      font-weight bold
      margin-top 32px
    .workers
    .groups
    .progress-buttons
      padding 24px
      background-color #f6f6f6
      border-radius 4px
      display flex
      align-items center
      flex-wrap wrap
      gap 56px
      &[buttons="true"]
        gap 20px
      &__select-inner-wrapper
        background-color #fff
        select
          font-size 15px
          padding 5px 24px 5px 10px
          border solid 1px #d5dbe0
          -webkit-appearance none
          -moz-appearance none
          border-radius 2px
          background url(//s3.marpple.co/files/u_193535/2022/11/original/e462add35987941d476946a8ee136154b8923dd11.svg) no-repeat 98% center
          background-size 18px 18px
      &__date
      &__select
        display flex
        gap 16px
        align-items center
      &__month
        font-size 15px
        padding 5px 10px
        border solid 1px #d5dbe0
        min-width 100px
      &__title
        font-size 15px
        font-weight 500
        line-height 0.1
      &__submit
        font-size 15px
        font-weight bold
        padding 9px 47px
        text-align center
        color white
        background-color #3c89f9
        border-width 0
        border-radius: 4px
      &__button
        font-size 15px
        padding 9px 20px
        text-align center
        color white
        background-color #3c89f9
        border-width 0
        border-radius: 4px
        &.not
          opacity 0.7
        &.selected
          font-weight bold
      &__done
      &__cols
        display flex
        gap 16px
        align-items center
        margin-right 10px
        input[type="number"]
        input[type="text"]
          font-size 15px
          padding 5px 10px
          border solid 1px #d5dbe0
          min-width 100px
    .summary
      display flex
      gap 24px
      padding 24px
      border-radius 4px
      background-color #f6f6f6
      &__table-section
        overflow auto
        background #fff
        border 1px solid #ddd
        width 50%
        max-height 400px
        .bold
          font-weight 600
        table
          border-collapse collapse
          width 100%
        thead
          th
            font-size 14px
            font-weight 600
            border-bottom 1px solid #000
          tr
            height 58px
        tbody
          tr
            height 58px
            text-align center
            &:hover
              background-color #ebf3fe
            td
              border-bottom 1px solid #ddd
              word-break break-all
              white-space nowrap
              text-align center
              padding 0 20px
              div
                box-sizing border-box
                padding 10px 0
                a
                  font-weight 600
                  text-decoration underline



  .df-outsource-ups_task_progresses
    min-width 1400px
    width 100%
    margin 0 auto
    display flex
    flex-direction column
    justify-self center
    padding 0 50px 50px 50px
    box-sizing border-box
    .title
      font-size 2em
      margin-bottom 24px
      font-weight bold
    .sub-title
      font-size 18px
      margin-bottom 12px
      font-weight bold
      margin-top 32px
    .progress-buttons
      padding 24px
      background-color #f6f6f6
      border-radius 4px
      display flex
      align-items center
      flex-wrap wrap
      gap 56px
      &[buttons="true"]
        gap 20px
      &__select-inner-wrapper
        background-color #fff
        select
          font-size 15px
          padding 5px 24px 5px 10px
          border solid 1px #d5dbe0
          -webkit-appearance none
          -moz-appearance none
          border-radius 2px
          background url(//s3.marpple.co/files/u_193535/2022/11/original/e462add35987941d476946a8ee136154b8923dd11.svg) no-repeat 98% center
          background-size 18px 18px
      &__date
      &__select
        display flex
        gap 16px
        align-items center
      &__month
        font-size 15px
        padding 5px 10px
        border solid 1px #d5dbe0
        min-width 100px
      &__title
        font-size 15px
        font-weight 500
        line-height 0.1
      &__submit
        font-size 15px
        font-weight bold
        padding 9px 47px
        text-align center
        color white
        background-color #3c89f9
        border-width 0
        border-radius: 4px
      &__button
        font-size 15px
        padding 9px 20px
        text-align center
        color white
        background-color #3c89f9
        border-width 0
        border-radius: 4px
        &.select_cancel
          background-color #111
        &.not
          opacity 0.7
          pointer-events none
        &.selected
          font-weight bold
      &__done
      &__cols
      &__excel
        display flex
        gap 16px
        align-items center
        margin-right 10px
        label
          font-size 15px
          padding 9px 20px
          text-align center
          color white
          background-color #3c89f9
          border-width 0
          border-radius: 4px
          &:hover
            cursor pointer
        input[type="number"]
        input[type="text"]
          font-size 15px
          padding 5px 10px
          border solid 1px #d5dbe0
          min-width 100px
    .progresses
      padding-top 20px
      padding-bottom 20px
      width 100%
      display none
      flex-wrap wrap
      gap 20px
      box-sizing border-box
      .progress
        display flex
        flex-direction column
        justify-content space-between
        overflow hidden
        border-radius 10px
        border #f6f6f6 solid 2px
        &:hover
          cursor pointer
        &.selected
          border #3c89f9 solid 2px
        &.done
          border #ffaed7 solid 2px
        &__header
          display flex
          align-items center
          justify-content space-between
          padding 14px
          flex-wrap wrap
          input[type=checkbox]
            zoom 1.8
          span
            display flex
            justify-content center
            align-items center
            flex-wrap wrap
          &__info
            cursor initial
          &__projection_id
            font-size 30px
            font-weight bold
        &__image__wrapper
          display flex
          flex-wrap wrap
          overflow hidden
        &__image
          display flex
          justify-content center
          align-items center
          &__canvas-wrapper
            position relative
            width 100%
            padding-top 100%
            canvas
              position absolute
              width 200%
              height 200%
              top 0
              left 0
              transform scale(0.5);
              transform-origin 0 0
        &__footer
          display flex
          &__options
            background-color #f6f6f6
            width 50%
            margin auto
            padding 14px
            min-height 100px
            display flex
            flex-direction column
            align-items center
            justify-content center
            &__title
              text-align center
              font-size 1.2rem
              font-weight bold
              word-wrap break-word
              text-overflow ellipsis
              overflow hidden
              width 100%

              display -webkit-box
              -webkit-line-clamp 2 // 원하는 라인수
              -webkit-box-orient vertical
            &__option
              text-align center
              padding 0
              margin 1px 0
              word-wrap break-word
              text-overflow ellipsis
              overflow hidden
              width 100%

              display -webkit-box
              -webkit-line-clamp 3 // 원하는 라인수
              -webkit-box-orient vertical
          &__button
            width 100%
            height 50%
            font-size 2rem
            font-weight bold
            padding 14px
            text-align center
            color white
            background-color #3c89f9
            border-width 0
            &__wrapper
              width 50%
              height 100%
            &.can_cancel
              background-color #ffaed7
            &.print
              background-color #111


