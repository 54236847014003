.don_frame[frame_name="/modules/NewMaker/SizeFacesEditor/S/Mui/frame.js"]
  .select_face:not(.section)
    display none
  .canvas-container
    canvas.lower-canvas
      background #c4c4c4 !important
  .don_wrapper >.main
    position relative
  .size_face_editor
    &.cv_print_area
      border 1px solid #00a919
    &.cv_print_item
      border 1px solid #ffc600
    &.cv_safety_area
      border 1px solid #0041ff
    &.cv_mask1
      border 1px solid #da00ff
    &.cv_mask2
      border 1px solid #ff0000
  .size_face_editor_wrapper
    .selected
      background-color #0e2ee2
    button
      border 1px solid #000
      margin-bottom 8px
    position absolute
    z-index 100
    top 0
    right 0
    width 430px
    height 100%
    background #fff
    overflow-y scroll
    padding 16px
    .row_wrapper
      margin-bottom 8px
      &:last-child
        margin-bottom 0
      label
        display inline-block
        width 100px
    .section
      padding 8px
      margin-bottom 16px
      border 1px solid #808080
      > *
        margin-bottom 8px
        &:last-child
          margin-bottom 0
    .head
      font-weight bold
      text-decoration underline
    .body
      padding 8px
    .option
      padding 8px
    [type="file"]
      display none
