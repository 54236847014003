.don_tab[tab_name="/modules/Df/OMP/NavigationMenu/S/Mui/tab.js"]
  .don_wrapper
    display flex
    justify-content center
    align-items center
    flex-direction column
    .content_wrap
      width 45%
      min-width 1300px
      display flex
      flex-direction column
      .navigation
        &__all_menu
          margin-bottom 20px
      .manage_menu
        display flex
        gap 10px
        > button
          width 100%
      .lang
        padding-bottom 20px
        font-size 15px
        text-align center
        &__selected
          font-weight bold
          text-decoration underline
        a
          cursor pointer
          padding  0 10px
      button
        background-color grey
        padding 10px
        color white
      table
        width 100%
        text-align center
        border 1px solid black
        background-color white
        margin 10px 0
        border-collapse collapse
        thead
          background-color #ededed
          height 30px
        tbody
          input
            width 80%
            border none
            padding 10px
            font-size 15px
          .hide-element
            display none
      .navigation__save
        background-color #1C75FF
        font-size 20px
        border 0
