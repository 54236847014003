  .don_tab[tab_name="/modules/Df/Stock/Holding/S/Mui/tab_view.js"]
    h1
      width 200px
      margin 10px 0 0 90px
      line-height 60px
    .view_contents
      padding-top 20px
      .controller
        display flex
        flex-flow row
        justify-content space-between
      .control_buttons
        display flex
        justify-content flex-end
        align-items center
        margin 0 50px
        input.search_command
          color gray
          text-decoration none
          border 1px solid #bbb
          border-radius 3px
          text-align end
          padding 0 10px
          outline none
          height 34px
        .button
          display flex
          justify-content center
          align-items center
          box-sizing border-box
          bottom 0
          width 41px
          height 36px
          text-decoration none
          border 1px solid #bbb
          cursor pointer
          line-height 1
          border-radius 3px
          border-left 0
          &:hover
            background-color #68c671
          svg
            width 23px
          &.selected
            background-color #AEB6BF
            &:hover
              background-color #68c671
          &.expand_collapse
            &.expanded
              .path_arrow, path_remove
                transition all 0.2s
            &:not(.expanded)
              .path_arrow
                transition all 0.2s
                fill #F4661E !important
                stroke #F4661E !important
                transform translateY(87px) scale(1,-1)
              .path_remove
                transition all 0.2s
                opacity 0
      .button.first
        margin-left 20px
        border-left 1px solid #bbb !important
      svg.cell_icon
        height 14px
      #holding_table
        margin 20px auto
        width 95%
        min-width 600px
        .tabulator-col
          height 67px
        .tabulator-tableHolder
          background-color #f7f7f7
        .tabulator-tree-level-0
          font-weight bolder
          border-top 2px solid #a4a8ad
        .tabulator-cell
          &.cancel
            color red
            text-decoration line-through
          &.link
            color blue
            text-decoration underline
            &.cancel
              text-decoration line-through
      #print_progress_bar
        position absolute !important
        transform translateX(-50)
        top 50%
        left 50%
        margin 10px
        padding 10px
        width 100px
        height 100px
    .aztec_code
      cursor pointer
      display flex
      margin-left 40px
      height 50px
      justify-content right
      align-items center
      svg
        width 40px
      span
        width 80px
        margin-right 10px
        text-align right
      &.trigger_print
        margin 0 10px 0 20px
      &.hidden
        visibility hidden