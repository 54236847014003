// modules/Df/Projection/Tag/F/Style/Pc/index.styl
.projection-tag-page
  display block
  &__search-container
    display flex
    flex-direction column
    row-gap 10px
    max-width 500px
    margin 100px auto
  &__input-dropdown-container
    flex-grow 1
    padding: 4px 8px;
    border-radius: 4px;
    border: 1px solid #d5dbe0;
    font-size: 14px;
    margin-left auto
  &__input-container
    display flex
    input
      flex-grow 1
      margin-left auto
      box-sizing border-box
      padding: 4px 8px;
      border-radius: 4px;
      border: 1px solid #d5dbe0;
      font-size: 14px;
  &__input-title
    width 200px;
    margin-right: 20px;
    display inline-block
  &__search-btn
    display: inline-block;
    font-weight: 600;
    cursor: pointer;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid;
    padding: 9px 1.125rem;
    line-height: 1.54;
    border-radius: .15rem;
    transition: all 0.2s ease-in-out;
    color #fff;
    background-color #3c89f9
    border-color #3c89f9
    width 100%
  &__projection-list
    padding 0 100px;
    display flex
    flex-direction column
    row-gap 50px

.projection-tag-item
  display flex
  justify-content space-between
  border 1px solid #757575
  border-radius 20px
  padding 20px 0
  align-items center
  column-gap 20px
  &[data-is_changed="true"]
    background-color #FFF9C4
  &__block
    text-align center
    width 150px;
    display flex
    flex-direction column
    row-gap 15px
    align-items center
  &__block-large
    width 250px
  &__title
    font-size 13px
  &__content
    font-size 16px
    font-weight bold
  &__content-products
    font-size 16px
    font-weight bold
    flex-direction column
    display flex
    span
      display -webkit-box
      -webkit-box-orient vertical
      -webkit-line-clamp 1
      overflow hidden

  &__content-tags
    display flex
    justify-content center
    flex-direction column

  &__tag-selector
    align-self center
    margin-bottom 5px

  &__tag-container
    display flex
    column-gap 5px
    row-gap 5px
    flex-wrap wrap

  &__tag
    padding: 6px 12px
    color black
    border-radius: 5px
    font-size 12px
    align-items center
    display inline-flex
    user-select none
    cursor: pointer
  &__tag-remove-btn
    margin-left 10px
    width 30px
    aspect-ratio 1
    border-radius 5px
    background-color #616161
    color white
  &__close-icon
    width 12px;
    height 12px
    margin-left 12px
  &__save-btn
    display: inline-block;
    font-weight: 600;
    cursor: pointer;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 9px 1.125rem;
    line-height: 1.54;
    border-radius: .15rem;
    border none
    color #fff;
    background-color #4CAF50
    width 80px
