#prev_product_face_canvass
  background #fff
  position absolute
  box-shadow 0 2px 10px 0 rgba(0,0,0,0.1)
  right 30px
  z-index 10
  .option
    z-index 2
  .close
    z-index 2
    padding 10px 16px
    font-size 14px
    position absolute
    top 20px
    left 20px
    border none
    background #000
    color #fff
    border-radius 8px
  canvas
    position absolute
    width 100% !important
    height auto !important
    left 50%
    top -20px
    transform translateX(-50%)
  .swiper-container
    width 100%
    height 100%
  .canvas_wrapper
    text-align center
    .text
      font-size 20px
      position absolute
      bottom 38px
      left 50%
      transform translateX(-50%)
