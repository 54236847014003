#DfWeModeSMuiFrameMain
  position fixed
  top 50px
  left 0
  right 0
  bottom 0
  justify-content center
  .complete_message
    display none
    position absolute
    border 1px solid #545454
    top 15%
    bottom 15%
    left 15%
    right 15%
    *
      color #000000 !important
    .blur
    .message
      position absolute
      top 0
      right 0
      bottom 0
      left 0
    .blur
      background #fff
      opacity 0.9
    .message
      display flex
      flex-direction column
      justify-content center
      align-items center
    .txt1
      justify-content center
      width 100%
      >.signature
        margin-right 32px
      *
        font-size 5vw
        font-weight bold
      margin-bottom 32px
    .txt2
      text-align center
      font-size 3vw
  >.header
    justify-content space-around
    margin-bottom 60px
    .signature
    .title
      width 650px
      border-radius 10px
      text-align center
      padding 16px
      font-size 5vw
  .title
    &:after
      html.we-complete-mode &
        content: "완료 모드"
      html.we-cancel-mode &
        content: "취소 모드"
  .prj
    font-size 3vw
  .up_pdf
    font-size 13vw
  .warning
    position fixed
    top 50px
    left 0
    right 0
    bottom 0
    display none
  .prj
    position absolute
    top 50px
    right 70px
    display flex
    label
      margin-right 16px
  .up_pdf
    > *
      justify-content center
    .up
      width 49%
      padding 16px
    .we_bar
      width 1%
      margin-left 0.5%
      margin-right 0.5%
    .pdf
      width 49%
      padding 16px
  .quantity_status
    justify-content center
    .complete_quantity
      flex 3
      text-align right
    .slash
      flex 1
      text-align center
    .quantity
      flex 3
      text-align left
  &[data-status="READY"]
    >.header
      margin-bottom 0
    .signature
      display none
    .title
      margin 32px
      width initial
      position initial
      &:after
        padding 70px
        font-size 20vw
    .quantity_status
      display none
  &[data-status="DONE"]
    *
      color #00a919
    .we_bar
      background #00a919
    &[data-is_complete="true"]
      .complete_message
        display block
  &[data-status="PROGRESS"]
    *
      color #000
    .prj
      *
        color #7c7c7c
    .we_bar
      background #4C4C4C
  &[data-status="CANCEL"]
    *
      color #d60000
    .we_bar
      background #ff7979
  &[data-has_up="false"]
    .up
      visibility hidden
  &[data-warning="TRUE"]
    .complete_message
      display none !important
    .warning
      background #000
      color #f0ff00
      font-size 6vw
      display flex
      justify-content center
      align-items center
