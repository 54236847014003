

.collection_list
  padding 50px
  h3
    margin 0
    padding 0
    font-size 20px
  .buttons
    margin-bottom 20px
    text-align right
    align-items center
    justify-content center
    a
    button
      display inline-flex
      vertical-align top
      height 40px
      padding 0 20px
      margin 0 0 0 10px
      font-size 12px
      align-items center
      justify-content center
      border 1px solid #ccc
      box-sizing border-box
  .list_header
    li
      color #333
      font-size 14px
      font-weight bold
      min-height 40px
      background-color #eee
      border-top 1px solid #ccc
      .title
        text-align center
  ul
    margin 0
    padding 0
    list-style none
    li
      display flex
      align-items center
      justify-content center
      border-bottom 1px solid #ccc
      box-sizing border-box
      min-height 120px
      text-align center
      .title
        flex 1
        padding-left 10px
        text-align left
      .image
        width 250px
        img
          max-width 150px
          max-height 100px
      .is_public
      .created_at
        width 200px

.collection_editor
  padding 50px
  h3
    margin 0 0 20px
    padding 0 0 10px
    font-size 20px
    border-bottom 1px solid #ccc
  .control
    border-bottom 1px solid #ddd
    padding-bottom 10px
    margin 10px 0 0
    display flex
    label
      display flex
      width 100%
      align-items center
      justify-content center
    .head
      padding-left 10px
      width 130px
      align-items center
      justify-content center
    .body
      display flex
      width 100%
      flex-direction column
      text-align left
      input[type="text"]
      input[type="password"]
        padding 5px 10px
        width 80%
        box-sizing border-box
        border 1px solid #ccc
        font-size 14px
      textarea
        padding 4px 10px
        width 80%
        height 80px
        font-size 16px
        line-height 20px
        box-sizing border-box
        border 1px solid #ccc
      p
        margin 7px 0 0
        padding 0
    &.image_upload
      .img_area
        margin-top 20px
      .img
        position relative
        width 300px
        text-align center
        padding 10px
        border 1px solid #ccc
        .delete
          position absolute
          top -15px
          right -15px
          display flex
          align-items center
          justify-content center
          width 30px
          height 30px
          font-size 16px
          cursor pointer
          background-color #eee
          border 1px solid #ccc
          border-radius 15px
      img
        max-width 100%
        max-height 300px
  .buttons
    margin-top 20px
    text-align center
    button
      margin 0 5px







