.don_tab[tab_name="/modules/Df/OMP/NavigationMenu/Detail/S/Mui/tab.js"]
  .don_wrapper
    display flex
    justify-content center
    align-items center
    flex-direction column
    .hide
      display none
    .save-sections
      width 100%
      height 50px
      background-color grey
      color white
      margin-bottom 70px
    .content_wrap
      width 60%
      min-width 1300px
      display flex
      flex-direction column
      .navigation
        &__desc
          display flex
          justify-content space-between
          align-items center
          padding 20px 0
          > label
            width 5%
          &__text
            width 100%
            padding 10px
            border 1px solid
            border-radius 4px
      button
        background-color grey
        padding 10px
        color white
      .content
        border 1px solid black
        padding 20px
        margin 20px 0
        background-color white
        border-radius 4px
        .category
          background-color #dcdcdc
          &__wrap
            display flex
            justify-content space-between
            align-items center
        &__save
          display flex
          justify-content right
        &__drag
          color #6d6d6d
          font-size 12px
          text-align center
          cursor grab
          .disable-drag
            opacity 30%
      .disable-content
        pointer-events none
        opacity 50%
      table
        width 100%
        text-align center
        border 1px solid black
        background-color white
        margin 10px 0
        border-collapse collapse
        thead
          background-color #ededed
          height 30px
        tbody
          .item
            width 100%
            &__title
              width 20%
            &__delete
              width 10%
              > button
                all revert
            &__banner_img
              width 100px
            &__img
              padding 10px 0
            input
              width 80%
              border none
              padding 10px
              font-size 15px
            &__cate_list_add_message
              padding 10px
      .section
      .content
        &__head
          display flex
          justify-content space-between
          align-items center
        &__remove_section
          float right
          cursor pointer
          font-size 20px
          padding 10px
        &__category
          .category
            &__remove-category
              background-color #f58716
.to-delete
  color red
