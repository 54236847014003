ic-close = url("//s3.marpple.co/files/u_18054/2018/9/original/f_5853_1537163999801_xZp4kmo6Zse1am5Ts1h9W.svg")
down_close = url(//s3.marpple.co/files/u_14355/2018/11/original/f_12437_1541409165567_lrHD9Mhcw3lhZ9a9p8kl.png);
body
  #don_frame_screen
    top 0
    left 0
    right 0
    bottom 0
    position fixed
    width 100%
    height 100%
    background transparent
    z-index 2147483647
  .don_frame
  .don_page
  .don_tab
    display none
    &[hide_frame_button_type=""]
      >.don_wrapper
        >.header
          .don_hide_frame
            display none

    >.don_wrapper
      position relative
    &.is_show
      display block
  .don_frame
  .don_page
    >.don_wrapper
      >.header
        >.don_back_page
          z-index 3
        >.title
          z-index 1
        >.tab_buttons
          z-index 1
        >.don_hide_frame
          z-index 3
  .don_frame[frame_index="0"]
    .don_hide_frame
      display none !important
  .don_frame
    >.don_wrapper
      height 100%
      >.body
        height 100%
    .don_hide_frame
    .don_back_page
    .tab_button
      padding 0
      margin 0
      border 0
      cursor pointer
    &.no_header[hide_frame_button_type="V"]
    &.no_header[hide_frame_button_type="v"]
      >.don_wrapper
        >.body
          z-index 1 !important
          box-shadow 0 2px 7px 2px #aaaa
        >.header
          z-index 2 !important
          .title
            display none
    &[hide_frame_button_type="V"]
    &[hide_frame_button_type="v"]
      >.don_wrapper >.body
        z-index 2
      >.don_wrapper >.header
        z-index 1
        background transparent
        .title
          box-shadow 0 2px 7px 2px #aaaa
          position absolute
          top 30px
          min-height 30px
          width 100%
          background white
        .don_hide_frame
          transform translateX(-50%)
          left 50%
          position absolute
          width 50px
          height 22px
          text-align center
          border none
          top 9px
          box-shadow: 0 -1px 10px 0px #aaaa
          border-radius 2px 2px 0 0
          background #fff url("//s3.marpple.co/file/u_18053/2018/7/original/f_5654_1532605972210_fSO2BpOAb5g7y7S4Afgr.png") no-repeat 50% 54%
          background-size 10px 6px
          &:focus
            outline none
          span
            color transparent
          span:after
            background #fff
            width 100px
            height 10px
            bottom -9px
            position absolute
            left -24px
            content ""
    .don_page
      >.don_wrapper
        >.header .title
          font-size 16px
          font-weight bold
          color color_black
      &[tab_length="0"]
      &[tab_length="1"]
        >.don_wrapper
          >.header
            .tab_buttons
              display none
      &[page_index="0"]
        >.don_wrapper
          >.header
            .don_back_page
              display none
      >.don_wrapper
        >.header
          position relative
          background #fff
          top 0
          //.don_back_page
          //  position absolute
          //  padding 7px 10px 10px 16px
          //  top 50%
          //  transform translateY(-50%)
          //  border none
          //  background transparent
          //  span
          //    width 19px
          //    height 19px
          //    font-family Campton
          //    font-size 10px
          //    font-weight 500
          //    line-height 1.2
          //    color #878b8f
          //    padding 0 0 0 10px
          //    background transparent url(//s3.marpple.co/files/u_193535/2018/10/original/f_12383_1540986214470_q4D2hxrKtVduBhyb5X.png) no-repeat 0 50%
          //    background-size 19px 16px
.infi_container
  opacity 0
  &.completed
    transition opacity .2s ease-in-out
    opacity 1
  .infi_view_more
    position absolute
    bottom 0
    left 0
    right 0
    &:before
      content ""
      background linear-gradient(to bottom, rgba(0,0,0,0), #fff 95%, #fff)
      display block
      height 200px
    button
      box-sizing border-box !important
      font-weight bold
      border 1px solid #000
      background #fff
      padding 0
      margin 0
      width 100%
      height 48px
  .infi_wrapper
    .infi_items
      >div.is_hide
        display none !important

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg) }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg) }
  100% { transform: rotate(360deg)  }
}

don_frame_mobile()
  .don_frame
    &.animating_back
      overflow hidden
      position fixed !important
    &.animating_front
      position fixed !important
      overflow hidden
      //transform none
      height auto
  .don_page
    &.animating_back
      position fixed
      overflow hidden
    &.animating_front
      overflow-y hidden
      top 0
      left 0
      width 100%
      transform none
      height auto
      box-shadow -5px 0 5px -5px #ddd
  .don_frame
  .don_page
  .don_tab
    position relative
    width 100%
    background-color #fff
    >.don_wrapper
      >.body
        position relative
  .don_page
    min-height 100%
    &[tab_length="0"]
    &[tab_length="1"]
      >.don_wrapper
        >.header
          .tab_buttons
            display none
    &[page_index="0"]
      .don_wrapper
        >.header
          .don_back_page
            display none
  .don_frame
    left 0
    right 0
    bottom 0
    width 100%
    box-sizing border-box
    &[is_height="false"]
      position absolute
      top 0
    &[is_height="true"]
      position fixed
      background-color transparent
    >.don_wrapper
      >.header
        position fixed
        top 0
        left 0
        width 100%
    &[is_height="true"]
      >.don_wrapper
        >.header
          position absolute

don_frame_pc()
  .don_frame[is_modal="true"]
    position fixed
    top 0
    left 0
    bottom 0
    right 0
    &:before
      content ''
      position fixed
      top -100px
      left -100px
      bottom -100px
      right -100px
      background-color rgba(0,0,0,0.4)
    > .don_wrapper
      position absolute
      top 0
      left 0
      bottom 0
      right 0
      margin auto
      width 1024px
      height 768px
      box-sizing border-box
      background-color #fff
      box-shadow 0 2px 16px 0 rgba(0, 0, 0, 0.1)
      > .body
        width 100%
        height 100%
        overflow auto
        box-sizing border-box
        > .don_page
          > .don_wrapper
            position static !important

@media (min-width: 1025px)
  don_frame_pc()
  .don_back_page
    position absolute
    top 10px
    left 10px
    display block
    padding 0
    width 40px
    height 40px
    cursor pointer
    background url(//s3.marpple.co/files/u_14355/2018/11/original/f_12438_1541409323249_q9vQ8BNlU4U9lrAq6ER5V.png) no-repeat
    background-size 19px auto
    background-position 50%
    &:active
      background-color #ced4da
  [hide_frame_button_type="X"]
  [hide_frame_button_type="x"]
  [hide_frame_button_type="V"]
  [hide_frame_button_type="v"]
    >.don_wrapper
      >.header
        .don_hide_frame
          display block
          background-color transparent
          position absolute
          background-position 50% 50%
          background-repeat no-repeat
          background-size 16px
          background-image url(//s3.marpple.co/files/u_193535/2018/12/original/f_18632_1545117841977_a8X6NA3p8T4cNCvpowk4t.png)
          width 36px
          height 36px
          top 11px
          right 14px
          border none
          outline none
          &:active
            background-color #ced4da
  [hide_frame_button_type="v"]
    >.don_wrapper
      >.header
        .don_hide_frame
          background-image url(//s3.marpple.co/files/u_14355/2018/11/original/f_12437_1541409165567_lrHD9Mhcw3lhZ9a9p8kl.png)
          top 6px
@media (max-width: 1024px)
  don_frame_mobile()
  .don_back_page
    position absolute
    top 4px
    left 4px
    display block
    padding 0
    cursor pointer
    width 40px
    height 40px
    background url(//s3.marpple.co/files/u_14355/2018/11/original/f_12438_1541409323249_q9vQ8BNlU4U9lrAq6ER5V.png) no-repeat
    background-size 19px auto
    background-position 50%
    &:active
      background-color #ced4da
  .don_frame[frame_name="mp.checkout.change_email"]
    .don_page
      >.don_wrapper >.header
        height 48px
  .don_frame[hide_frame_button_type="-v-"]
    >.don_wrapper >.body
      z-index 10
    >.don_wrapper >.header
      .title
        box-shadow: 0 2px 7px 2px #aaaa
        position: absolute;
        bottom 0
        width: 100%;
      >.don_hide_frame
        transform translateX(-50%)
        left 50%
        position absolute
        width 50px
        height 22px
        top 9px
        text-align center
        border none
        box-shadow: 0 2px 7px 2px #aaaa
        border-radius 2px 2px 0 0
        background #fff url("//s3.marpple.co/file/u_18053/2018/7/original/f_5654_1532605972210_fSO2BpOAb5g7y7S4Afgr.png") no-repeat 50% 54%
        background-size 10px 6px
        &:focus
          outline none
        span
          color transparent
  [hide_frame_button_type="X"]
  [hide_frame_button_type="x"]
  [hide_frame_button_type="V"]
  [hide_frame_button_type="v"]
    >.don_wrapper
      >.header
        .don_hide_frame
          display block
          background-color transparent
          position absolute
          background-position 50% 50%
          background-repeat no-repeat
          background-size 16px
          background-image url(//s3.marpple.co/files/u_193535/2018/12/original/f_18632_1545117841977_a8X6NA3p8T4cNCvpowk4t.png)
          width 36px
          height 36px
          top 14px
          right 6px
          border none
          outline none
          &:active
            background-color #ced4da
  [hide_frame_button_type="v"]
    >.don_wrapper
      >.header
        .don_hide_frame
          background-image url(//s3.marpple.co/files/u_14355/2018/11/original/f_12437_1541409165567_lrHD9Mhcw3lhZ9a9p8kl.png)
          top 6px
  [hide_frame_button_type="V"]
    .don_wrapper >.header >.title
      text-align center
      line-height 48px
      font-size 14px
      color #000
      font-weight bold
    .don_page
      background #fff
