// modules/Df/Lgl/OutboundProductList/F/Style/Pc/index.styl
$gray-200 = #F2F2F2
$gray-500 = #A2A2A2
$gray-600 = #828282
$sky = #3C89F9
$white = #FFFFFF
$black = #111111

.don_frame[frame_name="/modules/Df/Lgl/OutboundProductList/S/Mui/frame.js"]
  > .don_wrapper
    width 1000px
    border-radius 12px
    > .body
      border-radius 12px
.don_tab[tab_name="/modules/Df/Lgl/OutboundProductList/S/Mui/tab.js"]
  .don_wrapper
    .header
      display flex
      align-items center
      justify-content space-between
      border-bottom 1px solid $gray-300
      .title
        font-size 18px
        > h1
          display inline-flex
          font-size 18px
          font-weight 700
          margin 0
          padding 16px 20px 16px 24px
        > strong
          font-width: 600
          margin-right 4px
      .switch_field
        display flex
        margin-right 72px
        input
          position absolute
          clip rect(0, 0, 0, 0)
          height 1px
          width 1px
          border 0
          overflow hidden
        label
          background-color $gray-200
          color $gray-500
          text-align center
          padding 7px 19px
          &:hover
            cursor pointer
          &:first-of-type
            border-radius 40px 0 0 40px
          &:last-of-type
            border-radius 0 40px 40px 0
        input:checked + label
          background-color $sky
          color $white
    .body
      height 700px
      overflow-y auto
      overflow-x hidden
      box-sizing border-box
      padding 24px 40px 40px 40px
      .column
        flex-direction column
      .tab_wrapper
        height 100%
      .table_wrapper
        display flex
        width 100%
        height 100%
        .text_center
          text-align center
        table
          width 100%
          border-radius 1em
          overflow hidden
          border 1px solid $gray-500
          border-collapse separate
          height 100%
          thead
            background-color $gray-200
            color $gray-700
            tr
              display table
              width 100%
              th
                padding 14.5px
                width 200px
          tbody
            display block
            width 100%
            height 100%
            overflow auto
            overscroll-behavior contain
            tr
              display table
              width 100%
              td
                padding 14.5px
                width 200px
                > .product_info
                  display flex
                  align-items center
                  gap 12px
                  > .thumb
                    width 60px
                    height 60px
                    background-color $gray-200
                    border-radius 4px
                  > .description
                    > .options
                      color $gray-600
                button
                  background none
                  border none
                  padding 0
                button.line
                  position relative
                  font-weight 600
                  cursor pointer
                  pointer-events auto
                  transition 0.35s
                  padding-bottom 4px
                  &:before,
                  &:after
                    content ''
                    width 100%
                    border-bottom 2px solid $black
                    position absolute
                    bottom 0
                    left 0
                    opacity 1
                    transform scale(1, 1)
                    transform-origin right center 0
                  &:after
                    opacity 0
                    transform scale(0, 1)
                    transform-origin left center 0
                  &:hover:before
                    opacity 0
                    transform scale(0, 1)
                    transition opacity 0.2s ease-in-out 0.2s, transform 0.2s ease-in-out 0s
                  &:hover:after
                    opacity 1
                    transform scale(1, 1)
                    transition transform 0.2s ease-in-out 0.25s
              &.no_data
                height 100%
                color $gray-400
                font-size 20px
            tr:not(:last-child)
              border-bottom 1px solid $gray-200
      .tabs
        display flex
        width 100%
        height 100%

        > nav
          display flex
          width 100%
          overflow-x auto
        > *
            box-sizing border-box
        nav
          border-bottom 1px solid $gray-300
          overflow-x auto
          scrollbar-width thin
        .tab_item
          display flex
          flex 0 0 auto
          align-items center
          justify-content center
          gap 4px
          padding 0 8px 8px 8px
          height 30px
          color $gray-500
          border none
          background-color $white
          .strong
            font-weight 700
          &.selected
            border-bottom 2px solid $black
            color $black
        .tab_content
          flex-grow 1
          margin-top 10px
          display none
          &.selected
            display block
