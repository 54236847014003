#maker_printable_product
  .dosu-colors
    margin-top 8px
    .color-pickers
      display flex
      flex-wrap wrap
      gap 13.5px
      .color-picker
        display flex
        justify-content center
        cursor pointer
        border-radius 100%
        span
          box-sizing border-box
          width 24px
          height 24px
          border-radius 100%
          margin 1px
          &.too_bright
            border 1px solid #CED4DA
        &.original_tag
          span
            background url(//s3.marpple.co/files/u_1187078/2023/10/original/4b0a81febbcf4ebb28d05033ce040cb71b9e32e71.png) center / 27px no-repeat
            border none
            border-radius 0
          &.selected
            box-shadow unset
        &.selected
          box-shadow: 0 0 0 1px #ff6b00
          border none
        &.used
          &::before
            content ""
            background-color #555
            width 4px
            height 4px
            border-radius 100%
            margin-top -8px
  > .don_wrapper
    > .header
      height 50px
      background #efefef
      .title
        text-align center
        height 50px
        line-height 50px
        font-size 16px
        font-weight bold
      button
        position absolute
        text-align center
        padding 0
        top 8px
        border-radius 8px
        width 8px * 8
        height 50px - 16px
        line-height @height
      .save
        display block
        background blue
        color #fff
        right 8px
      .don_hide_frame
        display block
        left 8px
        background black
        color #fff
