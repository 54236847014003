// modules/Df/OMP/PlatformProductLists/F/Style/Pc/index.styl

.omp-pf
  &__title
    display flex
    justify-content flex-start
    align-items center
    margin-bottom 80px
    > span
      margin-left 40px
      a
        margin-right 10px
      a.current
        color red
        font-weight bold

  &__section.open &__section-content
    height auto
    padding 10px

  &__section-header
    display flex
    justify-content space-between
    align-items center
    margin 0
    padding 20px 0
    font-size 20px
    cursor pointer

  &__section-content
    height 0
    overflow hidden
    border 1px solid #aaa

  &__form
    display flex
    align-items stretch
    width 100%
    border 1px solid #ccc
    &-label
      display flex
      align-items center
      width 160px
      padding 0 20px
      flex-shrink 0
      background #eee
    &-input
      width 100%
      flex 1
      padding 10px
      input
        padding 5px 5px
        width 100%
        box-sizing border-box
    &-button
      width 100px
      padding 10px
      button
        padding 7px 0 6px
        width 100%
        border none
        outline none
        text-align center
        border-radius 5px

  &__table
    margin-top 10px
    width 100%
    border 1px solid #ccc
    border-collapse collapse
    th, td
      padding 10px
    tr + tr
      border-top 1px solid #ccc
    tr.private
      background #FFDBE9
    tbody.list
      tr:hover:not(.private)
        background #fafafa
    th
      background #eee
    td
      &.center
        text-align center
      .handle-btn
        display flex
        justify-content center
        align-items center
        font-size 16px
        height 16px
        background transparent
        border none
        outline none
    label.check
      padding 10px 15px
      cursor pointer
    .thumb
      width 120px
      height 120px
      object-fit contain
    .table__tools
      display flex
      justify-content space-between
      align-items center
      .btn-public,
      .btn-private,
      .btn-remove-selected,
      .btn-cancel,
      .btn-save-order
        display none
      &.selected
        .btn-public,
        .btn-private,
        .btn-remove-selected
          display inline
      &.edit
        .btn-cancel,
        .btn-save-order
          display inline
        .btn-edit-order
          display none
    &.list
      td .thumb
        width 60px
        height 60px