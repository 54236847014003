
.earthcake_layer
.earthcake
  position fixed
  top 0
  left 0
  right 0
  bottom 0
  z-index 999
  .bg
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    background-color rgba(0, 0, 0, 0.6)
  .container
    position absolute
    top 20px
    left 20px
    right 20px
    bottom 20px
    width 600px
    height 600px
    margin auto
    background-color #fff
    z-index 10
    .layer_btns
      .close
        position absolute
        top -30px
        right -20px
        font-size 30px
        line-height 20px
        color #fff
        cursor pointer

.image_editor_layer
  .container
    width 1000px
    height 700px


.df_image_editor_frame
  > .don_wrapper > .header
    .title
      display none
    .don_hide_frame
      position absolute
      top 14px
      right 20px
      cursor pointer
  .don_page > .don_wrapper > .header
    display none
    &[is_group_image="true"]
      .controls
        .btns
          .btn_change_group_image
            display none
  .df_image_editor
    position fixed
    top 0
    left 0
    right 0
    bottom 0
    > .header
      margin-bottom 10px
      padding 10px 0
      background-color #eee
      h2
        margin 0
        padding 0
        font-size 20px
        text-align center
    > .controls
      position absolute
      top 46px
      left 0
      width 30%
      bottom 0
      border-right 1px solid #eee
      padding-top 10px
      .control
        padding 5px 10px
        .head
          display inline-block
          margin-right 10px
      .btns
        margin-top 20px
        padding 10px
        text-align center
        border-top 1px solid #eee
        button
          margin 0 3px
          &.btn_change_group_image
            position absolute
            bottom 10px
            left 10px
      .original_file
        border-top 1px solid #ccc
        padding-top 50px
        margin-top 50px
        text-align center
        label
          position relative
          overflow hidden
          display block
          margin-bottom 10px
        input[type="file"]
          visibility hidden
          position absolute
          top -99999px
          left -99999px
        .original_thumb_url
          display inline-block
          img
            max-width 90%
            max-height 150px
            padding 10px
            border 1px solid #ccc

    > .image_color_list
      position absolute
      top 46px
      right 0
      bottom 0
      width 70%
      overflow auto
      .ic_controls
        margin 10px
        padding 10px
        text-align right
        background-color #f1f1f1
        input[type="file"]
          display none
        button
          margin-left 5px
      ul
        margin 0
        padding 10px
        list-style none
        li
          display inline-block
          position relative
          margin 10px
          padding 10px
          min-height 240px
          border 1px solid #ccc
          vertical-align top
          .img
            min-height 150px
            text-align center
            img
              max-width 150px
              max-height 150px
          .btns
            .btn_ic_delete
              position absolute
              top -10px
              right -10px
          &[is_first="false"] .first_txt
          &[is_first="true"] .btn_first_image
            display none
          &[is_first="true"]
            background-color #f1f1f1

.df_group_image_editor
  > .don_wrapper > .header
    .title
      display none
    .don_hide_frame
      position absolute
      top 20px
      right 20px
      cursor pointer
  .don_page > .don_wrapper > .header
    display none
  .header h2
    margin 0
    padding 20px 10px 0
    text-align center
  .image_list
    .image_controls
      position relative
      margin 10px
      padding 10px
      text-align right
      background-color #eee
      input[type="file"]
        display none
      input[type="text"]
        margin-right 5px
      .btn_add_image
        marign-left 10px
        display inline-block
      label.public
        position absolute
        top 10px
        left 10px
    .buttons
      margin 0 10px 10px
      padding 0 10px 10px
      text-align right
      border-bottom 1px solid #ccc
      span
        margin-left 5px
        font-size 12px
        color #999
      > button
        margin-left 5px
    ul
      margin 0
      padding 0 2%
      list-style none
    .image_item
      display inline-block
      position relative
      width 230px
      height 220px
      margin 0 10px 10px 0
      border 1px solid #eee
      box-sizing border-box
      background-position center 30%
      background-size auto 25%
      background-repeat no-repeat
      &[is_group="true"]
        border-color red
        .btns
          button.btn_group_first
          button.btn_pop
          button.btn_delete
            display none
      > a
        display block
        width 100%
        height 100%
      .name
        position absolute
        left 0
        right 0
        bottom 24px
        height 24px
        line-height 24px
        text-align center
        background-color #ddd
      .price
        position absolute
        left 0
        right 0
        bottom 0
        height 24px
        line-height 24px
        text-align center
        background-color #f1f1f1
      .resolution
        position absolute
        left 0
        right 0
        bottom 48px
        height 24px
        line-height 24px
        text-align center
        background-color #f1f1f1
      .btns
        position absolute
        top 0
        right 0
        > button
          display none
          margin-left 5px
        .btn_group_first
        .btn_modify
        .btn_pop
        .btn_delete
        .btn_image_download
          display inline-block


.don_frame.share_order_align_editor
  position fixed !important
  top 0
  left 0
  right 0
  bottom 0
  background-color #fff !important
  z-index 200
  > .don_wrapper > .header
    display none
  .don_page
    border-radius 10px
    width auto
    min-height auto !important
    > .don_wrapper
      > .header
        .title
          padding-top 30px
          text-align center;
        .don_back_page
          display none
        .tab_buttons
          padding 20px 0 0 20px
          button
            margin 0
            font-size 20px
            background-color transparent
            border 0 none
        .don_hide_frame
          position absolute
          top 25px
          right 20px
          text-align center
          cursor pointer
          font-size 18px
          background-color transparent
          border 0 none
    .btn_finish
      position absolute
      top -20px
      right 60px
      cursor pointer
    .order_align
      position fixed
      top 50px
      left 0
      right 0
      bottom 20px
    #share_order_align_editor
      position absolute
      top 30px
      left 20px
      right 20px
      bottom 20px
      padding-top 20px
      overflow auto
      border-top 1px solid #ccc
      &[type="image"]
        .item
          position relative
          display inline-block
          width 100px
          height 156px
          padding 0 10px 20px 0
          &[is_group="true"]
            .image
              border 1px solid red
          &.sortable-chosen
            opacity 0.8
            .image
              background-color #eee
            .btns
              display none
          .no
            padding 3px 5px
            background-color #ccc
          .image
            margin-top 5px
            height 100px
            background-size 60% auto
            background-repeat no-repeat
            background-position 50% 50%
            border 1px solid #ccc
            cursor pointer
          .name
            display none
          .btns
            right 10px
            button
              position absolute
              bottom 20px
              &.btn_first
                left 0
              &.btn_last
                right 10px
      &[type="text"]
        .item
          position relative
          padding 0 10px 0
          cursor pointer
          border-bottom 1px solid #eee
          &:hover
            background-color #eee
          &:first-child
            border-top 1px solid #eee
          .image
          .btns
            display none
          > div
            display inline-block
            vertical-align middle
            margin-right 10px
            padding 10px
      &[type="image_text"]
        .item
          position relative
          display inline-block
          width 100px
          height 176px
          padding 0 10px 20px 0
          &[is_group="true"]
            .image
              border 1px solid red
          &.sortable-chosen
            opacity 0.8
            .image
              background-color #eee
            .btns
              display none
          .no
            padding 3px 5px
            background-color #ccc
          .image
            margin-top 5px
            height 100px
            background-size 60% auto
            background-repeat no-repeat
            background-position 50% 50%
            border 1px solid #ccc
            cursor pointer
          .name
            margin-top 5px
            padding 2px
            height 15px
            text-align center
            overflow hidden
            background-color #eee
          .btns
            right 10px
            button
              position absolute
              bottom 20px
              z-index 10
              &.btn_first
                left 0
              &.btn_last
                right 10px


.df_group_selected
  > .don_wrapper > .header
    display none
  .selected_images
    position fixed
    top 0
    left 0
    right 0
    padding 20px 20px 0
    height 150px
    z-index 1
    background-color #e1e1e1
    border-bottom 1px solid #ccc
    h3
      margin 0
      padding 0
    .buttons
      position absolute
      top 30px
      right 30px
      button
        margin-left 5px
    .first
      position absolute
      top 50px
      left 20px
      button
        display none
    .list
      position absolute
      top 44px
      left 150px
      right 10px
      padding-top 6px
      height 100px
      overflow auto
      white-space nowrap
    .item
      display inline-block
      position relative
      margin-right 10px
      width 90px
      height 90px
      background-color #fff
      background-repeat no-repeat
      background-size 70% auto
      background-position 50% 50%
      border 1px solid #ccc
      &[is_new="true"]
        button.btn_delete
          display inline-block
      .name
        display none
      .btn_delete
        display none
        position absolute
        top -5px
        right -5px
  .selection_images
    position relative
    padding 170px 0 20px
    ul
      margin 0
      padding 20px 20px 0
      list-style none
    .image_item
      display inline-block
      position relative
      width 230px
      height 220px
      margin 0 10px 10px 0
      border 1px solid #eee
      box-sizing border-box
      background-position center 30%
      background-size auto 50%
      background-repeat no-repeat
      &[group_image="true"]
        border-color #9acd32
        .btns
          .btn_image_select
            display none
          .btn_image_cancel
            display inline-block
      &[is_group="true"]
        border-color red
        .btns
          button.btn_group_first
          button.btn_pop
          button.btn_delete
            display none
      > a
        display block
        width 100%
        height 100%
      .name
        position absolute
        left 0
        right 0
        bottom 24px
        height 24px
        line-height 24px
        text-align center
        background-color #ddd
      .price
        position absolute
        left 0
        right 0
        bottom 0
        height 24px
        line-height 24px
        text-align center
        background-color #f1f1f1
      .resolution
        position absolute
        left 0
        right 0
        bottom 48px
        height 24px
        line-height 24px
        text-align center
        background-color #f1f1f1
      .btns
        position absolute
        top 0
        right 0
        > button
          display none
          margin-left 5px
        .btn_image_select
          display inline-block

.df_change_folder
  > .don_wrapper > .header
    display none
  .selected_images
    position fixed
    top 0
    left 0
    right 0
    height 150px
    padding 20px 20px 0
    z-index 1
    background-color #e1e1e1
    border-bottom 1px solid #ccc
    h3
      margin 0
      padding 0
    .controls
      position absolute
      top 30px
      right 30px
      select
        margin-left 10px
        margin-right 30px
        vertical-align middle
      button
        margin-left 5px
    .list
      position absolute
      top 54px
      left 20px
      right 10px
      padding-top 6px
      height 100px
      overflow auto
      white-space nowrap
    .item
      display inline-block
      position relative
      margin-right 10px
      width 90px
      height 90px
      background-color #fff
      background-repeat no-repeat
      background-size 70% auto
      background-position 50% 50%
      border 1px solid #ccc
      &[is_new="true"]
        button.btn_delete
          display inline-block
      .name
        display none
      .btn_delete
        display none
        position absolute
        top -5px
        right -5px
  .selection_images
    position relative
    padding 170px 0 20px
    ul
      margin 0
      padding 20px 20px 0
      list-style none
    .image_item
      display inline-block
      position relative
      width 230px
      height 220px
      margin 0 10px 10px 0
      border 1px solid #eee
      box-sizing border-box
      background-position center 30%
      background-size auto 50%
      background-repeat no-repeat
      &[selected="true"]
        border-color #9acd32
        .btns
          .btn_image_select
            display none
          .btn_image_cancel
            display inline-block
      &[is_group="true"]
        border-color red
        .btns
          button.btn_group_first
          button.btn_pop
          button.btn_delete
            display none
      > a
        display block
        width 100%
        height 100%
      .name
        position absolute
        left 0
        right 0
        bottom 24px
        height 24px
        line-height 24px
        text-align center
        background-color #ddd
      .price
        position absolute
        left 0
        right 0
        bottom 0
        height 24px
        line-height 24px
        text-align center
        background-color #f1f1f1
      .resolution
        position absolute
        left 0
        right 0
        bottom 48px
        height 24px
        line-height 24px
        text-align center
        background-color #f1f1f1
      .btns
        position absolute
        top 0
        right 0
        > button
          display none
          margin-left 5px
        .btn_image_select
          display inline-block


