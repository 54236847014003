.don_frame[frame_name="composite_template_maker"]
  .neo_border
    margin 10px auto
    padding 17px
    width 100%
    display flex
    flex-wrap wrap
    justify-content space-evenly
    border-radius: 14px;
    background #ffffff;
    box-shadow 6px 6px 12px #e6e6e6, -6px -6px 12px #fafafa
    >.head
      font-weight bolder
      margin 3px 0 17px 0
      text-align center
      width 100%
  .text_input
    border-radius 11px
    background #ffffff
    box-shadow inset 6px 7px 11px #dedede, inset -9px -9px 11px #fafafa
    border none
    padding 7px 7px 7px 15px
    vertical-align -webkit-baseline-middle
  >.don_wrapper
    width 80%
    height 95%
    min-width 1000px
  .don_tab, .don_page, .don_page .don_wrapper >.body, .don_page >.don_wrapper, .don_tab >.don_wrapper
    position initial !important
  .don_page >.don_wrapper >.header
    text-align center
    height 58px
    display flex
    justify-content center
    align-items center
    .title
      text-shadow 2px 2px 2px #cccccc
      font-size 27px
  select.base_product_colors_color_code
    box-shadow none
  .main
    //padding 10px 0
  .don_back_page
    display none
  .option
    .prev, .next
      border none !important
    .prev
      background url(//s3.marpple.co/files/u_193535/2018/11/original/f_12418_1541061387939_Ck2SBQTwcRZmv7Mde.png) no-repeat center center
      background-size 14px
      width 30px
      height 30px
      transform translateY(-50%) rotate(180deg)
      position absolute
      left 20px
      top 50%
    .next
      background url(//s3.marpple.co/files/u_193535/2018/11/original/f_12418_1541061387939_Ck2SBQTwcRZmv7Mde.png) no-repeat center center
      background-size 14px
      width 30px
      height 30px
      transform translateY(-50%)
      position absolute
      right 20px
      top 50%
  .body>.title
      background: cornflowerblue;
  .don_page[page_name="composite_masks_step_page"]
    .heads>.head
      width 90px
    .design_shade_url
      img
        background #a1a1a1
    .heads
      .head:nth-child(2)
        margin-top 50px
      .head:nth-child(3)
        margin-top 65px
      .head:nth-child(4)
        margin-top 50px
    .list.body
      max-width 700px
      >.composite_mask
        width 700px
    .texture_url
      flex-wrap unset
    .list.body
      flex-direction column
    .base_product_name
      background-color #ffc719
    .composite_textures
      .content
        margin-bottom 25px
    .composite_texture
      >.head
        position relative
        margin-bottom 25px
        .title
          text-align center
        .option
          position absolute
          right 0
          top 50%
          transform translateY(-50%)
  .don_page[page_name="composite_template_bg_step_page"]
    button.upload
      margin-top 10px
    .head:nth-child(2)
    .head:nth-child(3)
      margin-top 30px
    .head:nth-child(4)
      margin-top 25px
    .prev
      display none
  .don_page[page_name="composite_faces_select_step_page"]
    .heads
      .head:nth-child(2)
        margin-top 40px
      .head:nth-child(3)
        margin-top 25px
    .composite_mask .title
      background-color #ffc719
  .don_page[page_name="composite_faces_step_page"]
    .composite_result
      display flex
      justify-content center
      margin-bottom 16px
      img
        width 900px
        background #f6f6f6
    .design_position
      table
        tr
          height 40px
    .title
      width 740px
    .composite_mask .title
      background-color #ffc719
    .head:nth-child(2)
      margin-top 35px
    .head:nth-child(3)
      margin-top 25px
    .location_btns
      display flex
      width 100%
      justify-content space-around
    input[type="number"]
      border-radius 10px
      background #ffffff
      box-shadow inset 6px 7px 11px #dedede, inset -9px -9px 11px #fafafa
      border none
      padding 7px 7px 7px 15px
      vertical-align -webkit-baseline-middle
  .don_page[page_name="composite_result_step_page"]
    .composite_toggle
      margin auto 15px
      .toggle_switch
        margin 5px auto
        isolation isolate
        position relative
        height 24px
        width 50px
        cursor pointer
        border-radius 15px
        overflow hidden
        box-shadow -8px -4px 8px 0px #ffffff, 8px 4px 12px 0px #d1d9e6, 4px 4px 4px 0px #c4ccd8 inset, -4px -4px 4px 0px #ffffff inset
      .toggle_state
        display none
      .indicator
        height 100%
        width 100%
        background #ecf0f3
        border-radius 15px
        cursor pointer
        transform translate(-50%, 0)
        transition transform 0.4s cubic-bezier(0.85, 0.05, 0.18, 1.35)
        box-shadow -8px -4px 8px 0px #ffffff, 8px 4px 12px 0px #d1d9e6
      svg
        fill none
        transition all 0.5s linear
        transform scale(1.77) translate(7px, 2px)
        path
          transition all 0.3s linear
          stroke #eb2f06
          stroke-dasharray 25
          stroke-dashoffset 25
          stroke-width 2
      .toggle_state:checked~.indicator
        transform translate(50%, 0)
        svg
          transition all 0.5s linear
          transform  scale(1.77) translate(-7px, 2px);
        path
          stroke #52d66b
          stroke-dasharray 24
          stroke-dashoffset 0
          stroke-linecap round
          stroke-linejoin round
    .base_product_colors_color_code
      height 43px
      margin auto 10px
    .don_hide_frame
      width 50px
      border 1px solid #424140
      background #424140
      border-radius 5px
      &:after
        color #fff
        content '닫기'
    .next
      display none
  .wrapper > div
    *
      font-size 20px
      box-sizing border-box
    .btn
      border 1px solid #424140
      background #424140
      border-radius 5px
      color #fff
      font-size 16px
      padding 8px
      height 32px
      display flex
      justify-content center
      align-items center
    [data-has_img="true"]
      .no_image
        display none !important
    [data-has_img="false"]
      img
        display none !important
    .img_wrapper
      position relative
      margin-top -10px
      canvas
      img
        position absolute
        top 0
        background #f6f6f6
        left 0
        right 0
        bottom 0
        margin auto
        max-width 100%
        max-height 100%
      &.with_mask
        img
          mask-size cover
          -webkit-mask-size cover
    .img_wrapper
      .no_image
        margin 0 auto
        display flex
        align-items center
        justify-content center
        height 100%
        color #ccc
    .img_input_wrapper
      .delete
        display none
      &.top_layer_url
        .delete
          display block
      a
        border 1px solid black
      .option
        display flex
        justify-content space-around
      .img_wrapper
        margin-bottom 16px
    .heads
      display flex
      flex-direction column
      margin 30px 40px 0 0
      >.head
        text-align right
        font-weight bold
        width 120px
    >.heads >label, >.body >.content
      margin-bottom 20px
      &:last-child
        margin-bottom 0
    &.just_image_upload_download
      display flex
      justify-content center
      .head
        text-align center
        margin-bottom 16px
      .content:nth-child(2n)
        padding-left 16px
        margin-left 16px
        border-left 1px solid #ccc
      &.other_urls
        img
          background #000000
        .content
          margin-bottom 32px
      >.wrapper
        width 633px
        display flex
        flex-wrap wrap
        justify-content center
      .img_input_wrapper
        text-align center
      .img_wrapper
        width 300px
        height 300px
        img
          max-width 280px
          max-height 280px
    &.composite_masks_select_bpfs
      .select_bpfs_head
        height 200px
      .select_thumb_bpfs_head
      .select_thumb_bpfs
        display none
      &[type="thumbnail"]
        .select_thumb_bpfs_head
        .select_thumb_bpfs
          display block
      display flex
      justify-content center
      .heads
        padding-top 16px
        >.bg_url_masked_head
          height 300px
      >.list
        display flex
      .composite_mask
        padding 16px 32px
        margin-right 16px
        border-left 1px solid #ccc
        .bg_url_masked
          >.img_wrapper
            width 300px
            height 300px
            img
              max-width 290px
              max-height 290px
        &:first-child
          border-left 0
        &:last-child
          margin-right 0
        .select_bpfs
          &[has_composite_faces="false"]
            .option
              .revise
                display none
          &[has_composite_faces="true"]
            .option
              .apply
                display none
            .base_product_faces
              margin-bottom 20px
              .wrapper
                display none
              .wrapper[is_checked="true"]
                color #ccc
                display block
        .select_bpfs
          display flex
          flex-direction column
          justify-content space-between
          .option
            display flex
            justify-content center
        > div
          margin-bottom 32px
          &:last-child
            margin-bottom 0
    &.composite_masks_list
      table
        width 300px
        td
          text-align center
        input
          width 100%
          text-align center
      .bg_url_masked
        .img_wrapper
          width 300px
          height 300px
          img
            max-width 290px
            max-height 290px
      .composite_faces
        overflow auto
        display flex
        width 900px
      .design_position
        display flex
        flex-direction column
        justify-content flex-end
        align-items center
        table
          margin-bottom: 25px
      .design_position, .composite_face >.body
        >*
          margin-bottom 16px
          &:last-child
            margin-bottom 0
      .map_distort_url
        text-align center
        >div
          margin-bottom 16px
      .composite_face
        >.body
          width: 700px
          display flex
          flex-direction column
          align-items center
        padding 20px
        margin 15px
      .composite_mask
        margin-bottom 32px
        &[has_composite_faces="true"]
          .heads
            display none
        .title
          text-align center
        > div
          display flex
          justify-content center
          margin-bottom 16px
        >.body
          display flex
          justify-content center
          width 100%
      .img_wrapper
        width 200px
        height 200px
        img
          max-width 180px
          max-height 180px
    &.composite_masks_set
      display flex
      justify-content center
      .heads
        .for_mask
          height 354px
      //.texture_seamless_url
      //  .img_wrapper
      //    display none
      .list
        display flex
        max-width 620px
        &.need_scroll
          overflow scroll
      .composite_mask
        min-width 300px
        margin-right 16px
        >.content
          >.option
            display flex
            justify-content center
          text-align center
          margin-bottom 32px
        .img_wrapper
          width 300px
          height 300px
          img
            max-width 280px
            max-height 280px
    &.composite_template
      >.body
        width 830px
        .row
          display grid
          grid-template-columns 150px 1fr
          align-items center
      .img_wrapper
        height 500px
        width 500px
        img
          max-width 480px
          max-height 480px
      display flex
      justify-content center
      .content >.body
        width 500px
        margin-right 32px
      .content
        .option
          display flex
          flex-direction column
          button.upload
            margin-bottom 16px
        display flex
        &.title
          input
            width 100%
    &.composite_template_result
      padding 0 80px
      canvas
        background #f6f6f6
      .result_url
        display flex
        overflow-x auto
        canvas
          width 750px
          height 750px
        img
          width 900px
          height auto
      .create_shade_url
        display flex
        button
          background #3f51b5
          margin-left 8px
          height 40px
      display flex
      flex-direction column
      align-items center
      .base_product_colors
        margin-bottom 16px
        text-align center
      .row
        margin-bottom 16px
        &.is_done
          margin-bottom 50px
          width 350px
          display flex
          justify-content center
          label
            margin-left 4px
          input
            margin-left 16px
      #crop_form
        margin 0 0 10px 0
        display flex
        justify-content center
        width 650px
        .crop_inputs
          display flex
          padding 10px
          margin-bottom 15px
          label
            width 20px
            padding-top 4px
          input
            width 100px
            margin-right 15px
      .option
        display flex
        justify-content center
        > *
          margin 15px
      .img_wrapper
        width 500px
        height 500px
        canvas
          max-width 480px
          max-height 480px
  .composite_template .bg_url img
    background repeating-linear-gradient(45deg, #eee 0, #eee 10px, #fff 10px, #fff 20px), #fff !important
