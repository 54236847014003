body.dev_image
  #body
    width 1200px
    margin auto
    padding 100px 0
    #dev_image
      h2
      h3
      h4
        display inline-block
      h1
        text-align center
      .remove
        float right
      .dev_images
        a
          display inline-block
          padding 5px 10px
          border 2px solid #222
          font-weight 500
          font-size 20px
          &[selected="true"]
            color #1f5ea8
            border-color #1f5ea8
      .images_wrap
        margin 30px 0
      .images
        .image
          position relative
          border 1px solid #222
          width 130px
          height 210px
          cursor pointer
          display inline-block
          vertical-align top
          .img
            font-size 0
            width 130px
            height 130px
            background #fff url(//s3.marpple.co/file/guest/2017/10/original/f_710_1507456502604_O2nn8NXb7wq0y0SdR1WTv.png) repeat left top
            display flex
            align-items center
            justify-content center
            &:hover
              background #222
            img
              max-width 90%
              max-height 90%
          .info
            position relative
            padding 10px
            width 110px
            height 60px
            >div
              margin 3px 0 5px
            .original_name
              word-break break-all
            .copy
              display none
            &:hover
              .copy
                display block
                position absolute
                width 130px
                height 80px
                line-height 80px
                font-size 18px
                top 0
                left 0
                bottom 0
                right 0
                margin auto
                background #f5f5f5
                text-align center
                box-shadow 0 16px 24px -8px rgba(33, 37, 41, 0.2)
