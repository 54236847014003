.df-tpl-admin
  padding 50px
  &__title
    font-size 28px
    margin 0 0 24px
.df-tpl-admin-search
  display flex
  align-items center
  background-color #f6f6f6
  padding 24px 20px
  width 100%
  margin-bottom 32px
  &__title
    margin 0 16px 0 0
    font-size 15px
  &__input
    border-radius 4px
    border solid 1px #d5dbe0
    margin-right 24px
    padding 5px
  &__submit
    padding 9px 47px
    border-radius 4px
    background-color #3c89f9
    color #fff
    font-size 15px
    font-weight bold
    border none
.df-tpl-admin-info
  &__title
    margin 0 0 12px
    font-size 18px
  &__list
    background-color #f6f6f6
    padding 24px
  &__overflow
    height calc(100vh - 420px)
    overflow auto
    position relative
  &__table
    width 100%
    background #fff
    th
    td
      padding 20px
      text-align center
      &.df-tpl-admin-info__img-wrapper
        padding 0
    th
      position sticky
      top 0
      background-color #fff
      z-index 100
      font-size pxToRem(14px)
      border-bottom 1px solid #000
  &__thead
    tr
      border-bottom 1px solid #000
  &__tbody
    border-bottom 1px solid #ededed
    tr
      border-top 1px solid #ededed
      &.df-tpl-admin-info__child
        border-top 1px solid #f8f8f8
        display none
        &--open
          display table-row
        td
          padding 12px
          color #4e4e4e
    td:nth-child(7)
        text-align left

  &__img
    width 44px
    height 44px
    background #f6f6f6
    margin 0 auto
    display flex
    align-items center
    justify-content center
    position relative
    img
      width 100%
    &:hover
      img
        position absolute
        width 500px
        height 500px
        top 0
        left 50%
        transform translate(-50%, 0)
        z-index 9999
  &__possible-open
    cursor pointer
  &__arrow
    img
      width 18px
      height 18px
    &--open
      img
        transform rotate(180deg)

