body.banner_items
  #banner_items
    margin-top 50px
    overflow hidden
    button
      margin-left 8px
      border-radius 8px
      padding 6px 8px
      border 1px solid #222
      box-sizing border-box
    nav.line-path-tab-selectors
      display flex
      .tab-selector
        margin-right 3px
        border 1px solid #cccccc
        border-top-left-radius 4px
        border-top-right-radius 4px
        border-bottom 0
        background #f2f2f2
        a
          padding 10px
          display block
        &.selected
          margin-bottom -1px
          background #ffffff
    >.header
      overflow hidden
      text-align center
      margin 16px 16px 0
      .langs
        margin-top 8px
        &.is-line
          text-align left
        a
          margin 0 8px
          font-size 15px
          &.selected
            font-weight bold
            text-decoration underline
    >.body
      padding 0 16px 16px
      .banners
        margin-bottom 80px
        .banner_item
          background-color #eee
          border 1px solid #000
          box-sizing border-box
          padding 16px
          margin-bottom 8px
          position relative
          .handle
            position absolute
            top 20px
            right 20px
            cursor grab
            padding 8px
            color #fc5926
          .editor_wrap
            width 30%
            display inline-block
            vertical-align top
            >div
              margin-bottom 10px
              select
                background-color #fff
                border 1px solid #000
                width 72px
                height 32px
                font-size 16px
              button
                color #fff
                border 0
                &.copy_to_lang
                  background-color #386ac6
                &.put
                  background-color #4cc37f
                &.delete
                  background-color #e25453
              input
              input
                width 250px
                height 26px
                font-size 14px
                padding 0 4px
              textarea
                padding 5px
                font-size 14px
                box-shadow none
                resize none
                width 250px
                height 36px
                &[name="sub_title"]
                  font-size 12px
                  height 30px
          .banner_src
            width 60%
            position relative
            overflow hidden
            display inline-block
            vertical-align top
            .pc
            .mobile
              width 44%
              display inline-block
              vertical-align top
              h3
                margin 0
              &.pc
                h3
                  span
                    font-size 12px
                    font-weight 400
                    margin-left 8px
                .src_wrap.src_video
                  display none
                &[video_show="true"]
                  .src[key="src_pc"]
                    display none
                  .src_wrap.src_video
                    display block
              .src
                input[type="file"]
                  width 150px
                .src_wrap
                  width 80%
                  position relative
                  video
                  img
                    max-width 100%
                    max-height 100%
                  button
                    position absolute
                    top -30px
                    right -30px
                    color #fff
                    border-color #fff
                    background-color #e25453
                    width 30px
                    height 30px
              &.mobile
                .src
                  .src_wrap
                    width 250px
        &#middel_banners
          position relative
          .banner_items
            position relative
            width 100%
          .middle_banner
            vertical-align top
            display inline-block
            width 50%
