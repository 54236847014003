// modules/Df/BpOptionGroups/F/Style/Pc/index.styl
.don_tab[tab_name="/modules/Df/BpOptionGroups/S/Mui/tab.js"]
  .don_wrapper
    display flex
    justify-content center
    align-items center
    flex-direction column
    .content_wrap
      width 90%
      min-width 1400px
      display flex
      flex-direction column
      padding-bottom 40px
      .option-group-list
        padding 15px 24px
        background-color #f6f6f6
        border-radius 4px
        &__table-wrap
          height 700px
          overflow auto
        &__group-table-wrap
          margin 20px 0
          border 3px solid #3c89f9
          border-radius 4px
          padding 10px
          background-color #dddddd66
          div
            margin 0 40px
          span
            font-weight bold
        &__group-table
          margin-bottom 10px
        &__select
          display flex
          align-items center
        &__title
          padding-right 10px
        button
          font-size 14px
          padding 6px 36px
          text-align center
          color white
          background-color #3c89f9
          border-width 0
          border-radius 4px
          margin 0 10px
          &.danger
            font-size 12px
            background-color red
            padding 5px
        &__id
          font-weight bold
          text-decoration underline
          cursor pointer
        &__connect-search
          display flex
        .input-file
          padding 6px 25px
          background-color #FF6600
          border-radius 4px
          color white
          cursor pointer
        &__template-button
          margin-left auto !important
          padding 6px 12px !important
          background-color #b3a7a7 !important
        &__upload
          display none
        table
          border-collapse collapse
          background white
          border 1px solid #dbdbdb
          tr
            height 45px
        thead
          th
            font-size 14px
            font-weight 600
            border-bottom 1px solid #000
            padding 0 10px
        tbody
          td
            border-bottom 1px solid #ddd
            padding 0 10px
            a
              text-decoration underline
              font-weight bold
        .product-list
          margin 10px 0
          div
            max-height 500px
            overflow auto
        .product-options-list
          max-height 700px
          overflow auto
          padding 10px 0
          > span
            display inline-block
            font-weight bold
            margin-top 10px
        .base_product_faces
          > div
            align-items center
            padding-top 10px
            ul
              display flex

