body.advance_reservation
  [data-type="pass"]
    background #0e3a95
    color #fff
  [data-type="defer"]
    background #80d2ff
  [data-type="fail"]
    background #ff4444
  [data-type="duplicate"]
    color #fff
    background #555
  [data-type="pass_mail_fail"]
    color #fff
    background #292a2b
  [data-is_email="true"]
    background #e06e2d
  #advance_reservations
    #filter
      form#search_bar
        .search
          input
            width 300px !important
        .search_result
          position relative
          .mail_btns
            position absolute
            top 0
            right 0
            .btn_all_pass_mail
              margin-right 10px
              color #fff !important
              border 0 none !important
              background-color #0e3a95 !important
            .btn_all_fail_mail
              color #fff !important
              border 0 none !important
              background-color #ff4444 !important

    >.body
      padding 24px
      .counts
        display flex
        align-items center
        margin-bottom 16px
        font-size 16px
        .count
          margin-right 20px
          display flex
          align-items center
          span
            display block
            margin 0 4px
      .advance_reservation_list
        margin-bottom 30px
        table
        th
        td
          border 1px solid #f0f0f0
        table
          tr.advance_reservation_item[data-is_pick="true"]
            tdfirst-child
              background -webkit-linear-gradient(top, #d4f4ad 0%, #dcf771 12%, #dcf756 100%) /* Chrome10-25,Safari5.1-6 */
          th
          td
            position relative
            padding 10px
            height 20px
            max-width 150px
            overflow hidden
            text-overflow ellipsis
            white-space nowrap
            &.patching
            &.description
            &.memo
              cursor pointer
              text-decoration underline
            &.memo
              max-width 230px
            &.description
              max-width 300px
            &.description
            &.memo
              overflow hidden
              text-overflow ellipsis
              white-space nowrap
            &.files
              overflow visible
              text-decoration underline
              .hover_wrap
                cursor pointer
                &hover
                  .files_wrap
                    display block
              .files_wrap
                display none
                position absolute
                top 10px
                right 10px
                padding 20px
                background #f0f0f0
                z-index 2
                cursor initial
                li
                  padding 10px
  #advance_reservation_detail
    padding 30px
    >div
      margin-bottom 16px
      .title
      .content
        display inline-block
        vertical-align top
        margin-right 12px
        font-size 16px
        > a
          display inline-block
          margin-bottom 4px
        input
          display block
          width 480px
          font-size 14px
      .title
        width 140px
      .content
        width 500px
        word-break keep-all
      &.patch
        button
          margin 0 5px
          width 200px
          height 32px
          font-size 16px
          font-weight 500
          border-color #000
          background-color #000
          color #fff
          &.btn_send_mail
            background-color #1b77ff
            border-color #1b77ff
      &.type
        select
          font-size 14px
      &.memo
        textarea
          font-size 14px
          resize none
          width 500px
          height 100px
