body.promotion
  button
    background #fff
    border 1px solid #000
  .don_tab[tab_name="df.promotion_code"]
    margin auto
    #promotion_code
      padding 50px
      table
        th
        td
          padding 5px 10px
          white-space nowrap
          text-align center
          &.description
            width 200px
            white-space initial
            text-align left
      .buttons
        margin 10px 0
  .don_tab[tab_name="df.promotion_code.upsert"]
    width 1200px
    margin auto
    padding 50px 0
    font-size 16px
    .form
      margin 30px 0
      input
        font-size 16px
        &[type="number"]
          width 100px
          text-align right
        &[name="domain_name"]
          width 300px
      textarea
        width 200px
        height 100px
        padding 10px
        font-size 14px
      label
        margin-right 18px
        span
          margin-right 10px
      .info
        margin 10px 0
      .buttons
        button
          width 100px
          height 50px
          font-size 16px
    .section
      margin-bottom 20px
      .cond
        display none
        &.selected
          display block
