html.adjustment
  #adjustment
    margin 80px 40px
    .header
      {flex-center-center}
      .title
        margin 32px 16px
    .table_wrap
      overflow-x scroll
    table, td, th, tr, thead, tbody
      border solid 1px #cfcfcf
      font-size 12px
    table
      border-spacing 0
      border-collapse collapse
      thead
        background-color #3ac5bb
        color #fff
      th, td
        padding 7px 10px
        text-align center
        white-space nowrap

