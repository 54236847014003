// modules/Df/LogModal/F/Style/Pc/index.styl

.don_frame[frame_name="/modules/Df/LogModal/S/Mui/frame.js"]
  > .don_wrapper
    width 1400px


.don_page[page_name="/modules/Df/LogModal/S/Mui/page.js"]
  > .don_wrapper
      .title
        padding 20px

.don_tab[tab_name="/modules/Df/LogModal/S/Mui/tab.js"]
  > .don_wrapper
    max-width 100%
    overflow auto
    padding 20px
    table
      width 100%
      thead
        height 50px
        text-align center
        border-bottom 1px solid black
      tbody
        td
          padding 10px
        .log_body
          width 950px
          overflow-x auto
          line-height 200%
