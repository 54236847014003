html.user
  #old_messages
    margin 50px 0 200px 200px !important
  #body
    .don_page:not([page_name="mp.review.detail"]):not([page_name="projection.detail"])
      > .don_wrapper
        >.header
          margin 30px
          .user_tab
            margin-bottom 16px
            ul
              li
                display inline-block
                vertical-align top
                margin-right 50px
                padding 8px 0
                position relative
                &[selected="true"]:after
                  content ''
                  background-color red
                  position absolute
                  bottom 0
                  left 0
                  width 100%
                  height 2px
  &.point
    table
      width 100%
      text-align center
      td
      th
        padding 5px 0
        &.description
          width 300px
          padding 5px
          input
            width 292px
    .user_point_logs
      margin 0 16px
      .add_point
        margin 0 0 30px
        input
          font-size 14px
          height 30px
          padding 0 10px
        button
          background #fff
          padding 2px 10px
          border 1px solid #888
          vertical-align top
          height 34px
        #description
          width 500px
  &.review
    .admin_review
      display block !important
    .base_product
      display none
    .mp_review_list
      margin 80px 0 120px 30px !important

  &.detail
  &.join
    .user_detail
      margin 0 16px
      h2
        margin-bottom 20px
      table
        width 50%
        .th
          width 210px
        td
        th
          padding 10px
  &.list
    .user_list
      position relative
      margin 0 16px
      > .header
        select
          margin-right 10px
        input[name="search"]
          width 200px
          height 18px
          line-height 18px
        .btn_search
          margin-left 5px
          margin-right 50px
      .don_pgn_container
        margin 50px 0
        table
          tr
            cursor pointer
            &:hover
              th
              td
                background-color #ccc
            th
            td
              padding 6px 10px
              h2
                text-align center

      table
        border-spacing 0
        border-collapse collapse
        width 100%
        tr
          th
          td
            border 1px solid #000
            padding 20px
            &.th
              width 170px
  .team_select
    width 130px
    border 1px solid grey
    box-sizing border-box
    padding 5px
  .don_select_list
    box-sizing border-box
    border 1px solid grey
    background #fff
    width 130px
    ul
      padding 0
    .selected_true
      padding 5px
      box-sizing border-box
      width 100%
      background #f1f1f1
      &:after
        top 11px
    li
      padding 5px
      border-bottom 1px solid grey
      margin 0
      &:last-child
        border-bottom none
      &:first-child
        border-top 1px solid grey
.don_frame[frame_name="df.worker.list"]
  >.don_wrapper
    height 95vh !important
.don_page[page_name="worker.list"]
  .worker
    position relative
    cursor pointer
    .cancel
      position absolute
      font-size 36px
      top 50%
      left 50%
      transform translate(-50%, -50%)
      display none
    &:hover
      .cancel
        display block
  .don_wrapper
    {jip-don_page_head}
  div
    box-sizing border-box
  .don_wrapper >.head
    grid-template-columns 1fr 8fr 1fr
    margin-bottom 8px
  .don_tab >.don_wrapper >.body
    width 800px
    margin 50px auto
  .selected_workers, .team_list, .worker_list
    border 1px solid gray
    border-radius 7px
  .change
    >.head
      background #ebebeb
      font-weight 500
      margin-bottom 10px
      font-size 20px
      padding 10px 0 10px 21px
      border-top-left-radius 7px
      border-top-right-radius 7px
    margin-bottom 10px
    padding-bottom 10px
  .selected_workers >.head
    border-bottom 1px solid #ccc
  .workers, .teams
    display grid
    grid-template-columns 1fr 1fr 1fr 1fr 1fr
    grid-gap 10px
    padding 0 20px
  .team_list .team, .workers .worker
    cursor pointer
    &:hover
      background #ebebeb !important
      border 1px solid #ccc !important
  .team_list .team
    border 1px solid transparent
    padding 10px
  .worker
    border 1px solid #ccc
    text-align center
    padding 10px
  .worker.selected
    background #ebebeb
  .team.selected
    background #ebebeb
    border 1px solid #ccc !important
  .worker.disabled
    background #ebebeb
    opacity 0.6
    cursor no-drop






