html.stock_list
  .stock_list_area
    position relative
    margin 50px
    h2
      margin 0 0 20px !important
      padding 0
    form
      button[type="submit"]
        position absolute
        top 0
        right 0
    .options
      position relative
      width 100%
      margin-bottom 20px
      input::-webkit-calendar-picker-indicator
        display none
      select,
      input
        margin-right 10px
      input
        width 400px
      .btns
        position absolute
        top 0
        right 0
        button
          margin-left 10px
      #bp_list
        display none
    table
      border-spacing 0
      border-collapse collapse
      width 100%
      text-align center
      thead
        td
        th
          padding 10px
          background-color #bbb
          border 1px solid #000
      tbody
        tr
          &:hover
            td
            th
              background-color #eee
        th
        td
          border 1px solid #000
          padding 10px
        td.bp_name
          text-align left
        input[type="number"]
          width 50px
        button
          margin 0 5px
    .don_pagination
      margin-top 30px


















