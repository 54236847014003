body.df-admin
  .don_frame
    .don_tab
      padding 60px 30px
      .df-admin-header
        margin-bottom 20px
        h2
          margin 0 0 10px 0
        .description
          font-size 16px
          ul
            padding-left 20px
            list-style disc
            li
              max-width 1000px
              margin-bottom 6px
      table
        width 100%
        margin 32px 0
        border 1px solid #cccccc
        font-size 15px
        border-right 0
        border-left 0
        th:first-child
          border-left 0
        th:last-child
          border-right 0
        &.type-table
          margin 0 0 32px
        thead
          th
            padding 5px 10px
            background #f2f2f2
            border 1px solid #cccccc
            min-width 201px
            text-align left
        tbody
          td
            padding 10px
            border-bottom 1px solid #cccccc
          tr
            &:hover
              background #E6F3FF
            .checkbox
              transition border-color ease 25ms
              height 16px
              width 16px
              background-color #FFF
              border 1px solid #ccc
              border-radius 4px
              position relative
              box-sizing border-box
              margin-top 2px
              vertical-align top
              cursor pointer
            &.checked
              .checkbox:before
                content ' '
                transform-origin left top
                box-sizing border-box
                position absolute
                width 3px
                background-color #16b
                display inline-block
                border-radius 4px
                transform rotate(-135deg)
                top 12px
                left 5px
                height 11px
            &.checked
              .checkbox:after
                transition opacity ease .5
                -ms-transform-origin left top
                transform-origin left top
                box-sizing border-box
                position absolute
                width 3px
                background-color #16b
                display inline-block
                border-radius 4px
                content ' '
                transform rotate(-45deg)
                top 8px
                left 1px
                height 6px
        .df-admin-user-item
          .push-page-df-admin-role
            display inline-block
            padding 4px
            font-size 14px
            background #f2f2f2
            border-radius 4px
            margin 0 2px
      .clickable:not([data-id="0"])
        cursor pointer
      span.clickable
        color #146eb4
        &:hover
          text-decoration underline
      .input-button-editor
        display flex
        align-items center
        *
          font-size 15px
        &.df-admin-group-add
        &.df-admin-role-add
        &.df-admin-user-add
          margin 32px 0
        &.df-admin-user-add
          margin 0 0 16px
        select
          padding 4px
          margin-right 8px
        input[type="text"]
          height 20px
          margin-right 8px
          padding 2px 4px
        button.btn
          height 29px
          margin-right 2px
          border-radius 2px
          background #f2f2f2
          border 1px solid #ccc
          &.insert-btn
            border 1px solid #0055aa
            background #257acf
            color #fff
          &.delete-btn
            border 1px solid #5e0000
            background #a00
            color #fff
      .df-admin-group-by-body
        margin 32px 0
      .df-admin-group-by-list
        fieldset
          border 1px solid #fff
          .df-admin-group-by-header
            display flex
            align-items center
            margin 2px 4px
            h3
              margin 0 16px 0 0
            .open
              display block
            .close
              display none
          .type-table
            display none
            table
              margin 0 0 32px 0
            th.comment
              width 50%

        &.open
          fieldset
            border-color #eee
            margin-bottom 40px
            .df-admin-group-by-header
              .open
                display none
              .close
                display block
            .type-table
              display block
    &.df-admin-detail
      >.don_wrapper
        width 1280px
        position absolute !important
        >.header
          position absolute
          left 0
          right 0
          top 0
          height 60px
          background rgba(255,255,255,.6)
      .summary
        font-size 16px
        margin-bottom 32px
        .summary-row
          display flex
          align-items center
          margin-bottom 8px
          height 32px
          &__btn-df-login-at
            border 1px solid #0055aa
            background #257acf
            color #fff
          .summary-row-key
            font-weight bold
            width 200px
          .summary-row-edit
            margin-left 4px
            font-size 12px
            color #146eb4
          .edit-name
            margin-left 16px
          select
            padding 4px
            font-size 15px
      .df-admin-detail-taps
        .tab-selectors
          display flex
          .tab-selector
            margin-right 3px
            cursor pointer
            padding 10px
            border 1px solid #cccccc
            border-top-left-radius 4px
            border-top-right-radius 4px
            border-bottom 0
            background #f2f2f2
            &.selected
              margin-bottom -1px
              background #ffffff
        .tab-bodys
          border 1px solid #cccccc
          padding 40px 20px
          .tab-body
            display none
            &.selected
              display block
            .list-header
              display flex
              justify-content space-between
            .df-admin-modal-parent
              position relative
              z-index 1
              .df-admin-modal
                background white
                position absolute
                box-shadow 0 0 60px 0
                padding 20px
                width 800px
                height 500px
                top 0
                right 0
                bottom 0
                margin auto
                overflow auto
                .input-button-editor
                  position absolute
                  top 0
                  left 0
                  right 0
                  height 50px
                  padding 0 20px
                  background rgba(255,255,255,.7)
                .df-admin-modal-body
                  margin-top 50px
                  table thead th
                    min-width auto
.df-admin-user-item__group-type
  font-weight 700
  color #0e2ee2
