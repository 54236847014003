// modules/Df/OMP/BizProduct/BizSort/F/Style/Pc/index.styl
.omp-biz-product-biz-sort-frame
  .title
    padding-top 24px
    text-align center
    font-weight bold
    font-size 16px

.omp-biz-product-biz-sort
  .btn-area
    display flex;
    justify-content flex-end
  .omp-biz-product-biz-sort__list
    display inline-flex
    flex-wrap wrap
    .omp-biz-product-biz-sort__biz-product
      width 100px
      margin: 10px
      cursor: move
