html.projection.waybill
  .invoice-number-maker
    padding 100px 50px
  table.waybill_records
    td,th
      padding 5px 10px
      font-size 12px
      text-align center
      a
        text-decoration underline
  button
    height 20px
    font-size 12px
  select
    font-size 14px
    margin-right 8px
