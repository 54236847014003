// modules/Df/OMP/BizProduct/BizFilter/F/Style/Pc/index.styl
.omp-biz-product-biz-filter
  h2
  h3
  h4
  h5
  form
    display inline-block
    margin 0

  &__groups
    display flex
    flex-direction column
  label,
  input[type="cehckbox"]
    cursor pointer

