.don_frame[frame_name="composite_templates_color"]
  > .don_wrapper > .body
    background #EBECF0
  .don_page
    height 100%
    background #EBECF0
  .don_page > .don_wrapper > .header
    height 95px
    display flex
    justify-content center
    align-items center
    background #EBECF0
    position fixed
    width 1024px
    top 30px
    z-index 99
    display none
    .title
      font-size 20px
      padding: 10px 40px;
      box-shadow: -5px -5px 20px #FFF, 5px 5px 20px #BABECC;
      border-radius: 20px;
      text-shadow: 2px 2px 2px white;
  .don_page > .don_wrapper > .body
    overflow hidden
    background #EBECF0
    .don_tab
      width 100%
    .don_wrapper
      display flex
      flex-direction row
      margin-top 65px
      .ct_bg_canvas_list
        width 50%
        flex-wrap wrap
        box-sizing border-box
        padding 0 15px
        background #EBECF0
        display block
        height 700px
        overflow auto
        .bg_img
          border-radius 25px;
          box-shadow inset 2px 2px 5px #BABECC, inset -5px -5px 10px #fcfcfc
          display flex
          justify-content center
          align-items center
          padding 12px
          margin-bottom 5px
          canvas
            width 100%
            border-radius 20px;
          img
            width 100%
            height 100%
            border-radius 20px;
      .ct_bg_colorSelector_wrapper
        position relative
        background #EBECF0
        padding 0 15px
        width 50%
        display flex
        justify-content center
        table
          position fixed
          border-collapse separate
          border-spacing 0 4px
          display block
          height 700px
          overflow auto
          .color_pick
            transition width 0.2s linear, height 0.2s linear
            margin auto
            border-radius 50%
            width 24px
            height 24px
          .color_pick:hover
            box-shadow inset -3px -3px 5px #BABECC, inset 3px 3px 7px #ffffff
          .selected .color_pick
            width 50px
            height 50px
          .table_header, .color_info
            border-radius 25px
            background-color #EBECF0
            box-shadow inset 2px 2px 5px #BABECC, inset -5px -5px 10px #fcfcfc
            text-align center
          .color_info.selected
            transition box-shadow 0.2s linear
            box-shadow inset -5px -5px 7px #BABECC, inset 5px 5px 10px #ffffff
          input[type="color"]
              border-radius 5px
              padding 0 1px
            .toggle
              isolation isolate
              position relative
              height 24px
              width 50px
              margin 2px
              cursor pointer
              border-radius 15px
              overflow hidden
              box-shadow -8px -4px 8px 0px #ffffff, 8px 4px 12px 0px #d1d9e6, 4px 4px 4px 0px #c4ccd8 inset, -4px -4px 4px 0px #ffffff inset
            .toggle_state
              display none
            .indicator
              height 100%
              width 100%
              background #ecf0f3
              border-radius 15px
              cursor pointer
              transform translate(-50%, 0)
              transition transform 0.4s cubic-bezier(0.85, 0.05, 0.18, 1.35)
              box-shadow -8px -4px 8px 0px #ffffff, 8px 4px 12px 0px #d1d9e6
              svg
                fill none
                transition all 0.5s linear
                transform scale(1.77) translate(7px, 2px)
                path
                  transition all 0.3s linear
                  stroke #eb2f06
                  stroke-dasharray 25
                  stroke-dashoffset 25
                  stroke-width 2
            .toggle_state:checked~.indicator
              transform translate(50%, 0)
              svg
                transition all 0.5s linear
                transform  scale(1.77) translate(-7px, 2px);
              path
                stroke #52d66b
                stroke-dasharray 24
                stroke-dashoffset 0
                stroke-linecap round
                stroke-linejoin round
            th
              padding 3px 7px
              height 33px
              &:first-child
                width 55px
              &:last-child
                width 60px
            td
              padding 3px
              width auto
