.bks_list
  #bks_list
    margin 0 auto
    width 96%
    max-width 1500px
    button
      font-size 13px
    h2
      margin 0 0 20px
    .filter
      label
        display inline-block
        padding 0 32px 24px 0
        select
        input
          margin-left 8px
        input:not([value=""])
          color color_black0cc
          font-weight bold
    .table
      text-align center
      width 100%
      border-top 1px solid #ccc
    .tr
      display grid
      grid-template-columns 1fr 1.5fr 1.3fr 1.8fr 1fr 80px 100px 6fr 1.5fr 1fr
      button.complete
        background #44c767
        border-color #fff
        color #fff
      button.ready
        font-size 13px
        -moz-box-shadow:inset 0px 39px 0px -24px #e67a73;
        -webkit-box-shadow:inset 0px 39px 0px -24px #e67a73;
        box-shadow:inset 0px 39px 0px -24px #e67a73;
        background-color:#e4685d;
        text-shadow:0px 1px 0px #b23e35
        border-color #fff
        color #fff
      button
        display none
      button.hand
        display inline-block
      &[is_hidden="true"]
        opacity 0.4
        color #999
        background #eee
      &[is_hidden="true"]
      &[is_complete="true"]
        .open_projection_detail
          opacity 1
      .open_projection_detail
        display inline-block
        min-width 410px
        margin-bottom 8px
        &:last-child
          margin-bottom 0
        span
          position relative
          width 74px
          display inline-block
          text-align left
          &.amount
            width 80px
            &:before
              content ''
      &[is_hidden="true"] button.show
      &[is_hidden="false"] button.hide
      &[is_complete="true"] button.complete
      &[is_complete="false"] button.ready
        display inline-block
    .th
    .td
      font-size 13px
      padding 10px 10px 10px
      border-right 1px solid #ccc
      border-bottom 1px solid #ccc
      align-items center
      justify-content center
      display flex
      word-break break-all
      input
        width 70px
      &:first-child
        border-left 1px solid #ccc
    .td:nth-child(8)
      display block
    .th
      background #eee
      font-size 12px

