REPEAT_TIMES = 30
REPEAT_TIME = 0.05s
NORMAL_TIMES = 8
NORMAL_TIME = 0.5s
FOCUSING_TIME = 0.8s
OUT_FOCUSING_TIME = 3s

CANCEL_COLOR_A = #ff3245
CANCEL_COLOR_B = #d00000
SUCCESS_COLOR_A = #70e000
SUCCESS_COLOR_B = #007200
FAIL_COLOR_A = #ffd000
FAIL_COLOR_B = #ff7b00
ERROR_COLOR_A = #6d23b6
ERROR_COLOR_B = #9d4edd
FOCUSING_COLOR_A = #ff9500
FOCUSING_COLOR_B = #ffc300
OUT_FOCUS_COLOR_A =#474847
OUT_FOCUS_COLOR_B = #7a7a7a

.don_frame[frame_name="/modules/Df/Stock/Holding/S/Mui/frame.js"]
  >.don_wrapper>.header
    cursor default
    top 90px !important
    width auto !important
    margin 0 40px
    padding 10px 50px
  .don_tab[tab_name="/modules/Df/Stock/Holding/S/Mui/tab.js"]
    box-sizing border-box
    display block
    &.hidden
      display none
    >.don_wrapper
      border-color transparent
      border-width 40px
      border-style solid
      width 100vw
      height calc(100vh - 50px)
      box-sizing border-box
      &.focusing
        animation focusing FOCUSING_TIME alternate infinite
        &.cancel_to_success
          background: linear-gradient(to right, CANCEL_COLOR_A 30%, SUCCESS_COLOR_A 45%) !important
        &.cancel_to_success:after {
          content ''
          position absolute
          top 0
          width: 100%
          height: 100%
          background: linear-gradient(to right, CANCEL_COLOR_B 20%, SUCCESS_COLOR_B 45%)
          animation opacity_change NORMAL_TIME NORMAL_TIMES infinite none
        }
        &.cancel_to_fail
          background: linear-gradient(to right, CANCEL_COLOR_A 20%, FAIL_COLOR_A 45%) !important
        &.cancel_to_fail:after {
          content ''
          position absolute
          top 0
          width: 100%
          height: 100%
          background: linear-gradient(to right, CANCEL_COLOR_B 20%, FAIL_COLOR_B 45%)
          animation opacity_change NORMAL_TIME alternate NORMAL_TIMES none
        }
        &.fail_to_success
          background: linear-gradient(to right, FAIL_COLOR_A 20%, SUCCESS_COLOR_A 45%) !important
        &.fail_to_success:after {
          content ''
          position absolute
          top 0
          width: 100%
          height: 100%
          background: linear-gradient(to right, FAIL_COLOR_B 20%, SUCCESS_COLOR_B 45%)
          animation opacity_change NORMAL_TIME alternate NORMAL_TIMES none
        }
        &.fail_to_cancel
          background: linear-gradient(to right, FAIL_COLOR_A 20%, CANCEL_COLOR_A 45%) !important
        &.fail_to_cancel:after {
          content ''
          position absolute
          top 0
          width: 100%
          height: 100%
          background: linear-gradient(to right, FAIL_COLOR_B 20%, CANCEL_COLOR_B 45%)
          animation opacity_change NORMAL_TIME alternate NORMAL_TIMES none
        }
        &.success_to_fail
          background: linear-gradient(to right, SUCCESS_COLOR_A 20%, FAIL_COLOR_A 45%) !important
        &.success_to_fail:after {
          content ''
          position absolute
          top 0
          width: 100%
          height: 100%
          background: linear-gradient(to right, SUCCESS_COLOR_B 20%, FAIL_COLOR_B 45%)
          animation opacity_change NORMAL_TIME alternate NORMAL_TIMES none
        }
        &.success_to_cancel
          background: linear-gradient(to right, SUCCESS_COLOR_A 20%, CANCEL_COLOR_A 45%) !important
        &.success_to_cancel:after {
          content ''
          position absolute
          top 0
          width: 100%
          height: 100%
          background: linear-gradient(to right, SUCCESS_COLOR_B 20%, CANCEL_COLOR_B 45%)
          animation opacity_change NORMAL_TIME alternate NORMAL_TIMES none
        }

      &.out_of_focus
        animation out_of_focus OUT_FOCUSING_TIME alternate infinite
        background-color gray !important
        span
          color #4e4d4d !important
      .holding_label_body
        display flex
        justify-content center
        align-items center
        flex-flow column
        height 100%
        .label_counters
          user-select none
          position absolute
          top 47%
          transform translateY(-50%)
          z-index 99
          span
            transition font-size 0.5s
            font-size 20vw
            &.scan_now
              align-items bottom
              font-size 27vw
              font-weight bold
              -webkit-text-stroke 13px aliceblue;
              text-stroke 13px aliceblue;
            &.cancel //projection cancelled
              color #b00f0f
            &.success //holding end, goto transfer
              color #07711f
            &.fail //still holding
              color #fb8500
        .billboard
          user-select none
          position absolute
          bottom 4%
          display flex
          justify-content center
          align-items center
          width 100%
          height 100px
          span
            font-weight bold
            font-size 5vw
            &.projection_id
              margin-right 5vw
        input.label_scanner
          position absolute
          bottom 0
          height 25px
          width 300px
          border none
          background-color transparent
          outline none
          caret-color transparent
          text-align center
          color grey

    .aztec_code
      z-index 999
      position absolute
      display flex
      justify-content center
      align-items center
      cursor pointer
      svg
        width 40px
      span
        width 80px
        margin-right 10px
        text-align right
      &.view
        top 10px
        right 10px
      &.reload
        bottom 10px
        right 10px
      &.fullscreen
        bottom 10px
        left 10px
        span
          text-align left
          margin-left 10px
      &.hidden
        display none


  @keyframes opacity_change {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes focusing {
    0% {
      border-color: FOCUSING_COLOR_A
    }
    100% {
      border-color: FOCUSING_COLOR_B
    }
  }
  @keyframes out_of_focus {
    0% {
      border-color: OUT_FOCUS_COLOR_A
    }
    100% {
      border-color: OUT_FOCUS_COLOR_B
    }
  }

