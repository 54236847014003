.don_frame[frame_name="/modules/Df/Outsourcing/Company/Release/DefaultInfo/S/Mui/frame.js"]
  .df-outsource-delivery-default-info
    min-width 1400px
    max-width 1800px
    width 100%
    margin 0 auto
    display flex
    justify-self center
    flex-direction column
    padding 50px
    box-sizing border-box
    .title
      font-size: 2em;
      margin-bottom 24px
      font-weight: bold;
    .default-info
      padding 24px
      background-color #f6f6f6
      border-radius 4px
      display flex
      align-items center
      gap 56px
      .delivery_company
      .delivery_price
        display flex
        gap 16px
        align-items center
      &__delivery-company
        background-color #fff
        select
          font-size 15px
          padding 5px 10px
          border solid 1px #d5dbe0
          min-width 100px
          -webkit-appearance none
          -moz-appearance none
          border-radius 2px
          background url(//s3.marpple.co/files/u_193535/2022/11/original/e462add35987941d476946a8ee136154b8923dd11.svg) no-repeat 95% center
          background-size pxToRem(18px) pxToRem(18px)
      &__delivery-price
        font-size 15px
        padding 5px 10px
        border solid 1px #d5dbe0
        min-width 100px
      &__form-title
        font-size 15px
        font-weight 500
        line-height 0.1
      &__save
        font-size 15px
        font-weight bold
        padding 9px 47px
        text-align center
        color white
        background-color #3c89f9
        border-width 0
        border-radius: 4px;

