body.creator-phonecase-selector
  background #f5f5f5
  #body
    margin-top 50px !important
  #creator-phonecase-selector-frame.don_frame
    .don_page
      padding 0 10px
      box-sizing border-box
      background transparent
      >.don_wrapper
        background transparent
        >.header
          background transparent
      .tab_buttons
        display flex
        -ms-flex-wrap wrap
        flex-wrap wrap
        padding-left 0
        margin-bottom 0
        list-style none
        position relative
        border 0
        button
          color #8c8e90
          display block
          padding .625rem 1.25rem
          line-height 1.54
          margin-bottom -1px
          border 1px solid transparent
          border-top-left-radius .25rem
          border-top-right-radius .25rem
          border-bottom 0
          margin-right .125rem
          background-color transparent
          background-clip padding-box
          outline none !important
          &[selected="true"]
            color #4e5155
            border-color rgba(24,28,33,0.06)
            border-bottom-color #fff
            background-color #fff
          &[selected="false"]:hover
            border-color rgba(24,28,33,0.06)
            border-bottom-color transparent
    .phone-case-cate_item
      padding 0 24px 80px
      background #fff
      flex-shrink 1
      border 1px solid rgba(24,28,33,0.06)
      background-clip padding-box
      box-shadow 0 1px 4px rgba(24,28,33,0.012)
.creator-phone-case-base-products
  input[type="text"]
    width 200px
    padding 4px
    margin 2px 0
  &__insert
    margin-bottom 40px
  &__item
    padding 20px 10px
    background #eee
    margin-bottom 20px
  &__flex
    display flex
    gap 20px
    &--align-center
      align-items center
  &__name
    font-size 20px
    gap 10px
    align-items center
    display flex
    font-weight bold
    margin-bottom 12px
  &__title
    font-size 16px
    font-weight 500
    margin 4px 0

.creator-phone-case-base-products-devices
  &__list
    margin 12px 0
    display grid
    grid-template-columns repeat(4, 1fr);
    grid-gap 20px
    align-items center
  &__item
    label
      cursor pointer
      display flex
      align-items center
      input
        margin 0 4px 0 0
    img
      display none
      width 50px
  &__bp-label-name
    word-break keep-all
    word-wrap break-word
  &__bp-name
    display flex
    align-items center
    gap 4px
    justify-content space-between
