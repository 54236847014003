.bds-manager
  padding 16px
  &__title
    margin-bottom 16px
  &__get
    margin-bottom 16px
    input
      width 500px
    *
      margin-right 16px
.bds-save
  padding 16px
  &__title
    margin-bottom 16px
  .bds-list__table-bp-price
  .bds-list__table-bp-size
  .bds-list__table-bp-option
  .bds-list__table-bp-face
    display none
.bds-check
  padding 32px
  &__title
    margin-bottom 16px
.bds-list
  > div
    margin-bottom 32px
  &__item
    margin-bottom 32px
    border 1px solid black
    padding 8px

.df_biz_application_list
  .body
    table
      td.comp-name
        cursor pointer


.df_biz_application_detail
  .pre
    white-space pre-wrap
  ul > li > a
    color #1b77ff
  textarea
    height 200px
  .mail-link
    text-decoration underline
