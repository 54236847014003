.df-scanning-menu-mode-selector
  .don_wrapper
    max-width 400px
    max-height 210px
.df-scanning-page-confirm
  padding 20px
  display flex
  justify-content center
  align-items center
  flex-direction column
  gap 16px
  &__selector-area
    display flex
    justify-content center
    align-items center
    box-sizing border-box
    gap 8px
  &__bottom-area
    padding 8px 16px 0
    display flex
    justify-content flex-end
    align-items  center
    box-sizing border-box
    width 100%
  button
    display flex
    justify-content center
    align-items center
    padding 8px 16px

