// modules/Df/Lgl/TrackingDetail/F/Style/Pc/index.styl
$gray-200 = #F2F2F2
$gray-300 = #DDDDDD
$gray-400 = #CCCCCC
$gray-500 = #A2A2A2
$gray-600 = #828282
$gray-700 = #4E4E4E
.don_frame[frame_name="/modules/Df/Lgl/TrackingDetail/S/Mui/frame.js"]
  > .don_wrapper
    width 1000px
    border-radius 12px
    > .body
      border-radius 12px
.don_tab[tab_name="/modules/Df/Lgl/TrackingDetail/S/Mui/tab.js"]
  .don_wrapper
    .header
      display flex
      align-items center
      border-bottom 1px solid $gray-300
      > h1
        display inline-flex
        font-size 18px
        font-weight 700
        margin 0
        padding 16px 20px 16px 24px
      strong
        font-width: 600
        margin-right 4px
    .body
      height 713px
      overflow-y auto
      overflow-x hidden
      box-sizing border-box
      padding 24px 40px 40px 40px
      > .box
        display flex
        flex-direction column
        align-items center
        > .information
          display flex
          gap 20px
          margin-top 14px
          > .information_item
            > strong
              margin-right 4px
      .table_wrapper
        height 550px
        margin-top 20px
        .text_center
          text-align center
        table
          width 100%
          border-radius 1em
          overflow hidden
          border 1px solid $gray-500
          border-collapse separate
          height 100%
          thead
            background-color $gray-200
            color $gray-700
            tr
              display table
              width 100%
              th
                padding 14.5px
                width 200px
          tbody
            display block
            width 100%
            height 100%
            overflow auto
            max-height 500px
            overscroll-behavior none
            tr
              display table
              width 100%
              td
                padding 14.5px
                width 200px
              &.no_data
                height 100%
                color $gray-400
                font-size 20px
            tr:not(:last-child)
              border-bottom 1px solid $gray-200
