html.simple_group
  #body
    margin 50px 100px 100px
    padding-top 50px
    .header
      > *
        margin-left 8px
    table
      border-spacing 0
      border-collapse collapse
      width 100%
      tr
        th
        td
          border 1px solid #000
          padding 20px
          &.th
            width 170px
          input
          textarea
            font-size 14px

    #simple_group_editor
    #simple_groups_data_editor
      .header
        button
          display none
          &.remove
            display inline-block
      &[type="create"] button.create
      &[type="edit"] button.edit
        display inline-block
