// modules/Df/OMP/CustomBadge/SavePop/F/Style/Pc/index.styl
.custom-badges-pop
  & > .don_wrapper
    width auto
    height auto
    max-width 480px
    max-height 420px
    border-radius 8px
    overflow hidden
    position relative
    .custom-badge__form-container
      padding 1.5rem
      .custom-badge__form-header
        display flex
        justify-content center
        align-items center
        position relative
        .btn-close
          position absolute
          right 0
