.don_frame[frame_name="/modules/Composite/Inspector/List/S/Mui/frame.js"]
  canvas
    width 500px
    height 500px
  .main
    display flex
    flex-direction column
    align-items center
    justify-content center
    > div
      margin-bottom 32px
    .composite_templates
      display flex
      flex-wrap wrap
      justify-content space-around
    .composite_result
      cursor pointer
    .composite_template
      margin-bottom 8px
      a
        text-decoration underline
        color #0000cc
      &[data-done_review="true"]
        a
          background #07ff00
        border 2px solid #07ff00

