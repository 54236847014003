// modules/Df/OMP/CustomBadge/F/Style/Pc/index.styl
.custom-badge__container
  padding 1.5rem
  .header
    display flex
    justify-content center
    align-items center
    position relative
    margin-bottom 16px
  .custom-badges__table
    margin-top 8px
