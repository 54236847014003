
body.folder_list_free .folder_list_area > .link a:nth-child(1)
body.folder_list_embro .folder_list_area > .link a:nth-child(2)
body.folder_list_shape .folder_list_area > .link a:nth-child(3)
body.folder_list_worker .folder_list_area > .link a:nth-child(4)
body.folder_list_bt21 .folder_list_area > .link a:nth-child(1)
body.folder_list_bnf .folder_list_area > .link a:nth-child(2)
body.folder_list_brawlstars .folder_list_area > .link a:nth-child(3)
body.folder_list_grafflex .folder_list_area > .link a:nth-child(4)
body.folder_list_wdzy .folder_list_area > .link a:nth-child(5)
body.folder_list_truz .folder_list_area > .link a:nth-child(6)
  font-weight bold
  background-color #ddd

body.folder_detail
body.folder2_detail
  button
    border 1px solid #888


.folder_list_area
  &[mode="delete"]
    .btn_delete_mode
      background-color #f00
    .folder_item .btns .btn_delete
      display inline-block !important
  width 1150px
  margin 80px auto 0
  padding-bottom 100px
  h2
    margin-bottom 10px !important
  > .link
    margin-bottom 10px
    a
      display inline-block
      width 100px
      margin-right 5px
      padding 10px 10px 8px
      text-align center
      background-color #eee
  > .controls
    position relative
    padding 10px
    background-color #eee
    > *
      vertical-align middle
    input
      width 30%
      padding 0 10px
      height 30px
      line-height 30px
    button
      margin-left 10px
      height 30px
      line-height 30px
      border 1px solid #888
    .btn_delete_mode
      position absolute
      top 10px
      right 90px
      bottom 10px
    .btn_order_align
      position absolute
      top 10px
      right 10px
      bottom 10px
  .folder_pgn
    position relative
    padding 20px auto 0
    overflow hidden
    .don_pagination
      margin-top 0
      position absolute
      top 10px
      right -2px
      button
        margin 0 5px
    .folder_list
      margin-top 60px
      .folder_item
        display inline-block
        position relative
        width 220px
        height 220px
        margin 0 10px 10px 0
        border 1px solid #eee
        background-position 50% 40%
        background-size auto 60%
        background-repeat no-repeat
        &:nth-child(5n)
          margin-right 0
        > a
          display block
          width 100%
          height 100%
        .name
          position absolute
          left 0
          right 0
          bottom 0
          height 24px
          line-height 24px
          text-align center
          background-color #eee
        .name_en
          position absolute
          left 0
          right 0
          bottom 24px
          height 24px
          line-height 24px
          text-align center
          background-color #eee
        .btns
          position absolute
          top 0
          right 0
          .btn_delete
            display none


.df_folder_detail
  > .don_wrapper > .header
    display none
  .don_page
    > .don_wrapper > .header
      display none
.folder_detail_area
  padding-bottom 100px
  width 1150px
  margin 40px auto 0
  .folder_info
    position relative
    min-height 150px
    padding-bottom 26px
    background-color #eee
    .thumbnail
      position absolute
      top 10px
      left 10px
      width 100px
      height 100px
      text-align center
      .img
        width 100%
        height 100%
        img
          max-width 80%
          max-height 80%
      input
        display none
    .name
    .name_en
    .name_jp
    .due_at
      padding 10px 0 0 160px
      > *
        vertical-align middle
      span
        display inline-block
        width 120px
      input
        padding 0 10px
        height 30px
        line-height 30px
    .due_at
      position absolute
      top 10px
      left 500px
      padding 0
      i
        margin-left 5px
        font-style normal
      input[type="number"]
        width 40px
    .public
      padding 20px 0 0 160px
    button.btn_save_folder
      position absolute
      left 284px
      bottom 16px
      height 30px
      line-height 30px
  .image_controls
    margin-top 10px
    padding 10px
    background-color #eee
    .add_group_image
    .add_image
      display inline-block
      margin-right 20px
      > *
        display inline-block
        margin-right 10px
    input
      padding 0 10px
      height 30px
      width 300px
      line-height 30px
  .image_list
    &[mode="delete"]
      .btn_delete_mode
        background-color #f00
      ul li .btns .btn_delete
        display inline-block !important
    > .head
      position relative
      .buttons
        position absolute
        top 0
        right 0
        button
          margin-left 5px
    > .alert
      font-size 13px
      background-color #fff0f0
      opacity 0
      padding 0
      height 0
      transition opacity 0.6s, padding 0.6s
    ul
      margin 0
      padding 0
      list-style none
      li
        display inline-block
        position relative
        width 200px
        height 245px
        margin 0 10px 10px 0
        border 1px solid #eee
        background-position center 40%
        background-size auto 30%
        background-repeat no-repeat
        &[is_group="true"]
          border-color red
          background none !important
          .thumbs
            display block
            position absolute
            top 24px
            left 10px
            right 10px
            bottom 10px
            text-align center
            padding-top 10px
            background-color #fff
            &[group_length="0"]
              display none
            .thumb
              display inline-block
              width 30%
              height 30%
              margin 0 8px 8px
              background-repeat no-repeat
              background-size contain
              background-position center center
        > a
          display block
          width 100%
          height 100%
        .thumbs
          display none
        .name
          position absolute
          left 0
          right 0
          bottom 24px
          height 24px
          line-height 24px
          text-align center
          background-color #ddd
        .price
          position absolute
          left 0
          right 0
          bottom 0
          height 24px
          line-height 24px
          text-align center
          background-color #f1f1f1
        .resolution
          position absolute
          left 0
          right 0
          bottom 48px
          height 24px
          line-height 24px
          text-align center
          background-color #f1f1f1
        .btns
          position absolute
          top 0
          right 0
          button
            margin-left 5px
            display none
          .btn_group_make
          .btn_modify
          .btn_delete
          .btn_image_download
            display inline-block
          .btn_delete
            display none
.folder_detail_area
  &[is_state="selection_images"]
    .image_list
      ul li
        &[group_image="true"]
          border-color #9acd32
          .btns
            .btn_image_select
              display none
            .btn_image_cancel
              display inline-block
        &[is_group="true"]
          display none
        &[group_first="true"]
          display inline-block
          border-color color_active
          .btns .btn_image_select
            display none
        &[group_image="true"]
          .btns .btn_group_select
            display none
        .btns
          > button
            display none
          .btn_image_select
            display inline-block


body.folder2_detail
  .df_folder2_detail
    position relative !important
  #body
    width auto
  .folder2_detail_area
    margin 0 30% 0 30px
    .folder_info
      position relative
      height 150px
      background-color #eee
      .thumbnail
        position absolute
        top 10px
        left 10px
        width 100px
        height 100px
        img
          width 100%
          height 100%
        input
          display none
      .name
        padding 50px 0 0 150px
        > *
          vertical-align middle
        input
          padding 0 10px
          height 30px
          line-height 30px
        button
          margin-left 10px
          height 30px
          line-height 30px
      .public
        padding 20px 0 0 150px
    .get_image
      position absolute
      top 0
      right 0
      margin 10px
    .image_list
      position fixed
      top 330px
      left 30px
      right 30%
      bottom 20px
      h4
        position absolute
        top -40px
        left 0
      .btn_order
        position absolute
        top -30px
        right 20px
      .scroll_body
        height 100%
        padding-bottom 10px
        overflow auto
      ul
        margin 0
        padding 0
        list-style none
        li
          display inline-block
          position relative
          width 180px
          height 220px
          margin 0 10px 10px 0
          border 1px solid #eee
          box-sizing border-box
          background-position center 30%
          background-size 60% auto
          background-repeat no-repeat
          &[is_group="true"]
            border-color red
            background none !important
            .thumbs
              display block
              position absolute
              top 10px
              left 10px
              right 10px
              bottom 10px
              text-align center
              padding-top 10px
              background-color #fff
              &[group_length="0"]
                display none
              .thumb
                display inline-block
                width 40%
                height 40%
                margin 0 5px 5px
                background-repeat no-repeat
                background-size 60% auto
                background-position center center
          &.temp
            opacity 0.4
            border-style dashed
            border-color #666
            > *
              display none
          > a
            display block
            width 100%
            height 100%
          .name
            position absolute
            left 0
            right 0
            bottom 24px
            height 24px
            line-height 24px
            text-align center
            background-color #ddd
          .price
            position absolute
            left 0
            right 0
            bottom 0
            height 24px
            line-height 24px
            text-align center
            background-color #f1f1f1
          .resolution
            position absolute
            left 0
            right 0
            bottom 48px
            height 24px
            line-height 24px
            text-align center
            background-color #f1f1f1
          .btns
            position absolute
            top 0
            right 0
            > button
              display none
            button.btn_delete
              display inline-block
html.folder2_detail
  overflow hidden
  body
    overflow hidden

#body .df_folder2_image_list
  display block !important
  position fixed
  top 300px
  left 30px
  right 30%
  bottom 20px
  .don_tabs
    position static
  .don_tab
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    min-height auto !important
    height auto !important
    .don_page
      position absolute
      top 0
      left 0
      right 0
      bottom 0
      overflow auto
  #select_folder2_image_list
    .don_is_item
      display inline-block
      position relative
      width 200px
      height 224px
      margin 0 10px 10px 0
      border 1px solid #eee
      background-position center 40%
      background-size 70% auto
      background-repeat no-repeat
      &.temp
        opacity 0.4
        border-style dashed
        border-color #666
        > *
          display none
      > a
        display block
        width 100%
        height 100%
      .name
        position absolute
        left 0
        right 0
        bottom 24px
        height 24px
        line-height 24px
        text-align center
        background-color #ddd
      .price
        position absolute
        left 0
        right 0
        bottom 0
        height 24px
        line-height 24px
        text-align center
        background-color #f1f1f1
      .resolution
        position absolute
        left 0
        right 0
        bottom 48px
        height 24px
        line-height 24px
        text-align center
        background-color #f1f1f1
      .btns
        position absolute
        top 0
        right 0
        .btn_modify
          display none

#body .df_folder_layer
  position fixed
  top 50px
  right 0
  left 70%
  bottom 0
  border-left 1px solid #eee
  > .don_head
    display none
  .don_tabs
  .don_tab
    position absolute
    top 0
    left 0
    right 0
    bottom 0
  [page_name="df.free_folder_page"]
    position absolute
    top 20px
    left 0
    right 0
    bottom 0
    overflow auto
  [page_name="df.free_image_page"]
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    overflow auto
  .folder_list
    margin 0 0 0 6%
    text-align center
    .folder_item
      position relative
      display inline-block
      margin -4px 4.2% 4% 0
      width 29%
      cursor pointer
      &:active
        .image
          > span
            background-color #fafcff
            border-color #b8d9fd
        .name
          color color_active
      .image
        position relative
        width 99%
        padding 0
        padding-top 100%
        margin-left 1px
        margin-top 5px
        > span
          position absolute
          display block
          width 90%
          height 86.5%
          border-radius 6px
          border 1px solid #dee2e6
          background-color #f8f9fa
        .l1
          top 4px
          left 0
          z-index 3
          img
            position absolute
            top 0
            left 0
            right 0
            bottom 0
            margin auto
            max-width 70%
            max-height 70%
        .l2
          top 2px
          left 2px
          z-index 2
        .l3
          top 0
          left 4px
          z-index 1
      .name
        padding 3px 0
        text-align center
        font-size 14px
        color #343a40
        height 17px
        overflow hidden
  .folder_detail_area
    > .head
      position relative
      height 30px
      padding 15px 5%
      z-index 2
      background-color #fff
      .btn_prev
        position absolute
        top 15px
        left 5%
        display block
        cursor pointer
        width 30px
        height 30px
        text-align center
        background url(//s3.marpple.co/file/u_37/2017/11/original/f_802_1509694903685_Zg3VcOtmG2zHvJg4F0i.png) no-repeat
        background-size 30px auto
        &:active
          border-radius 15px
          background-color #f1f1f1
      .folder_img
        position absolute
        top 15px
        left 17%
        width 100px
        bottom 15px
        background-position left center
        background-size auto 80%
        background-repeat no-repeat
      .name
        position absolute
        top 22px
        left 50px
        right 50px
        text-align center
    > .body
      padding 0 0 0 5%
  .maker_image_list
    .image_item
      position relative
      display inline-block
      margin 0 3% 3% 0
      width 21%
      cursor pointer
      border 1px solid #dee2e6
      border-radius 6px
      .folder2_name
        display none
        position absolute
        bottom 0
        left 0
        right 0
        height 20px
        line-height 20px
        text-align center
        overflow hidden
        background-color #fff
        border-bottom-left-radius 6px
        border-bottom-right-radius 6px
      &[empty_folder2="false"]
        .folder2_name
          display block
      &[is_group="true"]
        border-color #b8d9fd
        background-color #f7fbff
        .thumbs
          padding 100% 0 0
          display block
          text-align center
          &[group_length="0"]
            display none
          .thumb
            display inline-block
            width 40%
            height 40%
            margin 0 5px 5px
            background-repeat no-repeat
            background-size 80% auto
            background-position center center
            &:nth-child(1)
              position absolute
              top 6%
              left 3%
            &:nth-child(2)
              position absolute
              top 6%
              right 3%
            &:nth-child(3)
              position absolute
              bottom 2%
              left 3%
            &:nth-child(4)
              position absolute
              bottom 2%
              right 3%
        .image
          display none
      &:nth-child(4n)
        margin-right 0
      &.selected
      &:active
        border-color #b8d9fd
        background-color #fafcff
      .thumbs
        display none
      .image
        padding 100% 0 0
        background #fff url(//s3.marpple.co/file/guest/2017/10/original/f_710_1507456502604_O2nn8NXb7wq0y0SdR1WTv.png) repeat left top
        img
          position absolute
          top 0
          left 0
          right 0
          bottom 0
          max-width 70%
          max-height 70%
          margin auto
          vertical-align top
      .name
        display none
